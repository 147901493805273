/* eslint-disable react/no-array-index-key -- React Element array don't have identifier, so we need to disable this lint rule. */
import cx from 'clsx';
import { type ReactElement } from 'react';
import { Dialog, Modal, ModalOverlay, Separator } from 'react-aria-components';
import { Button, CloseIcon } from '@repo/design-system-kit';
import ENV from 'qonto/config/environment';
import styles from './styles.strict-module.css';

const isTest = ENV.environment === 'test';

export interface SidebarSlots {
  header?: ReactElement;
  contents?: ReactElement[];
}

export interface SidebarProps {
  title: string;
  isOpened: boolean;
  onClose: () => void;
  slots?: SidebarSlots;
}

export function Sidebar({ isOpened, onClose, slots, title, ...props }: SidebarProps): ReactElement {
  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <div> component with 'ember-testing' id)
  const TEST_PORTAL_CONTAINER = document.getElementById('ember-testing') ?? undefined;

  return (
    <ModalOverlay
      UNSTABLE_portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
      className={styles.overlay}
      isDismissable
      isOpen={isOpened}
      onOpenChange={onClose}
    >
      <Modal className={styles.sidebar} data-test-sidebar {...props}>
        <Dialog aria-label={title} className={styles.dialog}>
          <section className={styles.header} data-test-sidebar-header>
            <div className={styles['top-header']} data-test-sidebar-top-header>
              <h4 className="ml-8 title-4" data-test-sidebar-top-header-title>
                {title}
              </h4>
              <Button
                data-test-sidebar-top-header-close-button
                iconOnly
                onPress={onClose}
                size="small"
                variant="tertiary"
              >
                <CloseIcon />
              </Button>
            </div>

            <div className={styles.container} data-test-sidebar-slot-header>
              {slots?.header}
            </div>
          </section>

          {slots?.contents?.map((content, index) => (
            <section
              className={styles.container}
              data-test-sidebar-slot-content={`sidebar-content-${index + 1}`}
              key={`sidebar-content-${index}`}
            >
              <Separator
                className={cx(styles.separator, 'mb-16')}
                data-test-sidebar-content-separator
              />
              {content}
            </section>
          ))}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
}
