import { type ReactNode } from 'react';

export function Institution(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m.504 4.599 7.221-3.057a.57.57 0 0 1 .43 0l7.349 3.057m-15 .075v1.814h15V4.674M2.558 6.711v5.818M6.19 6.711v5.818M9.82 6.711v5.818m3.632-5.818v5.818m-12.95.157h15V14.5h-15v-1.814Z"
      />
    </svg>
  );
}
