import { type ReactNode } from 'react';

export function Computer(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none">
      <path
        fill="currentColor"
        d="M6.016 15.004a.5.5 0 1 0 0 1v-1Zm5.982 1a.5.5 0 1 0 0-1v1Zm-3.527-3.128v2.5h1v-2.5h-1ZM15.33.004H2.606v1h12.727v-1Zm-12.726 0C1.702.004.968.737.968 1.64h1c0-.35.286-.636.637-.636v-1ZM.968 11.672c0 .903.734 1.636 1.637 1.636v-1a.637.637 0 0 1-.637-.636h-1Zm14.364 1.636c.902 0 1.636-.733 1.636-1.636h-1c0 .35-.286.636-.636.636v1ZM16.968 1.64c0-.903-.733-1.636-1.636-1.636v1c.35 0 .636.286.636.636h1Zm-16 0v8.235h1V1.64h-1Zm16 8.235V1.64h-1v8.235h1Zm-15.5.5h15v-1h-15v1Zm-.5-.5v1.796h1V9.876h-1Zm1.637 3.433h12.727v-1H2.605v1Zm14.363-1.636V9.874h-1v1.796h1ZM6.016 16.003h5.982v-1H6.016v1Z"
      />
    </svg>
  );
}
