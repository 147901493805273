import type { Amount } from 'qonto/react/models/amount';
import type { CashflowPeriodRate } from 'qonto/react/models/cash-flow-period';
import type { CashflowParentCategory } from './cash-flow-category';

export interface TimeframeInterval {
  inclusive_start_date: string; // ISO UTC date
  exclusive_end_date: string; // ISO UTC date
}

export interface CategoryColumnData {
  inflow: CashflowParentCategory[];
  outflow: CashflowParentCategory[];
}

export interface CashflowDataPoint {
  transactions_count: number;
  amount_sum: Amount;
}

export interface CashflowSubcategoryDataPoint extends CashflowDataPoint {
  subcategory_id: string;
}

export interface CashflowCategoryDataPoint extends CashflowDataPoint {
  category_id: string;
  subcategories_data_points?: CashflowSubcategoryDataPoint[];
}

export interface CashflowSideDatapoint extends CashflowDataPoint {
  categories_data_points: CashflowCategoryDataPoint[];
  growth_rate_over_previous_period: number;
}

export interface CashflowVAT {
  credit_amount: Amount;
  debit_amount: Amount;
}

export interface CashflowTimeframeDatapoint {
  transactions_count: number;
  starting_amount: Amount;
  ending_amount: Amount;
  inflows: CashflowSideDatapoint;
  outflows: CashflowSideDatapoint;
  vat?: CashflowVAT;
}

export interface CashflowTimeframe extends TimeframeInterval {
  cash_flow_data: CashflowTimeframeDatapoint;
}

export interface CashflowTimeseries {
  timeframe: TimeframeInterval;
  categories_data: CategoryColumnData;
  timeframes: CashflowTimeframe[];
}

export interface CashflowTimeframeRequest {
  start_year_month: string; // YYYY-MM string date format
  end_year_month: string; // YYYY-MM string date format
}

export interface CashflowTimeseriesRequest extends CashflowTimeframeRequest {
  bank_account_ids: string[] | undefined;
  aggregation_interval?: CashflowPeriodRate;
}

export const CASHFLOW_REQUEST_DATE_FORMAT = 'YYYY-MM';
