export const getDisplayFilename = (fileName: string | undefined): string => {
  if (!fileName) {
    return '';
  }
  const extensionIndex = fileName.lastIndexOf('.');
  if (extensionIndex === -1 || extensionIndex <= 20) {
    return fileName;
  }
  return `${fileName.slice(0, 20)}…${fileName.slice(extensionIndex + 1)}`;
};
