import type { ComponentPropsWithRef, ReactNode } from 'react';

export function ArrowRight(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.61366 4.15028L10.0287 7.4718C10.1691 7.61228 10.248 7.80271 10.248 8.00125C10.248 8.19979 10.1691 8.39022 10.0287 8.53069L6.61366 11.8497C6.56709 11.8973 6.51148 11.9351 6.45009 11.9609C6.3887 11.9867 6.32276 12 6.25616 12C6.18955 12 6.12362 11.9867 6.06223 11.9609C6.00083 11.9351 5.94522 11.8973 5.89866 11.8497C5.80553 11.7561 5.75326 11.6295 5.75326 11.4976L5.75201 4.50366C5.75201 4.43802 5.76497 4.37303 5.79013 4.31239C5.81529 4.25175 5.85217 4.19667 5.89866 4.15028C5.94522 4.10271 6.00083 4.06491 6.06223 4.0391C6.12362 4.01329 6.18955 4 6.25616 4C6.32276 4 6.3887 4.01329 6.45009 4.0391C6.51148 4.06491 6.56709 4.10271 6.61366 4.15028Z"
        fill="currentColor"
      />
    </svg>
  );
}
