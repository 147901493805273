import { useEffect, type ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import { clsx } from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedMessage } from 'react-intl';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';

interface ErrorScreenProps {
  onCtaClick: () => void;
}

export function ErrorScreen({ onCtaClick }: ErrorScreenProps): ReactNode {
  const segment = useEmberService('segment');

  useEffect(() => {
    segment.track('nrc_failure');
  }, [segment]);

  return (
    <div className={styles.wrapper}>
      <StaticThemedAsset
        assetPath="/illustrations/nrc-payments/result-error.svg"
        className={clsx(styles['illustration-error'], 'mb-32')}
        data-test-nrc-payments-error-illustration
      />

      <h1 className={clsx(styles.title, 'mb-8', 'title-2')} data-test-nrc-payments-error-title>
        <FormattedMessage id="aeat.pay.review.failure.title" />
      </h1>

      <p className={clsx(styles.subtitle, 'body-2 mb-32')} data-test-nrc-payments-error-subtitle>
        <FormattedMessage id="aeat.pay.review.failure.subtitle" />
      </p>

      <Button data-test-nrc-payments-error-cta onPress={onCtaClick} type="button" variant="primary">
        <FormattedMessage id="btn.close" />
      </Button>
    </div>
  );
}
