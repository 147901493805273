import cx from 'clsx';
import { type ReactNode } from 'react';
import styles from './styles.strict-module.css';

interface MemberProps {
  name: string;
  teamName: string;
}

export function Member({ name, teamName }: MemberProps): ReactNode {
  return (
    <div className={styles.cellContent} data-test-task-cell-member>
      <div className={styles.info}>
        <div className={styles.title} data-test-member-cell-member-name>
          {name}
        </div>
        <div className={cx(styles.subtitle, 'caption')} data-test-member-cell-team-name>
          {teamName}
        </div>
      </div>
    </div>
  );
}
