import cx from 'clsx';
import type { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './styles.strict-module.css';

export function ProcessingRepaymentInfo(): ReactElement {
  return (
    <div className={styles['processing-repayment']} data-test-processing-repayment-info>
      <div
        className={cx(styles['processing-repayment-title'], 'body-1')}
        data-test-processing-repayment-info-title
      >
        <FormattedMessage id="financing.pay-later.cockpit.details.timeline.processing-title" />
      </div>
      <div className={styles['processing-repayment-description']}>
        <div>
          <img
            alt=""
            className={styles['processing-repayment-illustration']}
            data-test-processing-repayment-info-illustration
            src="/illustrations/attachments/pending.svg"
          />
        </div>
        <p className="body-2" data-test-processing-repayment-info-description>
          <FormattedMessage id="financing.pay-later.cockpit.details.timeline.processing-description" />
        </p>
      </div>
    </div>
  );
}
