import cx from 'clsx';
import { MEMBER_STATUS, ROLES } from 'qonto/constants/membership';
import { StopOutlined } from 'qonto/react/assets/icons/stop-outlined';
import { LetterOutlined } from 'qonto/react/assets/icons/letter-outlined';
import { UserOutlined } from 'qonto/react/assets/icons/user-outlined';
import type { Membership } from 'qonto/react/models/membership';
import styles from './styles.strict-module.css';

const MEMBERS_SHOWN = 4;
const ROLES_FILTER_VALUES = {
  [ROLES.OWNER]: 1,
  [ROLES.ADMIN]: 2,
  [ROLES.MANAGER]: 3,
  [ROLES.REPORTING]: 4,
  [ROLES.EMPLOYEE]: 5,
};

interface ShowMembersProps {
  members: Membership[];
}

export function ShowMembers({ members }: ShowMembersProps): JSX.Element {
  const sortedMembers = members.toSorted((member1: Membership, member2: Membership) => {
    const member1Role = ROLES_FILTER_VALUES[member1.role];
    const member2Role = ROLES_FILTER_VALUES[member2.role];

    if (member1Role === member2Role) {
      if (member1.firstName < member2.firstName) {
        return -1;
      } else if (member1.firstName > member2.firstName) {
        return 1;
      }
      return 0;
    }

    if (member1Role && member2Role) {
      return member1Role - member2Role;
    }

    return 0;
  });

  const sortedMembersToDisplay = sortedMembers.slice(0, MEMBERS_SHOWN);

  const renderStatusImage = (member: Membership): JSX.Element => {
    switch (member.status) {
      case MEMBER_STATUS.INVITABLE:
        return <UserOutlined className={styles.invitables} data-test-user />;
      case MEMBER_STATUS.REVOKED:
        return <StopOutlined data-test-status />;
      case MEMBER_STATUS.PENDING:
        return <LetterOutlined data-test-email />;
      default:
        return (
          <img
            alt={`${member.firstName} ${member.lastName}`}
            data-test-image
            src={member.avatar?.fileUrl}
          />
        );
    }
  };

  return (
    <div className={styles.container}>
      {sortedMembersToDisplay.map(member => (
        <div className={styles['member-icon']} data-test-member={member.firstName} key={member.id}>
          {renderStatusImage(member)}
        </div>
      ))}
      {sortedMembers.length > MEMBERS_SHOWN && (
        <div
          className={cx(styles['remaining-memberships-count'], 'caption-bold')}
          data-test-membership-count
        >
          <p>+{sortedMembers.length - MEMBERS_SHOWN}</p>
        </div>
      )}
    </div>
  );
}
