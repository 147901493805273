import { type ReactNode } from 'react';
import { Comment, NotificationDot } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface CommentsCellProps {
  textSlot: ReactNode;
  iconSlot?: ReactNode;
  showNotificationDot?: boolean;
}

export function CommentsCellRoot({
  textSlot,
  iconSlot,
  showNotificationDot = false,
}: CommentsCellProps): ReactNode {
  return (
    <div className={styles['cell-container']}>
      <div className={styles['icons-container']} data-testid="icon-content">
        {iconSlot ? (
          iconSlot
        ) : (
          <Comment aria-hidden className={styles['comment-icon']} data-testid="comment-icon" />
        )}
        {showNotificationDot ? (
          <NotificationDot
            aria-hidden
            className={styles['status-icon']}
            data-testid="notification-dot"
          />
        ) : null}
      </div>
      <div className="body-2" data-testid="text-content">
        {textSlot}
      </div>
    </div>
  );
}
