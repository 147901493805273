import { type ReactNode } from 'react';

export function Key(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M7.712 6.6h7.827L15 7.93l-1.175.799-.484-.445-.718.445-.644-.445-.688.445-.635-.445-.838.88H7.891M3.917 7.28a.817.817 0 0 1 .19.921.853.853 0 0 1-.323.38.9.9 0 0 1-.974 0 .853.853 0 0 1-.324-.38.817.817 0 0 1 .19-.921.88.88 0 0 1 .62-.248.906.906 0 0 1 .621.248Zm.253 4.217c2.005 0 3.63-1.567 3.63-3.5s-1.625-3.5-3.63-3.5S.54 6.063.54 7.996s1.625 3.5 3.63 3.5Z"
      />
    </svg>
  );
}
