import type {
  CashflowCategories,
  CashflowCategory,
  CashflowParentCategory,
} from 'qonto/react/models/cash-flow-category';
import { camelizeKeys } from 'qonto/react/api/utils/camelize-keys';

export function getFlatCategories(categories: CashflowCategories): CashflowCategory[] {
  return [...categories.inflow, ...categories.outflow].flatMap(category => [
    category,
    ...(category.subcategories ?? []),
  ]);
}

export const camelizeWithData = (
  cashFlowCategories: CashflowParentCategory[]
): CashflowParentCategory[] => {
  return [...cashFlowCategories].map((category: CashflowParentCategory) => {
    const camelizedCategory = camelizeKeys(category);

    if (camelizedCategory.subcategories?.length) {
      camelizedCategory.subcategories = camelizedCategory.subcategories.map(subcategory => ({
        ...subcategory,
        iconKey: camelizedCategory.iconKey,
      }));
    }

    return camelizedCategory;
  });
};

export const formatRawCashFlowCategories = (
  rawCategories: CashflowCategories
): CashflowCategories => {
  return {
    inflow: camelizeWithData(rawCategories.inflow),
    outflow: camelizeWithData(rawCategories.outflow),
  };
};
