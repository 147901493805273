import type { ComponentPropsWithRef, ReactNode } from 'react';

export function Note(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.625 7.875h-5.25A.371.371 0 0 1 3 7.5c0-.21.165-.375.375-.375h5.25c.21 0 .375.165.375.375s-.165.375-.375.375Zm0-3h-5.25A.371.371 0 0 1 3 4.5c0-.21.165-.375.375-.375h5.25c.21 0 .375.165.375.375s-.165.375-.375.375Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M10.5.75v10.5h-9V.75h9Zm0-.75h-9a.752.752 0 0 0-.75.75v10.5c0 .412.337.75.75.75h9c.412 0 .75-.338.75-.75V.75A.752.752 0 0 0 10.5 0Z"
      />
    </svg>
  );
}
