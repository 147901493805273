import { type ReactNode } from 'react';

export function Atm(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        fill="currentColor"
        d="M12.892 4.721a.5.5 0 0 0 0-1v1Zm-9.785-1a.5.5 0 0 0 0 1v-1Zm3.96.81a.5.5 0 0 0-1 0h1Zm-1 9.839a.5.5 0 0 0 1 0h-1Zm6.526-9.921v-.5h-1v.5h1Zm-8.174 0v-.5h-1v.5h1Zm-.71 2.82h.5v-1h-.5v1Zm8.727-1h-.5v1h.5v-1Zm.456-2.548H3.107v1h9.785v-1Zm-6.825.81v9.839h1V4.53h-1Zm5.526-.082v9.107h1V4.449h-1Zm-.441 9.548H4.86v1h6.292v-1Zm-6.733-.441V4.449h-1v9.107h1Zm-.71-7.287H.741v1h2.966v-1Zm-2.706.273V1.724h-1v4.818h1ZM.742 1.997h14.522v-1H.742v1Zm14.261-.273v4.818h1V1.724h-1Zm.261 4.545h-2.828v1h2.828v-1Zm-.261.273c0-.174.14-.273.261-.273v1a.733.733 0 0 0 .739-.727h-1Zm.261-4.545a.267.267 0 0 1-.261-.273h1a.733.733 0 0 0-.739-.727v1ZM1.003 1.724c0 .174-.14.273-.261.273v-1a.733.733 0 0 0-.739.727h1ZM.742 6.27c.12 0 .261.099.261.273h-1c0 .425.355.727.739.727v-1Zm4.118 7.728a.441.441 0 0 1-.441-.441h-1c0 .796.645 1.441 1.441 1.441v-1Zm6.733-.441a.441.441 0 0 1-.441.441v1c.796 0 1.441-.645 1.441-1.441h-1Z"
      />
    </svg>
  );
}
