import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { VAT_STATUS } from 'qonto/constants/vat';
import styles from './styles.strict-module.css';

export type ValueOf<T> = T[keyof T];

type VatStatusType = ValueOf<typeof VAT_STATUS>;

interface EmptyStateProps {
  vat?: {
    status: VatStatusType;
  };
}

export function EmptyState({ vat }: EmptyStateProps): ReactNode {
  return (
    <div data-testid="multi-vat-empty">
      <p className={styles['empty-state-message']}>
        <FormattedMessage
          id={
            vat?.status === VAT_STATUS.undetected
              ? 'transactions.sidebar.bookkeeping.vat-not-detected'
              : 'transactions.sidebar.bookkeeping.no-vat'
          }
        />
      </p>
    </div>
  );
}
