import { type ReactNode, type SVGProps } from 'react';

export function IconStars(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.366 1.957c.208-.613 1.086-.613 1.294 0l.532 1.57h1.738c.614 0 .98.819.392 1.228l-1.386.965.527 1.553a.668.668 0 0 1-.255.767.685.685 0 0 1-.785 0l-1.41-.981-1.41.98a.685.685 0 0 1-.785 0 .668.668 0 0 1-.255-.766L6.09 5.72l-1.386-.965c-.588-.41-.222-1.228.392-1.228h1.737l.533-1.57Zm.647 1.204-.307.906a.682.682 0 0 1-.647.46h-.933l.736.512c.24.167.352.477.254.767l-.293.863.797-.555a.689.689 0 0 1 .786 0l.796.555-.292-.863a.671.671 0 0 1 .254-.767l.735-.512h-.932a.682.682 0 0 1-.647-.46l-.307-.906ZM2.959 8.29c.208-.614 1.086-.614 1.294 0l.533 1.57h1.737c.614 0 .98.818.393 1.227l-1.387.965.527 1.553a.668.668 0 0 1-.255.767.685.685 0 0 1-.785 0l-1.41-.981-1.41.981a.685.685 0 0 1-.785 0 .668.668 0 0 1-.254-.767l.526-1.553-1.386-.965C-.291 10.68.075 9.861.69 9.861h1.737l.532-1.57Zm.647 1.203L3.3 10.4a.682.682 0 0 1-.647.46H1.72l.735.512c.24.168.353.477.254.767l-.292.863.796-.555a.689.689 0 0 1 .786 0l.797.555-.293-.863a.671.671 0 0 1 .255-.767l.735-.511H4.56a.682.682 0 0 1-.647-.46l-.307-.907Zm8.14-1.203c.209-.614 1.087-.614 1.295 0l.532 1.57h1.737c.615 0 .98.818.393 1.227l-1.386.965.527 1.553a.668.668 0 0 1-.255.767.685.685 0 0 1-.785 0l-1.41-.981-1.41.981a.685.685 0 0 1-.786 0 .668.668 0 0 1-.254-.767l.527-1.553-1.387-.965c-.587-.409-.221-1.227.393-1.227h1.737l.533-1.57Zm.648 1.203-.307.906a.682.682 0 0 1-.647.46h-.933l.736.512c.24.168.352.477.254.767l-.293.863.797-.555a.689.689 0 0 1 .786 0l.796.555-.292-.863a.671.671 0 0 1 .254-.767l.735-.511h-.932a.682.682 0 0 1-.647-.46l-.307-.907Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
