import { type ReactNode } from 'react';
import cx from 'clsx';
import {
  IconArrowBottomOutlined,
  IconChevronUpDownOutlined,
} from '@repo/design-system-kit/assets/icons';
import styles from './styles.strict-module.css';

interface SortIconProps extends React.HTMLAttributes<HTMLSpanElement> {
  isActive?: boolean;
  isAscending?: boolean;
  className?: string;
}

export function SortIcon({ isActive, isAscending, className, ...props }: SortIconProps): ReactNode {
  function getIcon(): ReactNode {
    if (isActive && isAscending) {
      return (
        <IconArrowBottomOutlined
          className={cx(styles.active, styles.ascending)}
          data-test-sort-asc
        />
      );
    }

    if (isActive) {
      return <IconArrowBottomOutlined className={styles.active} data-test-sort-desc />;
    }

    return <IconChevronUpDownOutlined data-test-sort-default />;
  }

  return (
    <span aria-hidden="true" className={cx(styles.icon, className)} {...props}>
      {getIcon()}
    </span>
  );
}
