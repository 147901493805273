import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { RECURRENCE } from 'qonto/constants/invoice-subscriptions';

interface FrequencyProps {
  frequency?: {
    value?: number;
    recurrence: RECURRENCE;
  };
}

export function Frequency({ frequency }: FrequencyProps): ReactNode {
  if (!frequency) return null;

  const { value, recurrence } = frequency;

  if (value && value > 1) {
    return (
      <FormattedMessage
        id="recurring-invoices.list.table.row.cell.custom-frequency"
        values={{
          number: value,
          frequency: recurrence,
        }}
      />
    );
  }

  const translationKeys: Record<RECURRENCE, string> = {
    [RECURRENCE.WEEKLY]: 'recurring-invoices.frequency.option.weekly',
    [RECURRENCE.MONTHLY]: 'recurring-invoices.frequency.option.monthly',
    [RECURRENCE.QUARTERLY]: 'recurring-invoices.frequency.option.quarterly',
    [RECURRENCE.YEARLY]: 'recurring-invoices.frequency.option.yearly',
  };

  return <FormattedMessage id={translationKeys[recurrence]} />;
}
