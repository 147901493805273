import { type ReactNode } from 'react';

export function MoneyBag(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.177 3.278H6.82c-.447 0-.875-.146-1.191-.407-.316-.26-.494-.614-.494-.983 0-.368.178-.722.494-.982.316-.261.744-.407 1.19-.407 1.349.833 2.36 0 2.36 0 .22 0 .44.035.644.105.204.07.39.173.546.302.157.129.281.282.366.45.084.17.128.35.128.532 0 .369-.178.723-.494.983-.315.26-.744.407-1.19.407ZM1.99 12.63c-.8.292-1.151 1.119-.901 1.896.2.584.751.924 1.402.973h11.016c.851 0 1.502-.68 1.502-1.459 0-.583-.4-1.117-1.001-1.36V8.645c0-3.402-3.004-5.346-6.009-5.346-3.004 0-6.009 1.944-6.009 5.346v3.986Z"
      />
      <path
        stroke="currentColor"
        strokeWidth=".5"
        d="M5.224 9.802h.542c-.06-.31-.06-.628 0-.938h-.542a.17.17 0 0 1-.117-.046.152.152 0 0 1-.049-.11c0-.042.018-.082.049-.111a.17.17 0 0 1 .117-.046h.616c.18-.585.557-1.1 1.073-1.466a3.105 3.105 0 0 1 1.79-.566h1.16a.17.17 0 0 1 .117.045c.031.03.049.07.049.11a.152.152 0 0 1-.049.111.17.17 0 0 1-.117.046h-1.16c-.557.001-1.1.168-1.553.476a2.52 2.52 0 0 0-.963 1.244H9.2a.17.17 0 0 1 .118.046c.03.03.048.07.048.11a.152.152 0 0 1-.048.111.17.17 0 0 1-.118.046H6.1c-.064.31-.064.628 0 .938h3.1a.17.17 0 0 1 .118.046c.03.03.048.069.048.11a.152.152 0 0 1-.048.111.171.171 0 0 1-.118.046H6.187c.174.5.512.935.963 1.244.452.308.996.475 1.553.476h1.16a.17.17 0 0 1 .117.046c.031.029.049.069.049.11a.152.152 0 0 1-.049.11.171.171 0 0 1-.117.046h-1.16a3.105 3.105 0 0 1-1.79-.566 2.821 2.821 0 0 1-1.073-1.466h-.616a.17.17 0 0 1-.117-.046.152.152 0 0 1-.049-.11c0-.042.018-.082.049-.111a.17.17 0 0 1 .117-.046Z"
      />
    </svg>
  );
}
