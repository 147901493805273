import type { ComponentPropsWithRef, ReactNode } from 'react';

export function ArrowDown(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.8497 6.61366L8.5282 10.0287C8.38772 10.1691 8.19729 10.248 7.99875 10.248C7.80021 10.248 7.60978 10.1691 7.46931 10.0287L4.15028 6.61366C4.10271 6.56709 4.06491 6.51148 4.0391 6.45009C4.01329 6.3887 4 6.32276 4 6.25616C4 6.18955 4.01329 6.12362 4.0391 6.06223C4.06491 6.00083 4.10271 5.94522 4.15028 5.89866C4.24386 5.80553 4.37046 5.75326 4.50241 5.75326L11.4963 5.75201C11.562 5.75201 11.627 5.76497 11.6876 5.79013C11.7482 5.81529 11.8033 5.85217 11.8497 5.89866C11.8973 5.94522 11.9351 6.00083 11.9609 6.06223C11.9867 6.12362 12 6.18955 12 6.25616C12 6.32276 11.9867 6.3887 11.9609 6.45009C11.9351 6.51148 11.8973 6.56709 11.8497 6.61366Z"
        fill="currentColor"
      />
    </svg>
  );
}
