import { type ReactNode, type SVGProps } from 'react';

export function IconRefund(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.155 2.659a.5.5 0 0 1-.024.707L1.772 6.5h9.446c2.6 0 4.786 1.992 4.786 4.5v2a.5.5 0 0 1-1 0v-2c0-1.892-1.672-3.5-3.786-3.5H1.772l3.359 3.134a.5.5 0 1 1-.683.732l-4.285-4a.5.5 0 0 1 0-.732l4.285-4a.5.5 0 0 1 .707.025Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
