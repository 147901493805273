import React, { type ReactNode } from 'react';
import cx from 'clsx';
import { Flag } from '@repo/design-system-kit';
import { ChevronBottomOutlined } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface LanguageSelectTriggerProps {
  languageIcon: 'de' | 'en' | 'es' | 'fr' | 'it';
  selectedLanguage: string;
}

export function LanguageSelectTrigger({
  languageIcon,
  selectedLanguage,
}: LanguageSelectTriggerProps): ReactNode {
  return (
    <div className={styles['language-trigger']}>
      <div className={styles['left-items-container']}>
        <Flag
          className={styles['language-icon-container']}
          locale={languageIcon}
          variant="isometric"
        />
        <div
          className={cx(styles['power-select-customs-trigger'], 'mr-8')}
          data-test-language-trigger-icon={languageIcon}
        >
          <span className={styles['language-text']} data-test-language-trigger-text="">
            {selectedLanguage}
          </span>
        </div>
      </div>
      <ChevronBottomOutlined
        className={styles['chevron-down']}
        data-test-power-select-customs-trigger-arrow="true"
      />
    </div>
  );
}
