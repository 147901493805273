import { type ComponentPropsWithoutRef, type ReactNode } from 'react';
import styles from './styles.strict-module.css';

export function CellAction({ children, ...props }: ComponentPropsWithoutRef<'div'>): ReactNode {
  return (
    <div className={styles.container} {...props}>
      {children}
    </div>
  );
}
