import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function AttachmentMissingOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        fill="currentColor"
        d="M14.305 4.17c0 .55-.12 1.09-.33 1.6-.22.51-.54.97-.93 1.35l-1.81 1.74-.74-.74 1.84-1.78c.3-.29.53-.62.69-.99.15-.38.24-.78.24-1.18 0-.4-.09-.8-.24-1.18a2.99 2.99 0 0 0-.69-1C11.085.82 9.005.82 7.745 2l-1.71 1.66-.74-.74 1.76-1.71c.8-.78 1.87-1.21 3-1.21s2.18.43 2.99 1.21c.39.38.71.84.93 1.35.21.51.33 1.05.33 1.61ZM9.255 5.66c.1-.11.17-.25.17-.38a.542.542 0 0 0-.15-.4.516.516 0 0 0-.16-.12c-.06-.02-.12-.04-.19-.04s-.13.01-.2.03c-.06.03-.16.11-.16.11l-.68.65.75.75.62-.6ZM9.365 10.67l-.74-.74-.63.6c-.55.5-1.41.49-1.94.01a1.08 1.08 0 0 1-.28-.4c-.08-.16-.11-.32-.11-.49 0-.17.03-.33.11-.49.06-.15.15-.29.28-.41l.71-.68-.75-.75-.66.64c-.24.22-.41.49-.53.78-.12.28-.19.58-.19.9 0 .32.07.62.19.91.12.3.29.55.53.77.9.87 2.44.87 3.33 0l.68-.65ZM11.975 13.28l-.74-.74-.97.93c-.98.95-2.26 1.46-3.62 1.46-1.36 0-2.64-.51-3.61-1.45a4.681 4.681 0 0 1-1.48-3.45 4.681 4.681 0 0 1 1.48-3.45l1.14-1.1-.76-.76-1.09 1.06c-.56.56-1.01 1.21-1.33 1.94-.3.74-.46 1.52-.46 2.31s.16 1.56.46 2.3c.32.73.77 1.38 1.33 1.94A6.251 6.251 0 0 0 6.645 16c1.62 0 3.15-.62 4.32-1.74l1.01-.98ZM15.465 9.69c0 .14-.06.28-.16.38l-1.46 1.4-.74-.74 1.5-1.46s.1-.08.17-.1c.06-.02.13-.04.2-.03.06 0 .13 0 .19.04.06.03.11.06.16.12.1.1.15.25.14.39ZM14.525 14.01l.02-.02c.19-.19.19-.5 0-.69L3.385 2.14c-.1-.1-.22-.14-.35-.14-.13 0-.25.04-.35.15h-.01c-.19.19-.19.51 0 .7l11.16 11.16a.494.494 0 0 0 .69 0Z"
      />
    </svg>
  );
}
