import { type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl, FormattedNumber } from 'react-intl';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './styles.strict-module.css';

interface InvoiceProps {
  date: string;
  nameLabel: string;
  showSignus: boolean;
  currency: string;
  name: string;
  amount?: string;
}

export function InvoicePreview({
  name,
  nameLabel,
  showSignus,
  date,
  amount,
  currency,
}: InvoiceProps): ReactNode {
  const { formatMessage, locale } = useIntl();

  const missingData = formatMessage({ id: 'receivable-invoices.label.missing-data' });
  const formattedDate = date ? dateToken({ date, locale, token: 'date-year-s' }) : missingData;
  const nameValue = name || missingData;

  const formattedAmount = (): JSX.Element | string => {
    if (amount === undefined) {
      return missingData;
    }

    const sign = showSignus ? '-\xA0' : '';
    return (
      <>
        {sign}
        <FormattedNumber
          maximumFractionDigits={2}
          minimumFractionDigits={2}
          value={Number(amount)}
        />
        {'\xA0'}
        {currency}
      </>
    );
  };

  return (
    <div className={styles.card} data-test-invoice-preview-container>
      <div className={cx('mb-8', styles.row)}>
        <p className="body-2" data-test-invoice-preview-name-label>
          {nameLabel}
        </p>
        <span className="body-1" data-test-invoice-preview-name-value>
          {nameValue}
        </span>
      </div>
      <div className={cx('mb-8', styles.row)}>
        <p className="body-2">
          {formatMessage({
            id: 'aeat.table-header.due-date',
          })}
        </p>
        <span className="body-1" data-test-invoice-preview-date>
          {formattedDate}
        </span>
      </div>
      <div className={styles.row}>
        <p className="body-2">
          {formatMessage({
            id: 'aeat.table-header.amount',
          })}
        </p>
        <span className="body-1" data-test-invoice-preview-amount>
          {formattedAmount()}
        </span>
      </div>
    </div>
  );
}
