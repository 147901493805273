import { type ReactNode, type SVGProps } from 'react';

export function IconLightbulb(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M8.16 3.498a.5.5 0 0 0-.631.319l-.708 2.155a.5.5 0 0 0 .475.656h.726l-.493 1.498a.5.5 0 0 0 .95.313l.709-2.155a.5.5 0 0 0-.475-.656h-.727l.493-1.499a.5.5 0 0 0-.319-.63Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 0C5.255 0 3 2.153 3 4.845c0 1.344.564 2.558 1.47 3.432a4.89 4.89 0 0 1 1.486 3.02.5.5 0 0 0-.456.501l.015 2.99v.002-.001C5.516 15.473 6.09 16 6.754 16h2.504c.666 0 1.238-.528 1.238-1.213v-2.992a.5.5 0 0 0-.453-.498 4.89 4.89 0 0 1 1.487-3.02A4.755 4.755 0 0 0 13 4.845C13 2.153 10.745 0 8 0Zm1.496 12.297H6.503l.012 2.49c0 .1.092.213.239.213h2.504c.147 0 .238-.114.238-.213v-2.49Zm-.457-1a5.888 5.888 0 0 1 1.795-3.738l.001-.001A3.755 3.755 0 0 0 12 4.845C12 2.738 10.226 1 8 1S4 2.738 4 4.845c0 1.057.442 2.015 1.165 2.712a5.89 5.89 0 0 1 1.796 3.74h2.078Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
