import { useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import { LottiePlayer } from '@repo/design-system-kit';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import styles from './styles.strict-module.css';

export function Timeout(): ReactElement {
  const [isAnimationReady, setIsAnimationReady] = useState(false);
  const lottiePath = useThemedAssetPath('/lotties/transfers/timeout.json');
  const playAnimation = (): void => {
    setIsAnimationReady(true);
  };

  return (
    <section className={styles.wrapper} data-test-transfers-sepa-settlement-timeout>
      <LottiePlayer
        autoplay
        className={cx('mb-32', styles.lottie, !isAnimationReady && styles.hidden)}
        data-test-transfers-sepa-settlement-timeout-illustration=""
        loop
        onDataReady={playAnimation}
        path={lottiePath}
      />

      <h1 className="title-1 mb-16" data-test-transfers-sepa-settlement-timeout-title>
        <FormattedMessage id="transfers.modals.instant.timeout.title" />
      </h1>

      <p className="body-1" data-test-transfers-sepa-settlement-timeout-description>
        <FormattedMessage id="transfers.modals.instant.timeout.description" />
      </p>
    </section>
  );
}
