import React from 'react';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'clsx';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import styles from './processing.strict-module.css';

interface PagoPaResultProcessingProps {
  transitionToNext: () => void;
}

export function PagoPaResultProcessing({
  transitionToNext,
}: PagoPaResultProcessingProps): React.JSX.Element {
  const { formatMessage } = useIntl();
  const lottiePath = useThemedAssetPath('/lotties/pagopa/result-processing.json');

  const processingSubtitle = formatMessage({
    id: 'pagopa.payment-creation.result.processing.subtitle',
  });

  return (
    <div className={styles.container} data-test-result-processing="">
      <LottiePlayer
        className={cx(styles.lottie, 'mb-32')}
        data-test-result-processing-lottie=""
        path={lottiePath}
      />
      <h1 className={cx('title-1', 'mb-16')} data-test-result-processing-title="">
        <FormattedMessage id="pagopa.payment-creation.result.processing.title" />
      </h1>
      <p
        className="body-1 mb-32"
        dangerouslySetInnerHTML={{ __html: processingSubtitle }}
        data-test-result-processing-subtitle=""
      />
      <Button data-test-result-processing-cta="" onPress={transitionToNext} variant="primary">
        <FormattedMessage id="btn.close" />
      </Button>
    </div>
  );
}
