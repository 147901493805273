import { type ReactNode } from 'react';

export function Wallet(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M4.028 3.867 11.04 1.2l.976 2.667m-7.988 0H1.691c-.65 0-1.183.545-1.183 1.212v7.909c0 .667.532 1.212 1.183 1.212h11.272c.65 0 1.183-.545 1.183-1.212v-2M4.028 3.867h8.935c.65 0 1.183.545 1.183 1.212v1.539m.974 4.37H9.706c-.71 0-1.302-.606-1.302-1.333V8.382c0-.727.592-1.334 1.302-1.334h5.414c.207 0 .385.182.385.394v3.152c.03.212-.148.394-.385.394Z"
      />
      <path fill="currentColor" d="M10.508 9.4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" />
    </svg>
  );
}
