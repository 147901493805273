import { createContext, useContext, type ReactNode } from 'react';
import type { Transaction } from '../graphql';

export const CellContext = createContext<Transaction | null>(null);

export function CellProvider({
  children,
  transaction,
}: {
  children: ReactNode;
  transaction: Transaction;
}): ReactNode {
  return <CellContext.Provider value={transaction}>{children}</CellContext.Provider>;
}

export function useCellContext(): Transaction {
  const context = useContext(CellContext);
  if (!context) {
    throw new Error('useCellContext must be used within a CellProvider');
  }

  return context;
}

export const cellContextManager = {
  useCellContext,
};
