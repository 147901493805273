import { SkeletonLoader } from '@repo/design-system-kit';
import { type ReactElement } from 'react';
import styles from './styles.strict-module.css';

export function ManagersCardPlaceholder(): ReactElement {
  return (
    <article className={styles.card} data-test-budget-details-managers-card-placeholder="">
      <div className={styles.managers}>
        <SkeletonLoader.Block
          borderRadius="36px"
          data-test-budget-details-managers-card-placeholder-block=""
          height="12px"
          width="144px"
        />
        <SkeletonLoader.Block
          borderRadius="36px"
          data-test-budget-details-managers-card-placeholder-block=""
          height="12px"
          width="95px"
        />
      </div>
      <SkeletonLoader.Block
        borderRadius="4px"
        data-test-budget-details-managers-card-placeholder-block=""
        height="24px"
        width="79px"
      />
    </article>
  );
}
