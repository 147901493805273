import { type FormEvent, type ReactNode, useRef } from 'react';
import { Form } from 'react-aria-components';
import { useDebounceCallback } from 'usehooks-ts';
import type {
  CashflowCategorySide,
  CashflowParentCategory,
} from 'qonto/react/models/cash-flow-category';
import { CategoryAvatar } from './category-avatar';
import styles from './category-details-form.strict-module.css';
import { NameField } from './name-field';
import { TypeSelect } from './type-select';
import { ColorSelect } from './color-select';
import { IconSelect } from './icon-select';
import { VatRateFields } from './vat-rate-field';

interface CategoryDetailsProps {
  category: CashflowParentCategory;
  currentType: CashflowCategorySide;
  isParentCategory: boolean;
  onSubmit: (formData: FormData) => void;
}
export function CategoryDetailsForm({
  category,
  currentType,
  isParentCategory,
  onSubmit,
}: CategoryDetailsProps): ReactNode {
  const formRef = useRef<HTMLFormElement>(null);

  const isSubcategory = !isParentCategory;
  const isSingleParentCategory = isParentCategory && category.subcategories?.length === 0;
  const showVatRateField = isSubcategory || isSingleParentCategory;

  const handleTitleChange = useDebounceCallback(() => {
    formRef.current?.requestSubmit();
  }, 500);

  const handleTypeChange = (): void => {
    formRef.current?.requestSubmit();
  };

  const handleColorChange = (): void => {
    formRef.current?.requestSubmit();
  };

  const handleIconChange = (): void => {
    formRef.current?.requestSubmit();
  };

  const handleVatRateChange = (): void => {
    formRef.current?.requestSubmit();
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    onSubmit(formData);
  };

  return (
    <Form
      className={styles['details-form']}
      key={category.id}
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <div className={styles['details-main']}>
        <CategoryAvatar color={category.colorKey} icon={category.iconKey ?? ''} />
        <NameField
          categoryName={category.name}
          isParentCategory={isParentCategory}
          onTitleChange={handleTitleChange}
        />
        {isParentCategory ? (
          <TypeSelect currentType={currentType} onSelectionChange={handleTypeChange} />
        ) : null}
        {showVatRateField ? (
          <VatRateFields categoryVatRate={category.vatRate} onVatRateChange={handleVatRateChange} />
        ) : null}
      </div>
      {isParentCategory ? (
        <div className={styles['details-secondary']}>
          <ColorSelect categoryColorKey={category.colorKey} onColorChange={handleColorChange} />
          <IconSelect
            categoryColorKey={category.colorKey}
            categoryIconKey={category.iconKey ?? ''}
            onIconChange={handleIconChange}
          />
        </div>
      ) : null}
    </Form>
  );
}
