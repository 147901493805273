import { useEffect, useRef, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useSearchTransactions } from 'qonto/react/hooks/use-search-transactions';
import { Pagination } from 'qonto/react/components/transactions/table/pagination';
import { useFetchLabelLists } from 'qonto/react/hooks/use-fetch-label-lists';
import { BulkSelectionProvider } from 'qonto/react/contexts/bulk-selection-context';
import type { SearchTransactionsMeta, TransactionsSearch } from 'qonto/react/graphql';
import { RouteContextProvider } from 'qonto/react/contexts/route-context';
import type ModalsService from 'ember-promise-modals/services';
import { DataTable } from '../../table-v2/data-table';
import { generateColumns } from './columns';
import styles from './styles.strict-module.css';
import { BulkSidePanel } from './bulk-side-panel';
import { TransactionTablePlaceholder } from './placeholder/placeholder';

interface TransactionsTableProps {
  bankAccountsQPValue?: string;
  organizationId: string;
  page: number;
  perPage: number;
  searchQuery: string;
  filterGroup: TransactionsSearch['filterGroup'];
  updatePage: (page: number) => void;
  updatePerPage: (perPage: number) => void;
  updateMeta: (meta: SearchTransactionsMeta) => void;
  highlightItem: (id: string) => void;
  selectedItemIds?: string[];
  selectItem: (id: string) => void;
  selectAll: () => void;
  highlightedItemId?: string;
  openModal: ModalsService['open'];
}

export function TransactionsTable({
  bankAccountsQPValue,
  filterGroup,
  organizationId,
  page,
  perPage,
  updatePage,
  updatePerPage,
  searchQuery = '',
  updateMeta,
  highlightItem,
  highlightedItemId,
  selectedItemIds = [],
  selectItem,
  selectAll,
  openModal,
}: TransactionsTableProps): ReactNode {
  const { formatMessage } = useIntl();

  const pagination = { page, perPage };
  const query: TransactionsSearch = {
    search: searchQuery,
    ...(bankAccountsQPValue ? { bankAccountIds: bankAccountsQPValue.split(',') } : {}),
    filterGroup,
  };
  const {
    data: labelLists,
    isLoading: isLabelListsLoading,
    isError: isLabelListsError,
  } = useFetchLabelLists(organizationId);

  const { data, isLoading, isError } = useSearchTransactions(updateMeta, {
    query,
    pagination,
  });

  const highlightedItemRef = useRef<string | undefined>();

  useEffect(() => {
    if (typeof highlightedItemId === 'undefined' && highlightedItemRef.current) {
      //close sidepanel
    }
    highlightedItemRef.current = highlightedItemId;
  }, [highlightedItemId]);

  if (isLoading || isLabelListsLoading) {
    return (
      <table className={styles.loading} data-testid="transactions-table-loading">
        <tbody>
          <TransactionTablePlaceholder isTableHeader />
          {Array.from({ length: 8 }, (_, index) => (
            <TransactionTablePlaceholder key={index} />
          ))}
        </tbody>
      </table>
    );
  }

  if (isError || isLabelListsError) {
    return <div data-testid="transactions-table-error">Error fetching data</div>;
  }

  if (data && labelLists) {
    const { columns, defaultColumnOrder } = generateColumns(labelLists, formatMessage);

    return (
      <RouteContextProvider routeContext={{ openModal, highlightItem }}>
        <BulkSelectionProvider
          selectAll={selectAll}
          selectItem={selectItem}
          selectedItemIds={selectedItemIds}
        >
          <div className={styles.main}>
            <div className={styles.container} data-testid="transactions-table">
              <DataTable
                columns={columns}
                data={data.transactions}
                defaultColumnOrder={defaultColumnOrder}
              />
            </div>
            {(data.meta.totalCount > data.meta.perPage || data.meta.totalCount > 25) && (
              <Pagination
                responseMeta={data.meta}
                updatePage={updatePage}
                updatePerPage={updatePerPage}
              />
            )}
            <BulkSidePanel labelLists={labelLists} organizationId={organizationId} />
          </div>
        </BulkSelectionProvider>
      </RouteContextProvider>
    );
  }

  return null;
}
