import React, { type ReactNode } from 'react';

export function Tag(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        d="M9.26 14.987c.09.076.203.122.325.134.256.024.53-.1.716-.326l4.878-5.9c.286-.348.285-.825-.004-1.067L6.892.902a.573.573 0 0 0-.12-.078.14.14 0 0 0-.134-.044c-.162-.016-.916.159-2.25.486-.242.06-.432.106-.515.125l-2.761.613a.146.146 0 0 0-.114.137L.903 4.985C.9 5.07.89 5.268.875 5.52.78 7.231.775 7.685.811 7.828H.808a.129.129 0 0 0 .058.11.56.56 0 0 0 .11.122l8.284 6.927Z"
      />
      <path
        fill="currentColor"
        d="M6.755 9.367a2.523 2.523 0 0 1-1.74-.449 2.525 2.525 0 0 1 3.336-.369c-.413.46-.982.752-1.596.818Zm2.213-2.76a2.484 2.484 0 0 1-.448 1.73 2.5 2.5 0 0 1-.378-3.32c.463.41.757.976.826 1.59Zm1.348 1.071a2.523 2.523 0 0 1 1.74.45 2.525 2.525 0 0 1-3.336.369c.413-.46.982-.752 1.596-.819Zm-2.213 2.76a2.505 2.505 0 0 1 .453-1.737c.462.41.751.983.82 1.597a2.484 2.484 0 0 1-.447 1.73 2.499 2.499 0 0 1-.826-1.59Z"
      />
      <ellipse
        cx="1"
        cy="1.001"
        stroke="currentColor"
        rx="1"
        ry="1.001"
        transform="matrix(.99538 .09596 -.11116 .9938 3.584 3.836)"
      />
    </svg>
  );
}
