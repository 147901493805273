import { type ReactNode } from 'react';
import type { CategoriesListProps } from './categories-list';
import { CategoriesItemLoading } from './categories-item-loading';

export function CategoriesListLoading({ type }: CategoriesListProps): ReactNode {
  return (
    <div data-testid={`categories-list-${type}-loading`}>
      {Array.from({ length: 7 }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key -- static array
        <CategoriesItemLoading key={index} />
      ))}
    </div>
  );
}
