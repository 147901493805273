export interface TransposedData {
  attribute: string;
  [key: string]: string | number;
}

export function transposeTableData<T>(data?: T[]): TransposedData[] {
  if (!data?.[0]) return [];

  const keys = Object.keys(data[0]);
  return keys.map(key => ({
    attribute: key,
    ...data.reduce(
      (acc, curr, index) => ({
        ...acc,
        [`col${index.toString()}`]: curr[key as keyof T],
      }),
      {}
    ),
  }));
}
