import React, { useState, type ReactNode } from 'react';
import { closestCorners, type DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import type { CashflowParentCategory } from 'qonto/react/models/cash-flow-category';
import { useUpdateCashFlowCategory } from 'qonto/react/hooks/use-update-cash-flow-category';
import { useDragAndDrop } from '../hooks/use-drag-and-drop';
import { CategoryItemGroup } from '../category-item-group';
import { CategoryItemNew } from '../category-item-new';
import styles from './categories-list.strict-module.css';
import type { CategoriesListProps } from './categories-list';

interface SortableCategoriesListProps extends CategoriesListProps {
  categoriesList: CashflowParentCategory[];
}
export function SortableCategoriesList({
  categoriesList,
  type,
}: SortableCategoriesListProps): ReactNode {
  const { categories, onDragEnd, sensors } = useDragAndDrop(categoriesList);
  const { mutate } = useUpdateCashFlowCategory();
  const [isAnySubcategoryHovered, setIsAnySubcategoryHovered] = useState(false);

  const handleSubcategoryMouseEnter = (): void => {
    setIsAnySubcategoryHovered(true);
  };

  const handleSubcategoryMouseLeave = (): void => {
    setIsAnySubcategoryHovered(false);
  };

  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, updatedCategories } = onDragEnd(event);
    if (updatedCategories.length > 0) {
      const order = updatedCategories.findIndex(category => category.id === active.id);
      mutate({
        type,
        categoryId: active.id.toString(),
        reorderedCategories: updatedCategories,
        payload: { order: order + 1 },
      });
    }
  };

  return (
    <div className={styles['categories-list']} data-testid={`categories-list-${type}`}>
      <DndContext
        collisionDetection={closestCorners}
        modifiers={[restrictToParentElement]}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <SortableContext items={categories} strategy={verticalListSortingStrategy}>
          {categories.map(category => {
            return (
              <CategoryItemGroup
                category={category}
                isAnySubcategoryHovered={isAnySubcategoryHovered}
                key={category.id}
                onSubcategoryMouseEnter={handleSubcategoryMouseEnter}
                onSubcategoryMouseLeave={handleSubcategoryMouseLeave}
                type={type}
              />
            );
          })}
        </SortableContext>
      </DndContext>
      <CategoryItemNew />
    </div>
  );
}
