import React from 'react';
import { clsx } from 'clsx';
import { type ComponentPropsWithoutRef } from 'react';
import { useDisplayCounterparty } from 'qonto/react/hooks/use-display-counterparty';
import styles from './counterparty.strict-module.css';

interface CounterpartyProps extends ComponentPropsWithoutRef<'div'> {
  name?: string | null;
}

export function Counterparty({ name, className, ...props }: CounterpartyProps): React.ReactElement {
  const displayCounterparty = useDisplayCounterparty();

  return (
    <div className={clsx(styles.counterparty, className)} data-test-counterparty {...props}>
      {displayCounterparty(name)}
    </div>
  );
}
