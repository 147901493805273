import { type FormEvent, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Button, Form, Spinner, TextField } from '@repo/design-system-kit';
import cx from 'clsx';
import styles from './category-item-new.strict-module.css';

interface NewItemProps {
  handleCancel: () => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleRemoveError: (value: boolean) => void;
  isSubmitting: boolean;
  isSubcategory?: boolean;
  showRequiredError?: boolean;
}

export function NewItemForm({
  handleCancel,
  handleSubmit,
  handleRemoveError,
  isSubmitting,
  isSubcategory = false,
  showRequiredError = false,
}: NewItemProps): ReactNode {
  const { formatMessage } = useIntl();
  const placeholder = isSubcategory
    ? formatMessage({ id: 'categories-management.create-subcategory.placeholder' })
    : formatMessage({ id: 'categories-management.create-category.placeholder' });
  const cancelButtonText = isSubcategory
    ? formatMessage({ id: 'categories-management.create-subcategory.cancel.cta' })
    : formatMessage({ id: 'categories-management.create-category.cancel.cta' });
  const createButtonText = isSubcategory
    ? formatMessage({ id: 'categories-management.create-subcategory.create.cta' })
    : formatMessage({ id: 'categories-management.create-category.create.cta' });

  const errorMessage = isSubcategory
    ? formatMessage({
        id: 'categories-management.create-subcategory.form.error.field-required',
      })
    : formatMessage({ id: 'categories-management.create-category.form.error.field-required' });

  const handleIsTyping = (): void => {
    showRequiredError ? handleRemoveError(true) : handleRemoveError(false);
  };

  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      <TextField
        aria-label={placeholder}
        /* eslint-disable-next-line jsx-a11y/no-autofocus -- The category input should focus on render */
        autoFocus
        className={styles['text-field']}
        data-testid="category-item-new-text-field"
        errorMessage={showRequiredError ? errorMessage : ''}
        isDisabled={isSubmitting}
        label=""
        name="name"
        onInput={handleIsTyping}
        placeholder={placeholder}
      />
      <div className={cx(styles['button-group'], showRequiredError ? styles['with-error'] : '')}>
        <Button
          data-testid="category-item-new-cancel-button"
          isDisabled={isSubmitting}
          onPress={handleCancel}
          size="medium"
          variant="secondary"
        >
          {cancelButtonText}
        </Button>
        <Button
          className={styles['create-button']}
          data-testid="category-item-new-create-button"
          isDisabled={isSubmitting}
          size="medium"
          type="submit"
          variant="primary"
        >
          <div className={styles['button-content']}>
            {createButtonText}
            {isSubmitting ? <Spinner size="small" /> : null}
          </div>
        </Button>
      </div>
    </Form>
  );
}
