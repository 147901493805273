import { useState } from 'react';
import type { ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { Spinner, Checkbox } from '@repo/design-system-kit';
import { BulletPoint } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

function Section({ title, items, ...props }: { title: string; items: string[] }): ReactNode {
  return (
    <div className={cx(styles['list-container'], 'mb-16')} {...props}>
      <h2 className="body-1 mb-16">{title}</h2>
      <ul className={styles.list}>
        {items.map((item: string, index: number) => {
          return (
            <li className="body-1" data-test-item={index} key={item}>
              <BulletPoint className={styles['bullet-point']} />
              <span className={styles['list-item-text']}>{item}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

interface TermsConditionsProps {
  onSubmit: () => void;
  submitIsRunning: boolean;
}

export function TermsAndConditions({ onSubmit, submitIsRunning }: TermsConditionsProps): ReactNode {
  const [isAccepted, setIsAccepted] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  const { formatMessage } = useIntl();

  const handleSubmit = () => {
    if (!isAccepted) {
      setIsInvalid(true);
      return;
    }

    onSubmit();
  };

  const handleCheckboxChange = () => {
    setIsAccepted(!isAccepted);
    setIsInvalid(false);
  };

  return (
    <div>
      <h1 className="title-1 mb-32" data-test-title>
        <FormattedMessage id="dispute-flow.terms-and-conditions.title" />
      </h1>

      <Section
        data-test-authorize-section
        items={[
          formatMessage({
            id: 'dispute-flow.terms-and-conditions.authorize.item-1',
          }),
          formatMessage({
            id: 'dispute-flow.terms-and-conditions.authorize.item-2',
          }),
        ]}
        title={formatMessage({
          id: 'dispute-flow.terms-and-conditions.authorize.statement',
        })}
      />
      <Section
        data-test-acknowledge-section
        items={[
          formatMessage({
            id: 'dispute-flow.terms-and-conditions.acknowledge.item-1',
          }),
          formatMessage({
            id: 'dispute-flow.terms-and-conditions.acknowledge.item-2',
          }),
        ]}
        title={formatMessage({
          id: 'dispute-flow.terms-and-conditions.acknowledge.statement',
        })}
      />
      <Section
        data-test-declare-section
        items={[
          formatMessage({
            id: 'dispute-flow.terms-and-conditions.declare.item-1',
          }),
          formatMessage({
            id: 'dispute-flow.terms-and-conditions.declare.item-2',
          }),
          formatMessage({
            id: 'dispute-flow.terms-and-conditions.declare.item-3',
          }),
          formatMessage({
            id: 'dispute-flow.terms-and-conditions.declare.item-4',
          }),
          formatMessage({
            id: 'dispute-flow.terms-and-conditions.declare.item-5',
          }),
        ]}
        title={formatMessage({
          id: 'dispute-flow.terms-and-conditions.declare.statement',
        })}
      />

      <Checkbox
        className="mb-32"
        data-test-checkbox
        errorMessage={formatMessage({
          id: 'dispute-flow.terms-and-conditions.checkbox.error',
        })}
        isInvalid={isInvalid}
        isSelected={isAccepted}
        onChange={handleCheckboxChange}
      >
        <FormattedMessage id="dispute-flow.terms-and-conditions.checkbox.label" />
      </Checkbox>

      <button className="btn btn--primary" data-test-submit onClick={handleSubmit} type="button">
        <FormattedMessage id="dispute-flow.additional-info.cta" />
        {submitIsRunning ? (
          <Spinner className="ml-8" color="primary-b" data-test-submit-spinner size="small" />
        ) : null}
      </button>
    </div>
  );
}
