import type { LabelList } from 'qonto/react/models/label';
import type { LabelStatisticsResponse, UnlabeledStatisticsResponse } from '../api/labels';
import { generateTableData } from '../utils/generate-table-data';
import { sumLabelListCashflowSides, sumUnlabeledCashflowSides } from '../utils/sum-sides';
import type { LabelTableRow } from '../models/labels-cashflow-display';

export const useLabelCashflows = (
  offset: number,
  displayedMonths: number,
  labelStatistics?: LabelStatisticsResponse,
  unlabeledStatistics?: UnlabeledStatisticsResponse,
  labelLists: LabelList[] = []
): LabelTableRow[] => {
  const [creditLabelStatistics, debitLabelStatistics] = labelStatistics ?? [];
  const creditLabelCashflows = creditLabelStatistics?.data.display_at_monthly ?? [];
  const debitLabelCashflows = debitLabelStatistics?.data.display_at_monthly ?? [];

  const labelCashflows = sumLabelListCashflowSides(creditLabelCashflows, debitLabelCashflows);

  const [creditUnlabeledStatistics, debitUnlabeledStatistics] = unlabeledStatistics ?? [];
  const creditUnlabeledCashflows = creditUnlabeledStatistics?.data.display_at_monthly ?? [];
  const debitUnlabeledCashflows = debitUnlabeledStatistics?.data.display_at_monthly ?? [];

  const unlabeledCashflows = sumUnlabeledCashflowSides(
    creditUnlabeledCashflows,
    debitUnlabeledCashflows
  );

  return generateTableData(labelLists, labelCashflows, unlabeledCashflows, offset, displayedMonths);
};
