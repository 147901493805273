import { type ReactNode } from 'react';
import * as Sentry from '@sentry/ember';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import { FormattedMessage } from 'react-intl';
import ENV from 'qonto/config/environment';
import { registerJsURL } from 'qonto/constants/hosts';
import { PlusOutlined } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface NavigationDropdownFooterProps {
  canAddOrganization?: boolean;
}

export function NavigationDropdownFooter({
  canAddOrganization,
}: NavigationDropdownFooterProps): ReactNode {
  const registerLink = `${registerJsURL}/signup/select-country`;
  const segment = useEmberService('segment');
  const sessionManager = useEmberService('sessionManager');

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    if (ENV.environment === 'test') {
      e.preventDefault();
    }
    segment.track('add_organization');
  };

  const handleSignOut = (): void => {
    segment.track('logout');
    Sentry.getCurrentScope().setUser(null);
    void sessionManager.invalidate();
  };
  return (
    <div className={styles.footer}>
      <span>{canAddOrganization}</span>
      {canAddOrganization ? (
        <a
          className={cx(styles['footer-item'], styles['add-organization'], styles['body-link'])}
          data-test-add-another-organization
          href={registerLink}
          onClick={handleLinkClick}
        >
          <PlusOutlined height="12" width="12" />
          <span className={styles['item-link']}>
            <FormattedMessage id="navigation.dropdown.add-organization" />
          </span>
        </a>
      ) : null}
      <button
        className={styles['footer-item']}
        data-test-signout-button
        onClick={handleSignOut}
        type="button"
      >
        <FormattedMessage id="btn.sign_out" />
      </button>
    </div>
  );
}
