import { type ReactNode, type SVGProps } from 'react';

export function IconBasket(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.797 1.067a.5.5 0 0 1 .683.184l2.364 4.109.001.002h2.306c.273 0 .513.121.674.34.147.197.212.46.154.705l-1.678 7.921a.862.862 0 0 1-.83.672H2.53c-.355 0-.759-.246-.832-.685L.018 6.395a.86.86 0 0 1 .135-.67c.16-.219.4-.34.674-.34H2.96l.141-.001a.513.513 0 0 1 .015-.028L5.483 1.25a.5.5 0 1 1 .866.5L4.256 5.38l7.437-.017-2.08-3.615a.5.5 0 0 1 .184-.682Zm3.22 5.294-10.055.023H1.038L2.652 14h10.696l1.619-7.639h-1.95Zm1.99-.183-.003.007.002-.007ZM4.77 7.636a.5.5 0 0 1 .5.5v4.099a.5.5 0 1 1-1 0v-4.1a.5.5 0 0 1 .5-.5Zm3.236 0a.5.5 0 0 1 .5.5v4.099a.5.5 0 1 1-1 0v-4.1a.5.5 0 0 1 .5-.5Zm3.26 0a.5.5 0 0 1 .5.5v4.099a.5.5 0 1 1-1 0v-4.1a.5.5 0 0 1 .5-.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
