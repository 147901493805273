import { type ReactNode, type SVGProps } from 'react';

export function IconBank(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.517 1.086c.263-.122.576-.103.816-.01l.01.004 7.349 3.057a.5.5 0 0 1 .306.5l.002.037v1.814a.5.5 0 0 1-.5.5h-1.551v5.198h1.55a.5.5 0 0 1 .5.5V14.5a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1.814a.5.5 0 0 1 .5-.5h1.555V6.988H.5a.5.5 0 0 1-.5-.5V4.674L0 4.637a.5.5 0 0 1 .304-.499l7.212-3.052ZM1 4.93v1.058h14V4.932L7.967 2.007A.12.12 0 0 0 7.922 2a.432.432 0 0 1-.006.002L1 4.93Zm2.054 2.058v5.198h2.632V6.988H3.054Zm3.632 0v5.198h2.631V6.988H6.686Zm3.631 0v5.198h2.632V6.988h-2.632ZM1 13.186V14h14v-.814H1Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
