import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorFilled } from 'qonto/react/assets/icons/error-filled';
import styles from './styles.strict-module.css';

export function ErrorState(): React.ReactElement {
  return (
    <div className={styles['error-state']} data-test-error-state>
      <span className={styles['error-icon']}>
        <ErrorFilled />
      </span>
      <span className={styles['error-description']}>
        <FormattedMessage id="transactions.sidebar.errors.attachments" />
      </span>
    </div>
  );
}
