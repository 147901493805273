import { type ReactNode } from 'react';

export function Document(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.667 14.265V1.732c0-.22.18-.4.4-.4h7.768a.4.4 0 0 1 .282.117l2.1 2.1a.4.4 0 0 1 .117.282v10.434a.4.4 0 0 1-.4.4H3.067a.4.4 0 0 1-.4-.4ZM5.333 6.668h5.333M5.333 12h5.333M5.333 9.332H8"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.667 1.332v2.267c0 .22.18.4.4.4h2.267"
      />
    </svg>
  );
}
