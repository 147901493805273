import { type ReactNode, type SVGProps } from 'react';

export function IconCalculator(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 1.152C2 .548 2.465 0 3.11 0h9.78C13.534 0 14 .548 14 1.152v13.696c0 .604-.466 1.152-1.11 1.152H3.11C2.465 16 2 15.452 2 14.848V1.152Zm1.044-.12a.174.174 0 0 0-.044.12v13.696c0 .053.02.094.044.12.023.025.047.032.066.032h9.78c.02 0 .043-.007.066-.032a.175.175 0 0 0 .044-.12V1.152a.174.174 0 0 0-.044-.12C12.933 1.007 12.91 1 12.89 1H3.11c-.02 0-.043.007-.066.032ZM4.215 3.22c0-.394.307-.773.756-.773h6.099c.448 0 .755.379.755.773v2.367c0 .395-.307.774-.755.774h-6.1c-.448 0-.755-.379-.755-.774V3.22Zm1 .227V5.36h5.61V3.446h-5.61Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M5.736 8.955c0 .361-.274.654-.612.654-.338 0-.612-.293-.612-.654 0-.362.274-.655.612-.655.338 0 .612.293.612.655ZM8.608 8.955c0 .361-.274.654-.612.654-.338 0-.611-.293-.611-.654 0-.362.273-.655.611-.655.338 0 .612.293.612.655ZM11.47 8.955c0 .361-.273.654-.611.654-.338 0-.612-.293-.612-.654 0-.362.274-.655.612-.655.338 0 .612.293.612.655ZM5.736 11.96c0 .361-.274.654-.612.654-.338 0-.612-.293-.612-.654 0-.362.274-.655.612-.655.338 0 .612.293.612.655ZM8.608 11.96c0 .361-.274.654-.612.654-.338 0-.611-.293-.611-.654 0-.362.273-.655.611-.655.338 0 .612.293.612.655ZM11.47 11.96c0 .361-.273.654-.611.654-.338 0-.612-.293-.612-.654 0-.362.274-.655.612-.655.338 0 .612.293.612.655Z"
      />
    </svg>
  );
}
