import type { ReactNode } from 'react';
import { FormattedNumber } from 'react-intl';
import type { Amount } from 'qonto/react/models/amount';

interface BalanceCellProps {
  amount?: Amount;
}

export function BalanceCell({ amount }: BalanceCellProps): ReactNode {
  const number = Number(amount?.value);
  const currency = amount?.currency;

  if (!amount || isNaN(number)) {
    return <span data-testid="balance-cell-empty">-</span>;
  }

  return (
    <span data-testid="balance-cell">
      <FormattedNumber currency={currency} style="currency" value={number} />
    </span>
  );
}
