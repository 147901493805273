import { type ReactNode } from 'react';

export function Hook(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.015 3.098V.829M6.32 13.487a1.685 1.685 0 1 0 2.62-1.4 1.703 1.703 0 0 0-.936-.303V10.13m-2.02-5.417V3.238h4.044v1.475m-4.678.032h5.27a.21.21 0 0 1 .208.18l.675 4.82a.21.21 0 0 1-.208.238h-6.58a.21.21 0 0 1-.208-.237l.636-4.819a.21.21 0 0 1 .207-.182Z"
      />
    </svg>
  );
}
