import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function NotificationDot(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="6"
      fill="none"
      viewBox="0 0 6 6"
      {...props}
    >
      <circle cx="3" cy="3" r="3" fill="currentColor" />
    </svg>
  );
}
