import { type ReactNode } from 'react';
import type { Row, Table } from '@tanstack/react-table';
import { useEventCallback } from 'usehooks-ts';
import { Checkbox } from 'qonto/react/components/table-v2/cells/checkbox';
import type { Transaction } from 'qonto/react/graphql';
import { bulkSelectionManager } from 'qonto/react/contexts/bulk-selection-context';

interface BulkCheckboxHeaderProps {
  table: Table<Transaction>;
}
export function BulkCheckboxHeader({ table }: BulkCheckboxHeaderProps): ReactNode {
  const { selectAll } = bulkSelectionManager.useBulkSelection();
  const isIndeterminate = table.getIsSomeRowsSelected();
  const isChecked = table.getIsAllRowsSelected();
  const toggleAll = table.getToggleAllRowsSelectedHandler();
  const resetAll = (): void => {
    table.resetRowSelection();
  };
  const handleOnChange = useEventCallback(event => {
    const onChange = !isChecked && isIndeterminate ? resetAll : toggleAll;
    onChange(event);
    selectAll();
  });

  return (
    <Checkbox
      checked={isChecked}
      data-testid={`bulk-checkbox-header${isChecked ? '-checked' : ''}`}
      indeterminate={isIndeterminate}
      onChange={handleOnChange}
    />
  );
}

interface BulkCheckboxCellProps {
  row: Row<Transaction>;
}
export function BulkCheckboxCell({ row }: BulkCheckboxCellProps): ReactNode {
  const isChecked = row.getIsSelected();
  const { selectItem } = bulkSelectionManager.useBulkSelection();
  const handleOnChange = useEventCallback(event => {
    const toggleSelectedHandler = row.getToggleSelectedHandler();
    selectItem(row.original.id);
    toggleSelectedHandler(event);
  });

  return (
    <Checkbox
      checked={isChecked}
      data-testid={`bulk-checkbox-cell${isChecked ? '-checked' : ''}`}
      disabled={!row.getCanSelect()}
      indeterminate={row.getIsSomeSelected()}
      onChange={handleOnChange}
    />
  );
}
