import type { ComponentPropsWithRef, ReactNode } from 'react';

export function ArrowRightOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.50076C10 1.23076 10.22 1.00076 10.5 1.00076H14.46C14.6 0.990762 14.74 1.04076 14.85 1.15076C14.94 1.24076 15 1.37076 15 1.50076V5.50076C15 5.78076 14.78 6.00076 14.5 6.00076C14.22 6.00076 14 5.78076 14 5.50076V2.71076L7.85 8.86076C7.65 9.06076 7.34 9.06076 7.14 8.86076C6.94 8.66076 6.94 8.35076 7.14 8.15076L13.29 2.00076H10.5C10.22 2.00076 10 1.78076 10 1.50076Z"
        fill="currentColor"
      />
      <path
        d="M15 8.50076V14.0008C15 14.5508 14.55 15.0008 14 15.0008H2C1.45 15.0008 1 14.5508 1 14.0008V2.00076C1 1.45076 1.45 1.00076 2 1.00076H7.5C7.78 1.00076 8 1.22076 8 1.50076C8 1.78076 7.78 2.00076 7.5 2.00076H2V14.0008H14V8.50076C14 8.22076 14.22 8.00076 14.5 8.00076C14.78 8.00076 15 8.22076 15 8.50076Z"
        fill="currentColor"
      />
    </svg>
  );
}
