import { type ReactElement } from 'react';
import { Button, CloseIcon, Spinner, Tooltip } from '@repo/design-system-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'clsx';
import { AttachmentItemBase } from '../attachment-item-base';
import styles from './styles.strict-module.css';

interface AttachmentItemUploadingProps {
  fileName: string;
  progress: number;
  onCancel: () => void;
}

export function AttachmentItemUploading({
  fileName,
  progress,
  onCancel,
}: AttachmentItemUploadingProps): ReactElement {
  const intl = useIntl();
  const shouldShowCancelButton = progress < 100;

  return (
    <AttachmentItemBase
      actions={
        shouldShowCancelButton ? (
          <Tooltip
            closeDelay={0}
            delay={400}
            label={intl.formatMessage({
              id: 'transactions.table.attachments.popover.tooltip.delete',
            })}
            tooltipTestId="tooltip"
          >
            <Button
              className={styles.button}
              data-testid="action-remove"
              iconOnly
              onPress={onCancel}
              type="button"
              variant="tertiary"
            >
              <CloseIcon />
            </Button>
          </Tooltip>
        ) : null
      }
      data-testid="attachment-item-uploading"
      fileName={
        <span className={cx('body-2', styles.file)} data-testid="filename">
          {fileName}
        </span>
      }
      icon={<Spinner color="primary-a" />}
      subtitle={
        <span className={cx('caption', styles.uploading)} data-testid="subtitle">
          <FormattedMessage
            id="transactions.table.attachments.status.title.uploading-single-file"
            values={{ progress }}
          />
        </span>
      }
    />
  );
}
