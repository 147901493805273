import {
  createContext,
  useCallback,
  useContext,
  useReducer,
  type Dispatch,
  type ReactNode,
} from 'react';
import type { Transaction } from 'qonto/react/graphql';
import type { AttachmentStatusOption } from '../models/transaction';
import { useFetchApi } from '../hooks/use-fetch-api';
import { useOrganizationManager } from '../hooks/use-organization-manager';
import {
  updateBulkAttachmentStatus,
  updateBulkVerificationStatus,
  updateOtherBulkFields,
} from '../utils/bulk/field-update-functions';

interface ContextState {
  labels: {
    updatedLabels: Record<string, string>;
  };
  verificationStatus: {
    verificationStatus: Transaction['qualifiedForAccounting'] | null;
    verificationStatusChanged: boolean;
  };
  category: {
    category: Transaction['activityTag'] | null;
    categoryChanged: boolean;
  };
  attachmentStatus: {
    attachmentStatus: AttachmentStatusOption | null;
    attachmentStatusChanged: boolean;
  };
  requestAttachment: {
    requestAttachment: Transaction['attachmentRequested'];
  };
  organizationId: string;
}
interface LabelAction {
  field: 'labels';
  type: 'setUpdatedLabels';
  payload: [string, string];
}

interface VerificationStatusAction {
  field: 'verificationStatus';
  type: 'setVerificationStatus';
  payload: Transaction['qualifiedForAccounting'];
}

interface CategoryAction {
  field: 'category';
  type: 'setCategory';
  payload: Transaction['activityTag'];
}

interface AttachmentStatusAction {
  field: 'attachmentStatus';
  type: 'setAttachmentStatus';
  payload: AttachmentStatusOption;
  options: { initialStatus: AttachmentStatusOption | null };
}

interface RequestAttachmentAction {
  field: 'requestAttachment';
  type: 'setRequestAttachment';
  payload: Transaction['attachmentRequested'];
}

interface BulkTransactionsContextType {
  state: ContextState;
  dispatch: Dispatch<
    | LabelAction
    | VerificationStatusAction
    | CategoryAction
    | AttachmentStatusAction
    | RequestAttachmentAction
  >;
}

const reducer = (
  state: ContextState,
  action:
    | LabelAction
    | VerificationStatusAction
    | CategoryAction
    | AttachmentStatusAction
    | RequestAttachmentAction
): ContextState => {
  switch (action.field) {
    case 'labels':
      return {
        ...state,
        labels: {
          updatedLabels: {
            ...state.labels.updatedLabels,
            [action.payload[0]]: action.payload[1],
          },
        },
      };
    case 'verificationStatus':
      return {
        ...state,
        verificationStatus: {
          verificationStatus: action.payload,
          verificationStatusChanged: true,
        },
      };
    case 'category':
      return {
        ...state,
        category: {
          category: action.payload,
          categoryChanged: true,
        },
      };
    case 'attachmentStatus':
      return {
        ...state,
        attachmentStatus: {
          attachmentStatus: action.payload,
          attachmentStatusChanged: action.options.initialStatus !== action.payload,
        },
      };
    case 'requestAttachment':
      return {
        ...state,
        requestAttachment: {
          requestAttachment: action.payload,
        },
      };
    default:
      return state;
  }
};

const initialState: ContextState = {
  labels: { updatedLabels: {} },
  verificationStatus: {
    verificationStatus: false,
    verificationStatusChanged: false,
  },
  category: {
    category: null,
    categoryChanged: false,
  },
  attachmentStatus: {
    attachmentStatus: null,
    attachmentStatusChanged: false,
  },
  requestAttachment: {
    requestAttachment: false,
  },
  organizationId: '',
};

const BulkTransactionsContext = createContext<BulkTransactionsContextType | undefined>(undefined);

export function BulkTransactionsProvider({
  children,
  organizationId,
}: {
  children: ReactNode;
  organizationId: string;
}): ReactNode {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    organizationId,
  });

  return (
    <BulkTransactionsContext.Provider value={{ state, dispatch }}>
      {children}
    </BulkTransactionsContext.Provider>
  );
}

interface UseBulkTransactions {
  labels: {
    updatedLabels: Record<string, string>;
    aggregatedUpdatedLabels: string[];
    setUpdatedLabels: (labelListId: string, labelId: string) => void;
    mutationFn: (selectedTransactions: string[]) => Promise<void>;
  };
  verificationStatus: {
    verificationStatus: Transaction['qualifiedForAccounting'] | null;
    verificationStatusChanged: boolean;
    setVerificationStatus: (status: Transaction['qualifiedForAccounting']) => void;
    mutationFn: (selectedTransactions: string[]) => Promise<void>;
  };
  category: {
    category: Transaction['activityTag'] | null;
    categoryChanged: boolean;
    setCategory: (category: Transaction['activityTag']) => void;
    mutationFn: (selectedTransactions: string[]) => Promise<void>;
  };
  attachmentStatus: {
    attachmentStatus: AttachmentStatusOption | null;
    attachmentStatusChanged: boolean;
    setAttachmentStatus: (
      newStatus: AttachmentStatusOption,
      initialStatus: AttachmentStatusOption | null
    ) => void;
    mutationFn: (selectedTransactions: string[]) => Promise<void>;
  };
  requestAttachment: {
    requestAttachment: Transaction['attachmentRequested'];
    setRequestAttachment: (requested: Transaction['attachmentRequested']) => void;
    mutationFn: (selectedTransactions: string[]) => Promise<void>;
  };
}

const useBulkTransactions = (): UseBulkTransactions => {
  const context = useContext(BulkTransactionsContext);
  if (!context) {
    throw new Error('useBulkTransactions must be used within a BulkTransactionsContext');
  }
  const fetchApi = useFetchApi();
  const {
    organization: { organizationId },
  } = useOrganizationManager();
  const { state, dispatch } = context;
  const {
    labels: { updatedLabels },
    verificationStatus: { verificationStatus, verificationStatusChanged },
    category: { category, categoryChanged },
    attachmentStatus: { attachmentStatus, attachmentStatusChanged },
    requestAttachment: { requestAttachment },
  } = state;
  const aggregatedUpdatedLabels = Object.values(updatedLabels);

  const setUpdatedLabels = useCallback(
    (labelListId: string, labelId: string) => {
      dispatch({ type: 'setUpdatedLabels', payload: [labelListId, labelId], field: 'labels' });
    },
    [dispatch]
  );

  const setVerificationStatus = useCallback(
    (status: Transaction['qualifiedForAccounting']) => {
      dispatch({ type: 'setVerificationStatus', payload: status, field: 'verificationStatus' });
    },
    [dispatch]
  );

  const setRequestAttachment = useCallback(
    (requested: Transaction['attachmentRequested']) => {
      dispatch({
        type: 'setRequestAttachment',
        payload: requested,
        field: 'requestAttachment',
      });
    },
    [dispatch]
  );

  const setCategory = useCallback(
    (newCategory: Transaction['activityTag']) => {
      dispatch({ type: 'setCategory', payload: newCategory, field: 'category' });
    },
    [dispatch]
  );

  const setAttachmentStatus = useCallback(
    (newStatus: AttachmentStatusOption, initialStatus: AttachmentStatusOption | null) => {
      dispatch({
        type: 'setAttachmentStatus',
        payload: newStatus,
        field: 'attachmentStatus',
        options: { initialStatus },
      });
    },
    [dispatch]
  );

  const mutateBulkUpdates = useCallback(
    (selectedTransactions: string[]) => {
      return updateOtherBulkFields(fetchApi, {
        organizationId,
        transactionIds: selectedTransactions,
        category: category ?? '',
        updatedLabelIds: aggregatedUpdatedLabels,
        requestAttachment,
      });
    },
    [fetchApi, organizationId, category, aggregatedUpdatedLabels, requestAttachment]
  );

  const mutateBulkVerificationStatus = useCallback(
    (selectedTransactions: string[]) => {
      return updateBulkVerificationStatus(fetchApi, organizationId, {
        transactionIds: selectedTransactions as [string, ...string[]],
        qualifiedForAccounting: verificationStatus === null ? false : !verificationStatus,
      });
    },
    [verificationStatus, organizationId, fetchApi]
  );

  const mutateAttachmentStatus = useCallback(
    (selectedTransactions: string[]) => {
      return updateBulkAttachmentStatus(fetchApi, organizationId, {
        transactionIds: selectedTransactions as [string, ...string[]],
        attachmentStatus,
      });
    },
    [attachmentStatus, organizationId, fetchApi]
  );

  return {
    labels: {
      updatedLabels,
      aggregatedUpdatedLabels,
      setUpdatedLabels,
      mutationFn: mutateBulkUpdates,
    },
    verificationStatus: {
      verificationStatus,
      verificationStatusChanged,
      setVerificationStatus,
      mutationFn: mutateBulkVerificationStatus,
    },
    category: {
      category,
      categoryChanged,
      setCategory,
      mutationFn: mutateBulkUpdates,
    },
    attachmentStatus: {
      attachmentStatus,
      attachmentStatusChanged,
      setAttachmentStatus,
      mutationFn: mutateAttachmentStatus,
    },
    requestAttachment: {
      requestAttachment,
      setRequestAttachment,
      mutationFn: mutateBulkUpdates,
    },
  };
};

//  required for stubbing with sinon.js
export const bulkTransactionsManager = {
  useBulkTransactions,
};
