import { Badge, type CountryCode, Flag } from '@repo/design-system-kit';
import cx from 'clsx';
import type { ComponentProps, ComponentPropsWithoutRef, ReactNode } from 'react';
import type { EnhancedCurrency } from 'qonto/utils/currency';
import styles from './styles.strict-module.css';

interface CurrencyProps extends ComponentPropsWithoutRef<'div'> {
  currency: Pick<EnhancedCurrency, 'code' | 'countryCode' | 'displayName'>;
  badgeOptions?: Pick<ComponentProps<typeof Badge>, 'type' | 'text'>;
  isoCodeOnly?: boolean;
}

export function Currency({
  currency: { code, countryCode, displayName },
  badgeOptions,
  isoCodeOnly = false,
  className,
  ...props
}: CurrencyProps): ReactNode {
  const formattedCurrencyName =
    isoCodeOnly || !displayName || code === displayName ? code : `${code} - ${displayName}`;

  return (
    <div className={cx(className, styles.currency)} data-test-currency={code} {...props}>
      <Flag
        className={styles.flag}
        code={countryCode as CountryCode}
        data-test-currency-flag={countryCode}
        size="small"
      />

      <span data-test-currency-name>{formattedCurrencyName}</span>

      {badgeOptions ? (
        <Badge className={styles.badge} {...badgeOptions} data-test-currency-badge />
      ) : null}
    </div>
  );
}
