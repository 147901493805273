import React, { type ReactNode, type MouseEvent } from 'react';
import cx from 'clsx';
import { ChevronBottomOutlined, CloseCircleFilled } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface TriggerProps {
  value?: string | null;
  placeholder?: string | null;
  onClear: () => void;
}

export function Trigger({ value, placeholder, onClear, ...props }: TriggerProps): ReactNode {
  const handleClear = (e: MouseEvent): void => {
    e.stopPropagation();
    onClear();
  };

  return (
    <div className={cx(styles.trigger, !value && styles.empty)} {...props}>
      <div className={cx(styles.value, !value && styles.placeholder)}>{value || placeholder}</div>
      <CloseCircleFilled
        className={cx(styles.clear, 'selected-icon-svg')}
        data-test-power-select-customs-trigger-clear="true"
        onMouseDown={handleClear}
        role="button"
        tabIndex={0}
      />
      <ChevronBottomOutlined
        className={styles['chevron-down']}
        data-test-power-select-customs-trigger-arrow="true"
      />
    </div>
  );
}
