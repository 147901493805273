import type {
  ActivityTag,
  Side,
  Status,
  StatusHistory as GqlStatusHistory,
  StatusHistoryStatus as GqlStatusHistoryStatus,
  OperationMethod,
} from 'qonto/react/graphql';

type EnumValues<T> = T[keyof T];
type EnumValuesToUnionType<T extends object> = EnumValues<T>;

export type TransactionStatus = EnumValuesToUnionType<typeof Status>;
export type StatusHistoryStatus = EnumValuesToUnionType<typeof GqlStatusHistoryStatus>;
export type StatusHistory = GqlStatusHistory;

export type TransactionCategory = EnumValuesToUnionType<typeof ActivityTag>;
export type TransactionSide = EnumValuesToUnionType<typeof Side>;
export type TransactionOperationMethod = EnumValuesToUnionType<typeof OperationMethod>;

export const MAX_ATTACHMENT_COUNT = 20;
export const UPLOAD_DISCLAIMER_TIMEOUT = 60 * 60 * 1000;

export const CHARGEBACK_CLAIM_CARD_POSSESSION_VALUES: {
  YES: 'yes';
  NO: 'no';
} = {
  YES: 'yes',
  NO: 'no',
} as const;
