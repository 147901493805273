import { type ReactNode, type SVGProps } from 'react';

export function IconSphere(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.313 6.962c-.55 3.687 1.996 7.143 5.69 7.716a6.712 6.712 0 0 0 7.684-5.64c.55-3.687-1.996-7.143-5.69-7.716a6.712 6.712 0 0 0-7.684 5.64Zm5.58 8.448c-4.095-.636-6.921-4.468-6.31-8.562A7.446 7.446 0 0 1 9.106.59c4.096.636 6.922 4.468 6.31 8.562a7.446 7.446 0 0 1-8.522 6.258Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.963 6.497a.37.37 0 0 1 .37.37c0 .9.659 1.819 1.89 2.533 1.219.706 2.928 1.165 4.84 1.165 1.892 0 3.57-.458 4.763-1.157 1.206-.705 1.845-1.607 1.845-2.478a.37.37 0 1 1 .74 0c0 1.25-.903 2.351-2.212 3.117-1.323.774-3.133 1.258-5.136 1.258-2.022 0-3.862-.483-5.21-1.265C1.518 9.266.593 8.15.593 6.867a.37.37 0 0 1 .37-.37Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.661 1.087a.367.367 0 0 1-.42.31c-.864-.135-1.85.379-2.726 1.5-.866 1.109-1.565 2.744-1.85 4.65-.282 1.888-.088 3.637.41 4.938.505 1.319 1.283 2.09 2.116 2.22.202.032.34.221.31.423a.367.367 0 0 1-.42.31c-1.22-.19-2.15-1.268-2.695-2.69-.55-1.44-.749-3.32-.451-5.314.3-2.012 1.041-3.771 1.998-4.996C6.88 1.228 8.1.47 9.351.665c.201.031.34.22.31.422Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.797.996c.03.202.218.34.42.31.864-.135 1.85.38 2.727 1.503.866 1.11 1.566 2.749 1.851 4.659.282 1.89.09 3.643-.41 4.946-.504 1.32-1.281 2.093-2.114 2.223a.372.372 0 0 0-.31.423c.03.202.217.34.419.309 1.22-.19 2.15-1.27 2.695-2.693.551-1.441.748-3.325.45-5.322-.3-2.015-1.042-3.777-1.999-5.003C9.579 1.138 8.359.379 7.108.574a.372.372 0 0 0-.31.422Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.2}
        d="M1.313 6.962c-.55 3.687 1.996 7.143 5.69 7.716a6.712 6.712 0 0 0 7.684-5.64c.55-3.687-1.996-7.143-5.69-7.716a6.712 6.712 0 0 0-7.684 5.64Zm5.58 8.448c-4.095-.636-6.921-4.468-6.31-8.562A7.446 7.446 0 0 1 9.106.59c4.096.636 6.922 4.468 6.31 8.562a7.446 7.446 0 0 1-8.522 6.258Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.2}
        d="M.963 6.497a.37.37 0 0 1 .37.37c0 .9.659 1.819 1.89 2.533 1.219.706 2.928 1.165 4.84 1.165 1.892 0 3.57-.458 4.763-1.157 1.206-.705 1.845-1.607 1.845-2.478a.37.37 0 1 1 .74 0c0 1.25-.903 2.351-2.212 3.117-1.323.774-3.133 1.258-5.136 1.258-2.022 0-3.862-.483-5.21-1.265C1.518 9.266.593 8.15.593 6.867a.37.37 0 0 1 .37-.37Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.2}
        d="M9.661 1.087a.367.367 0 0 1-.42.31c-.864-.135-1.85.379-2.726 1.5-.866 1.109-1.565 2.744-1.85 4.65-.282 1.888-.088 3.637.41 4.938.505 1.319 1.283 2.09 2.116 2.22.202.032.34.221.31.423a.367.367 0 0 1-.42.31c-1.22-.19-2.15-1.268-2.695-2.69-.55-1.44-.749-3.32-.451-5.314.3-2.012 1.041-3.771 1.998-4.996C6.88 1.228 8.1.47 9.351.665c.201.031.34.22.31.422Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.2}
        d="M6.797.996c.03.202.218.34.42.31.864-.135 1.85.38 2.727 1.503.866 1.11 1.566 2.749 1.851 4.659.282 1.89.09 3.643-.41 4.946-.504 1.32-1.281 2.093-2.114 2.223a.372.372 0 0 0-.31.423c.03.202.217.34.419.309 1.22-.19 2.15-1.27 2.695-2.693.551-1.441.748-3.325.45-5.322-.3-2.015-1.042-3.777-1.999-5.003C9.579 1.138 8.359.379 7.108.574a.372.372 0 0 0-.31.422Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
