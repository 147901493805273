import type { ReactNode } from 'react';
import cx from 'clsx';
import { CheckmarkOutlined } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

const getDataTestAttr = (isText: boolean, isIcon: boolean): Record<string, boolean> => {
  if (isText) return { 'data-test-value': isText };
  if (isIcon) return { 'data-test-icon': isIcon };
  return {};
};

interface FeatureProps {
  value: string | boolean;
}

export function Feature({ value }: FeatureProps): ReactNode {
  const isText = typeof value === 'string';
  const isIcon = value === true;

  return (
    <span className={cx(isIcon && styles.check)} {...getDataTestAttr(isText, isIcon)}>
      {Boolean(isText) && value}
      {Boolean(isIcon) && <CheckmarkOutlined />}
    </span>
  );
}
