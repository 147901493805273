import type { ComponentPropsWithRef, ReactNode } from 'react';

export function NoAttachment(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 11C22 9.34 23.34 8 25 8C26.66 8 28 9.34 28 11C28 12.66 26.66 14 25 14C23.34 14 22 12.66 22 11ZM24 11C24 11.56 24.44 12 25 12C25.56 12 26 11.56 26 11C26 10.44 25.56 10 25 10C24.44 10 24 10.44 24 11Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4H30C31.1 4 32 4.9 32 6V26C32 27.1 31.1 28 30 28H2C0.9 28 0 27.1 0 26V6C0 4.9 0.9 4 2 4ZM2 19.42V26H15.98L5.76 15.76L5.68 15.7L2 19.42ZM14.1 21.3L18.8 26H25.98L17.76 17.76L17.68 17.7L14.18 21.22L14.1 21.3ZM28.8 26H30V6H2V16.58L4.98 13.58C5.38 13.2 6 13.2 6.4 13.58L12.68 19.88L16.98 15.58C17.38 15.2 18 15.2 18.4 15.58L28.8 26Z"
      />
    </svg>
  );
}
