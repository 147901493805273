import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function Pole(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.96231 33.7026L12.7422 38.2691L9.74206 36.049C9.6504 35.9811 9.63391 35.8505 9.70584 35.762L12.2454 32.6382C12.2749 32.6019 12.2907 32.5566 12.2902 32.5099L12.225 26.3815C12.2231 26.2031 12.0063 26.1162 11.8818 26.244L4.92029 33.3906C4.8297 33.4836 4.85035 33.6369 4.96231 33.7026Z"
        fill="#1D1D1B"
      />
      <path
        d="M4.96231 33.7026L12.7422 38.2691L9.74206 36.049C9.6504 35.9811 9.63391 35.8505 9.70584 35.762L12.2454 32.6382C12.2749 32.6019 12.2907 32.5566 12.2902 32.5099L12.225 26.3815C12.2231 26.2031 12.0063 26.1162 11.8818 26.244L4.92029 33.3906C4.8297 33.4836 4.85035 33.6369 4.96231 33.7026Z"
        stroke="#1D1D1B"
        strokeWidth="0.8"
        strokeLinejoin="round"
      />
      <path
        d="M35.257 17.2311C35.257 17.3726 35.2007 17.5453 35.108 17.6914C28.1946 28.5887 21.2815 19.0746 14.3681 28.8049C14.0635 29.2336 13.6088 29.2617 13.6088 28.8285L13.6093 14.5166C13.6093 14.375 13.6656 14.2024 13.7583 14.0563C20.6717 3.15898 27.5847 12.673 34.4982 2.9427C34.8028 2.51401 35.2574 2.48595 35.2574 2.9191L35.257 17.2311Z"
        fill="#D5C8FB"
        stroke="#1D1D1B"
        strokeWidth="0.8"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_41323_9215)">
        <path
          d="M23.6721 15.3712C23.1289 14.9497 22.8295 14.1487 22.8303 13.1678C23.638 12.8998 24.3838 13.0024 24.9227 13.4203C25.4655 13.8402 25.7649 14.5455 25.7645 15.4477C24.9567 15.8113 24.211 15.7973 23.6721 15.3712ZM28.3199 13.1365C27.7175 14.0233 26.9344 14.7981 26.1215 15.2648C26.2101 14.324 26.5937 13.2954 27.1997 12.4114C27.8022 11.5268 28.5853 10.8477 29.3982 10.4596C29.31 11.3044 28.9259 12.2446 28.3199 13.1365ZM28.1956 15.5414C28.7384 15.8345 29.0382 16.4229 29.0374 17.2405C28.2296 17.7078 27.4839 17.8202 26.945 17.52C26.4022 17.2247 26.1028 16.5404 26.1032 15.6444C26.9109 15.2733 27.6567 15.2491 28.1956 15.541L28.1956 15.5414ZM23.5477 18.1105C24.1502 17.0999 24.9448 16.2979 25.7573 15.8253C25.6687 16.7735 25.274 17.8467 24.6679 18.8655C24.0655 19.8782 23.2824 20.7692 22.4695 21.3211C22.5577 20.2775 22.9417 19.1213 23.5477 18.1109L23.5477 18.1105Z"
          fill="#1D1D1B"
        />
      </g>
      <rect
        x="11.9336"
        y="9.37543"
        width="2"
        height="29"
        rx="1"
        fill="white"
        stroke="#1D1D1B"
        strokeWidth="0.8"
      />
      <path
        d="M11.9336 17.3755V17.3755C12.5883 17.5544 13.2789 17.5544 13.9336 17.3755V17.3755V20.3755H11.9336V17.3755Z"
        fill="#FFEADD"
        stroke="#1D1D1B"
        strokeWidth="0.8"
        strokeLinejoin="round"
      />
      <path
        d="M11.9336 19.3755V19.3755C12.5883 19.5544 13.2789 19.5544 13.9336 19.3755V19.3755V22.3755H11.9336V19.3755Z"
        fill="white"
        stroke="#1D1D1B"
        strokeWidth="0.8"
        strokeLinejoin="round"
      />
      <path
        d="M11.9336 21.3755V21.3755C12.5948 21.496 13.2724 21.496 13.9336 21.3755V21.3755V23.3755H11.9336V21.3755Z"
        fill="#D5C8FB"
        stroke="#1D1D1B"
        strokeWidth="0.8"
        strokeLinejoin="round"
      />
      <circle cx="12.9336" cy="8.37543" r="2" fill="#FFEADD" stroke="#1D1D1B" strokeWidth="0.8" />
      <defs>
        <clipPath id="clip0_41323_9215">
          <rect
            width="8"
            height="8"
            fill="white"
            transform="matrix(0.86601 -0.500028 -3.18351e-05 1 22.4697 13.376)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
