import { type ChangeEvent, type MouseEvent, type ReactNode, useState } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import { EINVOICING_UNIT_CODES } from 'qonto/constants/receivable-invoice';
import { ChevronBottomOutlined } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface UnitSearchTriggerProps {
  onChange: (value: string) => void;
  value: string;
  disabled: boolean;
  maxLength: number;
  select?: { actions: { open: () => void } };
}

const PREDEFINED_VALUE = 'predefined_value';
const CUSTOM_VALUE = 'custom';

export function UnitSearchTrigger({
  onChange,
  value = '',
  disabled,
  maxLength,
  select,
}: UnitSearchTriggerProps): ReactNode {
  const [hasTypedCustomUnit, setHasTypedCustomUnit] = useState<boolean>(false);
  const [valueDisplayedOnTyping, setValueDisplayedOnTyping] = useState<string>('');

  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    // a string with only whitespace won't be sent to BE as it will only take space in the pdf
    onChange(event.target.value.trim());

    // in the case the user types anything === to a unit code, the value should not be
    // already updated with the translation of the code, so tracking the text typed will prevent this from happening
    setValueDisplayedOnTyping(event.target.value);
  };

  const handleFocusOut = (): void => {
    setValueDisplayedOnTyping('');

    if (!hasTypedCustomUnit && value.length > 0) {
      const type = EINVOICING_UNIT_CODES.includes(value) ? PREDEFINED_VALUE : CUSTOM_VALUE;

      segment.track('invoice-creation_item_unit_filled', { type });

      // the event will be recorded only one time per item each time the form will be opened
      setHasTypedCustomUnit(true);
    }
  };

  const handleFocus = (): void => {
    select?.actions.open();
  };

  const handleMouseUp = (event: MouseEvent<HTMLInputElement>): void => {
    event.stopPropagation();
    select?.actions.open();
  };

  const valueDisplayed = (): string => {
    // while typing and focus in, the typed text will be displayed
    if (valueDisplayedOnTyping.length > 0) {
      return valueDisplayedOnTyping;
    } else if (EINVOICING_UNIT_CODES.includes(value)) {
      // on focus out, the translations of the code will be displayed
      return formatMessage({ id: `unit.${value}` });
    }

    return value;
  };

  return (
    <div className={styles.wrapper}>
      <input
        className={`input-field ${styles['search-input']}`}
        data-test-item-unit-search-trigger
        disabled={disabled}
        maxLength={maxLength}
        onBlur={handleFocusOut}
        onChange={handleChange}
        onFocus={handleFocus}
        onMouseUp={handleMouseUp}
        placeholder={formatMessage({
          id: 'receivable-invoices.invoice-creation.items.unit.placeholder',
        })}
        value={valueDisplayed()}
      />
      <span className={styles['arrow-container']}>
        {/*dropdown-arrow comes from PowerSelect library*/}
        {/*we need to keep it for the chevron animation to work properly when the dropdown is opened*/}
        <ChevronBottomOutlined className="dropdown-arrow" />
      </span>
    </div>
  );
}
