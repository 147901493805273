import { type ReactNode, type SVGProps } from 'react';

export function IconShield(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m8.59.189 1.135.806a5.178 5.178 0 0 0 3.05.922h.01c.278 0 .551.028.742.056h.005c.614.097.968.655.968 1.203v.03l-.348 5.842c-.082 1.714-.922 3.324-2.314 4.327l-.002.001-3.546 2.531a.5.5 0 0 1-.58 0l-3.552-2.535a5.74 5.74 0 0 1-2.31-4.324L1.5 3.206l-.001-.03c0-.542.364-1.06.926-1.194.26-.065.585-.065.829-.065H3.3c1.051.023 2.112-.32 2.998-.946H6.3L7.412.187c.348-.25.83-.25 1.177 0ZM7.994 1l-.005.003-1.115.784c-1.046.739-2.313 1.155-3.591 1.129-.294 0-.492.004-.615.035l-.009.002c-.077.019-.155.102-.16.211l.347 5.833a4.74 4.74 0 0 0 1.9 3.567L8 14.885l3.253-2.321h.001c1.135-.82 1.832-2.143 1.9-3.566v-.006l.346-5.827a.27.27 0 0 0-.05-.155c-.025-.032-.05-.045-.071-.048a4.355 4.355 0 0 0-.589-.045 6.179 6.179 0 0 1-3.636-1.1l-.005-.005-1.144-.811a.022.022 0 0 0-.01 0Zm2.877 5.015a.5.5 0 0 1-.004.707l-3.503 3.465a.5.5 0 0 1-.75-.053l-1.379-1.81a.5.5 0 0 1 .796-.607l1.034 1.359 3.099-3.065a.5.5 0 0 1 .707.004Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
