import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useEffect, type ReactElement } from 'react';
import {
  Sidebar as BaseSidebar,
  type SidebarProps as BaseSidebarProps,
} from 'qonto/react/components/sidebar';
import type { FinancingModel } from 'qonto/react/models/financing';
import type { SidebarSlots } from 'qonto/react/components/sidebar/sidebar';
import { FinancingDetails } from './financing-details';
import { Header } from './header';
import { ProcessingRepaymentInfo } from './processing-repayment-info';

interface SidebarProps extends BaseSidebarProps {
  financing?: FinancingModel;
}

function getSlots(financing?: FinancingModel): SidebarSlots | undefined {
  if (!financing) {
    return undefined;
  }

  const contents = [];

  if (financing.status === 'processing_repayment') {
    contents.push(<ProcessingRepaymentInfo key="processing-repayment-info" />);
  }

  contents.push(<FinancingDetails financing={financing} key="financing-details" />);

  return {
    header: <Header financing={financing} />,
    contents,
  };
}

export function Sidebar({ financing, isOpened, onClose, title }: SidebarProps): ReactElement {
  const segment = useEmberService('segment');

  useEffect(() => {
    if (isOpened) {
      segment.track('pay-later_cockpit-financing-details_displayed');
    }
  }, [segment, isOpened]);

  return (
    <BaseSidebar isOpened={isOpened} onClose={onClose} slots={getSlots(financing)} title={title} />
  );
}
