import { forwardRef, type ButtonHTMLAttributes, type ReactNode } from 'react';
import { IconChevronLeftOutlined } from '@repo/design-system-kit';
import cx from 'clsx';
import styles from './filter-button.strict-module.css';

interface FilterButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isOpen: boolean;
  children: ReactNode;
  isActive: boolean;
}

export const FilterButton = forwardRef<HTMLButtonElement, FilterButtonProps>(
  function FilterButton(props, ref): ReactNode {
    const { children, className, isOpen, isActive, ...restProps } = props;
    return (
      <button
        className={cx(className, 'btn-filter', styles.btn, isActive && 'btn--active')}
        {...restProps}
        ref={ref}
        type="button"
      >
        {children}
        <IconChevronLeftOutlined
          className={cx(isOpen && styles['is-open'])}
          data-test-filter-arrow-icon
        />
      </button>
    );
  }
);
