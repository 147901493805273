import type { ReactNode, SVGProps } from 'react';

export function BulletPoint({ ...props }: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      width="16"
      {...props}
    >
      <circle cx="8" cy="8" r="3" fill="currentColor" />
    </svg>
  );
}
