import React, { type ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import { FormattedMessage } from 'react-intl';
import { CrossOutlined } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface HeaderProps {
  numberOfTransactions: number;
  onClose: () => void;
}

export function Header({ numberOfTransactions, onClose }: HeaderProps): ReactNode {
  return (
    <header className={styles.header} data-testid="header">
      <h1 className={styles.title}>
        <FormattedMessage
          id="transactions.sidebar.bulk.selected"
          values={{
            count: numberOfTransactions,
          }}
        />
      </h1>
      <Button data-testid="close-button" iconOnly onPress={onClose} variant="tertiary">
        <CrossOutlined />
      </Button>
    </header>
  );
}
