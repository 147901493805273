import { createContext, useContext, type ReactNode } from 'react';
import type ModalsService from 'ember-promise-modals/services';

export interface EmberRouteContext {
  routeContext: {
    openModal: ModalsService['open'];
    highlightItem: (id: string) => void;
  };
}

export const RouteContext = createContext<EmberRouteContext | null>(null);

export function RouteContextProvider({
  children,
  routeContext,
}: {
  children: ReactNode;
  routeContext: EmberRouteContext['routeContext'];
}): ReactNode {
  return (
    <RouteContext.Provider
      value={{
        routeContext,
      }}
    >
      {children}
    </RouteContext.Provider>
  );
}

export function useRouteContext(): EmberRouteContext['routeContext'] {
  const context = useContext(RouteContext);
  if (!context) {
    throw new Error('useRouteContext must be used within a RouteContextProvider');
  }

  return context.routeContext;
}

export const routeContextManager = {
  useRouteContext,
};
