import { type ReactNode } from 'react';

export function People(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="square"
        d="M7.471 2.097C7.888 1.155 8.811.5 9.882.5c1.462 0 2.647 1.22 2.647 2.726 0 1.28-.309 2.467-1.08 3.714a.427.427 0 0 0 .143.597c2.146 1.219 3.915 1.916 3.915 3.524v1.08a.283.283 0 0 1-.283.282h-2.582M7.472 2.098c1.374.281 2.41 1.525 2.41 3.018 0 1.462-.358 2.816-1.254 4.241a.425.425 0 0 0 .142.598c1.638.93 3.088 1.59 3.872 2.468M7.472 2.098a2.941 2.941 0 0 0-.59-.06c-1.657 0-3 1.378-3 3.078 0 1.462.358 2.816 1.254 4.241a.426.426 0 0 1-.142.598C2.539 11.348.507 12.134.507 13.961v1.255c0 .157.127.283.283.283h12.184a.283.283 0 0 0 .283-.283v-1.255c0-.608-.225-1.1-.615-1.539"
      />
    </svg>
  );
}
