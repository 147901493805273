import { type ReactNode, type SVGProps } from 'react';

export function InPossession(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.644 39.4209L2.72718 27.0904C2.39986 26.8975 2.39887 26.4244 2.72537 26.23L15.2033 18.8036C15.5178 18.6165 15.9093 18.616 16.2242 18.8025L37.1385 31.1865C37.4657 31.3802 37.4655 31.8538 37.1381 32.0472L24.6606 39.4203C24.3471 39.6056 23.9577 39.6058 23.644 39.4209Z"
        fill="white"
      />
      <path
        d="M23.644 39.4209L2.72718 27.0904C2.39986 26.8975 2.39887 26.4244 2.72537 26.23L15.2033 18.8036C15.5178 18.6165 15.9093 18.616 16.2242 18.8025L37.1385 31.1865C37.4657 31.3802 37.4655 31.8538 37.1381 32.0472L24.6606 39.4203C24.3471 39.6056 23.9577 39.6058 23.644 39.4209Z"
        stroke="#1D1D1B"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 20.1343V18.5471C2 18.3697 2.09401 18.2056 2.24703 18.1158L15.4857 10.3509C15.6263 10.2684 15.8005 10.2684 15.9411 10.3509L37.8659 23.2173V25.1338C37.8659 25.3112 37.7719 25.4752 37.619 25.565L24.9132 33.0236C24.4436 33.2992 23.8616 33.2987 23.3924 33.0224L2.24621 20.5651C2.09365 20.4753 2 20.3114 2 20.1343Z"
        fill="#1D1D1B"
      />
      <path
        d="M2 20.1343V18.5471C2 18.3697 2.09401 18.2056 2.24703 18.1158L15.4857 10.3509C15.6263 10.2684 15.8005 10.2684 15.9411 10.3509L37.8659 23.2173V25.1338C37.8659 25.3112 37.7719 25.4752 37.619 25.565L24.9132 33.0236C24.4436 33.2992 23.8616 33.2987 23.3924 33.0224L2.24621 20.5651C2.09365 20.4753 2 20.3114 2 20.1343Z"
        stroke="#1D1D1B"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.644 31.021L2.72718 18.6905C2.39986 18.4976 2.39887 18.0245 2.72537 17.8301L15.2033 10.4037C15.5178 10.2166 15.9093 10.2161 16.2242 10.4026L37.1385 22.7866C37.4657 22.9803 37.4655 23.4539 37.1381 23.6473L24.6606 31.0204C24.3471 31.2057 23.9577 31.2059 23.644 31.021Z"
        fill="#D5C8FB"
      />
      <path
        d="M23.644 31.021L2.72718 18.6905C2.39986 18.4976 2.39887 18.0245 2.72537 17.8301L15.2033 10.4037C15.5178 10.2166 15.9093 10.2161 16.2242 10.4026L37.1385 22.7866C37.4657 22.9803 37.4655 23.4539 37.1381 23.6473L24.6606 31.0204C24.3471 31.2057 23.9577 31.2059 23.644 31.021Z"
        stroke="#1D1D1B"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.175 13.7224C15.1797 13.7196 15.1874 13.7196 15.1922 13.7224L19.9051 16.5292C19.9525 16.5574 19.9525 16.6033 19.9051 16.6315L16.9523 18.39C16.9476 18.3929 16.9399 18.3929 16.9351 18.39L12.2223 15.5833C12.1748 15.555 12.1748 15.5092 12.2223 15.4809L15.175 13.7224Z"
        fill="#FFEADD"
      />
      <path
        d="M15.175 13.7224C15.1797 13.7196 15.1874 13.7196 15.1922 13.7224L19.9051 16.5292C19.9525 16.5574 19.9525 16.6033 19.9051 16.6315L16.9523 18.39C16.9476 18.3929 16.9399 18.3929 16.9351 18.39L12.2223 15.5833C12.1748 15.555 12.1748 15.5092 12.2223 15.4809L15.175 13.7224Z"
        stroke="#1D1D1B"
        strokeWidth="0.8"
      />
      <path
        d="M12.8685 7.69995C14.6363 6.7392 17.1507 6.7392 18.592 7.69995M11.3096 4.99085C14.1167 3.4072 18.1103 3.4072 20.4017 4.99085M9.5 2.33665C13.5135 0.155439 19.2231 0.153328 22.5 2.33665"
        stroke="#393937"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8685 8.69995C14.6363 7.7392 17.1507 7.7392 18.592 8.69995M11.3096 5.99085C14.1167 4.4072 18.1103 4.4072 20.4017 5.99085M9.5 3.33665C13.5135 1.15544 19.2231 1.15333 22.5 3.33665"
        stroke="white"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
