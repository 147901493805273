import { useEffect, useState, type ReactNode } from 'react';
import {
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
  type ColumnPinningState,
  type RowSelectionState,
} from '@tanstack/react-table';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { bulkSelectionManager } from 'qonto/react/contexts/bulk-selection-context';
import { CellProvider } from 'qonto/react/contexts/cell-context';
import type { Transaction } from 'qonto/react/graphql';
import { useTrackRender } from 'qonto/react/hooks/use-track-render';
import { HeaderCell } from './components/header-cell';
import styles from './styles.strict-module.css';
import { BodyCell } from './components/body-cell';

interface TableProps<TData, TValue> {
  data: TData[];
  columns: ColumnDef<TData, TValue>[];
  defaultColumnOrder: string[];
}

type TablesPreferences = {
  columnOrder?: string[];
  columnSizing?: Record<string, number>;
} | null;

export function DataTable<TData extends Transaction, TValue>({
  data,
  columns,
  defaultColumnOrder,
}: TableProps<TData, TValue>): ReactNode {
  useTrackRender({ eventName: 'transactions-modular-table-loaded' });
  const tablesPreferences = JSON.parse(
    localStorage.getItem('tablesPreferences') ?? '{}'
  ) as TablesPreferences;
  const rightAlignedHeaders = ['amount', 'settledBalance'];

  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: ['bulk-select', 'transaction'],
  });
  const [columnOrder, setColumnOrder] = useState<string[]>(
    () => tablesPreferences?.columnOrder ?? defaultColumnOrder
  );

  // handles selection at the table level
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  // handles selection at the context level
  const { setSelection, selectedItemIds, shouldResetSelection, resetSelection } =
    bulkSelectionManager.useBulkSelection();

  useEffect(() => {
    setRowSelection(prev => {
      const initialRowSelection = selectedItemIds.reduce<RowSelectionState>((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});
      return { ...prev, ...initialRowSelection };
    });
  }, [selectedItemIds]);

  const table = useReactTable({
    data,
    columns,
    state: { columnPinning, columnOrder, rowSelection },
    columnResizeMode: 'onChange',
    enableRowSelection: true,
    getRowId: row => row.id,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    onColumnPinningChange: setColumnPinning,
    onColumnOrderChange: setColumnOrder,
  });

  useEffect(() => {
    setSelection(Object.keys(rowSelection));
  }, [rowSelection, setSelection]);

  useEffect(() => {
    if (shouldResetSelection) {
      table.resetRowSelection();
      resetSelection();
    }
  }, [shouldResetSelection, table, resetSelection]);

  function handleDragEnd(event: DragEndEvent): void {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = columnOrder.indexOf(active.id as string);
      const newIndex = columnOrder.indexOf(over.id as string);
      const newColumnOrder = arrayMove(columnOrder, oldIndex, newIndex);
      setColumnOrder(newColumnOrder);
      const newPref = { ...tablesPreferences, columnOrder: newColumnOrder };
      localStorage.setItem('tablesPreferences', JSON.stringify(newPref));
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const resizedColumn = table.getState().columnSizingInfo.isResizingColumn;

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <table className={styles['data-table']}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
                {headerGroup.headers.map(header => (
                  <HeaderCell
                    header={header}
                    key={header.id}
                    resizedColumn={resizedColumn}
                    {...(rightAlignedHeaders.includes(header.id) && { align: 'right' })}
                  />
                ))}
              </SortableContext>
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.length ? (
            table.getRowModel().rows.map(row => (
              <CellProvider key={row.id} transaction={row.original}>
                <tr className={styles['table-row']} data-testid="transaction-row">
                  {row.getVisibleCells().map((cell, index) => (
                    <SortableContext
                      items={columnOrder}
                      key={cell.id}
                      strategy={horizontalListSortingStrategy}
                    >
                      <BodyCell
                        cell={cell}
                        cellIndex={index}
                        className={styles['table-cell-hovered']}
                        key={cell.id}
                      />
                    </SortableContext>
                  ))}
                </tr>
              </CellProvider>
            ))
          ) : (
            <tr>
              <p>No results</p>
            </tr>
          )}
        </tbody>
      </table>
    </DndContext>
  );
}
