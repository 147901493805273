import { type ReactNode } from 'react';

export function Briefcase(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        d="M10.776 3.136v-1.06c0-.324-.37-.587-.826-.587H6.098c-.456 0-.826.263-.826.587v1.06M.494 13.38a1.11 1.11 0 0 0 1.111 1.108h12.777a1.11 1.11 0 0 0 1.11-1.108V4.238c0-.61-.031-1.076-.642-1.076m.641 10.219a1.11 1.11 0 0 1-1.11 1.108H1.603a1.11 1.11 0 0 1-1.111-1.108V4.238c0-.61.031-1.076.642-1.076h13.928M7.156 9.724v.474c0 .504.409.912.914.912a.913.913 0 0 0 .914-.912v-.474M2.507 3.496v4.879c0 .649.414 1.18.92 1.18h9.203c.506 0 .92-.531.92-1.18V3.496"
      />
    </svg>
  );
}
