import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function External(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11 .501c0-.27.22-.5.5-.5h3.96c.14-.01.28.04.39.15.09.09.15.22.15.35v4c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-2.79l-6.15 6.15c-.2.2-.51.2-.71 0-.2-.2-.2-.51 0-.71l6.15-6.15H11.5c-.28 0-.5-.22-.5-.5Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M16 8.501v6.5c0 .55-.45 1-1 1H1c-.55 0-1-.45-1-1v-14c0-.55.45-1 1-1h6.5c.28 0 .5.22.5.5s-.22.5-.5.5H1v14h14v-6.5c0-.28.22-.5.5-.5s.5.22.5.5Z"
      />
    </svg>
  );
}
