import React from 'react';
import { useIntl } from 'react-intl';
import { FlowFeedback } from '../../screens';
import { FlowFeedbackStatus } from '../../screens/feedback';

interface SuccessScreenProps {
  onClose: () => void;
}
export function SuccessScreen({ onClose }: SuccessScreenProps): React.ReactNode {
  const { formatMessage } = useIntl();
  return (
    <FlowFeedback
      feedback={FlowFeedbackStatus.Success}
      primaryAction={{
        label: formatMessage({ id: 'subscription.account-closing.book-a-call.btn-close' }),
        onClick: onClose,
      }}
      subtitle={formatMessage({ id: 'subscription.account-closing.book-a-call.success.subtitle' })}
      title={formatMessage({ id: 'subscription.account-closing.book-a-call.success.title' })}
    />
  );
}
