import EmberObject from '@ember/object';
import RSVP from 'rsvp';

export const localFilesKeys = [
  'downloadUrl',
  'fileContentType',
  'fileName',
  'fileSize',
  'fileUrl',
  'originalFile',
  'status',
];

class LocalFile extends EmberObject {
  downloadUrl = null;
  fileContentType = null;
  fileName = null;
  fileSize = null;
  fileUrl = null;
  originalFile = null;
  status = null;
}

LocalFile.reopenClass({
  async read(file) {
    let result = await new RSVP.Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onerror = error => reject(error);
      reader.onload = () => resolve(reader.result);

      reader.readAsDataURL(file);
    });

    return LocalFile.create({
      downloadUrl: file.downloadUrl,
      fileContentType: file.type,
      fileName: file.name,
      fileSize: file.size,
      fileUrl: result,
      originalFile: file,
      status: file.status,
    });
  },
});

export default LocalFile;
