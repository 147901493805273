import { type ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

type CustomAvatarSize = '32' | '24';

interface CustomAvatarProps {
  name: string;
  small?: boolean;
  size?: CustomAvatarSize;
}

export function CustomAvatar({ name, small, size = '32' }: CustomAvatarProps): ReactNode {
  const [first = '', second = ''] = name.split(' ');

  const initials = small
    ? (first[0] ?? '').toUpperCase()
    : `${first[0] ?? ''}${second[0] ?? ''}`.toUpperCase();

  return (
    <div
      className={cx(styles['custom-avatar'], styles[`size-${size}`])}
      data-testid="custom-avatar"
    >
      <span>{initials}</span>
    </div>
  );
}
