import React from 'react';
import { LottiePlayer } from '@repo/design-system-kit';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import styles from './waiting.strict-module.css';

export function PagoPaResultWaiting(): React.JSX.Element {
  const lottiePath = useThemedAssetPath('/lotties/pagopa/result-waiting.json');

  return (
    <div className={styles.container} data-test-result-waiting="">
      <LottiePlayer
        className={cx(styles.lottie, 'mb-32')}
        data-test-result-waiting-lottie=""
        path={lottiePath}
      />
      <h1 className={cx('title-1', 'mb-16')} data-test-result-waiting-title="">
        <FormattedMessage id="pagopa.payment-creation.result.waiting.title" />
      </h1>
      <p className="body-1" data-test-result-waiting-subtitle="">
        <FormattedMessage id="pagopa.payment-creation.result.waiting.subtitle" />
      </p>
    </div>
  );
}
