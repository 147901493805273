import { type ReactNode } from 'react';

export function Documents(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.667 12h4.667M4.667 9.332h.667M4.667 6.668h2M4.667 1.332H11l3 3v8.333"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2 13.665V4.332a1 1 0 0 1 1-1h6.501a.4.4 0 0 1 .283.117l2.099 2.1A.4.4 0 0 1 12 5.83v7.834a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.333 3.332v2.267c0 .22.18.4.4.4H12"
      />
    </svg>
  );
}
