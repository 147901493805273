import { type ReactNode, type SVGProps } from 'react';

export function IconThumbtack(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.007.018a1.6 1.6 0 0 1 .611.026c.2.048.39.135.555.257.166.122.305.277.408.455v.001c.103.179.168.376.19.581a1.54 1.54 0 0 1-.054.609 1.6 1.6 0 0 1-.316.581l3.784 3.838.26-.15c.354-.206.775-.271 1.173-.175.4.095.744.346.95.702l.32.555a1.5 1.5 0 0 1 .135 1.175 1.6 1.6 0 0 1-.738.93s0-.001 0 0l-3.4 1.961 1.813 3.135a.5.5 0 1 1-.866.5L9.02 11.865 5.62 13.828c-.354.205-.776.27-1.174.175a1.498 1.498 0 0 1-.95-.703l-.32-.555a1.5 1.5 0 0 1-.134-1.175 1.6 1.6 0 0 1 .737-.929l.261-.15L2.61 5.295a1.6 1.6 0 0 1-.661-.017 1.517 1.517 0 0 1-.963-.712v-.002a1.517 1.517 0 0 1-.136-1.19c.118-.397.389-.734.748-.94L5.43.22c.178-.103.374-.172.578-.203ZM4.87 11.166l-.59.34a.603.603 0 0 0-.279.347.498.498 0 0 0 .042.392l.32.553a.499.499 0 0 0 .318.232c.142.034.3.012.439-.068l7.665-4.426a.603.603 0 0 0 .28-.347.498.498 0 0 0-.042-.391l-.32-.553a.5.5 0 0 0-.318-.232.6.6 0 0 0-.439.068l-7.071 4.082a.131.131 0 0 1-.005.003Zm.066-1.193 5.354-3.09-3.714-3.768L3.53 4.873l1.405 5.1Zm1.533-7.951a.646.646 0 0 0 .183-.156.6.6 0 0 0 .106-.203.54.54 0 0 0 .02-.214.499.499 0 0 0-.393-.433.6.6 0 0 0-.23-.01.642.642 0 0 0-.225.08L2.097 3.3a.625.625 0 0 0-.289.36.518.518 0 0 0 .043.406.518.518 0 0 0 .33.24.623.623 0 0 0 .455-.07L6.47 2.022Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
