import { type ReactNode } from 'react';
import { Popup } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import type { CashflowParentCategory } from 'qonto/react/models/cash-flow-category';
import styles from './category-item-new.strict-module.css';

interface ConfirmFirstSubcategoryCreationPopupProps {
  isOpen: boolean;
  onPopupCancel: () => void;
  onPopupConfirm: () => void;
  parentCategory: CashflowParentCategory;
}
export function ConfirmFirstSubcategoryCreationPopup({
  isOpen,
  parentCategory,
  onPopupCancel,
  onPopupConfirm,
}: ConfirmFirstSubcategoryCreationPopupProps): ReactNode {
  const { formatMessage } = useIntl();
  const { name } = parentCategory;

  return (
    <Popup
      cancelText={formatMessage({
        id: 'categories-management.create-subcategory.modal.cancel.cta',
      })}
      confirmText={formatMessage({
        id: 'categories-management.create-subcategory.modal.create.cta',
      })}
      data-test-id="confirm-first-subcategory-creation-modal"
      isOpen={isOpen}
      onCancel={onPopupCancel}
      onConfirm={onPopupConfirm}
      title={formatMessage({
        id: 'categories-management.create-subcategory.modal.title',
      })}
    >
      <section className={cx('body-1', styles['popup-content'])}>
        <p data-test-id="modal-subtitle">
          {formatMessage(
            {
              id: 'categories-management.create-subcategory.modal.subtitle',
            },
            { parentCategory: name }
          )}
        </p>
        <ul>
          <li data-test-id="modal-point1">
            {formatMessage({
              id: 'categories-management.create-subcategory.modal.point1',
            })}
          </li>
          <li data-test-id="modal-point2">
            {formatMessage(
              {
                id: 'categories-management.create-subcategory.modal.point2',
              },
              { parentCategory: name }
            )}
          </li>
          <li data-test-id="modal-point3">
            {formatMessage(
              {
                id: 'categories-management.create-subcategory.modal.point3',
              },
              { parentCategory: name }
            )}
          </li>
        </ul>
      </section>
    </Popup>
  );
}
