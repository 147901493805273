import { type ReactNode } from 'react';

export function Tool(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M5.209 10.821c.051-.077.103-.153.18-.23l5.457-5.351m-3.7 7.495c.077-.051.122-.098.199-.175L12.8 7.139m.67-2.608-.308-1.228 1.57-1.56s-1.648-.894-3.166.614a2.858 2.858 0 0 0 0 4.066 2.902 2.902 0 0 0 4.092 0c1.415-1.406.592-3.145.592-3.145l-1.57 1.56-1.21-.307Zm-8.953 8.898.309 1.228-1.57 1.56s1.647.894 3.165-.614a2.858 2.858 0 0 0 0-4.066 2.902 2.902 0 0 0-4.092 0c-1.415 1.406-.617 3.145-.617 3.145l1.57-1.56 1.235.307Z"
      />
    </svg>
  );
}
