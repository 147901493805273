import { type ReactElement } from 'react';
import cx from 'clsx';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

export function CardPaymentLoading(): ReactElement {
  return (
    <div className={styles.skeleton} data-testid="card-payment-loading">
      <SkeletonLoader.Block
        borderRadius="12px"
        className="mb-32"
        data-test-skeleton-block=""
        height="14px"
        width="280px"
      />
      <SkeletonLoader.Line
        className="mb-48"
        data-test-skeleton-line=""
        height="8px"
        width="240px"
      />
      <SkeletonLoader.Line className="mb-12" data-test-skeleton-line="" width="140px" />
      <SkeletonLoader.Block
        borderRadius="4px"
        className="mb-24"
        data-test-skeleton-block=""
        height="40px"
        width="100%"
      />
      <div className={cx('mb-40', styles.skeletonCvvDate)}>
        <div>
          <SkeletonLoader.Line
            className="mb-12"
            data-test-skeleton-line=""
            standalone
            width="140px"
          />
          <SkeletonLoader.Block
            borderRadius="4px"
            data-test-skeleton-block=""
            height="40px"
            width="240px"
          />
        </div>
        <div>
          <SkeletonLoader.Line
            className="mb-12"
            data-test-skeleton-line=""
            standalone
            width="140px"
          />
          <SkeletonLoader.Block
            borderRadius="4px"
            data-test-skeleton-block=""
            height="40px"
            width="240px"
          />
        </div>
      </div>
      <SkeletonLoader.Block
        borderRadius="4px"
        className="mb-24"
        data-test-skeleton-block=""
        height="40px"
        width="100%"
      />
      <SkeletonLoader.Line className="mb-24" data-test-skeleton-line="" width="240px" />
    </div>
  );
}
