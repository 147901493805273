import { type ReactNode } from 'react';

export function LightBulb(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M6.476 11.793a5.378 5.378 0 0 0-1.664-3.88 4.252 4.252 0 0 1-1.318-3.072c0-2.4 2.014-4.345 4.5-4.345 2.485 0 4.5 1.945 4.5 4.345 0 1.2-.504 2.286-1.318 3.072a5.378 5.378 0 0 0-1.665 3.88M7.997 8.279l.71-2.155H7.288l.708-2.155m1.993 7.822H6.009v2.992c0 .392.333.713.738.713h2.505c.406 0 .738-.32.738-.713v-2.992Z"
      />
    </svg>
  );
}
