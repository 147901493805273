import React, { type ReactElement } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface TypeProps {
  content: string;
  status: string;
}

export function Type({ content, status }: TypeProps): ReactElement {
  const className = cx(styles.type, styles[status]);

  return (
    <h2 className={className} data-test-type>
      {content}
    </h2>
  );
}
