import { type ReactNode } from 'react';
import styles from './styles.strict-module.css';

interface ObfuscatedIbanProps {
  iban?: string;
}

const DOTS = [1, 2, 3, 4];

export function ObfuscatedIban({ iban }: ObfuscatedIbanProps): ReactNode {
  return (
    <div className={`body-1 ${styles.wrapper}`} data-test-obfuscated-iban>
      <div
        aria-hidden="true"
        className={styles['dots-wrapper']}
        data-test-obfuscated-iban-dots-wrapper
      >
        {DOTS.map(id => (
          <span className={styles.dot} data-test-obfuscated-iban-dot key={id} />
        ))}
      </div>

      <span data-test-obfuscated-iban-digits>{iban?.slice(-4)}</span>
    </div>
  );
}
