import { useEffect, useState } from 'react';
import type RouteInfo from '@ember/routing/route-info';
import { useEmberService, useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import type { CashflowCategorySide } from 'qonto/react/models/cash-flow-category';

export const useCategoriesManagementRouting = (): {
  currentType: CashflowCategorySide;
  routeParams: Record<string, string | undefined>;
  showSidePanel: boolean;
  isCreatingNewCategory: boolean;
  isCreatingNewSubcategoryForParent: (categoryId: string) => boolean;
  navigateToType: (type: CashflowCategorySide) => void;
  navigateToNewCategory: () => void;
  navigateToNewSubcategory: (categoryId: string) => void;
  navigateToCategoryDetails: (categoryId: string, type?: CashflowCategorySide) => void;
} => {
  const emberRouter = useEmberService('router');
  const router = useRouter();
  const { organization } = useOrganizationManager();
  const baseUrl = `/organizations/${organization.slug}/cash-flow-categories`;

  const [routeParams, setRouteParams] = useState<Record<string, string | undefined>>(
    getAllParams(emberRouter.currentRoute)
  );

  useEffect(() => {
    const handleRouteChange = (): void => {
      setRouteParams(getAllParams(emberRouter.currentRoute));
    };

    emberRouter.on('routeDidChange', handleRouteChange);
    return () => {
      emberRouter.on('routeDidChange', handleRouteChange);
    };
  }, [emberRouter]);

  const currentType = routeParams.type?.slice(0, -1) as CashflowCategorySide;

  const showSidePanel =
    emberRouter.currentRoute.name === 'cash-flow-categories.type.categories.category.index';
  const isCreatingNewCategory =
    emberRouter.currentRoute.name === 'cash-flow-categories.type.categories.new';
  const isCreatingNewSubcategory =
    emberRouter.currentRoute.name === 'cash-flow-categories.type.categories.category.new';

  const isCreatingNewSubcategoryForParent = (categoryId: string): boolean =>
    routeParams.category_id === categoryId && isCreatingNewSubcategory;

  const navigateToType = (type: CashflowCategorySide): void => {
    void router.push(`${baseUrl}/${`${type}s`}`);
  };

  const navigateToNewCategory = (): void => {
    void router.push(`${baseUrl}/${`${currentType}s`}/categories/new`);
  };

  const navigateToNewSubcategory = (categoryId: string): void => {
    void router.push(`${baseUrl}/${`${currentType}s`}/categories/${categoryId}/new`);
  };

  const navigateToCategoryDetails = (
    categoryId: string,
    type: CashflowCategorySide = currentType
  ): void => {
    void router.push(`${baseUrl}/${`${type}s`}/categories/${categoryId}`);
  };

  return {
    routeParams,
    currentType,
    showSidePanel,
    isCreatingNewCategory,
    isCreatingNewSubcategoryForParent,
    navigateToType,
    navigateToNewCategory,
    navigateToNewSubcategory,
    navigateToCategoryDetails,
  };
};

const getAllParams = (currentRoute: RouteInfo): Record<string, string | undefined> => {
  const params = { ...currentRoute.params };
  if (currentRoute.parent) {
    return { ...getAllParams(currentRoute.parent), ...params };
  }
  return params;
};
