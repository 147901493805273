import { Badge, Spinner } from '@repo/design-system-kit';
import cx from 'clsx';
import { type FC, type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SddOneOff, SddRecurring, SddRecurringInvoice } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

type CardType = 'one-off' | 'recurring' | 'recurring-invoice';

interface TypeCardProps {
  type: CardType;
  showUnlockBadge?: boolean;
  onClick: (type: CardType) => void;
  isLoading?: boolean;
}

const getTypeIcon = (type: CardType): FC<{ className?: string }> => {
  switch (type) {
    case 'one-off':
      return SddOneOff;
    case 'recurring':
      return SddRecurring;
    case 'recurring-invoice':
      return SddRecurringInvoice;
  }
};

export function TypeCard({
  type,
  showUnlockBadge,
  onClick,
  isLoading = false,
}: TypeCardProps): ReactNode {
  const { formatMessage } = useIntl();
  const TypeIcon = getTypeIcon(type);

  return (
    <button
      className={styles.container}
      data-test-type-card={type}
      onClick={() => {
        onClick(type);
      }}
      type="button"
    >
      <div className={cx(styles['type-card'], isLoading && styles.loading)}>
        <TypeIcon className={styles['type-icon']} data-test-type-icon={type} />

        {showUnlockBadge ? (
          <Badge
            className="mb-8"
            data-test-type-card-unlock-badge
            text={formatMessage({ id: 'upsell.add-ons.badge' })}
            type="upgrade"
          />
        ) : null}

        <header className="title-3 mb-8" data-test-type-card-title>
          <FormattedMessage id={`sdd-collections.creation-flow.type-selection.${type}.title`} />
        </header>

        <p className="body-2" data-test-type-card-subtitle>
          <FormattedMessage
            id={`sdd-collections.creation-flow.type-selection.${type}.description`}
          />
        </p>
      </div>

      {isLoading ? (
        <Spinner
          className={styles.spinner}
          color="primary-a"
          data-test-type-card-loading-spinner
          size="large"
        />
      ) : null}
    </button>
  );
}
