import { TabList, TabPanel, Tabs } from '@repo/design-system-kit';
import React, { type ReactNode } from 'react';
import { useSegment } from '@repo/poly-hooks/segment';
import type { Key } from 'react-aria';
import { useIntl } from 'react-intl';
import type { CashflowCategorySide } from 'qonto/react/models/cash-flow-category';
import { useFetchCashflowCategories } from 'qonto/react/hooks/use-fetch-cashflow-categories';
import { CategoriesList } from '../categories-list';
import { useCategoriesManagementRouting } from '../hooks/use-categories-management-routing';
import styles from './categories-tabs.strict-module.css';

export function CategoriesManagementTabs(): ReactNode {
  const query = useFetchCashflowCategories();
  const segment = useSegment();
  const { formatMessage } = useIntl();

  const { currentType, navigateToType } = useCategoriesManagementRouting();

  const handleChangeTab = (key: Key): void => {
    segment.track(`cash-flow-categories_${`${key.toString()}s`}_select`);
    navigateToType(key.toString() as CashflowCategorySide);
  };

  const tabs = [
    {
      id: 'inflow',
      title: formatMessage({ id: 'categories-management.inflows.tab-title' }),
      content: <CategoriesList query={query} type="inflow" />,
      index: 1,
    },
    {
      id: 'outflow',
      title: formatMessage({ id: 'categories-management.outflows.tab-title' }),
      content: <CategoriesList query={query} type="outflow" />,
      index: 2,
    },
  ];

  return (
    <nav data-testid="categories-management-tabs">
      <Tabs activeId={currentType} changeTab={handleChangeTab}>
        <TabList
          tabs={tabs.map(({ id, title, index }) => ({
            id,
            title,
            'data-test-tabs-nav-item': index,
            'data-test-tabs-nav-item-value': id,
          }))}
        />
        <div className={styles['tab-panel-container']}>
          {tabs.map(tab => (
            <TabPanel key={tab.id} tabId={tab.id}>
              {tab.content}
            </TabPanel>
          ))}
        </div>
      </Tabs>
    </nav>
  );
}
