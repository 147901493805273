import type { NormalizedBalances } from './normalize-balances';
import { transposeTableData, type TransposedData } from './transpose-table-data';

export interface TransposedBalances {
  startBalances: TransposedData[];
  endBalances: TransposedData[];
}

export const transposeBalances = (balances: NormalizedBalances): TransposedBalances => {
  return {
    startBalances: transposeTableData(balances.startBalances),
    endBalances: transposeTableData(balances.endBalances),
  };
};
