import { type ComponentPropsWithoutRef, type ReactElement, type ReactNode } from 'react';
import { Checkbox as BaseCheckbox } from '@repo/design-system-kit';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface CheckboxProps<T extends { id: string }>
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onSelect' | 'title'> {
  icon?: ReactElement | null;
  title: ReactElement;
  subtitle: ReactElement;
  item: T;
  selected: string[];
  onSelect: (item: T) => void;
}

export function Checkbox<T extends { id: string }>({
  icon,
  title,
  subtitle,
  item,
  selected,
  onSelect,
  className,
  ...props
}: CheckboxProps<T>): ReactNode {
  return (
    <div
      className={cx(styles.selector, className)}
      data-test-selector-checkbox={item.id}
      {...props}
    >
      <div className={styles.content}>
        <BaseCheckbox
          className="mr-16"
          data-test-selector-checkbox-input
          id={item.id}
          isSelected={Boolean(selected.find(id => id === item.id))}
          onChange={() => {
            onSelect(item);
          }}
        />
        <div className={styles.header}>
          {icon ? (
            <div className={styles.icon} data-test-selector-checkbox-icon>
              {icon}
            </div>
          ) : null}
          <div className="ml-16">
            <div data-test-selector-checkbox-title>{title}</div>
            <div data-test-selector-checkbox-subtitle>{subtitle}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
