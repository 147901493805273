import React, { type ReactNode } from 'react';
import { PageHeader } from './page-header';
import { CategoriesManagementTabs } from './categories-tabs';
import { CategoriesManagementSidePanel } from './categories-side-panel';
import styles from './categories-management-page.strict-module.css';

export function CategoriesManagementPage(): ReactNode {
  return (
    <div className={styles['page-container']}>
      <div className={styles.wrapper}>
        <PageHeader />
        <CategoriesManagementTabs />
      </div>
      <div className={styles['side-panel-wrapper']}>
        <CategoriesManagementSidePanel />
      </div>
    </div>
  );
}
