import { SkeletonLoader } from '@repo/design-system-kit';
import type { HTMLAttributes, ReactElement } from 'react';
import cx from 'clsx';
import styles from './approver-placeholder.strict-module.css';

export function ApproverPlaceholder({
  className,
  ...props
}: HTMLAttributes<HTMLTableRowElement>): ReactElement {
  return (
    <tr className={cx(styles.row, className)} {...props}>
      <td aria-hidden="true" />
      <td className={styles.cell}>
        <div className={styles.cellContent}>
          <SkeletonLoader.Block
            borderRadius="1px"
            className={styles.icon}
            data-test-card-requests-table-approver-placeholder-block=""
            height="20px"
            width="32px"
          />
          <SkeletonLoader.Header
            data-test-card-requests-table-approver-placeholder-header=""
            lines={1}
            smallLinesWidth="192px"
            width="50%"
          />
        </div>
      </td>
      <td className={styles.cell}>
        <div className={styles.cellContent}>
          <SkeletonLoader.Line
            data-test-card-requests-table-approver-placeholder-line=""
            standalone
            width="120px"
          />
        </div>
      </td>
      <td className={styles.cell}>
        <div className={styles.cellContent}>
          <SkeletonLoader.Line
            data-test-card-requests-table-approver-placeholder-line=""
            standalone
            width="96px"
          />
        </div>
      </td>
      <td className={styles.cell}>
        <div className={cx(styles.cellContent, styles.alignRight)}>
          <SkeletonLoader.Line
            data-test-card-requests-table-approver-placeholder-line=""
            standalone
            width="104px"
          />
        </div>
      </td>
      <td className={cx(styles.cell, 'cell-approve')}>
        <SkeletonLoader.Line
          className={styles.approveContent}
          data-test-card-requests-table-approver-placeholder-line=""
          standalone
          width="232px"
        />
      </td>
      <td className={cx(styles.cell, 'empty')} />

      <td aria-hidden="true" />
    </tr>
  );
}
