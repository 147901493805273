import type { SVGProps, ReactNode } from 'react';

export function KycPendingPopupIllustration(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      fill="none"
      height="120"
      viewBox="0 0 120 120"
      width="120"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m76.667 118.353 28.855-16.56a5.992 5.992 0 0 0 2.693-5.017l.065-74.352c-.006-.132-.03-.266-.052-.39-.375-2.754-3.656-4.297-6.17-2.851l-.045.03a1.661 1.661 0 0 1-.089.04L73.235 35.76a5.887 5.887 0 0 0-2.645 4.933l-.032 74.085c.002.14.016.277.033.407.193 2.926 3.48 4.66 6.076 3.169Z"
        fill="#CEF6E9"
        stroke="#1D1D1B"
        strokeWidth=".5"
      />
      <path
        d="m73.12 115.975.072-74.348a5.99 5.99 0 0 1 2.693-5.017l28.721-16.496c1.674-.74 3.687.09 3.691 1.984l-.042 74.088a5.89 5.89 0 0 1-2.645 4.933l-28.683 16.509c-2.086 1.168-3.688.105-3.808-1.653Z"
        fill="#fff"
        stroke="#1D1D1B"
        strokeWidth=".5"
      />
      <path
        d="M85.85 33.608a.811.811 0 0 0 .282-.28.962.962 0 0 0 .13-.45c0-.158-.058-.283-.162-.343-.09-.052-.213-.052-.342.033-.211.127-.373.44-.376.715.002.158.059.283.162.343.09.052.195.047.306-.018Zm7.666-4.582a.714.714 0 0 1-.245.265l-5.83 3.368c-.11.065-.215.07-.305.017-.103-.06-.16-.185-.162-.343.003-.275.165-.588.376-.715l5.83-3.368c.111-.064.215-.07.305-.017.104.06.161.185.162.343a1.009 1.009 0 0 1-.13.45Z"
        stroke="#1D1D1B"
        strokeWidth=".5"
      />
      <path
        d="M88.51 101.988c.072 2.507 1.915 3.584 4.116 2.403 2.199-1.177 3.92-4.159 3.848-6.666-.075-2.502-1.912-3.575-4.11-2.399-2.205 1.173-3.93 4.16-3.854 6.662Z"
        fill="#fff"
        stroke="#1D1D1B"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <path
        d="M90.106 101.163c.041 1.55 1.175 2.212 2.534 1.478 1.357-.731 2.422-2.577 2.382-4.126-.042-1.546-1.173-2.207-2.53-1.476-1.36.729-2.428 2.578-2.386 4.124Z"
        fill="#CEF6E9"
        stroke="#1D1D1B"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <path
        d="M76.954 55.024c-.745.43-1.345 1.47-1.341 2.326l.053 18.897c.004.855.604 1.2 1.349.771l28.309-16.343c.745-.43 1.346-1.47 1.342-2.326l-.053-18.893c-.004-.859-.608-1.205-1.353-.775L76.954 55.024Z"
        fill="#fff"
        stroke="#1D1D1B"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <path
        d="m76.8 54.928 28.305-16.34c.376-.216.714-.239.957-.094l.227.133c-.243-.14-.585-.122-.958.095l-28.305 16.34c-.745.429-1.345 1.47-1.342 2.325l.053 18.897c0 .425.153.726.392.867l-.227-.134c-.243-.14-.391-.44-.391-.866l-.053-18.897c0-.851.596-1.896 1.341-2.326Z"
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <path
        d="M99.5 56.684a.886.886 0 0 0 .403-.696c0-.258-.183-.361-.407-.232l-6.737 3.888a.887.887 0 0 0-.403.699c0 .258.182.36.403.231l6.74-3.89Zm-2.823-3.55a.887.887 0 0 0 .403-.696c0-.258-.183-.36-.407-.232l-3.914 2.26a.887.887 0 0 0-.403.699c0 .258.182.36.403.232l3.918-2.263Zm7.003-4.044a.887.887 0 0 0 .403-.695c0-.259-.182-.361-.407-.232l-3.914 2.26a.887.887 0 0 0-.403.698c0 .259.183.361.403.232l3.918-2.263Zm.396 2.253a.889.889 0 0 0 .403-.7c0-.254-.183-.357-.407-.227l-11.323 6.536a.88.88 0 0 0-.403.695c0 .259.182.361.403.232l11.327-6.536Z"
        fill="#1D1D1B"
      />
      <path
        d="m79.749 58.22 8.895-5.137c.344-.198.62-.198.8-.096.179.103.318.339.32.733l.03 10.221c0 .394-.14.843-.373 1.247-.233.404-.551.75-.894.948l-8.896 5.138c-.344.198-.62.198-.8.095-.179-.102-.318-.338-.32-.732M79.75 58.22l-1.393 12.416h.156M79.75 58.22c-.343.197-.662.543-.895.948-.233.404-.373.852-.373 1.246m1.268-2.194-1.268 2.194m.03 10.221-.03-10.22m.03 10.22-.03-10.22m0 0"
        stroke="#1D1D1B"
        strokeWidth=".5"
      />
      <path
        d="M79.67 58.084c-.744.43-1.344 1.474-1.344 2.33l.03 10.221c.004.855.608 1.201 1.353.772l8.895-5.138c.745-.43 1.346-1.474 1.346-2.33l-.03-10.22c-.005-.856-.609-1.202-1.354-.772l-8.895 5.137Z"
        fill="#DFF9F0"
        stroke="#1D1D1B"
        strokeWidth=".5"
      />
      <path
        d="M84.357 62.886c-2.499 1.486-4.541 5.163-4.685 8.352-.01.199.126.275.293.177l8.784-5.226c.166-.1.302-.337.293-.525-.144-3.019-2.187-4.265-4.685-2.779Zm-.008-.016c1.296-.77 2.346-2.618 2.346-4.126 0-1.508-1.05-2.105-2.346-1.334-1.296.771-2.347 2.619-2.347 4.127 0 1.508 1.05 2.105 2.347 1.334Z"
        fill="#B0F0DA"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeWidth=".5"
      />
      <path
        d="M59.226 9.683c0-1.339-.94-1.889-2.096-1.223L13.172 33.674c-1.156.665-2.095 2.29-2.095 3.63l.001 29.577c.002 1.335.938 1.882 2.092 1.221l43.956-25.214c1.156-.665 2.095-2.29 2.093-3.625l.006-29.58Z"
        fill="#fff"
        stroke="#1D1D1B"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <path
        d="M57.133 8.455 13.178 33.67c-1.157.665-2.096 2.29-2.096 3.63l-.001 29.583c.002.67.237 1.141.62 1.363l-.835-.483c-.384-.221-.619-.692-.62-1.363l.003-29.587c0-1.34.939-2.966 2.096-3.63L56.301 7.967c.574-.332 1.095-.359 1.475-.14l.836.483c-.383-.214-.907-.182-1.479.144Z"
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <path
        d="M15.82 58.015c0 1.34.94 1.89 2.094 1.23l13.818-7.925c1.157-.665 2.095-2.29 2.096-3.63l-.002-16.006c0-1.34-.938-1.882-2.091-1.222l-13.822 7.93c-1.153.66-2.089 2.28-2.09 3.62l-.003 16.003Z"
        fill="#DFF9F0"
        stroke="#1D1D1B"
        strokeWidth=".5"
      />
      <path
        d="m17.631 59.272 14.017-8.038c-.088-4.455-3.195-6.287-7.009-4.1-3.813 2.186-6.922 7.58-7.008 12.138Zm3.002-14.676c-.004 2.45 1.784 3.423 4.008 2.15 2.216-1.27 4.005-4.294 4.001-6.743.003-2.452-1.785-3.424-4.004-2.149-2.216 1.271-4.005 4.294-4.005 6.742Z"
        fill="#B0F0DA"
        stroke="#1D1D1B"
        strokeMiterlimit="10"
        strokeWidth=".5"
      />
      <path
        clipRule="evenodd"
        d="M16.69 68.59a.337.337 0 0 0-.46-.125l-8.059 4.623-.003-9.347a.337.337 0 0 0-.674 0l.004 9.929a.337.337 0 0 0 .504.292l8.564-4.913a.337.337 0 0 0 .125-.46ZM7.836 42.483a.337.337 0 0 0 .337-.337l-.003-9.733 8.39-4.81a.337.337 0 0 0-.334-.585l-8.561 4.907a.337.337 0 0 0-.17.293l.004 9.928c0 .186.15.337.337.337Zm54.002-30.57c.186 0 .337-.15.337-.337l.002-9.925a.337.337 0 0 0-.504-.292l-8.565 4.913a.337.337 0 0 0 .336.584l8.06-4.623-.003 9.343c0 .186.15.337.337.337Zm.163 31.487a.337.337 0 0 0 .17-.293l.002-9.925a.337.337 0 0 0-.674 0l-.002 9.73-8.392 4.81a.337.337 0 1 0 .335.584l8.561-4.907Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M47.817 37.18c.349-.201.627-.687.627-1.083 0-.401-.284-.561-.633-.36L37.33 41.785c-.35.2-.627.686-.627 1.088s.284.561.627.36l10.487-6.053Zm-4.392-5.522c.35-.201.627-.687.627-1.082 0-.402-.284-.562-.633-.36l-6.09 3.514c-.348.2-.626.686-.626 1.088 0 .401.284.561.627.36l6.095-3.52Zm10.895-6.292c.35-.201.627-.686.627-1.082 0-.402-.283-.562-.632-.36l-6.09 3.514c-.349.2-.627.686-.627 1.088s.284.561.627.36l6.096-3.52Zm.616 3.506c.35-.202.627-.687.627-1.088 0-.397-.284-.556-.633-.355L37.314 37.597c-.349.201-.626.68-.626 1.082s.283.562.626.36l17.623-10.168Z"
        fill="#1D1D1B"
      />
    </svg>
  );
}
