import { type ReactNode } from 'react';

export function Pin(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m9.333 12.38 1.94 3.36m-6.64-4.02 6.355-3.669M2.895 5.657l3.833-2.212m4.975 4.193-.595.344-4.44-4.503.06-.034c.124-.072.233-.166.322-.278a1.1 1.1 0 0 0 .195-.372 1.04 1.04 0 0 0 .038-.411.999.999 0 0 0-.774-.864 1.1 1.1 0 0 0-.42-.018c-.141.022-.278.07-.402.142L1.855 3.856a1.122 1.122 0 0 0-.519.65c-.08.272-.048.56.09.799s.37.41.646.476c.276.067.572.022.823-.124l.059-.034 1.68 6.097-.596.343a1.1 1.1 0 0 0-.508.638.998.998 0 0 0 .087.783l.32.554a.998.998 0 0 0 .635.468c.27.064.56.02.806-.122l7.665-4.425a1.1 1.1 0 0 0 .508-.638.998.998 0 0 0-.087-.783l-.32-.554a.998.998 0 0 0-.635-.467 1.1 1.1 0 0 0-.806.121Z"
      />
    </svg>
  );
}
