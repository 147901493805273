import { type ReactElement, type ReactNode } from 'react';
import { Avatar } from '@repo/design-system-kit';
import cx from 'clsx';
import styles from './styles.strict-module.css';
import { CustomAvatar } from './custom-avatar';

interface StatusAvatarProps {
  avatar?: 'base' | 'custom';
  src?: string;
  name?: string;
  icon?: ReactElement;
  alt?: string;
  hasBorder?: boolean;
  isDisabled?: boolean;
  isLazyLoaded?: boolean;
  dataTestId?: string;
}

export function StatusAvatar({
  avatar = 'base',
  icon,
  hasBorder = false,
  isDisabled = false,
  isLazyLoaded = false,
  alt,
  src,
  name = '',
  dataTestId,
  ...props
}: StatusAvatarProps): ReactNode {
  return (
    <div className={styles.container} {...props}>
      {avatar === 'custom' ? (
        <CustomAvatar data-testid={dataTestId} name={name} />
      ) : (
        <Avatar
          alt={alt}
          className={cx(isDisabled && styles.disabled)}
          data-test-status-avatar-image
          data-testid={dataTestId}
          loading={isLazyLoaded ? 'lazy' : 'eager'}
          src={src}
          withBorder={hasBorder}
        />
      )}
      {icon ? (
        <div className={styles.icon} data-test-status-avatar-icon>
          {icon}
        </div>
      ) : null}
    </div>
  );
}
