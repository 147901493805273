import type { CashflowTimeseries } from 'qonto/react/models/cash-flow-timeseries';
import type { CashflowTimeseries as CashflowTimeseriesApiResponse } from 'qonto/react/api/models/cash-flow-timeseries';
import { camelizeKeys } from 'qonto/react/api/utils/camelize-keys';

export const mockTimeseriesResponse: CashflowTimeseriesApiResponse = {
  timeframe: {
    inclusive_start_date: '2023-10-01T00:00:00Z',
    exclusive_end_date: '2024-10-01T00:00:00Z',
  },
  categories_data: {
    inflow: [
      {
        id: 'cat_uuid_1',
        name: 'Tax Income',
        name_key: 'inflows.taxes.corporate-credit',
        color_key: 'category_green',
        icon_key: 'icon_calculator',
        subcategories: [
          {
            id: 'sub_uuid_1',
            name: 'Salaries',
            name_key: 'inflows.taxes.salary-deductions',
            color_key: 'category_green',
          },
        ],
      },
    ],
    outflow: [
      {
        id: 'cat_uuid_2',
        name: 'Operational Expenses',
        name_key: 'outflows.operational',
        color_key: 'category_blue',
        icon_key: 'icon_box',
        subcategories: [
          {
            id: 'sub_uuid_2',
            name: 'Office Supplies',
            name_key: 'office_supplies',
            color_key: 'category_blue',
          },
        ],
      },
    ],
  },
  timeframes: [
    // October 2023
    {
      inclusive_start_date: '2023-10-01T00:00:00Z',
      exclusive_end_date: '2023-11-01T00:00:00Z',
      cash_flow_data: {
        transactions_count: 20,
        vat: {
          credit_amount: {
            value: 100.0,
            currency: 'EUR',
          },
          debit_amount: {
            value: 250.0,
            currency: 'EUR',
          },
        },
        starting_amount: {
          value: 1000.0,
          currency: 'EUR',
        },
        ending_amount: {
          value: 1200.0,
          currency: 'EUR',
        },
        inflows: {
          amount_sum: {
            value: 800.0,
            currency: 'EUR',
          },
          growth_rate_over_previous_period: 0.2,
          transactions_count: 12,
          categories_data_points: [
            {
              category_id: 'cat_uuid_1',
              transactions_count: 12,
              amount_sum: {
                value: 800.0,
                currency: 'EUR',
              },
              subcategories_data_points: [
                {
                  subcategory_id: 'sub_uuid_1',
                  transactions_count: 12,
                  amount_sum: {
                    value: 800.0,
                    currency: 'EUR',
                  },
                },
              ],
            },
          ],
        },
        outflows: {
          amount_sum: {
            value: 600.0,
            currency: 'EUR',
          },
          growth_rate_over_previous_period: 0.2,
          transactions_count: 8,
          categories_data_points: [
            {
              category_id: 'cat_uuid_2',
              transactions_count: 8,
              amount_sum: {
                value: 600.0,
                currency: 'EUR',
              },
              subcategories_data_points: [
                {
                  subcategory_id: 'sub_uuid_2',
                  transactions_count: 8,
                  amount_sum: {
                    value: 600.0,
                    currency: 'EUR',
                  },
                },
              ],
            },
          ],
        },
      },
    },
    // November 2023
    {
      inclusive_start_date: '2023-11-01T00:00:00Z',
      exclusive_end_date: '2023-12-01T00:00:00Z',
      cash_flow_data: {
        transactions_count: 22,
        starting_amount: {
          value: 1200.0,
          currency: 'EUR',
        },
        ending_amount: {
          value: 1400.0,
          currency: 'EUR',
        },
        inflows: {
          amount_sum: {
            value: 900.0,
            currency: 'EUR',
          },
          growth_rate_over_previous_period: 0.2,
          transactions_count: 13,
          categories_data_points: [
            {
              category_id: 'cat_uuid_1',
              transactions_count: 13,
              amount_sum: {
                value: 900.0,
                currency: 'EUR',
              },
              subcategories_data_points: [
                {
                  subcategory_id: 'sub_uuid_1',
                  transactions_count: 13,
                  amount_sum: {
                    value: 900.0,
                    currency: 'EUR',
                  },
                },
              ],
            },
          ],
        },
        outflows: {
          amount_sum: {
            value: 700.0,
            currency: 'EUR',
          },
          growth_rate_over_previous_period: 0.2,
          transactions_count: 9,
          categories_data_points: [
            {
              category_id: 'cat_uuid_2',
              transactions_count: 9,
              amount_sum: {
                value: 700.0,
                currency: 'EUR',
              },
              subcategories_data_points: [
                {
                  subcategory_id: 'sub_uuid_2',
                  transactions_count: 9,
                  amount_sum: {
                    value: 700.0,
                    currency: 'EUR',
                  },
                },
              ],
            },
          ],
        },
      },
    },
    // December 2023
    {
      inclusive_start_date: '2023-12-01T00:00:00Z',
      exclusive_end_date: '2024-01-01T00:00:00Z',
      cash_flow_data: {
        transactions_count: 24,
        starting_amount: {
          value: 1400.0,
          currency: 'EUR',
        },
        ending_amount: {
          value: 1600.0,
          currency: 'EUR',
        },
        inflows: {
          amount_sum: {
            value: 1000.0,
            currency: 'EUR',
          },
          growth_rate_over_previous_period: 0.2,
          transactions_count: 14,
          categories_data_points: [
            {
              category_id: 'cat_uuid_1',
              transactions_count: 14,
              amount_sum: {
                value: 1000.0,
                currency: 'EUR',
              },
              subcategories_data_points: [
                {
                  subcategory_id: 'sub_uuid_1',
                  transactions_count: 14,
                  amount_sum: {
                    value: 1000.0,
                    currency: 'EUR',
                  },
                },
              ],
            },
          ],
        },
        outflows: {
          amount_sum: {
            value: 800.0,
            currency: 'EUR',
          },
          growth_rate_over_previous_period: 0.2,
          transactions_count: 10,
          categories_data_points: [
            {
              category_id: 'cat_uuid_2',
              transactions_count: 10,
              amount_sum: {
                value: 800.0,
                currency: 'EUR',
              },
              subcategories_data_points: [
                {
                  subcategory_id: 'sub_uuid_2',
                  transactions_count: 10,
                  amount_sum: {
                    value: 800.0,
                    currency: 'EUR',
                  },
                },
              ],
            },
          ],
        },
      },
    },
    // January 2024
    {
      inclusive_start_date: '2024-01-01T00:00:00Z',
      exclusive_end_date: '2024-02-01T00:00:00Z',
      cash_flow_data: {
        transactions_count: 26,
        starting_amount: {
          value: 1600.0,
          currency: 'EUR',
        },
        ending_amount: {
          value: 1800.0,
          currency: 'EUR',
        },
        inflows: {
          amount_sum: {
            value: 1100.0,
            currency: 'EUR',
          },
          growth_rate_over_previous_period: 0.2,
          transactions_count: 15,
          categories_data_points: [
            {
              category_id: 'cat_uuid_1',
              transactions_count: 15,
              amount_sum: {
                value: 1100.0,
                currency: 'EUR',
              },
              subcategories_data_points: [
                {
                  subcategory_id: 'sub_uuid_1',
                  transactions_count: 15,
                  amount_sum: {
                    value: 1100.0,
                    currency: 'EUR',
                  },
                },
              ],
            },
          ],
        },
        outflows: {
          amount_sum: {
            value: 900.0,
            currency: 'EUR',
          },
          growth_rate_over_previous_period: 0.2,
          transactions_count: 11,
          categories_data_points: [
            {
              category_id: 'cat_uuid_2',
              transactions_count: 11,
              amount_sum: {
                value: 900.0,
                currency: 'EUR',
              },
              subcategories_data_points: [
                {
                  subcategory_id: 'sub_uuid_2',
                  transactions_count: 11,
                  amount_sum: {
                    value: 900.0,
                    currency: 'EUR',
                  },
                },
              ],
            },
          ],
        },
      },
    },
  ],
};

const months = [
  '2024-02-01T00:00:00Z',
  '2024-03-01T00:00:00Z',
  '2024-04-01T00:00:00Z',
  '2024-05-01T00:00:00Z',
  '2024-06-01T00:00:00Z',
  '2024-07-01T00:00:00Z',
  '2024-08-01T00:00:00Z',
  '2024-09-01T00:00:00Z',
  '2024-10-01T00:00:00Z',
];

let startingAmount = 1800.0;
let inflowAmount = 1100.0;
let outflowAmount = 900.0;
let transactionsCount = 26;
let vatCreditAmount = 37.25;
let vatDebitAmount = 180.0;

months.forEach((startDate, index) => {
  const endDate = months[index + 1] ?? '2024-10-01T00:00:00Z';
  startingAmount += 200.0;
  inflowAmount += 100.0;
  outflowAmount += 100.0;
  vatCreditAmount += 10.0;
  vatDebitAmount += 20.0;
  transactionsCount += 2;

  mockTimeseriesResponse.timeframes.push({
    inclusive_start_date: startDate,
    exclusive_end_date: endDate,
    cash_flow_data: {
      transactions_count: transactionsCount,
      vat: {
        credit_amount: {
          value: vatCreditAmount,
          currency: 'EUR',
        },
        debit_amount: {
          value: vatDebitAmount,
          currency: 'EUR',
        },
      },
      starting_amount: {
        value: startingAmount,
        currency: 'EUR',
      },
      ending_amount: {
        value: startingAmount + 200.0,
        currency: 'EUR',
      },
      inflows: {
        amount_sum: {
          value: inflowAmount,
          currency: 'EUR',
        },
        growth_rate_over_previous_period: 0.2,
        transactions_count: transactionsCount / 2,
        categories_data_points: [
          {
            category_id: 'cat_uuid_1',
            transactions_count: transactionsCount / 2,
            amount_sum: {
              value: inflowAmount,
              currency: 'EUR',
            },
            subcategories_data_points: [
              {
                subcategory_id: 'sub_uuid_1',
                transactions_count: transactionsCount / 2,
                amount_sum: {
                  value: inflowAmount,
                  currency: 'EUR',
                },
              },
            ],
          },
        ],
      },
      outflows: {
        amount_sum: {
          value: outflowAmount,
          currency: 'EUR',
        },
        growth_rate_over_previous_period: 0.2,
        transactions_count: transactionsCount / 2,
        categories_data_points: [
          {
            category_id: 'cat_uuid_2',
            transactions_count: transactionsCount / 2,
            amount_sum: {
              value: outflowAmount,
              currency: 'EUR',
            },
            subcategories_data_points: [
              {
                subcategory_id: 'sub_uuid_2',
                transactions_count: transactionsCount / 2,
                amount_sum: {
                  value: outflowAmount,
                  currency: 'EUR',
                },
              },
            ],
          },
        ],
      },
    },
  });
});

export const mockTimeseries: CashflowTimeseries = camelizeKeys(
  mockTimeseriesResponse
) as CashflowTimeseries;
