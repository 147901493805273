import { type ReactNode } from 'react';

export function Jerrican(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        d="M4.6 2.431h2.487M5.022 6.814l6.122 6.205m-6.129-.028 6.008-6.059m-.272-3.494.527-1.534a.13.13 0 0 1 .169-.081l2.238.832a.133.133 0 0 1 .079.167l-.541 1.615m.623.307a.25.25 0 0 1 .162.231v9.434c0 .288-.117.565-.324.769a1.115 1.115 0 0 1-.781.32h-9.79c-.293-.001-.574-.116-.781-.32a1.083 1.083 0 0 1-.324-.77V1.587c0-.288.117-.565.324-.77.207-.203.488-.318.78-.319H8.56c.292 0 .573.116.78.32.208.204.324.48.325.77v1.462a.15.15 0 0 0 .096.14l4.086 1.555Z"
      />
    </svg>
  );
}
