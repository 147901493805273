import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { BadgeStatus } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import type { Transaction } from 'qonto/react/graphql';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { useToggleVerificationStatus } from 'qonto/react/hooks/use-toggle-verification-status';
import { Status as TransactionStatusEnum } from 'qonto/react/graphql';
import {
  VERIFICATION_STATUS_CELL_CLICK_EVENT_NAME,
  VERIFICATION_STATUS_POPOVER_CLICK_EVENT_NAME,
} from 'qonto/react/constants';
import { VerificationStatusCellPopover } from './popover';
import { getVerificationStatusInfo } from './util';

interface VerificationStatusCellProps {
  transaction: Transaction;
}

export function VerificationStatusCell({ transaction }: VerificationStatusCellProps): ReactNode {
  const qualifiedForAccounting = transaction.qualifiedForAccounting;
  const segment = useEmberService('segment');
  const { formatMessage } = useIntl();

  const { mutate, isPending } = useToggleVerificationStatus();
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const { status } = transaction;

  const statusBadgeInfo = getVerificationStatusInfo(status, qualifiedForAccounting, formatMessage);
  const shouldEnablePopover = ![
    TransactionStatusEnum.Declined,
    TransactionStatusEnum.Reversed,
  ].includes(status);

  const toggleVerificationStatus = (closePopover: () => void): void => {
    mutate(
      {
        transactionIds: [transaction.id],
        qualifiedForAccounting,
        closePopover,
      },
      {
        onSuccess: () => {
          segment.track(VERIFICATION_STATUS_POPOVER_CLICK_EVENT_NAME, {
            action_type: qualifiedForAccounting ? 'cancel_verification' : 'mark_as_verified',
          });
          const toastMessage = qualifiedForAccounting
            ? formatMessage({
                id: 'transactions.modular_table.bookkeeping.verification_cancelled.toast',
              })
            : formatMessage({
                id: 'transactions.modular_table.bookkeeping.marked_as_verified.toast',
              });
          toastFlashMessages.toastSuccess(toastMessage);
        },
        onError: () => {
          toastFlashMessages.toastError(
            formatMessage({ id: 'transactions.modular_table.bookkeeping.error.toast' })
          );
        },
      }
    );
  };

  const trackCellOpen = (isOpen: boolean): void => {
    if (isOpen) {
      segment.track(VERIFICATION_STATUS_CELL_CLICK_EVENT_NAME);
    }
  };

  return (
    <BaseCell
      data-testid="verification-status-cell"
      onOpenChange={trackCellOpen}
      popoverSlot={
        shouldEnablePopover ? (
          <VerificationStatusCellPopover
            isUpdatingStatus={isPending}
            qualifiedForAccounting={qualifiedForAccounting}
            status={status}
            toggleVerificationStatus={toggleVerificationStatus}
          />
        ) : null
      }
    >
      {statusBadgeInfo ? <BadgeStatus {...statusBadgeInfo} /> : '-'}
    </BaseCell>
  );
}
