import { type ReactNode, type SVGProps } from 'react';

export function IconVault(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.398 1C2.062 1 1 2.054 1 3.33v8.463c0 1.276 1.062 2.33 2.398 2.33h9.204c1.336 0 2.398-1.054 2.398-2.33V3.33C15 2.054 13.938 1 12.602 1H3.398ZM0 3.33C0 1.48 1.533 0 3.398 0h9.204C14.467 0 16 1.48 16 3.33v8.463c0 1.781-1.42 3.219-3.19 3.324v.383a.5.5 0 1 1-1 0v-.377H4.507v.377a.5.5 0 0 1-.593.491.502.502 0 0 1-.59-.492v-.377C1.491 15.084 0 13.62 0 11.792V3.33Zm3.804-.039a.306.306 0 0 0-.316.297v7.744c0 .152.13.296.316.296h8.405a.306.306 0 0 0 .316-.296V3.588a.306.306 0 0 0-.316-.297H3.804Zm-1.316.297c0-.727.6-1.297 1.316-1.297h8.405c.716 0 1.316.57 1.316 1.297v7.744c0 .727-.6 1.296-1.316 1.296H3.804c-.716 0-1.316-.57-1.316-1.296v-.4h-.25a.5.5 0 0 1 0-1h.25V5.055h-.25a.5.5 0 1 1 0-1h.25v-.466Zm6.687 3.707a.5.5 0 0 1 .5-.5h1.834a.5.5 0 1 1 0 1H9.675a.5.5 0 0 1-.5-.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
