import { type ReactNode } from 'react';
import { Spinner } from '@repo/design-system-kit';
import { useFetchCashflowCategories } from 'qonto/react/hooks/use-fetch-cashflow-categories';
import { useCategoriesManagementRouting } from '../hooks/use-categories-management-routing';
import { SidePanel } from './side-panel';
import styles from './categories-management-side-panel.strict-module.css';
import { Header } from './header';
import { CategoryDetails } from './category-details';

export function CategoriesManagementSidePanel(): ReactNode {
  const { currentType, routeParams, showSidePanel, navigateToType } =
    useCategoriesManagementRouting();
  const categoryId = routeParams.category_id;

  const { data: categories, isPending, isError } = useFetchCashflowCategories();
  const hasCategories = !isPending && categories;

  if (isError) {
    navigateToType(currentType);
    return null;
  }

  return (
    <SidePanel
      className={styles['categories-side-panel']}
      data-testid="categories-management-side-panel"
      isVisible={showSidePanel}
    >
      <div className={styles.content}>
        {isPending ? <CategoryDetailsLoading /> : null}
        {hasCategories ? (
          <CategoryDetails categories={categories} categoryId={categoryId ?? ''} />
        ) : null}
      </div>
    </SidePanel>
  );
}

function CategoryDetailsLoading(): ReactNode {
  return (
    <>
      <Header />
      <div className={styles['loading-wrapper']}>
        <Spinner color="primary-a" size="large" />
      </div>
    </>
  );
}
