import { type ReactNode } from 'react';

export function ArrowBack(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="m4.79 2.996-4.286 4m0 0 4.285 4m-4.285-4h10.714c2.357 0 4.286 1.8 4.286 4v2"
      />
    </svg>
  );
}
