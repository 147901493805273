import { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RadioGroup, RadioButton, Disclaimer } from '@repo/design-system-kit';
import { CARD_LOCK_REASONS } from 'qonto/constants/cards';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';

interface SuspendProps {
  onChange: () => void;
  shouldShowReasonError?: boolean;
}

export function Suspend({ onChange, shouldShowReasonError = false }: SuspendProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <>
      <RadioGroup
        className={styles['selector-group']}
        errorMessage={formatMessage({ id: 'cards.modals.lost-stolen.missing-reason-disclaimer' })}
        onChange={onChange}
      >
        <RadioButton
          className={styles.selector}
          data-testid="card-radio-lost"
          value={CARD_LOCK_REASONS.LOST}
        >
          <span className={styles['selector-content']}>
            <StaticThemedAsset alt="" assetPath="/icon/cards/lost.svg" className={styles.icon} />
            <FormattedMessage id="cards.modals.lost-stolen.lost-option" />
          </span>
        </RadioButton>
        <RadioButton
          className={styles.selector}
          data-testid="card-radio-stolen"
          value={CARD_LOCK_REASONS.STOLEN}
        >
          <span className={styles['selector-content']}>
            <StaticThemedAsset alt="" assetPath="/icon/cards/stolen.svg" className={styles.icon} />
            <FormattedMessage id="cards.modals.lost-stolen.stolen-option" />
          </span>
        </RadioButton>
      </RadioGroup>

      {Boolean(shouldShowReasonError) && (
        <Disclaimer.Inline
          className={styles['reason-error']}
          data-testid="reason-error-disclaimer"
          level="error"
        >
          <FormattedMessage id="cards.modals.lost-stolen.missing-reason-disclaimer" />
        </Disclaimer.Inline>
      )}
    </>
  );
}
