import { type ReactElement } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import cx from 'clsx';
import styles from './styles.strict-module.css';

export function AmountsCardPlaceholder(): ReactElement {
  return (
    <article className={styles.card} data-test-id="budget-details-amounts-card-placeholder">
      <header className={cx(styles.header, 'mb-8')}>
        <div className={styles.amountSpent}>
          <SkeletonLoader.Block
            borderRadius="36px"
            data-test-id="budget-details-amounts-card-placeholder-block"
            height="12px"
            width="95px"
          />
        </div>

        <div className={styles.amountBudgeted}>
          <SkeletonLoader.Block
            borderRadius="36px"
            data-test-id="budget-details-amounts-card-placeholder-block"
            height="12px"
            width="95px"
          />
        </div>
      </header>

      <footer>
        <SkeletonLoader.Block
          borderRadius="36px"
          data-test-id="budget-details-amounts-card-placeholder-block"
          height="6px"
          width="100%"
        />
      </footer>
    </article>
  );
}
