import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function TrashOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" {...props}>
      <g fill="#F95656" clipPath="url(#a)">
        <path d="M15.5 3.5H12v-2c0-.55-.45-1-1-1H5c-.55 0-1 .45-1 1v2H.5c-.28 0-.5.22-.5.5s.22.5.5.5H2v10c0 .55.45 1 1 1h10c.55 0 1-.45 1-1v-10h1.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5ZM5 1.5h6v2H5v-2Zm8 13H3v-10h10v10Z" />
        <path d="M5.5 9.5c-.28 0-.5.22-.5.5v3c0 .28.22.5.5.5s.5-.22.5-.5v-3c0-.28-.22-.5-.5-.5ZM8 7.5c-.28 0-.5.22-.5.5v5c0 .28.22.5.5.5s.5-.22.5-.5V8c0-.28-.22-.5-.5-.5ZM10.5 9.5c-.28 0-.5.22-.5.5v3c0 .28.22.5.5.5s.5-.22.5-.5v-3c0-.28-.22-.5-.5-.5Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
