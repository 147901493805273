import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function OpenLink(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.729 4.007a.5.5 0 0 0 .5.5h6.807l-7.632 7.632a.5.5 0 1 0 .707.707l7.632-7.632v6.807a.5.5 0 1 0 1 0V4.007a.5.5 0 0 0-.5-.5H4.229a.5.5 0 0 0-.5.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
