import { type ReactNode, type SVGProps } from 'react';

export function IconAtm(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.999 1.997V6.27h2.416V4.72h-.312a.5.5 0 0 1 0-1h9.785a.5.5 0 1 1 0 1h-.299v1.548H15V1.997h-14Zm11.59 5.272h2.671A.733.733 0 0 0 16 6.542V1.724a.733.733 0 0 0-.739-.727H.738a.733.733 0 0 0-.739.727v4.818c0 .425.355.727.739.727h2.677v6.287c0 .796.645 1.441 1.441 1.441h6.292c.796 0 1.441-.645 1.441-1.441V7.269Zm-1-2.548H7.063v9.276h4.085a.441.441 0 0 0 .441-.441V4.721Zm-5.526 9.276V4.721H4.415v8.835c0 .244.197.441.441.441h1.207Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
