import { type ReactNode } from 'react';

export function Box(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        d="M2.947 8.157v3.955c0 .007.003.013.009.016l5.023 2.867a.018.018 0 0 0 .018 0l5.024-2.867a.018.018 0 0 0 .009-.016V8.157M7.989 14.59V8.303m7.431-1.51-4.993 2.819a.16.16 0 0 1-.157 0L8.227 8.461a.154.154 0 0 1 0-.27l4.97-2.818a.16.16 0 0 1 .157 0l2.065 1.15c.107.06.107.21.001.27Zm-14.84 0 4.97 2.819a.16.16 0 0 0 .157 0L7.75 8.46a.154.154 0 0 0 0-.27L2.78 5.373a.16.16 0 0 0-.157 0L.58 6.523a.154.154 0 0 0 0 .27Zm0-2.954 4.97-2.818a.16.16 0 0 1 .157 0L7.75 2.17c.106.06.106.21 0 .27L2.78 5.26a.16.16 0 0 1-.157 0L.58 4.109a.154.154 0 0 1 0-.27Zm14.84 0L10.429 1.02a.16.16 0 0 0-.157 0L8.227 2.17a.154.154 0 0 0 0 .27l4.97 2.819a.16.16 0 0 0 .157 0L15.42 4.11a.154.154 0 0 0 0-.27Z"
      />
    </svg>
  );
}
