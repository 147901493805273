import { type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl, FormattedNumber } from 'react-intl';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './styles.strict-module.css';

interface InvoiceProps {
  id: string;
  date: string;
  showSignus: boolean;
  currency: string;
  name: string;
  selectedInvoiceId: string;
  handleSelectInvoice: (invoiceId: string) => void;
  amount?: number | undefined;
}

export function InvoiceDetailsCard({
  id,
  currency,
  name,
  showSignus,
  date,
  amount,
  selectedInvoiceId,
  handleSelectInvoice,
}: InvoiceProps): ReactNode {
  const { locale } = useIntl();
  const { formatMessage } = useIntl();

  const isSelected = selectedInvoiceId === id;

  const missingData = formatMessage({ id: 'receivable-invoices.label.missing-data' });
  const formattedDate = date
    ? `${formatMessage({ id: 'attachment-auto-matching.invoice-selection-modal.update-text' })} ${dateToken({ date, locale, token: 'date-time-s' })}`
    : missingData;
  const nameValue = name || missingData;

  const formattedAmount = (): JSX.Element | string => {
    if (amount === undefined) {
      return missingData;
    }

    const sign = showSignus ? '-\xA0' : '+\xA0';
    return (
      <>
        {sign}
        <FormattedNumber maximumFractionDigits={2} minimumFractionDigits={2} value={amount} />
        {'\xA0'}
        {currency}
      </>
    );
  };

  return (
    <button
      className={cx(styles.card, isSelected ? styles.selected : styles.unselected)}
      data-test-invoice-details-card-button
      key={id}
      onClick={() => {
        handleSelectInvoice(id);
      }}
      type="button"
    >
      <div className={styles.titleRow}>
        <span className={cx('body-1', styles.titleText)} data-test-invoice-details-name>
          {nameValue}
        </span>
        <span
          className={cx('body-1', !amount ? styles.greyedOut : '', styles.amount)}
          data-test-invoice-details-amount
        >
          {formattedAmount()}
        </span>
      </div>
      <div className={styles.date}>
        <time data-test-invoice-details-date>{date ? formattedDate : '-'}</time>
      </div>
    </button>
  );
}
