import { type ReactNode, type SVGProps } from 'react';

export function Filter(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      fill="currentColor"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M1 6a1 1 0 0 1 1-1h28a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1Zm4 10a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Zm5 9a1 1 0 1 0 0 2h12a1 1 0 1 0 0-2H10Z" />
    </svg>
  );
}
