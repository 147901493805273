import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function Invoice(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="18.832"
        height="28.5938"
        rx="0.2"
        transform="matrix(0.866025 -0.5 5.051e-08 -1 7 39.2097)"
        fill="#D5C8FB"
        stroke="#1D1D1B"
        strokeWidth="0.8"
      />
      <path
        d="M25.9954 2.85259L9.78665 12.1145C9.72435 12.1501 9.68589 12.2157 9.68588 12.2874C9.68582 12.6146 9.68567 13.6765 9.68567 15.968L9.68555 33.1953C9.68555 36.9911 13.0282 39.655 14.9264 38.706L29.0527 30.5037C29.213 30.4106 29.1488 30.1179 28.9723 30.0615C27.4107 29.5626 26.2924 27.3263 26.2924 24.6546L26.2926 6.47837L26.2927 3.02406C26.2927 2.87097 26.1283 2.77664 25.9954 2.85259Z"
        fill="white"
        stroke="#1D1D1B"
        strokeWidth="0.8"
      />
      <rect
        width="5.82537"
        height="2.85881"
        rx="0.2"
        transform="matrix(0.866025 -0.5 5.051e-08 -1 13.2461 16.9919)"
        fill="#FFEADD"
        stroke="#1D1D1B"
        strokeWidth="0.8"
      />
      <rect
        width="4.22864"
        height="2.87221"
        rx="0.2"
        transform="matrix(0.866025 -0.5 5.051e-08 -1 20.2617 28.9897)"
        fill="#FFEADD"
        stroke="#1D1D1B"
        strokeWidth="0.8"
      />
      <path
        d="M13.2461 25.6755C13.2461 25.7859 13.3236 25.8307 13.4193 25.7755L23.4772 19.9686C23.5728 19.9133 23.6504 19.779 23.6504 19.6686L23.6504 15.281C23.6504 15.1706 23.5728 15.1258 23.4772 15.181L13.4193 20.988C13.3236 21.0432 13.2461 21.1775 13.2461 21.288L13.2461 25.6755Z"
        fill="#FFEADD"
        stroke="#1D1D1B"
        strokeWidth="0.8"
      />
      <path d="M20 22.0403L20 17.2528" stroke="#1D1D1B" strokeWidth="0.8" />
      <path d="M23.6504 17.4941L13.2461 23.501" stroke="#1D1D1B" strokeWidth="0.8" />
    </svg>
  );
}
