import { type ReactNode, type SVGProps } from 'react';

export function IconMoneyBag(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.683.996a1.36 1.36 0 0 0-.751.289c-.217.178-.314.398-.314.601 0 .203.097.423.314.601.219.181.533.294.877.294h2.36c.343 0 .657-.113.876-.294.217-.178.314-.398.314-.601a.688.688 0 0 0-.076-.31.913.913 0 0 0-.238-.291 1.25 1.25 0 0 0-.392-.215 1.485 1.485 0 0 0-.34-.073l-.08.047c-.147.083-.357.18-.62.245-.507.125-1.184.123-1.93-.293Zm2.17-.883.002-.001.001-.001.001-.001A.494.494 0 0 1 9.17 0c.274 0 .547.045.805.132.257.088.496.219.702.389.205.17.375.376.493.61.118.236.18.493.18.755 0 .534-.257 1.022-.673 1.365l-.044.036c2.146.818 3.866 2.651 3.866 5.36v3.731c.593.353 1.002.965 1.002 1.668C15.5 15.1 14.624 16 13.501 16H2.478l-.037-.001c-.795-.06-1.554-.489-1.835-1.306l-.003-.01c-.29-.9.042-1.883.878-2.362V8.647c0-2.708 1.72-4.542 3.865-5.36a2.07 2.07 0 0 1-.045-.036c-.416-.343-.674-.83-.674-1.365 0-.534.258-1.022.674-1.365C5.714.18 6.258 0 6.81 0c.092 0 .182.026.26.074.55.34 1 .33 1.306.253a1.498 1.498 0 0 0 .481-.216M7.99 3.793c-2.837 0-5.518 1.816-5.518 4.854v3.988c0 .209-.13.395-.326.466-.53.192-.774.73-.601 1.274.12.344.456.592.952.634h11.004c.582 0 1.008-.462 1.008-.963 0-.348-.245-.722-.692-.903a.495.495 0 0 1-.31-.46V8.648c0-3.038-2.681-4.854-5.517-4.854Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.677 6.25h1.177c.1 0 .2.038.275.108a.381.381 0 0 1 0 .555.403.403 0 0 1-.275.109H8.677a2.58 2.58 0 0 0-1.447.442c-.324.22-.584.51-.759.842h2.71c.101 0 .2.038.276.109a.38.38 0 0 1 0 .555.402.402 0 0 1-.276.108H6.23c-.02.164-.02.33 0 .494H9.18c.101 0 .2.038.276.108a.38.38 0 0 1 0 .555.403.403 0 0 1-.276.109h-2.71c.175.331.435.621.759.842.42.286.926.442 1.447.442h1.177c.1 0 .2.038.275.109a.381.381 0 0 1 0 .555.402.402 0 0 1-.275.108H8.677a3.386 3.386 0 0 1-1.949-.615 3.096 3.096 0 0 1-1.12-1.441h-.462a.403.403 0 0 1-.275-.109.38.38 0 0 1 0-.555.402.402 0 0 1 .275-.108h.288a2.72 2.72 0 0 1 0-.494h-.288a.402.402 0 0 1-.275-.108.38.38 0 0 1 0-.555.402.402 0 0 1 .275-.109h.462a3.096 3.096 0 0 1 1.12-1.441 3.386 3.386 0 0 1 1.949-.615Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
