import React, { type ReactElement } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface ActionSlotProps {
  children: React.ReactNode;
  size?: '32' | '28';
  className?: string;
}

export function ActionSlot({ children, size = '32', className }: ActionSlotProps): ReactElement {
  return <div className={cx(styles.wrapper, styles[`size-${size}`], className)}>{children}</div>;
}
