import type { ComponentPropsWithRef, ReactNode } from 'react';

export function ArrowUpLeftOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M5.508 15a.5.5 0 0 1 0-1h5.241a4.25 4.25 0 0 0 0-8.5H1.532l3.32 3.14c.21.18.22.5.03.7a.5.5 0 0 1-.37.16c-.12 0-.22-.04-.32-.11L.322 5.72a.999.999 0 0 1 0-1.47l2.06-1.91L4.162.62h.01s.01-.01.01-.02c.1-.07.2-.11.32-.11s.26.05.36.16c.19.2.18.52-.03.7l-1.78 1.72s-.01 0-.02.02l-1.5 1.41h9.218a5.25 5.25 0 1 1 0 10.5H5.507Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
