import { useState, type ReactNode } from 'react';
import { DataObjectToggleButton, Timeline, type TimelineItemProps } from '@repo/design-system-kit';
import {
  IconClockOutlined,
  IconCheckmarkRoundedFilled,
  IconWarningSignFilled,
  IconErrorFilled,
} from '@repo/design-system-kit/assets/icons';
import cx from 'clsx';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { dateToken, DATE_FORMAT_TOKENS } from '@qonto/ui-kit/utils/date-token';
import { InstallmentStatus, type InstallmentModel } from 'qonto/react/models/installment';
import styles from './styles.strict-module.css';

function timelineItemMedia(installment: InstallmentModel): ReactNode {
  const unpaidStatuses = [
    InstallmentStatus.NotPaid,
    InstallmentStatus.AtRisk,
    InstallmentStatus.Overdue,
  ];

  if (unpaidStatuses.includes(installment.status)) {
    return (
      <IconClockOutlined
        className={cx(styles.icon, styles['not-paid'])}
        data-testid="icon-not-paid"
      />
    );
  }

  return (
    <IconCheckmarkRoundedFilled className={cx(styles.icon, styles.paid)} data-testid="icon-paid" />
  );
}

function timelineItemTitle(installment: InstallmentModel): ReactNode {
  return (
    <span className={styles.title} data-testid="title">
      {'-\xA0'}
      <FormattedNumber
        currency={installment.totalAmount.currency}
        maximumFractionDigits={2}
        minimumFractionDigits={0}
        style="currency"
        value={Number(installment.totalAmount.value)}
      />
      {installment.status === InstallmentStatus.Overdue && (
        <IconErrorFilled className={styles.overdue} data-testid="icon-overdue" />
      )}
      {installment.status === InstallmentStatus.AtRisk && (
        <IconWarningSignFilled className={styles['at-risk']} data-testid="icon-at-risk" />
      )}
    </span>
  );
}

function timelineItemContent(installment: InstallmentModel, locale: string): ReactNode {
  const formattedDate = dateToken({
    date: installment.date,
    locale,
    token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
  });
  return <span data-testid="content">{formattedDate}</span>;
}

function processLongTimeline(list: InstallmentModel[], locale: string): TimelineItemProps[] {
  const paidInstallments = list.filter(
    installment => installment.status === InstallmentStatus.Paid
  );

  if (paidInstallments.length === 0 || paidInstallments.length === list.length) {
    const slicedList = list.slice(0, 3);
    return slicedList.map((installment, index) =>
      transformInstallment(installment, index.toString(), locale)
    );
  }

  const shortList: TimelineItemProps[] = [];

  shortList.push({
    id: '0',
    lineVariant: 'solid',
    slots: {
      media: (
        <IconCheckmarkRoundedFilled
          className={cx(styles.icon, styles.paid)}
          data-testid="icon-paid"
        />
      ),
      title: (
        <span data-testid="title">
          <FormattedMessage
            id="financing.pay-later.cockpit.details.timeline.item.title"
            values={{ count: paidInstallments.length, current: paidInstallments.length }}
          />
        </span>
      ),
      content: null,
    },
  });

  const unpaidInstallments = list.filter(
    installment => installment.status === InstallmentStatus.NotPaid
  );

  const slicedUnpaidInstallments = unpaidInstallments.slice(0, 2);

  slicedUnpaidInstallments.forEach((installment, index) => {
    shortList.push(transformInstallment(installment, (index + 1).toString(), locale));
  });

  return shortList;
}

function transformInstallment(
  installment: InstallmentModel,
  id: string,
  locale: string
): TimelineItemProps {
  return {
    id,
    lineVariant: installment.status === InstallmentStatus.NotPaid ? 'dashed' : 'solid',
    slots: {
      media: timelineItemMedia(installment),
      title: timelineItemTitle(installment),
      content: timelineItemContent(installment, locale),
    },
  };
}

function canExpandTimeline(list: InstallmentModel[]): boolean {
  if (list.length <= 3) {
    return false;
  }

  const paidInstallments = list.filter(
    installment => installment.status === InstallmentStatus.Paid
  );

  if (paidInstallments.length === 0 || paidInstallments.length === list.length) {
    return true;
  }

  const unpaidInstallments = list.filter(
    installment => installment.status === InstallmentStatus.NotPaid
  );

  // If the grouped paid installments (1) + unpaid installments > 3, we can expand the timeline
  return unpaidInstallments.length + 1 > 3;
}

export function InstallmentTimeline({ list }: { list: InstallmentModel[] }): ReactNode {
  const { locale } = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);

  let installmentList: TimelineItemProps[] = [];

  function handleToggleExpand(): void {
    setIsExpanded(!isExpanded);
  }

  if (list.length > 3 && !isExpanded) {
    installmentList = processLongTimeline(list, locale);
  } else {
    installmentList = list.map((installment, index) =>
      transformInstallment(installment, index.toString(), locale)
    );
  }

  const canExpand = canExpandTimeline(list);
  const showLastLine = canExpand && !isExpanded;

  return (
    <>
      <div className="mb-12">
        <Timeline list={installmentList} showLastLine={showLastLine} />
      </div>
      {Boolean(canExpand) && (
        <DataObjectToggleButton data-testid="toggle-button" onPress={handleToggleExpand}>
          <FormattedMessage
            id={
              isExpanded
                ? 'financing.pay-later.cockpit.details.collapse-button.show-less'
                : 'financing.pay-later.cockpit.details.collapse-button.show-more'
            }
          />
        </DataObjectToggleButton>
      )}
    </>
  );
}
