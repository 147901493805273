import { type ReactNode } from 'react';

export function Scale(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="none">
      <path
        fill="currentColor"
        d="M14.076 2.5a.5.5 0 1 0 0-1v1Zm-10.187-1a.5.5 0 0 0 0 1v-1Zm8.153 13a.5.5 0 1 0 0-1v1Zm-5.85-1a.5.5 0 0 0 0 1v-1Zm2.306.435a.5.5 0 0 0 1 0h-1Zm1-11.857a.5.5 0 0 0-1 0h1ZM3.8 2.14l-.47-.173.47.173Zm.385 0-.47.172.47-.172ZM1.504 6.418v-.5H.869l.149.618.486-.118Zm4.978 0 .486.118.15-.618h-.636v.5Zm7.34-4.278-.47-.173.47.173Zm.385 0-.47.172.47-.172Zm-2.682 4.278v-.5h-.635l.15.617.485-.117Zm4.979 0 .486.118.15-.618h-.636v.5ZM14.076 1.5H3.889v1h10.187v-1Zm-2.034 12h-5.85v1h5.85v-1Zm-2.544.435V2.078h-1v11.857h1ZM4.269 2.312a.295.295 0 0 1-.553 0l.938-.345c-.225-.614-1.097-.614-1.323 0l.938.345ZM1.018 6.536a3.056 3.056 0 0 0 2.975 2.327v-1A2.056 2.056 0 0 1 1.99 6.3l-.972.235Zm2.975 2.327c1.443 0 2.652-.99 2.975-2.327L5.996 6.3a2.056 2.056 0 0 1-2.003 1.562v1Zm2.49-2.945h-4.98v1h4.98v-1Zm7.808-3.606a.295.295 0 0 1-.554 0l.939-.344c-.226-.615-1.098-.615-1.324 0l.94.344ZM11.04 6.535a3.055 3.055 0 0 0 2.976 2.328v-1A2.055 2.055 0 0 1 12.01 6.3l-.972.234Zm2.976 2.328c1.442 0 2.651-.991 2.975-2.327l-.972-.236a2.057 2.057 0 0 1-2.003 1.563v1Zm2.489-2.945h-4.979v1h4.979v-1Zm-13.824.72 1.59-4.326-.94-.345-1.588 4.325.938.345Zm1.036-4.326 1.59 4.325.938-.345-1.59-4.325-.938.345Zm8.986 4.325 1.59-4.325-.94-.345-1.589 4.325.939.345Zm1.035-4.325 1.59 4.325.938-.345-1.59-4.325-.938.345Z"
      />
    </svg>
  );
}
