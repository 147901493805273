import { type ReactNode } from 'react';

export function Restaurant(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M13.495.509v5.617c0 .71-.62 1.287-1.385 1.287h-1.112c-.765 0-1.386-.576-1.386-1.287V.509m1.94.002v14.992M4.78 7.885v7.624M7.077 4.81c0-1.924-.809-4.3-2.291-4.3-1.483 0-2.29 2.376-2.29 4.3 0 1.925 1.015 2.73 2.29 2.73 1.275 0 2.291-.805 2.291-2.73Z"
      />
    </svg>
  );
}
