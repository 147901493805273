import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function Globe(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.312 6.956c-.55 3.687 1.995 7.143 5.69 7.717a6.712 6.712 0 0 0 7.683-5.64c.55-3.687-1.995-7.143-5.69-7.717a6.712 6.712 0 0 0-7.683 5.64Zm5.58 8.449C2.797 14.769-.03 10.937.582 6.843A7.446 7.446 0 0 1 9.105.585c4.095.636 6.922 4.468 6.31 8.561a7.446 7.446 0 0 1-8.523 6.259Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.962 6.491a.37.37 0 0 1 .37.37c0 .902.658 1.82 1.89 2.533 1.218.707 2.927 1.166 4.839 1.166 1.892 0 3.57-.459 4.763-1.157 1.206-.706 1.846-1.608 1.846-2.478a.37.37 0 1 1 .739 0c0 1.25-.902 2.35-2.212 3.117-1.322.774-3.133 1.258-5.136 1.258-2.021 0-3.862-.484-5.21-1.265C1.516 9.26.592 8.145.592 6.86a.37.37 0 0 1 .37-.37Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.66 1.082a.367.367 0 0 1-.42.31c-.865-.135-1.85.378-2.726 1.5C5.647 4 4.948 5.636 4.664 7.542c-.282 1.887-.089 3.637.41 4.938.505 1.318 1.282 2.09 2.116 2.22.201.032.34.22.31.423a.367.367 0 0 1-.42.309c-1.22-.19-2.15-1.268-2.695-2.69-.551-1.439-.75-3.32-.452-5.313.3-2.012 1.042-3.772 1.999-4.996C6.878 1.223 8.099.465 9.349.66c.202.032.34.221.31.423Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.796.99c.03.203.218.341.42.31.864-.135 1.849.38 2.726 1.504.867 1.11 1.566 2.749 1.851 4.659.283 1.89.09 3.642-.409 4.946-.504 1.32-1.282 2.093-2.115 2.223a.372.372 0 0 0-.31.422c.03.203.218.34.42.31 1.22-.19 2.15-1.27 2.695-2.694.55-1.44.748-3.324.45-5.321-.301-2.015-1.043-3.777-2-5.004C9.579 1.132 8.357.374 7.106.568a.372.372 0 0 0-.31.423Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        d="M1.312 6.956c-.55 3.687 1.995 7.143 5.69 7.717a6.712 6.712 0 0 0 7.683-5.64c.55-3.687-1.995-7.143-5.69-7.717a6.712 6.712 0 0 0-7.683 5.64Zm5.58 8.449C2.797 14.769-.03 10.937.582 6.843A7.446 7.446 0 0 1 9.105.585c4.095.636 6.922 4.468 6.31 8.561a7.446 7.446 0 0 1-8.523 6.259Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        d="M.962 6.491a.37.37 0 0 1 .37.37c0 .902.658 1.82 1.89 2.533 1.218.707 2.927 1.166 4.839 1.166 1.892 0 3.57-.459 4.763-1.157 1.206-.706 1.846-1.608 1.846-2.478a.37.37 0 1 1 .739 0c0 1.25-.902 2.35-2.212 3.117-1.322.774-3.133 1.258-5.136 1.258-2.021 0-3.862-.484-5.21-1.265C1.516 9.26.592 8.145.592 6.86a.37.37 0 0 1 .37-.37Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        d="M9.66 1.082a.367.367 0 0 1-.42.31c-.865-.135-1.85.378-2.726 1.5C5.647 4 4.948 5.636 4.664 7.542c-.282 1.887-.089 3.637.41 4.938.505 1.318 1.282 2.09 2.116 2.22.201.032.34.22.31.423a.367.367 0 0 1-.42.309c-1.22-.19-2.15-1.268-2.695-2.69-.551-1.439-.75-3.32-.452-5.313.3-2.012 1.042-3.772 1.999-4.996C6.878 1.223 8.099.465 9.349.66c.202.032.34.221.31.423Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        d="M6.796.99c.03.203.218.341.42.31.864-.135 1.849.38 2.726 1.504.867 1.11 1.566 2.749 1.851 4.659.283 1.89.09 3.642-.409 4.946-.504 1.32-1.282 2.093-2.115 2.223a.372.372 0 0 0-.31.422c.03.203.218.34.42.31 1.22-.19 2.15-1.27 2.695-2.694.55-1.44.748-3.324.45-5.321-.301-2.015-1.043-3.777-2-5.004C9.579 1.132 8.357.374 7.106.568a.372.372 0 0 0-.31.423Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
