import React from 'react';
import { LottiePlayer, Button } from '@repo/design-system-kit';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import styles from './success.strict-module.css';

interface PagoPaResultSuccessProps {
  transitionToNext: () => void;
  handleNewPayment: () => void;
}

export function PagoPaResultSuccess({
  transitionToNext,
  handleNewPayment,
}: PagoPaResultSuccessProps): React.JSX.Element {
  const lottiePath = useThemedAssetPath('/lotties/pagopa/result-success.json');

  return (
    <div className={styles.container} data-test-result-success="">
      <LottiePlayer
        className={cx(styles.lottie, 'mb-32')}
        data-test-result-success-lottie=""
        path={lottiePath}
      />
      <h1 className={cx('title-1', 'mb-16')} data-test-result-success-title="">
        <FormattedMessage id="pagopa.payment-creation.result.success.title" />
      </h1>
      <p className={cx(styles.subtitle, 'body-1', 'mb-32')} data-test-result-success-subtitle="">
        <FormattedMessage id="pagopa.payment-creation.result.success.subtitle" />
      </p>
      <Button
        className="mb-16"
        data-test-result-success-primary-button=""
        onPress={transitionToNext}
        variant="primary"
      >
        <FormattedMessage id="pagopa.payment-creation.result.success.cta" />
      </Button>
      <Button
        data-test-result-success-tertiary-button=""
        onPress={handleNewPayment}
        variant="tertiary"
      >
        <FormattedMessage id="pagopa.payment-creation.result.success.new-payment-cta" />
      </Button>
    </div>
  );
}
