import { useEffect, type ReactNode } from 'react';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import { clsx } from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './styles.strict-module.css';

interface SuccessProps {
  f24Order: {
    expirationDate: Date;
  };
  completeFlow: () => void;
}

export function Success({ f24Order, completeFlow }: SuccessProps): ReactNode {
  const segment = useEmberService('segment');

  useEffect(() => {
    segment.track('f24_create-flow_step6-order-created');
  }, [segment]);

  const intl = useIntl();

  return (
    <div className={styles['step-container']} data-test-f24-success-component>
      <div className={styles.content}>
        <LottiePlayer
          className={clsx(styles['success-icon'], 'mb-32')}
          data-test-f24-success-icon
          loop={false}
          path="/lotties/success.json"
        />

        <h1 className="title-1 mb-16" data-test-f24-success-title>
          <FormattedMessage id="f24.manual-declaration.confirmation.title" />
        </h1>

        <p className="body-2 mb-32" data-test-f24-success-subtitle>
          <FormattedMessage
            id="f24.manual-declaration.confirmation.subtitle"
            values={{
              paymentDate: dateToken({
                date: f24Order.expirationDate.toISOString(),
                locale: intl.locale,
                token: 'date-year-l',
              }),
            }}
          />
        </p>

        <Button className="mb-24" data-test-f24-success-close onPress={completeFlow}>
          <FormattedMessage id="f24.manual-declaration.confirmation.cta" />
        </Button>
      </div>
    </div>
  );
}
