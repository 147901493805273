import { type ReactNode } from 'react';
import cx from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { External } from 'qonto/react/assets/icons';
import { CONNECT_FEATURE_LEVELS, INTEGRATION_TYPES } from 'qonto/constants/connect';
import styles from './styles.strict-module.css';

interface Subscription {
  findTrial: (trial: string) => boolean;
}
interface Link {
  label: string;
  link: {
    url: string;
    target: string;
  };
}

interface ApplicationSidebarProps {
  application: {
    category: {
      name: string;
    };
    integration_type: string;
    made_by: string;
    minimum_integration_level: string;
    support_links?: Link[];
    useful_links?: Link[];
  };
  canAccessGoldConnect: boolean;
  canAccessSilverConnect: boolean;
  canAccessAccountsPayable: boolean;
  canAccessAccountsReceivable: boolean;
}

export function ApplicationSidebar({
  application,
  canAccessGoldConnect,
  canAccessSilverConnect,
  canAccessAccountsPayable,
  canAccessAccountsReceivable,
}: ApplicationSidebarProps): ReactNode {
  const { formatMessage } = useIntl();
  const subscriptionManager = useEmberService('subscription-manager');
  const zendeskLocalization = useEmberService('zendesk-localization');

  const isPartnership = application.integration_type === INTEGRATION_TYPES.partnerships;
  const isInternal = application.integration_type === INTEGRATION_TYPES.internal;

  const resourceLinks: Link[] =
    application.useful_links?.filter(({ link }) => Boolean(link.url)) ?? [];
  const supportLinks: Link[] =
    application.support_links?.filter(({ link }) => Boolean(link.url)) ?? [];

  const sidebarTitle = (): string => {
    if (isInternal) {
      return formatMessage({ id: 'qonto-hub.connect.details.made-by-qonto' });
    } else if (isPartnership) {
      return formatMessage({ id: 'qonto-hub.connect.details.recommended-by-qonto' });
    }
    return formatMessage(
      { id: 'qonto-hub.connect.details.made-by-title' },
      { developerName: application.made_by }
    );
  };

  const isIntegrationAvailable = (): boolean => {
    switch (application.minimum_integration_level) {
      case CONNECT_FEATURE_LEVELS.silver:
        return canAccessSilverConnect;
      case CONNECT_FEATURE_LEVELS.gold:
        return canAccessGoldConnect;
      case CONNECT_FEATURE_LEVELS.accounts_payable:
        return canAccessAccountsPayable;
      case CONNECT_FEATURE_LEVELS.accounts_receivable:
        return canAccessAccountsReceivable;
      default:
        return true;
    }
  };

  const isTrialAvailable: boolean = (
    subscriptionManager.currentSubscription as Subscription
  ).findTrial('connect_integration_level_silver');

  const integrationPricing = (): string => {
    if (isIntegrationAvailable()) {
      return formatMessage({ id: 'qonto-hub.connect.details.pricing.available' });
    } else if (isTrialAvailable) {
      return formatMessage({ id: 'qonto-hub.connect.details.pricing.trial' });
    }

    return formatMessage({ id: 'qonto-hub.connect.details.pricing.unavailable' });
  };

  return (
    <aside className={cx(styles['connect-app-sidebar'], 'body-2')} data-test-sidebar>
      <h3 className={cx(styles['sidebar-title'], isInternal && 'mb-24')} data-test-sidebar-header>
        {sidebarTitle()}
      </h3>

      {!isInternal && (
        <p className={cx(styles['sidebar-subtitle'], 'mb-24')} data-test-sidebar-description>
          {isPartnership ? (
            formatMessage({ id: 'qonto-hub.connect.details.verified-partnership' })
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: formatMessage(
                  { id: 'qonto-hub.connect.details.verified-integration' },
                  {
                    faqUrl: zendeskLocalization.getLocalizedArticle(4470305),
                  },
                  { ignoreTag: true }
                ),
              }}
            />
          )}
        </p>
      )}

      {!isPartnership && (
        <section className={styles['sidebar-item']} data-test-pricing>
          <h3 className={styles['sidebar-title']} data-test-pricing-title>
            <FormattedMessage id="qonto-hub.connect.details.pricing.title" />
          </h3>
          <p className={styles['sidebar-subtitle']} data-test-pricing-subtitle>
            {integrationPricing()}
          </p>
        </section>
      )}

      {application.category.name ? (
        <>
          <h3 className={styles['sidebar-title']}>
            <FormattedMessage id="qonto-hub.connect.details.category-title" />
          </h3>
          <section className="mb-24">
            <span className={styles['sidebar-category']} data-test-category>
              {application.category.name}
            </span>
          </section>
        </>
      ) : null}

      {resourceLinks.length ? (
        <>
          <h3 className={styles['sidebar-title']} data-test-useful-link-title>
            {formatMessage({ id: 'qonto-hub.connect.details.resources-title' })}
          </h3>
          <section className="mb-24">
            {resourceLinks.map(element => {
              return (
                <a
                  className={cx(styles['sidebar-link'], 'body-link')}
                  data-test-useful-link
                  href={element.link.url}
                  key={element.label}
                  target={element.link.target}
                >
                  {element.label ? element.label : element.link.url}
                  <External className={styles['external-link-icon']} />
                </a>
              );
            })}
          </section>
        </>
      ) : null}

      {supportLinks.length ? (
        <>
          <h3 className={styles['sidebar-title']} data-test-support-link-title>
            {formatMessage({ id: 'qonto-hub.connect.details.support-title' })}
          </h3>
          <section>
            {supportLinks.map(element => {
              return (
                <a
                  className={cx(styles['sidebar-link'], 'body-link')}
                  data-test-support-link
                  href={element.link.url}
                  key={element.label}
                  target={element.link.target}
                >
                  {element.label ? element.label : element.link.url}
                  <External className={styles['external-link-icon']} />
                </a>
              );
            })}
          </section>
        </>
      ) : null}
    </aside>
  );
}
