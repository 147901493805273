import { Label } from '@repo/design-system-kit';
import React, { useEffect, useState, type ReactNode } from 'react';
import { useIntl, type IntlShape } from 'react-intl';
import { ListBoxItem, ListBox, Popover, Select, SelectValue, Button } from 'react-aria-components';
import { ChevronBottomOutlined, ChevronTopOutlined } from 'qonto/react/assets/icons';
import { colorsMap } from 'qonto/react/utils/cashflow-categories/categories-data-map';
import styles from './category-details-form.strict-module.css';

interface ColorSelectProps {
  categoryColorKey: string;
  onColorChange: () => void;
}
export function ColorSelect({ categoryColorKey, onColorChange }: ColorSelectProps): ReactNode {
  const intl = useIntl();

  const [isColorSelectOpen, setIsColorSelectOpen] = useState(false);
  const [hasColorChanged, setHasColorChanged] = useState(false);
  useEffect(() => {
    if (hasColorChanged) {
      setHasColorChanged(false);
      onColorChange();
    }
  }, [hasColorChanged, onColorChange]);

  return (
    <Select
      data-testid="category-details-select-color"
      defaultSelectedKey={colorTokenToName(categoryColorKey)}
      isOpen={isColorSelectOpen}
      name="color"
      onOpenChange={setIsColorSelectOpen}
      onSelectionChange={() => {
        setHasColorChanged(true);
      }}
    >
      <Label data-testid="category-details-select-color-label">
        {intl.formatMessage({ id: 'categories-management.create-category.color.label' })}
      </Label>
      <Button
        className={styles['select-color-trigger']}
        data-selected-color={categoryColorKey}
        data-testid="category-details-select-color-trigger"
      >
        <div className={styles['select-trigger-content']}>
          <SelectValue className={styles['select-trigger-text']} />
          {isColorSelectOpen ? (
            <ChevronTopOutlined aria-hidden="true" className={styles['select-trigger-arrow']} />
          ) : (
            <ChevronBottomOutlined aria-hidden="true" className={styles['select-trigger-arrow']} />
          )}
        </div>
      </Button>
      <Popover className={styles['select-color-popover']}>
        <ListBox data-testid="category-details-select-color-listbox" items={colorOptions}>
          {colorOption => (
            <ListBoxItem className={styles['select-listbox-item']} id={colorOption.id}>
              <ColorOption
                colorKey={colorOption.colorKey}
                colorName={colorOption.colorName}
                intl={intl}
              />
            </ListBoxItem>
          )}
        </ListBox>
      </Popover>
    </Select>
  );
}

function ColorOption({
  colorName,
  colorKey,
  intl,
}: {
  colorName: string;
  colorKey: string;
  intl: IntlShape;
}): ReactNode {
  return (
    <div className={styles['color-option']}>
      <div className={styles['color-dot']} style={{ backgroundColor: `var(${colorKey})` }} />
      <span className={styles['color-name']}>{getColorName(colorName, intl)}</span>
    </div>
  );
}

const colorTokenToName = (colorToken: string): string => {
  return Object.entries(colorsMap).find(([_, val]) => val === colorToken)?.[0] ?? colorToken;
};

const colorOptions = Object.entries(colorsMap)
  .map(([key, value]) => ({
    id: key,
    colorName: key,
    colorKey: value,
  }))
  .filter(({ colorName }) => colorName !== 'default');

const getColorName = (colorName: string, intl: IntlShape): string => {
  switch (colorName) {
    case 'category_mint':
      return intl.formatMessage({
        id: 'categories-management.create-category.color.mint.dropdown-option',
      });
    case 'category_mustard':
      return intl.formatMessage({
        id: 'categories-management.create-category.color.mustard.dropdown-option',
      });
    case 'category_peach':
      return intl.formatMessage({
        id: 'categories-management.create-category.color.peach.dropdown-option',
      });
    case 'category_blue':
      return intl.formatMessage({
        id: 'categories-management.create-category.color.blue.dropdown-option',
      });
    case 'category_green':
      return intl.formatMessage({
        id: 'categories-management.create-category.color.green.dropdown-option',
      });
    case 'category_pink':
      return intl.formatMessage({
        id: 'categories-management.create-category.color.pink.dropdown-option',
      });
    case 'category_gray':
      return intl.formatMessage({
        id: 'categories-management.create-category.color.gray.dropdown-option',
      });
    case 'category_purple':
      return intl.formatMessage({
        id: 'categories-management.create-category.color.purple.dropdown-option',
      });
    default:
      return colorName;
  }
};
