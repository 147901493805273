import { type ReactNode, type SVGProps } from 'react';

export function IconPeople(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.183 1.552A3.13 3.13 0 0 1 9.874-.001c1.752 0 3.147 1.458 3.147 3.226 0 1.352-.324 2.612-1.118 3.917.264.149.527.293.786.434.73.4 1.42.777 1.956 1.175.768.57 1.354 1.277 1.354 2.309v1.08c0 .432-.35.782-.783.782h-1.672c.132.314.205.658.205 1.039v1.255c0 .433-.35.784-.783.784H.783a.783.783 0 0 1-.784-.784v-1.255c0-1.145.655-1.928 1.527-2.569.6-.44 1.37-.86 2.188-1.306.312-.17.632-.345.952-.525-.918-1.484-1.292-2.91-1.292-4.446 0-1.964 1.555-3.577 3.5-3.577.104 0 .207.005.31.014Zm1.033.258a3.58 3.58 0 0 1 2.158 3.305c0 1.535-.374 2.962-1.292 4.446.324.182.653.362.973.536.433.237.85.464 1.217.68.598.35 1.149.718 1.577 1.146h2.15v-.863c0-.576-.298-1.022-.95-1.506-.477-.355-1.072-.68-1.772-1.062a65.258 65.258 0 0 1-.94-.521.927.927 0 0 1-.32-1.295c.721-1.168 1.004-2.263 1.004-3.451 0-1.243-.975-2.226-2.147-2.226-.663 0-1.261.313-1.658.811Zm-1.342.728c-1.369 0-2.5 1.142-2.5 2.577 0 1.368.332 2.63 1.177 3.975a.926.926 0 0 1-.318 1.299 69.9 69.9 0 0 1-1.111.614c-.786.428-1.461.796-2.004 1.195C1.36 12.755 1 13.278 1 13.96v1.038h11.75v-1.038c0-.466-.166-.844-.488-1.206-.338-.378-.84-.73-1.496-1.117-.367-.215-.75-.424-1.16-.647a67.538 67.538 0 0 1-1.09-.602.925.925 0 0 1-.318-1.299c.846-1.346 1.177-2.607 1.177-3.975 0-1.259-.872-2.295-2.01-2.528a2.442 2.442 0 0 0-.49-.05Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
