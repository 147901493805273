import React from 'react';
import { PagoPaResultWaiting } from './waiting';
import { PagoPaResultSuccess } from './success';
import { PagoPaResultError } from './error';
import { PagoPaResultProcessing } from './processing';

interface PagoPaResultManagerProps {
  status: 'waiting' | 'success' | 'error' | 'processing';
  transitionToNext: () => void;
  handleNewPayment: () => void;
}

export function PagoPaResultManager({
  status,
  transitionToNext,
  handleNewPayment,
}: PagoPaResultManagerProps): React.JSX.Element {
  if (status === 'waiting') {
    return <PagoPaResultWaiting data-test-result-waiting="waiting" />;
  }

  if (status === 'success') {
    return (
      <PagoPaResultSuccess
        data-test-result-success="success"
        handleNewPayment={handleNewPayment}
        transitionToNext={transitionToNext}
      />
    );
  }

  if (status === 'error') {
    return <PagoPaResultError data-test-result-error="error" transitionToNext={transitionToNext} />;
  }

  return (
    <PagoPaResultProcessing
      data-test-result-processing="processing"
      transitionToNext={transitionToNext}
    />
  );
}
