import { type PropsWithChildren, type ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface AiGlowProps extends PropsWithChildren {
  className?: string;
}

export function AiGlow({ children, className }: AiGlowProps): ReactNode {
  return (
    <div className={cx(styles.wrapper, className)}>
      <div className={styles.mask} data-test-ai-glow="">
        <div className={cx(styles.glow, styles['glow--1'])} />
        <div className={cx(styles.glow, styles['glow--2'])} />
        <div className={cx(styles.glow, styles['glow--3'])} />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
