import type { ForwardRefExoticComponent, ReactNode, Ref, RefAttributes } from 'react';
import { forwardRef, useEffect } from 'react';
import cx from 'clsx';
import { useScrollTo } from 'qonto/react/hooks/ui';
import styles from './styles.strict-module.css';

interface TimelineItemProps<T> {
  item: T;
  slots: {
    media: ReactNode;
    title: ReactNode;
    content: ReactNode;
  };
  lineVariant?: 'solid' | 'dashed';
  'data-testid': string;
}

export const TimelineItem = forwardRef(function TimelineItem<T>(
  props: TimelineItemProps<T>,
  ref: Ref<HTMLLIElement> | null
): ReactNode {
  const { slots, lineVariant = 'solid', ...rest } = props;
  return (
    <li
      className={cx(styles['timeline-item'], styles[`variant-${lineVariant}`])}
      ref={ref}
      {...rest}
    >
      <div className={styles['timeline-media-container']} data-testid="item-media">
        {slots.media}
      </div>
      <div className={styles['timeline-item-content']} data-testid="item-content">
        <div data-testid="item-title">{slots.title}</div>
        <div data-testid="item-content">{slots.content}</div>
      </div>
    </li>
  );
});

interface TimelineProps<T> {
  list: T[];
  Item: ForwardRefExoticComponent<
    { item: T; 'data-testid': string } & RefAttributes<HTMLLIElement>
  >;
  getKey: (item: T) => string | number;
  className?: string;
}

export function Timeline<T>({ list, Item, getKey, className }: TimelineProps<T>): ReactNode {
  const [scrollToRef, setScrollToRef] = useScrollTo<HTMLLIElement>();

  useEffect(() => {
    setScrollToRef(true);
  }, [setScrollToRef, scrollToRef]);

  return (
    <ol className={cx(styles.timeline, className)}>
      {list.map((item, index) => (
        <Item
          data-testid={`timeline-item-${String(index)}`}
          item={item}
          key={getKey(item)}
          ref={index === list.length - 1 ? scrollToRef : null}
        />
      ))}
    </ol>
  );
}
