import {
  createContext,
  type Dispatch,
  type ReactNode,
  useCallback,
  useContext,
  useReducer,
} from 'react';

type SelectedIds = string[];

interface ContextState {
  selection: SelectedIds;
  shouldResetSelection: boolean;
  selectedItemIds: SelectedIds;
}
type Action =
  | { type: 'setSelection'; payload: SelectedIds }
  | { type: 'setShouldResetSelection'; payload: boolean }
  | { type: 'resetSelection' };

interface BulkSelectionContextType {
  state: ContextState;
  dispatch: Dispatch<Action>;
  selectedItemIds: SelectedIds;
  selectItem: (id: string) => void;
  selectAll: () => void;
}

const reducer = (state: ContextState, action: Action): ContextState => {
  switch (action.type) {
    case 'setSelection':
      return { ...state, selection: action.payload };
    case 'setShouldResetSelection':
      return { ...state, shouldResetSelection: action.payload };
    case 'resetSelection':
      return { ...state, selection: [], shouldResetSelection: false };
    default:
      return state;
  }
};

const initialState: ContextState = {
  selection: [],
  shouldResetSelection: false,
  selectedItemIds: [],
};
const BulkSelectionContext = createContext<BulkSelectionContextType | undefined>(undefined);

export function BulkSelectionProvider({
  children,
  selectedItemIds = [],
  selectItem,
  selectAll,
}: {
  children: ReactNode;
  selectedItemIds?: SelectedIds;
  selectItem: (id: string) => void;
  selectAll: () => void;
}): ReactNode {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <BulkSelectionContext.Provider
      value={{ state, selectAll, selectedItemIds, selectItem, dispatch }}
    >
      {children}
    </BulkSelectionContext.Provider>
  );
}

interface UseBulkSelection {
  selection: SelectedIds;
  selectedItemIds: SelectedIds;
  selectItem: (id: string) => void;
  selectAll: () => void;
  shouldResetSelection: boolean;
  setSelection: (bulkSelection: SelectedIds) => void;
  setShouldResetSelection: (shouldResetSelection: boolean) => void;
  resetSelection: () => void;
}
const useBulkSelection = (): UseBulkSelection => {
  const context = useContext(BulkSelectionContext);
  if (!context) {
    throw new Error('useBulkSelection must be used within a BulkSelectionContext');
  }

  const { state, dispatch, selectAll, selectItem, selectedItemIds } = context;
  const { selection, shouldResetSelection } = state;

  const setSelection = useCallback(
    (selectedIds: SelectedIds): void => {
      dispatch({ type: 'setSelection', payload: selectedIds });
    },
    [dispatch]
  );
  const setShouldResetSelection = useCallback(
    (value: boolean): void => {
      dispatch({ type: 'setShouldResetSelection', payload: value });
    },
    [dispatch]
  );
  const resetSelection = useCallback(() => {
    dispatch({ type: 'resetSelection' });
  }, [dispatch]);

  return {
    selection,
    shouldResetSelection,
    setSelection,
    selectAll,
    selectItem,
    setShouldResetSelection,
    resetSelection,
    selectedItemIds,
  };
};

//  required for stubbing with sinon.js
export const bulkSelectionManager = {
  useBulkSelection,
};
