import { type ReactNode } from 'react';

export function Safe(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M9.675 7.295h1.834M3.824 15.5v-.405m8.485.406v-.405m-8.3.405v-.405m-1.77-4.663h.414m-.415-5.878h.415M3.398.5h9.204c1.6 0 2.898 1.267 2.898 2.83v8.463c0 1.563-1.297 2.83-2.898 2.83H3.398c-1.6 0-2.898-1.267-2.898-2.83V3.33C.5 1.767 1.798.5 3.398.5Zm9.627 3.088v7.744c0 .44-.365.796-.816.796H3.804a.806.806 0 0 1-.816-.796V3.588c0-.44.365-.797.816-.797h8.405c.45 0 .816.357.816.797Z"
      />
    </svg>
  );
}
