import type { ReactNode } from 'react';
import { Tooltip, Button } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

interface AutoCategorizeIndicatorProps {
  children: ReactNode;
  label: string;
}

export function AutoCategorizeIndicator({
  children,
  label,
}: AutoCategorizeIndicatorProps): ReactNode {
  return (
    <Tooltip
      className={styles.tooltip}
      data-testid="auto-categorised-tooltip"
      label={label}
      placement="bottom"
    >
      <Button
        className={styles['notice-button']}
        data-testid="auto-categorised-tooltip-button"
        variant="tertiary"
      >
        <span className={styles['category-icon-wrapper']}>{children}</span>
      </Button>
    </Tooltip>
  );
}
