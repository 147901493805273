import { type ComponentType, type PropsWithChildren, type ReactNode, type SVGProps } from 'react';
import * as CashFlow from 'qonto/react/assets/icons/cash-flow-categories';
import styles from './styles.strict-module.css';

interface CategoryAvatarProps extends PropsWithChildren {
  color: string;
  icon: string;
}

export function CategoryAvatar({ children, color, icon }: CategoryAvatarProps): ReactNode {
  const Icon = getIcon(icon);

  return (
    <div
      className={styles['category-avatar']}
      data-testid="category-avatar"
      style={{
        backgroundColor: `var(${color})`,
      }}
    >
      {Icon ? <Icon data-testid={`category-avatar-${icon}`} /> : null}
      {children}
    </div>
  );
}

function getIcon(icon: string): ComponentType<SVGProps<SVGSVGElement>> | undefined {
  return iconsMap[icon] ?? iconsMap.default;
}

export const iconsMap: Record<string, ComponentType<SVGProps<SVGSVGElement>>> = {
  icon_atm: CashFlow.IconAtm,
  icon_lock: CashFlow.IconLock,
  icon_computer: CashFlow.IconComputer,
  icon_wrench: CashFlow.IconWrench,
  icon_fees: CashFlow.IconFees,
  icon_bank: CashFlow.IconBank,
  icon_basket: CashFlow.IconBasket,
  icon_gas: CashFlow.IconGas,
  icon_stars: CashFlow.IconStars,
  icon_shield: CashFlow.IconShield,
  icon_legal: CashFlow.IconLegal,
  icon_box: CashFlow.IconBox,
  icon_hook: CashFlow.IconHook,
  icon_bullhorn: CashFlow.IconBullhorn,
  icon_key: CashFlow.IconKey,
  icon_thumbtack: CashFlow.IconThumbtack,
  icon_sphere: CashFlow.IconSphere,
  icon_money_clip: CashFlow.IconMoneyClip,
  icon_money_bag: CashFlow.IconMoneyBag,
  icon_briefcase: CashFlow.IconBriefcase,
  icon_refund: CashFlow.IconRefund,
  icon_food: CashFlow.IconFood,
  icon_people: CashFlow.IconPeople,
  icon_target: CashFlow.IconTarget,
  icon_calculator: CashFlow.IconCalculator,
  icon_rocket: CashFlow.IconRocket,
  icon_vault: CashFlow.IconVault,
  icon_lightbulb: CashFlow.IconLightbulb,
  icon_tag: CashFlow.IconTag,
  icon_mileage: CashFlow.IconMileage,
  default: CashFlow.IconMoneyClip,
};
