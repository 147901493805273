import { useEffect, useRef, useState, type FormEvent, type ReactNode } from 'react';
import { useSegment } from '@repo/poly-hooks/segment';
import { type CountryCode } from '@repo/design-system-kit';
import { useCreateCashFlowCategory } from 'qonto/react/hooks/use-create-cash-flow-category';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { useCategoriesManagementRouting } from '../hooks/use-categories-management-routing';
import { DEFAULT_VAT_RATE_PER_COUNTRY } from '../../../constants/vat';
import styles from './category-item-new.strict-module.css';
import { NewItemForm } from './new-item-form';

export function CategoryItemNew(): ReactNode {
  const segment = useSegment();
  const { organization } = useOrganizationManager();
  const { mutate, isPending } = useCreateCashFlowCategory();
  const { isCreatingNewCategory, currentType, navigateToType, navigateToCategoryDetails } =
    useCategoriesManagementRouting();

  const countryCode = organization.legalCountry as CountryCode;
  const defaultVatRate =
    (DEFAULT_VAT_RATE_PER_COUNTRY as Partial<Record<CountryCode, string>>)[countryCode] ?? '20';

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);

  const textFieldRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isCreatingNewCategory && textFieldRef.current) {
      setShowRequiredError(false);
      textFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [isCreatingNewCategory]);

  const handleRemoveError = (): void => {
    setShowRequiredError(false);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    if (!formData.get('name')?.toString()) {
      setShowRequiredError(true);
    } else {
      setIsSubmitting(true);
      segment.track('cash-flow-categories_category_add_confirmed');
      mutate(
        {
          payload: {
            name: formData.get('name')?.toString() || 'New category',
            type: currentType,
            color_key: '--category-gray',
            icon_key: 'icon_money_clip',
            vat_rate: defaultVatRate,
          },
        },
        {
          onSuccess: response => {
            navigateToCategoryDetails(response.id);
          },
          onSettled: () => {
            setIsSubmitting(false);
            setShowRequiredError(false);
          },
        }
      );
    }
  };

  const handleCancel = (): void => {
    navigateToType(currentType);
  };

  if (!isCreatingNewCategory || isPending) return null;

  return (
    <div
      className={styles['category-container']}
      data-testid="category-item-new"
      ref={textFieldRef}
    >
      <NewItemForm
        handleCancel={handleCancel}
        handleRemoveError={handleRemoveError}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        showRequiredError={showRequiredError}
      />
    </div>
  );
}
