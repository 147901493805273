import type { ReactNode } from 'react';
import cx from 'clsx';
import { CategoryIcon } from 'qonto/react/components/transactions/sidebar/category/cashflow-category/components/category-icon';
import { ArrowRight } from 'qonto/react/assets/icons/arrow-right';
import { ArrowDown } from 'qonto/react/assets/icons/arrow-down';
import { EmptyAvatar } from 'qonto/react/assets/icons/empty-avatar';
import styles from './styles.strict-module.css';

interface CategoryCellProps {
  name: string;
  canExpand: boolean;
  type?: 'category' | 'subcategory' | 'uncategorized';
  color?: string;
  icon?: string;
  isExpanded?: boolean;
  onExpandToggle?: () => void;
}

export function CategoryCell({
  name,
  type = 'category',
  color = '',
  icon = '',
  isExpanded = false,
  canExpand = false,
  onExpandToggle,
}: CategoryCellProps): ReactNode {
  const handleExpandToggle = (): void => {
    if (canExpand) onExpandToggle?.();
  };

  return (
    <button
      className={cx(styles.categoryCell, canExpand && styles.canExpand)}
      data-testid="category-cell"
      onClick={handleExpandToggle}
      type="button"
    >
      <div
        className={canExpand ? styles.categoriesActionButton : styles.categoriesActionButtonHidden}
        data-testid="expand-toggle"
      >
        <ExpandToggle canExpand={canExpand} isExpanded={isExpanded} />
      </div>
      {type === 'uncategorized' ? (
        <EmptyAvatar className={styles.iconBox} data-testid="empty-avatar" />
      ) : (
        <CategoryIcon className={styles.iconBox} color={color} icon={icon} />
      )}
      <span className={styles.categoryLabel}>{name}</span>
    </button>
  );
}

interface ExpandToggleProps {
  canExpand: boolean;
  isExpanded: boolean;
}
function ExpandToggle({ canExpand, isExpanded }: ExpandToggleProps): ReactNode {
  if (!canExpand) return null;
  return isExpanded ? (
    <ArrowDown data-testid="arrow-down-icon" />
  ) : (
    <ArrowRight data-testid="arrow-right-icon" />
  );
}
