import React, { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import type { CategoriesListProps } from './categories-list';
import styles from './categories-list.strict-module.css';

export function CategoriesListError({ handleRetry, type }: CategoriesListProps): ReactNode {
  const { formatMessage } = useIntl();
  const lottieSrc = useThemedAssetPath('/lotties/error-404.json');

  return (
    <div className={styles['categories-list-error']} data-testid={`categories-list-${type}-error`}>
      <LottiePlayer
        className={styles['lottie-illustration']}
        data-testid="categories-list-error-illustration"
        path={lottieSrc}
      />
      <div className={styles['list-content']}>
        <h3 className="title-2" data-testid={`categories-list-${type}-error-title`}>
          {formatMessage({ id: 'categories-management.page.error.title' })}
        </h3>
        <h4 className="body-2 mb-32" data-testid={`categories-list-${type}-error-subtitle`}>
          {formatMessage({ id: 'categories-management.page.error.subtitle' })}
        </h4>
        <Button
          data-testid="categories-list-error-page-cta-button"
          onPress={handleRetry}
          size="medium"
          variant="primary"
        >
          {formatMessage({ id: 'categories-management.page.error.cta' })}
        </Button>
      </div>
    </div>
  );
}
