import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { transactionNamespace } from 'qonto/constants/hosts';
import { useFetchApi } from 'qonto/react/hooks/use-fetch-api';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';

interface SearchTransactionsByCategoryIdResponse {
  transactions: Record<string, unknown>[];
  meta: {
    total_count: number;
  };
}

export function useSearchTransactionsByCategoryId(
  categoryId: string | null
): UseQueryResult<SearchTransactionsByCategoryIdResponse> {
  const fetchApi = useFetchApi();
  const { organization } = useOrganizationManager();
  const { id: organizationId } = organization;

  const payload = {
    organization_id: organizationId,
    sort: {
      property: 'emitted_at',
      direction: 'desc',
    },
    filter_group: {
      conditional: 'and',
      expressions: [
        {
          conditional: 'and',
          expressions: [
            {
              property: 'category_assignment_category_id',
              operator: 'in',
              values: [categoryId],
            },
          ],
        },
      ],
    },
  };

  const searchTransactions = async (): Promise<SearchTransactionsByCategoryIdResponse> => {
    const response = await fetchApi(`${transactionNamespace}/transactions/search`, {
      method: 'POST',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Error fetching transactions`);
    }

    return (await response.json()) as SearchTransactionsByCategoryIdResponse;
  };

  return useQuery({
    queryKey: ['transactions-by-category-id', categoryId, payload],
    queryFn: () => searchTransactions(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 30, // 30 seconds,
    enabled: Boolean(categoryId),
  });
}
