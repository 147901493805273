import { type ReactNode } from 'react';
import { utcToZonedDate } from 'qonto/utils/chart';
import type { LabelTableInterval } from '../../models/labels-cashflow-display';

interface HeaderCellProps {
  interval: LabelTableInterval | undefined;
}

export function HeaderCell({ interval }: HeaderCellProps): ReactNode {
  if (!interval) return null;

  const { start } = interval;
  const date = utcToZonedDate(start) ?? new Date(start);
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const content = `${year}-${month}`;

  return <span>{content}</span>;
}
