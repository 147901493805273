import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function CertifiedFilled(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
      {...props}
    >
      <g clipPath="url(#icon_certified_filled_svg__a)">
        <path
          fillRule="evenodd"
          d="M15.74 7.2l-1.15-1.59c-.13-.17-.21-.37-.25-.58l-.31-1.94c-.04-.28-.17-.54-.37-.75-.21-.2-.47-.33-.75-.38l-1.94-.3c-.21-.04-.41-.12-.58-.24L8.79.26C8.56.09 8.29 0 8 0c-.29 0-.56.09-.79.26H7.2L5.61 1.42c-.17.12-.37.2-.58.24l-1.94.31a1.356 1.356 0 00-1.13 1.12l-.3 1.94c-.04.21-.12.41-.24.58L.26 7.2a1.365 1.365 0 000 1.6l1.16 1.59c.12.17.2.37.24.58l.31 1.94c.04.28.17.54.37.75.21.2.47.33.75.38l1.94.3c.21.04.41.12.58.25l1.59 1.15a1.343 1.343 0 001.59 0l1.6-1.15c.17-.13.37-.21.58-.25l1.94-.31c.28-.04.54-.17.75-.37.2-.21.33-.47.38-.75l.3-1.94c.04-.21.12-.41.25-.58l1.15-1.59a1.365 1.365 0 000-1.6zM11.87 6l-4.15 4.15-.72.71-.73-.71L4.13 8c-.2-.19-.2-.52 0-.71.2-.2.52-.2.72 0L7 9.42l4.15-4.13c.2-.2.53-.2.73 0 .2.2.19.52-.01.71z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="icon_certified_filled_svg__a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
