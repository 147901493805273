import React, { type ReactNode } from 'react';
import { Avatar, Spinner } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

interface ApplicationDataLogo {
  url: string;
  alt?: string;
}

interface ApplicationCardProps {
  isLoading?: boolean;
  logo?: ApplicationDataLogo;
  name: string;
}

export function ApplicationCard({ name, logo, isLoading }: ApplicationCardProps): ReactNode {
  return (
    <div className={styles['card-container']}>
      {logo ? (
        <span className={styles['application-logo']}>
          <Avatar
            alt={logo.alt ?? name}
            data-test-application-logo
            loading="eager"
            size="40"
            src={logo.url}
            withBorder
          />
        </span>
      ) : null}
      <span className={styles['application-name']}>
        <span className={styles['application-title']} data-test-application-card-name={name}>
          {name}
        </span>
      </span>
      {isLoading ? <Spinner className={styles.spinner} color="primary-a" size="medium" /> : null}
    </div>
  );
}
