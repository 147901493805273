import type { ReactNode } from 'react';

export function DownloadOutlined(): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#a)">
        <path
          fillRule="evenodd"
          d="M11.36 5.65c.2.2.2.51 0 .71L8.39 9.33c-.09.11-.23.17-.38.17-.13 0-.26-.05-.36-.15L4.64 6.34c-.2-.2-.2-.51 0-.71.2-.2.51-.2.71 0l2.16 2.16V1c0-.28.22-.5.5-.5s.5.22.5.5v6.8l2.15-2.15c.2-.2.51-.2.71 0h-.01Z"
          clipRule="evenodd"
        />
        <path d="M15 15.5H1c-.55 0-1-.45-1-1V8c0-.28.22-.5.5-.5s.5.22.5.5v6.5h14V8c0-.28.22-.5.5-.5s.5.22.5.5v6.5c0 .55-.45 1-1 1Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
