import { type ReactNode } from 'react';
import {
  useUpdateCashFlowCategory,
  useUpdateCashFlowSubcategory,
} from 'qonto/react/hooks/use-update-cash-flow-category';
import type {
  CashflowCategories,
  CashflowCategory,
  CashflowCategorySide,
  CashflowParentCategory,
} from 'qonto/react/models/cash-flow-category';
import { useCategoriesManagementRouting } from '../hooks/use-categories-management-routing';
import { Header } from './header';
import { CategoryDetailsForm } from './category-details-form/category-details-form';

interface CategoryDetailsProps {
  categoryId: string;
  categories: CashflowCategories;
}
export function CategoryDetails({ categoryId, categories }: CategoryDetailsProps): ReactNode {
  const { currentType } = useCategoriesManagementRouting();
  const { mutate: mutateCashFlowCategory } = useUpdateCashFlowCategory();
  const { mutate: mutateCashFlowSubcategory } = useUpdateCashFlowSubcategory();

  const findCategory = (): CashflowParentCategory | CashflowCategory | undefined => {
    for (const parentCategory of categories[currentType]) {
      const subcategory = parentCategory.subcategories?.find(subcat => subcat.id === categoryId);
      if (subcategory) {
        return subcategory;
      }
    }
    return categories[currentType].find(cat => cat.id === categoryId);
  };

  const category = findCategory();
  if (!category) {
    return <Header hideTitle />;
  }

  const isParentCategory = Boolean(!category.parentCategoryId);

  const handleSubmit = (formData: FormData): void => {
    const name = formData.get('name') as string;
    const updatedType = formData.get('type') as CashflowCategorySide;
    const icon = formData.get('icon') as string;
    const color = formData.get('color') as string;
    const vatRate = formData.get('vatRate') as string;

    if (isParentCategory) {
      mutateCashFlowCategory({
        type: currentType,
        categoryId: category.id ?? '',
        payload: {
          name,
          type: updatedType,
          icon_key: icon,
          color_key: color,
          vat_rate: { value: vatRate, valid: true },
        },
      });
    } else {
      mutateCashFlowSubcategory({
        type: currentType,
        categoryId: category.parentCategoryId ?? '',
        subcategoryId: category.id ?? '',
        payload: { name, vat_rate: { value: vatRate, valid: true } },
      });
    }
  };

  return (
    <>
      <Header isParentCategory={isParentCategory} />
      <CategoryDetailsForm
        category={category}
        currentType={currentType}
        isParentCategory={isParentCategory}
        onSubmit={handleSubmit}
      />
    </>
  );
}
