import { SkeletonLoader } from '@repo/design-system-kit';
import type { HTMLAttributes, ReactElement } from 'react';
import cx from 'clsx';
import styles from './requester-placeholder.strict-module.css';

export function RequesterPlaceholder({
  className,
  ...props
}: HTMLAttributes<HTMLTableRowElement>): ReactElement {
  return (
    <tr className={cx(styles.row, className)} {...props}>
      <td aria-hidden="true" />
      <td className={styles.cell}>
        <div className={styles.cellContent}>
          <SkeletonLoader.Block
            borderRadius="1px"
            className={styles.icon}
            data-test-card-requests-table-requester-placeholder-block=""
            height="20px"
            width="32px"
          />
          <SkeletonLoader.Header
            data-test-card-requests-table-requester-placeholder-header=""
            lines={1}
            smallLinesWidth="192px"
            width="50%"
          />
        </div>
      </td>
      <td className={styles.cell}>
        <SkeletonLoader.Line
          data-test-card-requests-table-requester-placeholder-line=""
          standalone
          width="50%"
        />
      </td>
      <td className={styles.cell}>
        <div className={styles.cellContent}>
          <SkeletonLoader.Line
            className="mr-8"
            data-test-card-requests-table-requester-placeholder-line=""
            standalone
            width="60px"
          />
        </div>
      </td>
      <td className={styles.cell}>
        <div className={styles.alignRight}>
          <SkeletonLoader.Line
            data-test-card-requests-table-requester-placeholder-line=""
            standalone
            width="50%"
          />
        </div>
      </td>
      <td aria-hidden="true" />
    </tr>
  );
}
