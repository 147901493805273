import { useCounter, useInterval, useTimeout } from 'usehooks-ts';
import { useEffect, useState } from 'react';
import { FormattedNumber } from 'react-intl';
import cs from 'clsx';
import ENV from 'qonto/config/environment';

const isTesting = (ENV as { environment: string }).environment === 'test';
const DELAY_FACTOR = isTesting ? 1 : 10;
const DEFAULT_TIMEOUT_DELAY = 0;

export const COUNTER_ANIMATION_DURATION = isTesting ? 1 : 800;

/**
 * Calculate the span by which add/subtract the amount on each interval
 * to reach the final value within the COUNTER_ANIMATION_DURATION
 */
function calculateIncrement(start: number, end: number, animationDuration: number): number {
  const interval = animationDuration / DELAY_FACTOR;

  return (end - start) / interval || 1;
}

/**
 * Refine the increment during the intervals to prevent the counter from going
 * over the end value
 */
function adjustIncrement(increment: number, limit: number, counter: number): number {
  if (increment > limit - counter) {
    return limit - counter;
  }
  return increment;
}

interface UseAnimateCounterReturn {
  count: number;
  startAnimation: () => void;
}

interface UseAnimateCounterArgs {
  end: number;
  clearTimeout: () => void;
}

const useAnimateCounter = ({
  end,
  clearTimeout,
}: UseAnimateCounterArgs): UseAnimateCounterReturn => {
  const { count, setCount } = useCounter(0);
  const [prevValue, setPrevValue] = useState<number>(0);

  const [intervalValue, setIntervalValue] = useState<typeof DELAY_FACTOR | null>(null);

  const startAnimation = (): void => {
    setIntervalValue(DELAY_FACTOR);
  };

  const clearInterval = (): void => {
    setIntervalValue(null);
    clearTimeout();
    setCount(end);
    setPrevValue(end);
  };

  const increment = calculateIncrement(prevValue, end, COUNTER_ANIMATION_DURATION);

  const animationFn = (): void => {
    if (prevValue > end) {
      if (count < end) {
        clearInterval();
        return;
      }
      setCount(c => c + adjustIncrement(increment, prevValue, c));
      return;
    }

    if (count >= end) {
      clearInterval();
      return;
    }
    setCount(c => c + adjustIncrement(increment, end, c));
  };

  useInterval(animationFn, intervalValue);

  return {
    count,
    startAnimation,
  };
};

interface CounterProps {
  isLoading: boolean;
  previousValue?: number;
  currentValue?: number;
  delay: number;
  className?: string;
}

function AnimatedCounter({
  previousValue = 0,
  currentValue,
  delay = DEFAULT_TIMEOUT_DELAY,
  className,
}: Omit<CounterProps, 'isLoading'>): JSX.Element {
  const [timeoutDelay, setTimeoutDelay] = useState<number | null>(delay);

  const { count, startAnimation } = useAnimateCounter({
    end: currentValue ?? previousValue,
    clearTimeout() {
      setTimeoutDelay(null);
    },
  });

  // This effect triggers the animation also when the currentValue
  // prop gets updated
  useEffect(() => {
    if (currentValue !== count) {
      startAnimation();
    }
  }, [count, startAnimation, currentValue]);

  useTimeout(startAnimation, timeoutDelay);

  return (
    <div className={cs(className)} data-testid="insight-figure">
      <FormattedNumber currency="EUR" style="currency" value={count} />
    </div>
  );
}

export function Counter(props: CounterProps): JSX.Element {
  if (props.isLoading) {
    return (
      <div className={cs(props.className)} data-testid="insight-figure">
        <FormattedNumber currency="EUR" style="currency" value={props.previousValue ?? 0} />
      </div>
    );
  }

  return <AnimatedCounter {...props} />;
}
