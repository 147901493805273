import type { CamelCasedProperties } from 'type-fest';
import type { TransactionModel } from '../../models/transaction';
import { camelizeKeys, camelizeKey } from '../utils/camelize-keys';
import type { Transaction } from '../models';
import type { SuccessResponseDocument, IncludedResource, BaseIncludedResource } from './search';

type TransactionRelationships = Transaction['relationships'];
type TransactionRelationshipsKeys = keyof TransactionRelationships;
type TransactionRelationshipsData = TransactionRelationships[TransactionRelationshipsKeys];
type TransactionModelResourcesKeys =
  | 'labels'
  | 'attachments'
  | 'receivableInvoices'
  | 'payableInvoices'
  | 'subject'
  | 'bankAccount'
  | 'thread';
type TransactionModelResource = TransactionModel[TransactionModelResourcesKeys];
export interface NormalizedTransactionResponse {
  transactions: TransactionModel[];
  meta: CamelCasedProperties<SuccessResponseDocument['meta']>;
}

export function normalize(response: SuccessResponseDocument): NormalizedTransactionResponse {
  return {
    transactions: normalizeTransactions(response),
    meta: camelizeKeys(response.meta),
  };
}

/**
 * Normalizes transactions from the API response to match the FE Transactions model.
 */
function normalizeTransactions(response: SuccessResponseDocument): TransactionModel[] {
  const { data: transactions, included } = response;

  const normalizedTransactions = transactions.map(transaction => {
    let normalizedTransaction = {} as TransactionModel;
    normalizedTransaction.id = transaction.id;
    normalizedTransaction = { ...normalizedTransaction, ...camelizeKeys(transaction.attributes) };

    Object.keys(transaction.relationships).forEach(key => {
      const { data } = transaction.relationships[key as TransactionRelationshipsKeys];
      const camelizedKey = camelizeKey(key);

      if (Array.isArray(data)) {
        const resources = data.map(rel => findAndNormalizeIncluded(rel, included));
        normalizedTransaction = { ...normalizedTransaction, [camelizedKey]: resources };
      } else if (data) {
        const resource = findAndNormalizeIncluded(data, included);
        normalizedTransaction = { ...normalizedTransaction, [camelizedKey]: resource };
      } else {
        normalizedTransaction = { ...normalizedTransaction, [camelizedKey]: null };
      }
    });

    return normalizedTransaction;
  });

  return normalizedTransactions;
}

/**
 * Recursively find and normalize resources included in the Transaction's relationships.
 */
function findAndNormalizeIncluded(
  relationship: { id: string; type: string },
  includedResources: IncludedResource[] | undefined
): TransactionModelResource {
  if (!includedResources) return null;
  const resource = includedResources.find(
    (item: BaseIncludedResource) => item.id === relationship.id
  );
  if (!resource) return null;

  let newResource = {
    id: resource.id,
    type: resource.type,
    ...camelizeKeys(resource.attributes),
  } as TransactionModelResource;

  // Recursively normalize nested relationships if they exist
  if (resource.relationships) {
    Object.keys(resource.relationships).forEach(key => {
      const nestedRelationship = resource.relationships?.[
        key as keyof typeof resource.relationships
      ] as TransactionRelationshipsData | undefined;

      if (nestedRelationship) {
        const { data } = nestedRelationship;
        const camelizedKey = camelizeKey(key);

        if (Array.isArray(data)) {
          const result = data.map(rel => findAndNormalizeIncluded(rel, includedResources));
          newResource = {
            ...newResource,
            [camelizedKey]: result,
          } as TransactionModelResource;
        } else if (data) {
          newResource = {
            ...newResource,
            [camelizedKey]: findAndNormalizeIncluded(data, includedResources),
          } as TransactionModelResource;
        } else {
          newResource = {
            ...newResource,
            [camelizedKey]: null,
          } as TransactionModelResource;
        }
      }
    });
  }

  return newResource;
}
