import { type ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

export function RemindersLoading(): ReactNode {
  return (
    <div className={styles.wrapper}>
      <div data-test-reminders-loading-header>
        <SkeletonLoader.Line standalone width="220px" />
      </div>
      <div className={styles.row} data-test-reminders-loading-line>
        <SkeletonLoader.Avatar height="32px" width="32px" />
        <SkeletonLoader.Line standalone width="220px" />
      </div>
      <div className={styles.row} data-test-reminders-loading-line>
        <SkeletonLoader.Avatar height="32px" width="32px" />
        <SkeletonLoader.Line standalone width="220px" />
      </div>
    </div>
  );
}
