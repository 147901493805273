import { type ReactNode, type SVGProps } from 'react';

export function IconBullhorn(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.852.014a.5.5 0 0 1 .384.486v4.955a.503.503 0 0 1 .083.024C15.31 5.861 16 6.855 16 8.002c0 1.147-.688 2.141-1.681 2.523a.508.508 0 0 1-.083.024V15.5a.5.5 0 0 1-.946.227l-.003-.006-.014-.027a6.62 6.62 0 0 0-.342-.542 8.192 8.192 0 0 0-1.152-1.315c-1.037-.955-2.612-1.9-4.808-1.9H4.915L6.22 14.55a.5.5 0 1 1-.894.447l-1.5-3a.506.506 0 0 1-.025-.059H2.265C.99 11.938 0 10.842 0 9.563V6.438C0 5.16.99 4.063 2.265 4.063h4.103a.52.52 0 0 1 .033 0h.57c2.196 0 3.771-.944 4.808-1.9A8.19 8.19 0 0 0 12.931.849a6.628 6.628 0 0 0 .342-.542l.014-.027.003-.005a.5.5 0 0 1 .562-.26Zm-.616 2.073a9.19 9.19 0 0 1-.78.812c-1.168 1.077-2.975 2.164-5.485 2.164h-.086v5.875h.086c2.51 0 4.317 1.088 5.486 2.164.3.277.56.553.779.812V2.087Zm-7.351 8.851V5.063h-3.62C1.598 5.063 1 5.653 1 6.438v3.125c0 .784.598 1.375 1.265 1.375h3.62Zm8.35-1.487c.451-.29.765-.823.765-1.449 0-.626-.314-1.159-.764-1.45v2.9Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
