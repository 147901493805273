import { type ReactNode, type SVGProps } from 'react';

export function IconBriefcase(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.232 1.264c.243-.173.553-.264.873-.264h3.853c.32 0 .63.091.873.264.242.173.452.456.452.823v.586h3.787v.015a.975.975 0 0 1 .796.595c.134.306.134.687.134.966v9.143A1.61 1.61 0 0 1 14.389 15H1.611A1.61 1.61 0 0 1 0 13.392V4.249c0-.28 0-.66.134-.967.076-.173.2-.34.393-.456.189-.112.401-.153.616-.153h3.636v-.586c0-.367.21-.65.453-.823Zm.547 1.409h4.504v-.566a.18.18 0 0 0-.031-.028A.52.52 0 0 0 9.958 2H6.105a.52.52 0 0 0-.294.08.18.18 0 0 0-.032.027v.566ZM1.612 14a.61.61 0 0 1-.61-.608H1V4.249c0-.151.002-.274.012-.377a.665.665 0 0 1 .038-.19.375.375 0 0 1 .093-.01h.871v4.714c0 .802.53 1.68 1.42 1.68h3.23v.143a1.413 1.413 0 0 0 2.828 0v-.143h3.145c.891 0 1.42-.878 1.42-1.68V3.673h.8c.05 0 .078.005.092.009a.663.663 0 0 1 .039.19c.01.103.012.226.012.377v9.143h-.001a.61.61 0 0 1-.611.608H1.612ZM13.058 3.673H3.014v4.713c0 .496.299.68.42.68h9.204c.12 0 .42-.184.42-.68V3.673ZM8.49 10.066h-.828v.143c0 .226.184.412.414.412.23 0 .414-.186.414-.412v-.143Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
