import { type ReactElement, type ReactNode } from 'react';
import cx from 'clsx';
import { ActionSlot } from 'qonto/react/components/table-v2/action-slot';
import styles from './styles.strict-module.css';

interface AttachmentItemBaseProps {
  icon: ReactElement;
  fileName: ReactElement;
  subtitle?: ReactNode;
  actions?: ReactNode;
}

export function AttachmentItemBase({
  fileName,
  icon,
  subtitle,
  actions,
  ...props
}: AttachmentItemBaseProps): ReactElement {
  const hasHoverActions = Boolean(actions);

  return (
    <div
      className={cx(hasHoverActions && 'overlay', styles.container)}
      data-testid="item"
      {...props}
    >
      {icon}
      {subtitle ? (
        <div className={styles['content-wrapper']}>
          {fileName}
          {subtitle}
        </div>
      ) : (
        fileName
      )}

      {hasHoverActions ? (
        <ActionSlot className={styles.action} size="28">
          {actions}
        </ActionSlot>
      ) : null}
    </div>
  );
}
