import React, { type CSSProperties, type HTMLAttributes, type ReactNode } from 'react';
import {
  Button,
  IconArrowSmallBottomFilled,
  IconArrowSmallTopFilled,
} from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import type { DraggableAttributes } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { DragCellHandle } from 'qonto/react/assets/icons';
import { Category } from 'qonto/react/components/transactions/sidebar/category/cashflow-category';
import type { CashflowCategory, CashflowCategorySide } from 'qonto/react/models/cash-flow-category';
import { SUBCATEGORIES_CREATION_LIMIT } from '../constants';
import { useCategoriesManagementRouting } from '../hooks/use-categories-management-routing';
import { AddSubcategoryButton } from './add-subcategory-button';
import styles from './category-item.strict-module.css';
import { CategoryItemMenu } from './category-item-menu';

interface CategoryItemProps extends HTMLAttributes<HTMLDivElement> {
  category: CashflowCategory;
  isExpanded?: boolean;
  isSubcategory?: boolean;
  canExpand?: boolean;
  dndParentAttributes?: DraggableAttributes;
  dndParentListeners?: SyntheticListenerMap | undefined;
  isSubcategoryHovered?: boolean;
  onAddSubcategory?: () => void;
  onToggleAccordion?: () => void;
  subcategoriesCount?: number;
  type: CashflowCategorySide;
}

export function CategoryItem({
  category,
  isSubcategory = false,
  isSubcategoryHovered = false,
  canExpand = false,
  isExpanded = false,
  dndParentAttributes,
  dndParentListeners,
  onAddSubcategory,
  onToggleAccordion,
  subcategoriesCount = 0,
  onMouseEnter,
  onMouseLeave,
  type,
  ...props
}: CategoryItemProps): ReactNode {
  const categoryId = category.id ?? '';
  const parentCategoryId = category.parentCategoryId ?? '';

  const { routeParams } = useCategoriesManagementRouting();
  const isSelected = routeParams.category_id === categoryId;

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: categoryId,
  });

  const dndAttributes = dndParentAttributes || attributes;
  const dndListeners = dndParentListeners || listeners;

  const dndStyle: CSSProperties = {
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleX: isDragging ? 1.01 : 1,
        scaleY: isDragging ? 1.01 : 1,
      }
    ),
    transition,
    zIndex: isDragging ? 10 : 'auto',
    position: isDragging ? 'relative' : 'static',
  };

  const { formatMessage } = useIntl();

  const shouldShowVat = (categoryObject: CashflowCategory): boolean => {
    const { vatRate } = categoryObject;
    const isZeroOrNull = (vat: number | null | undefined): boolean =>
      ['0', '0.0', 'null'].includes(String(vat));

    if (isZeroOrNull(vatRate) || subcategoriesCount >= 1) {
      return false;
    }
    return true;
  };

  const hasReachedSubcategoriesCreationLimit = subcategoriesCount >= SUBCATEGORIES_CREATION_LIMIT;

  return (
    <div
      className={cx(
        styles.container,
        isExpanded && styles.expanded,
        isSubcategory && styles.subcategory,
        !isSubcategory && !canExpand && styles['no-hover'],
        isSelected && styles.selected
      )}
      data-category-id={categoryId}
      data-testid={`${isSubcategory ? '' : 'parent-'}category-item`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={setNodeRef}
      style={dndParentListeners ? undefined : dndStyle}
      {...props}
    >
      <button
        className={styles['drag-handle']}
        data-testid="drag-handle"
        disabled={isSubcategoryHovered}
        type="button"
        {...dndAttributes}
        {...dndListeners}
      >
        <DragCellHandle />
      </button>
      {canExpand ? (
        <Button
          className={styles['toggle-accordion']}
          data-testid="toggle-accordion"
          iconOnly
          onPress={onToggleAccordion}
          variant="tertiary"
        >
          <div className={styles['category-details']} data-testid="category-details">
            <Category
              avatarSize={32}
              emphasized
              item={category}
              showIcon={!isSubcategory}
              showVat={shouldShowVat(category)}
            />
            <div className={styles['icon-container']}>{getTriggerIcon(isExpanded)}</div>
          </div>
        </Button>
      ) : (
        <div className={styles['category-details']} data-testid="category-details">
          <Category
            avatarSize={32}
            emphasized
            item={category}
            showIcon={!isSubcategory}
            showVat={shouldShowVat(category)}
          />
        </div>
      )}
      <div className={styles['menu-block']}>
        {onAddSubcategory ? (
          <AddSubcategoryButton
            handleOnPress={onAddSubcategory}
            hasReachedLimit={hasReachedSubcategoriesCreationLimit}
            limitReachedToolTipText={formatMessage({
              id: 'categories-management.limit-reached.tooltip.subcategory',
            })}
            withinLimitsToolTipText={formatMessage({
              id: 'categories-management.create-subcategory.tooltip',
            })}
          />
        ) : null}
        <CategoryItemMenu
          categoryId={categoryId}
          isSubcategory={isSubcategory}
          parentCategoryId={parentCategoryId}
          type={type}
        />
      </div>
    </div>
  );
}

function getTriggerIcon(isExpanded: boolean): ReactNode {
  return isExpanded ? (
    <IconArrowSmallTopFilled data-testid="icon-expanded" height={16} width={16} />
  ) : (
    <IconArrowSmallBottomFilled data-testid="icon-collapsed" height={16} width={16} />
  );
}
