import { type ReactNode } from 'react';

export function Basket(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="m12.427 5.564-2.364-4.11M3.564 5.562l2.367-4.106M4.786 8.09v4.1m6.497-4.1v4.1m-3.261-4.1v4.1M2.977 5.838H.843a.322.322 0 0 0-.273.137c-.045.07-.068.161-.045.276l1.68 7.927c.023.16.182.276.34.276h10.942a.362.362 0 0 0 .34-.276l1.68-7.927a.37.37 0 0 0-.067-.299.323.323 0 0 0-.273-.137h-2.134l-10.056.023Z"
      />
    </svg>
  );
}
