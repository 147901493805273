import { type ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-aria-components';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './styles.strict-module.css';

interface SubscriptionHeaderDeactivatedProps {
  readonly registerLink: string;
  readonly deactivationDate?: string;
}

export function SubscriptionHeaderDeactivated(
  props: SubscriptionHeaderDeactivatedProps
): ReactNode {
  const { registerLink, deactivationDate } = props;
  const { locale } = useIntl();

  const getFormattedDeactivationDate = (): string | undefined => {
    if (!deactivationDate) {
      return;
    }

    return dateToken({
      date: deactivationDate,
      locale,
      token: 'date-year-l',
    });
  };

  return (
    <>
      <div className={cx(styles['card-header'], 'mb-8')}>
        <h2 className="title-3" data-test-settings-subscription-plan-title-account-closed>
          <FormattedMessage id="subscription.account-closing.steps.closing" />
        </h2>
      </div>
      <p className="body-1 mb-16" data-test-settings-subscription-plan-description-account-closed>
        <span className={styles['subtitle-account-closed']}>
          <FormattedMessage id="subscription.account-closing.post-closing.requirements.account-is-closed.part-1" />
          <span className={styles.date} data-test-settings-subscription-plan-date-closed>
            {getFormattedDeactivationDate()}
          </span>
          <FormattedMessage id="subscription.account-closing.post-closing.requirements.account-is-closed.part-2" />
        </span>
      </p>

      <Link
        className="btn btn--primary mr-16"
        data-test-settings-subscription-open-new-account-cta
        href={registerLink}
      >
        <FormattedMessage id="subscription.account-closing.post-closing.requirements.open-account" />
      </Link>
    </>
  );
}
