import { type ReactNode } from 'react';
import { BadgeStatus } from '@repo/design-system-kit';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import type { TransactionStatus } from 'qonto/react/constants';
import type { StatusHistory } from 'qonto/react/graphql';
import { useStatusMaps } from './status-map';
import { StatusCellPopover } from './popover';

interface StatusCellProps {
  status: TransactionStatus;
  statusHistory: StatusHistory[];
}

export function StatusCell({ status, statusHistory }: StatusCellProps): ReactNode {
  const { statusMap } = useStatusMaps();
  const { title, badgeStatus } = statusMap[status];
  const currentStatus = (
    <BadgeStatus data-testid={`badge-${status.toLowerCase()}`} level={badgeStatus} text={title} />
  );

  return (
    <BaseCell
      popoverSlot={
        <StatusCellPopover currentStatus={currentStatus} statusHistory={statusHistory} />
      }
    >
      {currentStatus}
    </BaseCell>
  );
}
