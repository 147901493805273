import { SkeletonLoader } from '@repo/design-system-kit';
import type { ReactElement } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

export function SupervisorsSelectionPlaceholder(): ReactElement {
  return (
    <>
      <div className="mb-24">
        <SkeletonLoader.Line
          borderRadius="36px"
          className={cx(styles.line, 'mb-8')}
          data-test-supervisors-selection-placeholder-line=""
          standalone
          width="96px"
        />

        <SkeletonLoader.Block
          borderRadius="4px"
          className="icon"
          data-test-supervisors-selection-placeholder-block=""
          height="40px"
          width="504px"
        />
      </div>

      <div>
        <SkeletonLoader.Line
          borderRadius="36px"
          className={cx(styles.line, 'mb-8')}
          data-test-supervisors-selection-placeholder-line=""
          standalone
          width="96px"
        />

        <SkeletonLoader.Block
          borderRadius="4px"
          className="icon"
          data-test-supervisors-selection-placeholder-block=""
          height="80px"
          width="504px"
        />
      </div>
    </>
  );
}
