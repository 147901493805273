import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function SddRecurringInvoice(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={80} height={80} fill="none" {...props}>
      <rect
        width="37.664"
        height="57.1875"
        rx="0.4"
        transform="matrix(0.866025 -0.5 5.051e-08 -1 17.5 78.4194)"
        fill="#D5C8FB"
        stroke="#1D1D1B"
        strokeWidth={0.8}
      />
      <path
        d="M55.4907 5.70512L23.0733 24.2288C22.9487 24.3 22.8718 24.4313 22.8718 24.5748C22.8716 25.2291 22.8713 27.353 22.8713 31.936L22.8711 66.3905C22.8711 73.9822 29.5564 79.3099 33.3527 77.412L61.6054 61.0074C61.926 60.8212 61.7977 60.2358 61.4445 60.123C58.3215 59.1251 56.0848 54.6526 56.0848 49.3092L56.0851 12.9567C56.0851 8.76659 56.0852 6.88921 56.0854 6.04806C56.0854 5.74187 55.7566 5.55321 55.4907 5.70512Z"
        fill="white"
        stroke="#1D1D1B"
        strokeWidth={0.8}
      />
      <rect
        width="11.6507"
        height="5.71762"
        rx="0.4"
        transform="matrix(0.866025 -0.5 5.051e-08 -1 29.9922 33.9839)"
        fill="#FFEADD"
        stroke="#1D1D1B"
        strokeWidth={0.8}
      />
      <rect
        width="8.45728"
        height="5.74442"
        rx="0.4"
        transform="matrix(0.866025 -0.5 5.051e-08 -1 44.0234 57.9795)"
        fill="#FFEADD"
        stroke="#1D1D1B"
        strokeWidth={0.8}
      />
      <path
        d="M29.9922 51.351C29.9922 51.5719 30.1473 51.6614 30.3386 51.551L50.4544 39.9371C50.6457 39.8267 50.8008 39.558 50.8008 39.3371L50.8008 30.5621C50.8008 30.3412 50.6457 30.2516 50.4544 30.3621L30.3386 41.9759C30.1473 42.0864 29.9922 42.355 29.9922 42.5759L29.9922 51.351Z"
        fill="#FFEADD"
        stroke="#1D1D1B"
        strokeWidth={0.8}
      />
      <path d="M43.5 44.0806L43.5 34.5055" stroke="#1D1D1B" strokeWidth={0.8} />
      <path d="M50.8008 34.9881L29.9922 47.002" stroke="#1D1D1B" strokeWidth={0.8} />
    </svg>
  );
}
