import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function DocumentDownload(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.52 13.67H5.5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h4.02c.28 0 .5.22.5.5s-.22.5-.5.5ZM11.56 11.33H5.44c-.24 0-.44-.22-.44-.5s.2-.5.44-.5h6.12c.24 0 .44.22.44.5s-.2.5-.44.5Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M14 1H9.74c.25.31.47.64.65 1H14v13H3V9.39c-.36-.18-.69-.4-1-.65V15c0 .55.45 1 1 1h11c.55 0 1-.45 1-1V2c0-.55-.45-1-1-1Z"
      />
      <path
        fill="currentColor"
        d="M5.5 1C7.43 1 9 2.57 9 4.5S7.43 8 5.5 8 2 6.43 2 4.5 3.57 1 5.5 1Zm0-1C3.01 0 1 2.01 1 4.5S3.01 9 5.5 9 10 6.99 10 4.5 7.99 0 5.5 0Z"
      />
      <path
        fill="currentColor"
        d="M5.5 6.75c-.05 0-.1 0-.16-.02 0 0-.08-.03-.11-.05a.27.27 0 0 1-.08-.07L3.74 5.2c-.2-.2-.2-.51 0-.71.2-.2.51-.2.71 0l.56.56v-2.3c0-.28.22-.5.5-.5s.5.22.5.5v2.3l.56-.56c.2-.2.51-.2.71 0 .2.2.2.51 0 .71L5.87 6.61s-.06.05-.09.07c-.03.02-.07.04-.1.05-.05.02-.11.03-.16.03l-.02-.01Z"
      />
    </svg>
  );
}
