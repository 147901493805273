import { type ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import type { Transaction } from 'qonto/react/graphql';
import { MEMBERSHIP_CELL_CLICK_EVENT_NAME } from 'qonto/react/constants';
import type { TransactionInitiator } from 'qonto/react/models/transaction';
import { MembershipCellPopover } from './popover/membership-cell-popover';
import { Member } from './member/member';

interface MemberCellProps {
  transaction: Transaction;
}

export function MembershipCell({ transaction }: MemberCellProps): ReactNode {
  const membership = transaction.initiator;
  const segment = useEmberService('segment');

  const trackCellOpen = (isOpen: boolean): void => {
    if (isOpen) {
      segment.track(MEMBERSHIP_CELL_CLICK_EVENT_NAME);
    }
  };

  if (!membership) {
    return (
      <BaseCell>
        <span data-testid="empty">-</span>
      </BaseCell>
    );
  }

  return (
    <BaseCell
      onOpenChange={trackCellOpen}
      popoverSlot={<MembershipCellPopover membership={membership as TransactionInitiator} />}
    >
      <Member
        avatarUrl={membership.avatar.url}
        data-testid="membership"
        fullName={membership.fullName}
      />
    </BaseCell>
  );
}
