import { type ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

export function InvoiceDetailsCardLoading(): ReactNode {
  return (
    <div className={styles.card} data-test-invoice-details-loading>
      <div className={styles.row} data-test-loading-line>
        <SkeletonLoader.Line standalone width="103px" />
        <SkeletonLoader.Line standalone width="72px" />
      </div>
      <div className={styles.row} data-test-loading-line>
        <SkeletonLoader.Line standalone width="160px" />
      </div>
    </div>
  );
}
