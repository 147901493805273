import React, { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { LottiePlayer } from '@repo/design-system-kit';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import type { CategoriesListProps } from './categories-list';
import styles from './categories-list.strict-module.css';

export function CategoriesListEmpty({ type }: CategoriesListProps): ReactNode {
  const { formatMessage } = useIntl();
  const lottieSrc = useThemedAssetPath('/lotties/no-items.json');

  return (
    <div className={styles['categories-list-empty']} data-testid={`categories-list-${type}-empty`}>
      <LottiePlayer
        className={styles['lottie-illustration']}
        data-testid={`categories-list-${type}-empty-illustration`}
        path={lottieSrc}
      />
      <div className={styles['list-content']}>
        <h3 className="title-2" data-testid={`categories-list-${type}-empty-title`}>
          {formatMessage({ id: 'categories-management.no-categories.empty-state.title' })}
        </h3>
        <h4 className="body-2" data-testid={`categories-list-${type}-empty-subtitle`}>
          {formatMessage({ id: 'categories-management.no-categories.empty-state.subtitle' })}
        </h4>
      </div>
    </div>
  );
}
