import { Label } from '@repo/design-system-kit';
import React, { useEffect, useState, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { ListBoxItem, ListBox, Popover, Select, SelectValue, Button } from 'react-aria-components';
import { ChevronBottomOutlined, ChevronTopOutlined } from 'qonto/react/assets/icons';
import type { CashflowCategorySide } from 'qonto/react/models/cash-flow-category';
import styles from './category-details-form.strict-module.css';

interface TypeSelectProps {
  currentType: CashflowCategorySide;
  onSelectionChange: () => void;
}
export function TypeSelect({ onSelectionChange, currentType }: TypeSelectProps): ReactNode {
  const { formatMessage } = useIntl();
  const typeOptions = [
    {
      id: 'inflow',
      name: formatMessage({
        id: 'categories-management.create-category.type.inflows.dropdown-option',
      }),
    },
    {
      id: 'outflow',
      name: formatMessage({
        id: 'categories-management.create-category.type.outflows.dropdown-option',
      }),
    },
  ];

  const [isTypeSelectOpen, setIsTypeSelectOpen] = useState(false);
  const [hasTypeChanged, setHasTypeChanged] = useState(false);
  useEffect(() => {
    if (hasTypeChanged) {
      setHasTypeChanged(false);
      onSelectionChange();
    }
  }, [hasTypeChanged, onSelectionChange]);

  return (
    <Select
      data-testid="category-details-select-type"
      defaultSelectedKey={currentType}
      isOpen={isTypeSelectOpen}
      name="type"
      onOpenChange={setIsTypeSelectOpen}
      onSelectionChange={() => {
        setHasTypeChanged(true);
      }}
    >
      <Label data-testid="category-details-select-type-label">
        {formatMessage({ id: 'categories-management.create-category.type.label' })}
      </Label>
      <Button
        className={styles['select-type-trigger']}
        data-testid="category-details-select-type-trigger"
      >
        <div className={styles['select-trigger-content']}>
          <SelectValue className={styles['select-trigger-text']} />
          {isTypeSelectOpen ? (
            <ChevronTopOutlined aria-hidden="true" className={styles['select-trigger-arrow']} />
          ) : (
            <ChevronBottomOutlined aria-hidden="true" className={styles['select-trigger-arrow']} />
          )}
        </div>
      </Button>
      <Popover className={styles['select-type-popover']}>
        <ListBox data-testid="category-details-select-type-listbox" items={typeOptions}>
          {typeOption => (
            <ListBoxItem className={styles['select-listbox-item']} id={typeOption.id}>
              {typeOption.name}
            </ListBoxItem>
          )}
        </ListBox>
      </Popover>
    </Select>
  );
}
