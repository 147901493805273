import React, { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useEmberService, useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { Avatar, Badge, BadgeHighlight, Tooltip, type BadgeProps } from '@repo/design-system-kit';
// @ts-expect-error -- TODO remove this when ui-kit constants are typed
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { GMI_SOLUTION_ID_PREFIX, INTEGRATION_TYPES } from 'qonto/constants/connect';
import styles from './styles.strict-module.css';

interface ApplicationDataLogo {
  url: string;
  alt?: string;
}

interface Subscription {
  findTrial: (trial: string) => boolean;
}

interface ApplicationTagLine {
  type: string;
  text: string;
  spans: string[];
}

interface BadgeType {
  TRY_FOR_FREE: BadgeProps['type'];
  UPGRADE: BadgeProps['type'];
}

interface ApplicationHeaderContentProps {
  application: {
    integration_type: string;
    tray_solution_id?: string;
    minimum_integration_level: string;
    name: string;
    logo?: ApplicationDataLogo;
    waitlisted: boolean;
    tag_line: ApplicationTagLine[];
  };
}

export function ApplicationHeaderContent({
  application,
}: ApplicationHeaderContentProps): ReactNode {
  const { formatMessage } = useIntl();
  const subscriptionManager = useEmberService('subscription-manager');
  const abilities = useEmberService('abilities');

  const badgeTypes = BADGE_TYPE as BadgeType;

  const { featureBooleanGmiBetaBadge /*feature--boolean-gmi-beta-badge*/ } = useFlags();

  const cannotAccessIntegrationConnect = abilities.cannot(
    'access integration connect',
    application.minimum_integration_level
  );

  const isTrialAvailable: boolean =
    Boolean(application.minimum_integration_level) &&
    (subscriptionManager.currentSubscription as Subscription).findTrial(
      application.minimum_integration_level
    );

  const showBetaBadge: () => boolean = () => {
    if (featureBooleanGmiBetaBadge) {
      return Boolean(application.tray_solution_id?.startsWith(GMI_SOLUTION_ID_PREFIX));
    }

    return false;
  };

  const isPartnership = application.integration_type === INTEGRATION_TYPES.partnerships;

  return (
    <div className={styles['connect-app-header-content-left']}>
      <div className={styles['connect-app-header-meta']}>
        <div className={styles['application-avatar']}>
          <Avatar
            alt={application.logo?.alt ? application.logo.alt : application.name}
            data-test-application-avatar
            size="56"
            src={application.logo?.url}
            withBorder
          />
        </div>

        {showBetaBadge() && (
          <BadgeHighlight
            data-test-application-beta-badge
            level="secondary"
            text={formatMessage({ id: 'bank_accounts.header.beta-badge' })}
          />
        )}

        {isPartnership ? (
          <Tooltip
            data-test-partnership-tooltip
            delay={0}
            label={formatMessage({ id: 'qonto-hub.connect.partnership-tooltip' })}
            needsButton
          >
            <BadgeHighlight
              data-test-application-partnership-badge
              text={formatMessage({ id: 'qonto-hub.connect.partnership-badge' })}
            />
          </Tooltip>
        ) : null}

        {!isPartnership && cannotAccessIntegrationConnect ? (
          <Badge
            data-test-application-details-upsell-badge
            text={
              isTrialAvailable
                ? formatMessage({ id: 'upsell.discover.trial.badge' })
                : formatMessage({ id: 'upsell.add-ons.badge' })
            }
            type={isTrialAvailable ? badgeTypes.TRY_FOR_FREE : badgeTypes.UPGRADE}
          />
        ) : null}
      </div>
      <div className={styles['title-wrapper']}>
        <h1 className={styles.title} data-test-integration-name>
          {application.name}
        </h1>
      </div>
      <p className={styles['tag-line']} data-test-integration-tag-line>
        {application.waitlisted
          ? formatMessage({ id: 'qonto-hub.connect.integration.waitlist.description' })
          : application.tag_line[0]?.text}
      </p>
    </div>
  );
}
