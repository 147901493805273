import { useEffect, useState, type ReactElement } from 'react';
import { Checkbox } from '@repo/design-system-kit';
import { FormattedMessage } from 'react-intl';
import { bulkTransactionsManager } from 'qonto/react/contexts/bulk-transactions-context';
import { bulkVerificationStatusManager } from 'qonto/react/hooks/use-bulk-verification-status';
import styles from './styles.strict-module.css';

export function VerificationStatusSection(): ReactElement {
  const { verificationStatus: initialStatus } =
    bulkVerificationStatusManager.useBulkVerificationStatus();
  const {
    verificationStatus: { setVerificationStatus: bulkVerificationUpdaterFn },
  } = bulkTransactionsManager.useBulkTransactions();
  const [verificationStatus, setVerificationStatus] = useState(initialStatus);
  const isIndeterminate = verificationStatus === null;

  useEffect(() => {
    setVerificationStatus(initialStatus);
  }, [initialStatus]);

  const handleChange = (): void => {
    setVerificationStatus(verificationStatus === null ? false : !verificationStatus);
    bulkVerificationUpdaterFn(verificationStatus === null ? false : !verificationStatus);
  };

  return (
    <section className={styles.section} data-testid="verification-status-section">
      <h2 className={styles.title} data-testid="title">
        <FormattedMessage id="transactions.sidebar.bulk.review.title" />
      </h2>
      <Checkbox
        data-testid="checkbox"
        isIndeterminate={isIndeterminate}
        isSelected={!isIndeterminate && verificationStatus}
        onChange={handleChange}
      >
        <FormattedMessage id="transactions.sidebar.accounting_review.mark_as_verified" />
      </Checkbox>
    </section>
  );
}
