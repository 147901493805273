import { type ReactNode } from 'react';

export function ThreeStars(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none">
      <path
        stroke="currentColor"
        d="M7.846 2.118c.054-.161.293-.161.348 0L8.8 3.906a.182.182 0 0 0 .174.121h1.964c.177 0 .251.218.107.317L9.459 5.45a.171.171 0 0 0-.067.195l.607 1.789c.055.161-.138.295-.282.196L8.127 6.524a.19.19 0 0 0-.215 0L6.323 7.63c-.143.1-.336-.035-.281-.196l.607-1.789a.171.171 0 0 0-.067-.195L4.993 4.344c-.143-.1-.07-.317.108-.317h1.964c.079 0 .15-.049.174-.12l.607-1.789ZM3.435 8.451c.055-.161.294-.161.349 0l.606 1.788a.182.182 0 0 0 .175.122h1.963c.178 0 .252.217.108.317l-1.589 1.105a.171.171 0 0 0-.066.196l.607 1.788c.054.161-.139.296-.282.196l-1.589-1.105a.19.19 0 0 0-.215 0l-1.59 1.105c-.143.1-.336-.035-.28-.196l.606-1.788a.171.171 0 0 0-.066-.196l-1.59-1.105c-.143-.1-.069-.317.108-.317h1.964c.08 0 .15-.05.174-.122l.607-1.788ZM12.23 8.451c.055-.161.294-.161.348 0l.607 1.788a.182.182 0 0 0 .175.122h1.963c.178 0 .252.217.108.317l-1.589 1.105a.171.171 0 0 0-.066.196l.607 1.788c.054.161-.139.296-.282.196l-1.589-1.105a.19.19 0 0 0-.215 0l-1.589 1.105c-.143.1-.337-.035-.282-.196l.607-1.788a.171.171 0 0 0-.066-.196l-1.59-1.105c-.143-.1-.069-.317.108-.317h1.964c.08 0 .15-.05.174-.122l.607-1.788Z"
      />
    </svg>
  );
}
