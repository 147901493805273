import type { ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import styles from './styles.strict-module.css';

interface AmountProps {
  transaction:
    | {
        status: string;
        signedAmount?: number;
        amount?: number;
        amountCurrency: string;
        localAmount?: number;
        signedLocalAmount?: number;
        localAmountCurrency?: string;
        side?: 'credit' | 'debit';
        fx?: boolean;
        operationType?: string;
      }
    | null
    | undefined;
  showLocalAmount?: boolean;
  fx?: boolean;
}

export function Amount({ transaction, showLocalAmount = false }: AmountProps): ReactNode {
  const { formatNumber } = useIntl();

  if (!transaction) {
    return null;
  }

  const formatAmount = (amount: number, currency: string): string => {
    const isNegative = amount < 0 || transaction.side === 'debit';
    const formattedNumber = formatNumber(Math.abs(amount), {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const sign = isNegative ? '-' : '+';
    return `${sign}\u00A0${formattedNumber}\u00A0${currency}`;
  };

  const amountValue = transaction.signedAmount ?? transaction.amount ?? 0;
  const localAmount = transaction.signedLocalAmount ?? 0;
  const localCurrency = transaction.localAmountCurrency ?? '';

  const wrapperClass = transaction.status ? styles[transaction.status] : '';
  const baseClass = styles.wrapper;

  const canShowLocalAmount = Boolean(
    showLocalAmount &&
      localAmount !== 0 &&
      localCurrency &&
      localCurrency !== transaction.amountCurrency
  );

  return (
    <div
      className={cx(baseClass, wrapperClass)}
      data-test-transaction-status={transaction.status || undefined}
    >
      <span className="title-3" data-test-amount>
        {formatAmount(amountValue, transaction.amountCurrency)}
      </span>

      {canShowLocalAmount ? (
        <span className={styles['local-amount']} data-test-local-amount>
          {formatAmount(localAmount, localCurrency)}
        </span>
      ) : null}
    </div>
  );
}
