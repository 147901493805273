import { type Selection } from 'react-aria-components';
import type { Label } from 'qonto/react/graphql';
import { getSelectionValue } from 'qonto/react/utils/react-aria-helpers';
import type { LabelList } from 'qonto/react/models/label';

export const getLabelValue = (
  labelId: Selection,
  labelListLabels: LabelList['labels']
): Label | undefined => {
  const selectedLabelId = getSelectionValue(labelId);
  return labelListLabels.find(l => l.id === selectedLabelId) as Label | undefined;
};

export const updateLabels = ({
  activeLabel,
  selectedLabel,
  labels = [],
}: {
  activeLabel?: Label | null;
  selectedLabel?: Label;
  labels?: Label[];
}): { newLabels: Label[]; newLabelIds: string[] } => {
  let newLabels = [...labels];
  if (activeLabel) newLabels = newLabels.filter(label => label.id !== activeLabel.id);
  if (selectedLabel) newLabels.push(selectedLabel);

  return {
    newLabels,
    newLabelIds: newLabels.map(l => l.id),
  };
};
