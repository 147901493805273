import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { type StatusProps } from '@repo/design-system-kit';
import { CheckmarkRoundedOutlined, ProcessingOutlined } from 'qonto/react/assets/icons';
import type { StatusHistoryStatus, TransactionStatus } from 'qonto/react/constants';
import {
  Status as TransactionStatusEnum,
  StatusHistoryStatus as StatusHistoryStatusEnum,
} from 'qonto/react/graphql';

type StatusMap = Record<
  TransactionStatus,
  {
    badgeStatus: StatusProps['level'];
    title: string;
  }
>;

export const statusMap = (formatMessage: (message: { id: string }) => string): StatusMap => ({
  [TransactionStatusEnum.Completed]: {
    badgeStatus: 'validated',
    title: formatMessage({ id: 'transactions.modular_table.status.completed' }),
  },
  [TransactionStatusEnum.Pending]: {
    badgeStatus: 'in-progress',
    title: formatMessage({ id: 'transactions.modular_table.status.processing' }),
  },
  [TransactionStatusEnum.Declined]: {
    badgeStatus: 'error',
    title: formatMessage({ id: 'transactions.modular_table.status.rejected' }),
  },
  [TransactionStatusEnum.Reversed]: {
    badgeStatus: 'error',
    title: formatMessage({ id: 'transactions.modular_table.status.rejected' }),
  },
});

type StatusHistoryMap = Record<
  StatusHistoryStatus,
  {
    title: string;
    icon: ReactNode;
  }
>;

export const statusHistoryMap = (
  formatMessage: (message: { id: string }) => string
): StatusHistoryMap => ({
  [StatusHistoryStatusEnum.Settled]: {
    title: formatMessage({ id: 'transactions.modular_table.status.completed' }),
    icon: <CheckmarkRoundedOutlined />,
  },
  [StatusHistoryStatusEnum.Authorized]: {
    title: formatMessage({ id: 'transactions.modular_table.status.processing' }),
    icon: <ProcessingOutlined />,
  },
});

export const useStatusMaps = (): {
  statusMap: StatusMap;
  statusHistoryMap: StatusHistoryMap;
} => {
  const { formatMessage } = useIntl();

  return {
    statusMap: statusMap(formatMessage),
    statusHistoryMap: statusHistoryMap(formatMessage),
  };
};
