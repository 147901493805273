import { useState, type ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { PlusComment } from 'qonto/react/assets/icons';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import { useThread } from 'qonto/react/hooks/use-thread';
import type { Transaction } from 'qonto/react/graphql';
import { COMMENT_CELL_CLICK_EVENT_NAME } from 'qonto/react/constants';
import { CommentsCellRoot } from './comments-cell-root';
import { CommentsCellPopover } from './popover';

interface CommentsCellProps {
  transaction: Transaction;
}

export function CommentsCell({ transaction }: CommentsCellProps): ReactNode {
  const [isHovered, setIsHovered] = useState(false);
  const segment = useEmberService('segment');
  const threadData = useThread(transaction);
  const {
    count,
    read,
    currentUserIsParticipant,
    toggleReadStatus,
    userId,
    participants,
    messages,
  } = threadData;

  const userIsNotOnlyParticipant = currentUserIsParticipant && participants.length > 1;
  const showNotificationDot = userIsNotOnlyParticipant && !read && count > 0;

  const trackCellOpen = (isOpen: boolean): void => {
    if (isOpen) {
      segment.track(COMMENT_CELL_CLICK_EVENT_NAME);
    }
  };

  return (
    <BaseCell
      data-testid="comment-cell"
      disableHoverAction={count === 0}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onOpenChange={trackCellOpen}
      popoverSlot={
        <CommentsCellPopover
          count={count}
          currentUserIsParticipant={currentUserIsParticipant}
          messages={messages}
          participants={participants}
          read={read}
          toggleReadStatus={toggleReadStatus}
          transaction={transaction}
          userId={userId}
        />
      }
    >
      {isHovered && count === 0 ? (
        <DataWithIconCell icon={<PlusComment aria-hidden data-testid="icon-plus-comment" />} />
      ) : (
        <CommentsCellRoot
          showNotificationDot={showNotificationDot}
          textSlot={count > 0 ? count : null}
        />
      )}
    </BaseCell>
  );
}
