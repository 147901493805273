import React, { type ReactNode } from 'react';
import { useSegment } from '@repo/poly-hooks/segment';
import { useIntl } from 'react-intl';
import { useFetchCashflowCategories } from 'qonto/react/hooks/use-fetch-cashflow-categories';
import { useCategoriesManagementRouting } from '../hooks/use-categories-management-routing';
import { CATEGORIES_CREATION_LIMIT } from '../constants';
import { CreateCategoryButton } from './create-category-button';
import styles from './page-header.strict-module.css';

export function PageHeader(): ReactNode {
  const segment = useSegment();
  const { formatMessage } = useIntl();
  const { navigateToNewCategory } = useCategoriesManagementRouting();
  const handleClick = (): void => {
    segment.track('cash-flow-categories_category_add_clicked');
    navigateToNewCategory();
  };

  const { data: categories } = useFetchCashflowCategories();
  const hasReachedCategoriesCreationLimit = (): boolean => {
    let hasReachedLimit = false;
    if (categories?.inflow) {
      hasReachedLimit =
        [...categories.inflow, ...categories.outflow].length >= CATEGORIES_CREATION_LIMIT;
    }

    return hasReachedLimit;
  };

  return (
    <header className={styles.header} data-testid="page-header">
      <div className={styles['title-group']}>
        <h1 className="title-1" data-testid="title">
          {formatMessage({ id: 'categories-management.page-header.title' })}
        </h1>
        <p className="body-2" data-testid="subtitle">
          {formatMessage({ id: 'categories-management.page-header.subtitle' })}
        </p>
      </div>
      <CreateCategoryButton
        buttonCtaText={formatMessage({ id: 'categories-management.page-header.add-category.cta' })}
        handleClick={handleClick}
        hasReachedLimit={hasReachedCategoriesCreationLimit()}
        toolTipText={formatMessage({ id: 'categories-management.limit-reached.tooltip.category' })}
      />
    </header>
  );
}
