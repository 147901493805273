import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function CrossRoundedOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8Zm0 15c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7Z"
      />
      <path
        fill="currentColor"
        d="M10.88 10.14c.2.2.2.53 0 .73a.508.508 0 0 1-.72 0L8.01 8.72l-2.16 2.15a.508.508 0 0 1-.72 0 .52.52 0 0 1 0-.73L7.28 8 5.13 5.86c-.2-.19-.2-.52 0-.72.2-.2.52-.2.72 0l2.16 2.14 2.15-2.14c.2-.2.52-.2.72 0 .2.2.2.53 0 .72L8.73 8l2.15 2.14Z"
      />
    </svg>
  );
}
