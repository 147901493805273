import { type ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { CARD_LEVELS, CARD_PRINT_TYPES } from 'qonto/constants/cards';
import { getColor } from 'qonto/utils/cards/design';
import styles from './card-review-panel.strict-module.css';

interface CardReviewPanelProps {
  cardDesign: string;
  cardLevel: string;
  className?: string;
  description: string;
  iconSrc: string;
  name: string;
  typeOfPrint?: string;
}

const useDescription = (cardLevel: string, typeOfPrint?: string): string | null => {
  const { formatMessage } = useIntl();

  switch (cardLevel) {
    case CARD_LEVELS.STANDARD:
    case CARD_LEVELS.PLUS: {
      return typeOfPrint === CARD_PRINT_TYPES.EMBOSSED
        ? formatMessage({ id: 'cards.steps.review.print-type.embossed' })
        : formatMessage({ id: 'cards.steps.review.print-type.printed' });
    }
    case CARD_LEVELS.METAL: {
      return formatMessage({ id: 'cards.steps.review.print-type.engraved' });
    }
    case CARD_LEVELS.VIRTUAL:
    case CARD_LEVELS.ADVERTISING:
      return formatMessage({ id: 'cards.steps.review.card-type.virtual-permanent' });
    case CARD_LEVELS.FLASH:
      return formatMessage({ id: 'cards.steps.review.card-type.virtual-disposable' });
    default:
      return null;
  }
};

export function CardReviewPanel({
  cardDesign,
  cardLevel,
  className: classNameProp,
  iconSrc,
  name,
  typeOfPrint,
}: CardReviewPanelProps): ReactNode {
  const { formatMessage } = useIntl();

  // need to fall back to '' due to typechecking in "t"
  const color = getColor({ cardDesign, cardLevel, t: id => formatMessage({ id }) }) ?? '';

  const description = useDescription(cardLevel, typeOfPrint);

  return (
    <div className={cx(styles.panel, classNameProp)} data-testid="card-review-panel">
      <img alt="" className={styles.icon} src={iconSrc} />
      <p className={cx('body-1', styles.description)} data-testid="card-review-panel-description">
        <span>{name} </span>
        {Boolean(description) && <span>{description} </span>}
        {Boolean(color) && (
          <span className="sr-only">
            <FormattedMessage id="cards.steps.review.card-color" values={{ cardColor: color }} />
          </span>
        )}
      </p>
    </div>
  );
}
