import { type ReactNode } from 'react';
import { CategoryIcon } from 'qonto/react/components/transactions/sidebar/category/cashflow-category/components/category-icon';
import styles from './category-details-form.strict-module.css';

interface CategoryAvatarProps {
  color: string;
  icon: string;
}
export function CategoryAvatar({ color, icon }: CategoryAvatarProps): ReactNode {
  return (
    <div className={styles['details-avatar']} data-testid="details-avatar">
      <CategoryIcon avatarSize={64} color={color} icon={icon} />
    </div>
  );
}
