import { type ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface RecycledProps {
  text: string;
  className?: string;
}

export function Recycled({ text, className, ...rest }: RecycledProps): ReactNode {
  return (
    <span className={cx(styles.badge, className)} {...rest}>
      <p className="caption-bold" data-test-recycled-badge-text>
        {text}
      </p>
    </span>
  );
}
