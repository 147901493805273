import cx from 'clsx';
import { Button, Checkbox, Tooltip } from '@repo/design-system-kit';
import { useEffect, useState, type Key, type ReactElement } from 'react';
import { ListBoxItem } from 'react-aria-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Info } from 'qonto/react/assets/icons/info';
import { Dropdown } from 'qonto/react/components/table-v2/dropdown';
import { bulkTransactionsManager } from 'qonto/react/contexts/bulk-transactions-context';
import { bulkAttachmentManager } from 'qonto/react/hooks/use-bulk-attachment-status';
import { bulkRequestAttachmentManager } from 'qonto/react/hooks/use-bulk-request-attachment';
import { ATTACHMENT_STATUS, type AttachmentStatusOption } from 'qonto/react/models/transaction';
import styles from './styles.strict-module.css';

function RequestAttachmentCheckbox(): ReactElement {
  const intl = useIntl();
  const {
    requestAttachment: { setRequestAttachment: bulkRequestAttachmentUpdaterFn },
  } = bulkTransactionsManager.useBulkTransactions();
  const [requestAttachment, setRequestAttachment] = useState(false);
  const { disabledRequestAttachment } = bulkRequestAttachmentManager.useBulkRequestAttachment();
  const tooltipLabel = intl.formatMessage({
    id: 'transactions.sidebar.bulk.attachment_disclaimer',
  });
  const disabledTooltipLabel = intl.formatMessage({
    id: 'transactions.sidebar.bulk.attachment-disabled-disclaimer',
  });

  const handleRequestAttachmentChange = (): void => {
    setRequestAttachment(!requestAttachment);
    bulkRequestAttachmentUpdaterFn(!requestAttachment);
  };

  return (
    <div className={styles['attachment-request-wrapper']} data-testid="request-attachment">
      <Checkbox
        isDisabled={disabledRequestAttachment}
        isSelected={requestAttachment}
        onChange={handleRequestAttachmentChange}
      >
        <FormattedMessage id="labels.bulk_attachment" />
      </Checkbox>
      <Tooltip
        className={styles.tooltip}
        closeDelay={0}
        delay={0}
        label={disabledRequestAttachment ? disabledTooltipLabel : tooltipLabel}
        placement="top"
      >
        <Button className={styles['tooltip-trigger']}>
          <Info className={styles.icon} />
        </Button>
      </Tooltip>
    </div>
  );
}

function AttachmentStatusSelect(): ReactElement {
  const intl = useIntl();
  const { attachmentStatus: initialStatus, allHaveAttachments } =
    bulkAttachmentManager.useBulkAttachmentStatus();
  const [selectedStatus, setSelectedStatus] = useState(initialStatus);
  const {
    attachmentStatus: { setAttachmentStatus: bulkAttachmentStatusUpdaterFn },
  } = bulkTransactionsManager.useBulkTransactions();

  const StatusOptions = [
    {
      id: ATTACHMENT_STATUS.LOST,
      title: intl.formatMessage({
        id: 'transactions.sidebar.bulk.attachment-status.option.lost',
      }),
    },
    {
      id: ATTACHMENT_STATUS.REQUIRED,
      title: intl.formatMessage({
        id: 'transactions.sidebar.bulk.attachment-status.option.required',
      }),
    },
    {
      id: ATTACHMENT_STATUS.NOT_REQUIRED,
      title: intl.formatMessage({
        id: 'transactions.sidebar.bulk.attachment-status.option.not-required',
      }),
    },
  ];

  useEffect(() => {
    setSelectedStatus(initialStatus);
  }, [initialStatus]);

  const handleSelectionChange = (key: Key): void => {
    setSelectedStatus(key as AttachmentStatusOption);
    bulkAttachmentStatusUpdaterFn(key as AttachmentStatusOption, initialStatus);
  };

  return (
    <Dropdown
      data-testid="attachment-select"
      isDisabled={allHaveAttachments}
      items={StatusOptions}
      label={intl.formatMessage({
        id: 'transactions.sidebar.attachment.title',
      })}
      onSelectionChange={handleSelectionChange}
      placeholder={intl.formatMessage({
        id: 'transactions.sidebar.bulk.attachment-status.placeholder',
      })}
      selectedKey={selectedStatus}
    >
      {item => (
        <ListBoxItem
          className={cx(styles['list-item'], 'body-2')}
          key={item.id}
          textValue={item.title}
        >
          {item.title}
        </ListBoxItem>
      )}
    </Dropdown>
  );
}

export function AttachmentSection(): ReactElement {
  const { allHaveAttachments } = bulkAttachmentManager.useBulkAttachmentStatus();

  return (
    <section className={styles.section} data-testid="attachment-section">
      <AttachmentStatusSelect />
      {allHaveAttachments ? null : <RequestAttachmentCheckbox />}
    </section>
  );
}
