import { useEffect, useRef, type CSSProperties, type ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { closestCorners, DndContext, type DragEndEvent } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import type {
  CashflowCategorySide,
  CashflowParentCategory,
} from 'qonto/react/models/cash-flow-category';
import { useCashFlowCategoriesStateStorage } from 'qonto/react/hooks/use-cash-flow-categories-storage';
import { useUpdateCashFlowSubcategory } from 'qonto/react/hooks/use-update-cash-flow-category';
import { useDragAndDrop } from '../hooks/use-drag-and-drop';
import { CategoryItem } from '../category-item';
import { useCategoriesManagementRouting } from '../hooks/use-categories-management-routing';
import { SubcategoryItemNew } from '../category-item-new/subcategory-item-new';
import styles from './category-item-group.strict-module.css';

interface CategoryItemGroupProps {
  category: CashflowParentCategory;
  type: CashflowCategorySide;
  isAnySubcategoryHovered: boolean;
  onSubcategoryMouseEnter: () => void;
  onSubcategoryMouseLeave: () => void;
}
export function CategoryItemGroup({
  category,
  type,
  isAnySubcategoryHovered,
  onSubcategoryMouseEnter,
  onSubcategoryMouseLeave,
}: CategoryItemGroupProps): ReactNode {
  const categoryId = category.id ?? '';

  const segment = useEmberService('segment');
  const { mutate } = useUpdateCashFlowSubcategory();
  const { navigateToNewSubcategory, isCreatingNewSubcategoryForParent } =
    useCategoriesManagementRouting();
  const { isExpanded, setIsExpanded } = useCashFlowCategoriesStateStorage(
    'cash-flow-categories-management-expanded-states',
    categoryId,
    true
  );

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: categoryId,
  });
  const dndStyle: CSSProperties = {
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleX: isDragging ? 1.01 : 1,
        scaleY: isDragging ? 1.01 : 1,
      }
    ),
    transition,
    zIndex: isDragging ? 10 : 'auto',
    position: isDragging ? 'relative' : 'static',
  };

  const { categories: subcategories, onDragEnd, sensors } = useDragAndDrop(category.subcategories);
  const subcategoriesCount = subcategories.length;
  const hasSubcategories = subcategoriesCount > 0;
  const showNewSubcategoryForm = isCreatingNewSubcategoryForParent(categoryId);
  const canExpand = hasSubcategories || showNewSubcategoryForm;
  const showSubcategories = isExpanded && (hasSubcategories || showNewSubcategoryForm);

  useEffect(() => {
    if (showNewSubcategoryForm) {
      setIsExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- adding setIsExpanded to deps creates a loop
  }, [showNewSubcategoryForm]);

  // Collapse subcategories when dragging category
  const previousExpandedRef = useRef<boolean>();
  useEffect(() => {
    if (isDragging && previousExpandedRef.current === undefined) {
      previousExpandedRef.current = isExpanded;
      setIsExpanded(false);
    } else if (!isDragging && previousExpandedRef.current !== undefined) {
      setIsExpanded(previousExpandedRef.current);
      previousExpandedRef.current = undefined;
    }
  }, [isDragging, isExpanded, setIsExpanded]);

  const handleAddSubcategory = (): void => {
    segment.track('cash-flow-categories_subcategory_add_clicked');
    navigateToNewSubcategory(categoryId);
  };

  const handleToggleAccordion = (): void => {
    setIsExpanded(!isExpanded);
  };

  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, updatedCategories: updatedSubcategories } = onDragEnd(event);

    if (updatedSubcategories.length > 0) {
      const order = updatedSubcategories.findIndex(subcategory => subcategory.id === active.id);
      mutate({
        type,
        categoryId,
        subcategoryId: active.id.toString(),
        reorderedSubcategories: updatedSubcategories,
        payload: { order: order + 1 },
      });
    }
  };

  return (
    <div
      className={styles.container}
      data-category-group-id={categoryId}
      data-testid="category-item-group"
      ref={setNodeRef}
      style={dndStyle}
    >
      <CategoryItem
        canExpand={canExpand}
        category={category}
        dndParentAttributes={attributes}
        dndParentListeners={listeners}
        isExpanded={showSubcategories}
        isSubcategoryHovered={isAnySubcategoryHovered}
        onAddSubcategory={handleAddSubcategory}
        onToggleAccordion={handleToggleAccordion}
        subcategoriesCount={subcategoriesCount}
        type={type}
      />
      {showSubcategories ? (
        <div>
          <DndContext
            collisionDetection={closestCorners}
            modifiers={[restrictToParentElement]}
            onDragEnd={handleDragEnd}
            sensors={sensors}
          >
            <SortableContext items={subcategories} strategy={verticalListSortingStrategy}>
              {subcategories.map(subcategory => (
                <CategoryItem
                  category={subcategory}
                  isSubcategory
                  key={subcategory.id}
                  onMouseEnter={onSubcategoryMouseEnter}
                  onMouseLeave={onSubcategoryMouseLeave}
                  type={type}
                />
              ))}
            </SortableContext>
          </DndContext>
          <SubcategoryItemNew category={category} />
        </div>
      ) : null}
    </div>
  );
}
