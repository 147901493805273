import { type ReactNode } from 'react';
import { NoAttachment } from 'qonto/react/assets/icons';
import styles from './non-financial-attachment-preview.strict-module.css';

interface NonFinancialAttachmentPreviewProps {
  subtitle: string;
  title: string;
}

export function NonFinancialAttachmentPreview({
  subtitle,
  title,
  ...props
}: NonFinancialAttachmentPreviewProps): ReactNode {
  return (
    <section className={styles['preview-container']} {...props}>
      <div className="mb-16">
        <NoAttachment />
      </div>
      <h3 className="title-2 mb-8" data-testid="preview-title">
        {title}
      </h3>
      <p className="body-2" data-testid="preview-subtitle">
        {subtitle}
      </p>
    </section>
  );
}
