import type { ReactElement } from 'react';
import { LottiePlayer } from '@repo/design-system-kit';
import { FormattedMessage } from 'react-intl';
import styles from './styles.strict-module.css';

export interface OcrLoadingProps {
  lottieSrc: string;
  hasTimedOut: boolean;
}

export function OcrLoading({ lottieSrc, hasTimedOut, ...props }: OcrLoadingProps): ReactElement {
  return (
    <div {...props} className={styles['ocr-pending']} data-test-ocr-processing>
      <LottiePlayer
        className={styles['ocr-illustration']}
        data-test-onboarding-scan-invoice-illustration
        path={lottieSrc}
      />
      <h3 className="mb-8 title-4" data-test-ocr-processing-title>
        <FormattedMessage id="receivable-invoices.onboarding.invoice-upload.title.loading" />
      </h3>
      <h4 className="body-2" data-test-ocr-processing-subtitle>
        <FormattedMessage
          id={
            hasTimedOut
              ? 'receivable-invoices.onboarding.invoice-upload.subtitle.timeout'
              : 'receivable-invoices.onboarding.invoice-upload.subtitle.loading'
          }
        />
      </h4>
    </div>
  );
}
