import { type ReactNode, type SVGProps } from 'react';

export function IconBox(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m10.349 1.55-1.344.757 4.273 2.424 1.361-.76-4.29-2.42Zm-.324-.965a.66.66 0 0 1 .649 0l4.992 2.818a.654.654 0 0 1-.003 1.143l-1.382.77 1.382.77c.447.25.45.891.003 1.143l-2.137 1.206v3.677c0 .186-.1.358-.26.45l-5.024 2.867a.518.518 0 0 1-.514 0l-5.023-2.867a.518.518 0 0 1-.261-.45V8.427L.333 7.228a.654.654 0 0 1 .001-1.14l1.37-.772-1.37-.771a.654.654 0 0 1-.001-1.14l4.97-2.82a.66.66 0 0 1 .65 0l2.036 1.147L10.025.585ZM7.989 2.88 3.694 5.316l4.294 2.435 4.295-2.435-4.294-2.435ZM6.973 8.325 2.7 5.903l-1.344.756 4.272 2.423 1.344-.757Zm.516.857-1.537.865a.66.66 0 0 1-.65 0L3.448 8.994v2.838l4.042 2.307V9.182Zm1 4.956 4.04-2.306V9l-1.856 1.048a.66.66 0 0 1-.649 0L8.49 9.184v4.955Zm.515-5.813 1.344.757 4.29-2.421-1.36-.76-4.274 2.424ZM5.629 1.551 1.357 3.973l1.344.757 4.272-2.423-1.344-.756Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
