import { type ReactNode } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { type TransactionModel } from 'qonto/react/models/transaction';
import styles from './styles.strict-module.css';

interface VatAccountingStatusProps {
  model: TransactionModel;
}

export function VatAccountingStatus({ model }: VatAccountingStatusProps): ReactNode {
  const { vatRate } = model;

  let formattedSuggestedRate = <FormattedMessage id="transactions.sidebar.vat.accounting.other" />;
  if (vatRate && vatRate > 0) {
    formattedSuggestedRate = (
      <>
        <FormattedNumber value={vatRate} /> %
      </>
    );
  } else if (vatRate === 0) {
    formattedSuggestedRate = <FormattedMessage id="transactions.sidebar.vat.accounting.no-rate" />;
  }

  const handleEditVatSuggested = (): void => {
    model.vatStatus = 'vat_overriden';
    model.vatAmount = null;
    model.vatRate = null;
    model.save();
  };

  const handleConfirmVatSuggested = (): void => {
    model.vatStatus = 'vat_confirmed';
    model.save();
  };

  return (
    <>
      {model.vatStatus === 'vat_not_found' && (
        <div className={styles.statusDescription} data-test-vat-not-found>
          <FormattedMessage id="transactions.sidebar.vat.not_found_desc" />
        </div>
      )}
      {model.vatStatus === 'vat_suggested' && (
        <>
          <div>
            <div className={styles.amount}>
              <FormattedNumber currency="EUR" style="currency" value={model.vatAmount ?? 0} />

              <div className={styles.amountRate} data-test-vat-rate>
                ({formattedSuggestedRate})
              </div>
            </div>
          </div>

          <div className={styles.vatSuggestedDescription}>
            <FormattedMessage id="transactions.sidebar.vat.suggested_desc" />
          </div>

          <div className={styles.actions}>
            <div className={styles.actionsLeft}>
              <button
                className="btn btn--secondary btn--stretch"
                data-test-edit-suggested-vat-button
                onClick={handleEditVatSuggested}
                type="button"
              >
                <FormattedMessage id="btn.edit" />
              </button>
            </div>

            <div className={styles.actionsRight}>
              <button
                className="btn btn--primary btn--stretch"
                data-test-confirm-suggested-vat-button
                onClick={handleConfirmVatSuggested}
                type="button"
              >
                <FormattedMessage id="btn.confirm" />
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
