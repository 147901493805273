import React from 'react';
import { Button } from '@repo/design-system-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'clsx';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './error.strict-module.css';

interface PagoPaResultErrorProps {
  transitionToNext: () => void;
}

export function PagoPaResultError({ transitionToNext }: PagoPaResultErrorProps): React.JSX.Element {
  const { formatMessage } = useIntl();

  const errorSubtitle = formatMessage({ id: 'pagopa.payment-creation.result.error.subtitle' });

  return (
    <div className={styles.container} data-test-result-error="">
      <StaticThemedAsset
        alt=""
        assetPath="/illustrations/pagopa/pagopa-result-error.svg"
        className={cx(styles.illustration, 'mb-32')}
        data-test-result-error-illustration=""
      />
      <h1 className={cx('title-1', 'mb-16')} data-test-result-error-title="">
        <FormattedMessage id="pagopa.payment-creation.result.error.title" />
      </h1>
      <p
        className="body-1 mb-32"
        dangerouslySetInnerHTML={{ __html: errorSubtitle }}
        data-test-result-error-subtitle=""
      />
      <Button data-test-result-error-cta="" onPress={transitionToNext} variant="primary">
        <FormattedMessage id="btn.close" />
      </Button>
    </div>
  );
}
