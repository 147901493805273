import { useState, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import {
  Calculator,
  LightBulb,
  Rocket,
  Tag,
  Restaurant,
  Basket,
  Jerrican,
  ThreeStars,
  Computer,
  Tool,
  Pin,
  Key,
  Shield,
  Box,
  Globe,
  Scale,
  Institution,
  People,
  Megaphone,
  Hook,
  Atm,
  Briefcase,
  Wallet,
  Safe,
  Target,
  MoneyBag,
  ArrowBack,
  Receipt,
  ProcessingOutlined,
  PlusSign,
} from 'qonto/react/assets/icons';

interface CategoryDetails {
  title: string;
  icon: ReactNode;
}

type CategoryMap = Record<string, CategoryDetails>;
type CategoriesCollection = (CategoryDetails & { id: string })[];

const useCategoryMap = (): CategoryMap => {
  const intl = useIntl();

  const categoryMap: CategoryMap = {
    restaurant_and_bar: {
      title: intl.formatMessage({ id: 'activities.restaurant_and_bar' }),
      icon: <Restaurant />,
    },
    food_and_grocery: {
      title: intl.formatMessage({ id: 'activities.food_and_grocery' }),
      icon: <Basket />,
    },
    transport: {
      title: intl.formatMessage({ id: 'activities.transport' }),
      icon: <Rocket />,
    },
    gas_station: {
      title: intl.formatMessage({ id: 'activities.gas_station' }),
      icon: <Jerrican />,
    },
    hotel_and_lodging: {
      title: intl.formatMessage({ id: 'activities.hotel_and_lodging' }),
      icon: <ThreeStars />,
    },
    it_and_electronics: {
      title: intl.formatMessage({ id: 'activities.it_and_electronics' }),
      icon: <Computer />,
    },
    hardware_and_equipment: {
      title: intl.formatMessage({ id: 'activities.hardware_and_equipment' }),
      icon: <Tool />,
    },
    office_supply: {
      title: intl.formatMessage({ id: 'activities.office_supply' }),
      icon: <Pin />,
    },
    office_rental: {
      title: intl.formatMessage({ id: 'activities.office_rental' }),
      icon: <Key />,
    },
    utility: {
      title: intl.formatMessage({ id: 'activities.utility' }),
      icon: <LightBulb />,
    },
    insurance: {
      title: intl.formatMessage({ id: 'activities.insurance' }),
      icon: <Shield />,
    },
    logistics: {
      title: intl.formatMessage({ id: 'activities.logistics' }),
      icon: <Box />,
    },
    online_service: {
      title: intl.formatMessage({ id: 'activities.online_service' }),
      icon: <Globe />,
    },
    legal_and_accounting: {
      title: intl.formatMessage({ id: 'activities.legal_and_accounting' }),
      icon: <Scale />,
    },
    finance: {
      title: intl.formatMessage({ id: 'activities.finance' }),
      icon: <Institution />,
    },
    tax: {
      title: intl.formatMessage({ id: 'activities.tax' }),
      icon: <Calculator />,
    },
    salary: {
      title: intl.formatMessage({ id: 'activities.salary' }),
      icon: <People />,
    },
    marketing: {
      title: intl.formatMessage({ id: 'activities.marketing' }),
      icon: <Megaphone />,
    },
    manufacturing: {
      title: intl.formatMessage({ id: 'activities.manufacturing' }),
      icon: <Hook />,
    },
    atm: {
      title: intl.formatMessage({ id: 'activities.atm' }),
      icon: <Atm />,
    },
    other_service: {
      title: intl.formatMessage({ id: 'activities.other_service' }),
      icon: <Briefcase />,
    },
    other_expense: {
      title: intl.formatMessage({ id: 'activities.other_expense' }),
      icon: <Wallet />,
    },
    treasury_and_interco: {
      title: intl.formatMessage({ id: 'activities.treasury_and_interco' }),
      icon: <Safe />,
    },
    sales: {
      title: intl.formatMessage({ id: 'activities.sales' }),
      icon: <Target />,
    },
    other_income: {
      title: intl.formatMessage({ id: 'activities.other_income' }),
      icon: <MoneyBag />,
    },
    refund: {
      title: intl.formatMessage({ id: 'activities.refund' }),
      icon: <ArrowBack />,
    },
    fees: {
      title: intl.formatMessage({ id: 'activities.fees' }),
      icon: <Receipt />,
    },
    voucher: {
      title: intl.formatMessage({ id: 'activities.voucher' }),
      icon: <Tag />,
    },
    subscription: {
      title: intl.formatMessage({ id: 'activities.subscription' }),
      icon: <Tag />,
    },
    pending: {
      title: 'Pending',
      icon: <ProcessingOutlined />,
    },
    fallback: {
      title: 'Other',
      icon: <Tag />,
    },
  };

  return categoryMap;
};

const useCategoriesCollection = (): CategoriesCollection => {
  const categoryMap = useCategoryMap();
  return Object.entries(categoryMap).map(([id, value]) => ({ id, ...value }));
};

export const useCategoryAttrs = (category?: string | null): { title: string; icon: ReactNode } => {
  const categoryMap = useCategoryMap();

  if (!category) {
    return {
      title: 'Add category',
      icon: <PlusSign />,
    };
  }

  const attrs = categoryMap[category.toLowerCase()];

  if (attrs) return attrs;

  return {
    title: transformToNormalCasing(category),
    icon: <Tag />,
  };
};

function transformToNormalCasing(title: string): string {
  return title
    .split('_')
    .map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
    .join(' ');
}

export function useCategoryFilter(): {
  filteredList: CategoriesCollection;
  filter: (value: string) => void;
  resetFilter: () => void;
} {
  const list = useCategoriesCollection();
  const [filteredList, setFilteredList] = useState(list);

  const filter = (value: string): void => {
    if (value === '') {
      resetFilter();
    } else {
      setFilteredList(list.filter(c => c.title.toLowerCase().includes(value.toLowerCase())));
    }
  };

  const resetFilter = (): void => {
    setFilteredList(list);
  };

  return {
    filteredList,
    filter,
    resetFilter,
  };
}
