import { type ReactNode } from 'react';

export function Shield(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="m5.634 8.021 1.379 1.812 3.503-3.466m2.938-3.9a4.844 4.844 0 0 0-.669-.05 5.678 5.678 0 0 1-3.347-1.012l-1.14-.81a.514.514 0 0 0-.596 0l-1.115.784c-.967.684-2.132 1.063-3.298 1.038-.272 0-.545 0-.744.05a.727.727 0 0 0-.545.71l.347 5.845a5.24 5.24 0 0 0 2.108 3.947L8 15.5l3.545-2.53c1.265-.912 2.034-2.38 2.108-3.948L14 3.176c0-.354-.223-.658-.546-.708Z"
      />
    </svg>
  );
}
