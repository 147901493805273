import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function ChevronBottomOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" {...props}>
      <path
        fill="currentColor"
        d="M8 11.49a.47.47 0 0 1-.35-.15L1.66 5.35c-.2-.19-.2-.51 0-.7.2-.19.51-.2.7 0l5.63 5.63 5.64-5.63c.2-.2.51-.2.71 0 .2.2.2.51 0 .71l-5.99 5.99c-.1.1-.23.15-.35.15v-.01Z"
      />
    </svg>
  );
}
