import {
  useState,
  type Dispatch,
  type ReactElement,
  type ReactNode,
  type SetStateAction,
  type UIEventHandler,
} from 'react';
import { Button } from 'react-aria-components';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Popover, PopoverHeader, PopoverSection } from 'qonto/react/components/table-v2/popover';
import { PlusComment } from 'qonto/react/assets/icons';
import { ParticipantsList } from 'qonto/react/components/participants-list';
import type { Transaction, Message, Participant } from 'qonto/react/graphql';
import { CommentsCellRoot } from '../comments-cell-root';
import { CommentsList } from '../comments-list';
import { CommentCreateForm } from '../comment-create-form';
import styles from './styles.strict-module.css';

interface CommentsCellPopoverProps {
  count: number;
  read: boolean;
  messages: Message[] | undefined;
  participants: Participant[] | undefined;
  currentUserIsParticipant: boolean;
  toggleReadStatus: () => void;
  userId: string;
  transaction: Transaction;
}

export function CommentsCellPopoverHeader({
  count,
  read,
  currentUserIsParticipant,
  toggleReadStatus,
  participants = [],
}: CommentsCellPopoverProps): ReactNode {
  const segment = useEmberService('segment');
  const { formatMessage } = useIntl();

  if (count === 0) {
    return (
      <CommentsCellRoot
        iconSlot={<PlusComment aria-hidden data-testid="icon-plus-comment" />}
        textSlot={formatMessage({ id: 'transactions.modular_table.comments.add_comment.label' })}
      />
    );
  }

  if (currentUserIsParticipant && participants.length > 1) {
    return (
      <Button
        className={styles['toggle-button']}
        data-testid="toggle-read-button"
        onPress={() => {
          segment.track('tables_comment-cell-mark-read-unread_clicked');
          toggleReadStatus();
        }}
      >
        <CommentsCellRoot
          showNotificationDot={read ? count > 0 : false}
          textSlot={
            read
              ? formatMessage({ id: 'transactions.modular_table.comments.mark_as_unread.cta' })
              : formatMessage({ id: 'transactions.modular_table.comments.mark_as_read.cta' })
          }
        />
      </Button>
    );
  }

  return <CommentsCellRoot textSlot={count} />;
}

interface CommentsListSectionProps {
  messages: Message[];
  setShouldShowDropShadow: Dispatch<SetStateAction<boolean>>;
}

function CommentsListSection({
  messages,
  setShouldShowDropShadow,
}: CommentsListSectionProps): ReactNode {
  const handleScroll: UIEventHandler<HTMLElement> = (event): void => {
    const target = event.target as HTMLElement | null;
    if (target) {
      setShouldShowDropShadow(target.scrollTop > 0);
    }
  };

  return (
    <PopoverSection className={styles['comments-container']} onScroll={handleScroll}>
      <CommentsList messages={messages} />
    </PopoverSection>
  );
}

export function CommentsCellPopover(props: CommentsCellPopoverProps): ReactNode {
  const { participants = [], messages = [], transaction, currentUserIsParticipant, userId } = props;
  const [shouldShowDropShadow, setShouldShowDropShadow] = useState(false);

  const renderParticipantsList = (): ReactElement | null => {
    const isSingleParticipant = participants.length === 1 && currentUserIsParticipant;
    const hasNoInitiator = messages.length === 0 && !transaction.initiator;
    const isInitiatorCurrentUser = messages.length === 0 && transaction.initiator?.id === userId;

    if (isSingleParticipant || hasNoInitiator || isInitiatorCurrentUser) {
      return null;
    }

    return (
      <PopoverSection
        className={cx(
          styles['participants-container'],
          shouldShowDropShadow && styles['drop-shadow']
        )}
      >
        <ParticipantsList participants={participants} />
      </PopoverSection>
    );
  };

  const renderCommentsList = (): ReactElement | null => {
    if (messages.length === 0) {
      return null;
    }

    return (
      <CommentsListSection messages={messages} setShouldShowDropShadow={setShouldShowDropShadow} />
    );
  };

  return (
    <Popover width={380}>
      <PopoverHeader>
        <CommentsCellPopoverHeader {...props} />
      </PopoverHeader>
      {renderParticipantsList()}
      {renderCommentsList()}
      <PopoverSection>
        <CommentCreateForm transaction={transaction} />
      </PopoverSection>
    </Popover>
  );
}
