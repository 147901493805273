import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from '@repo/design-system-kit';
import { Info } from 'qonto/react/assets/icons/info';
import styles from './payable-amount-disclaimer.strict-module.css';

export interface PayableAmountDisclaimerProps {
  totalAmount: string;
  creditNotesAmount: string;
  payableAmount: string;
}

function TooltipContent({
  creditNotesAmount,
  payableAmount,
  totalAmount,
}: PayableAmountDisclaimerProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <>
      <div className={styles.content}>
        <p>
          {formatMessage({ id: 'supplier-invoices.edit.modal.credit-note.tooltip.invoice-amount' })}
        </p>
        <p>{totalAmount}</p>
      </div>
      <div className={styles.content}>
        <p>
          {formatMessage({ id: 'supplier-invoices.edit.modal.credit-note.tooltip.credit-note' })}
        </p>
        <p>{creditNotesAmount}</p>
      </div>
      <div className={styles.content}>
        <p>
          {formatMessage({ id: 'supplier-invoices.edit.modal.credit-note.tooltip.payable-amount' })}
        </p>
        <p>{payableAmount}</p>
      </div>
    </>
  );
}

export function PayableAmountDisclaimer({
  creditNotesAmount,
  payableAmount,
  totalAmount,
}: PayableAmountDisclaimerProps): ReactNode {
  return (
    <Tooltip
      className={styles.tooltip}
      closeDelay={0}
      delay={0}
      label={
        <TooltipContent
          creditNotesAmount={creditNotesAmount}
          payableAmount={payableAmount}
          totalAmount={totalAmount}
        />
      }
      needsButton
      placement="top"
    >
      <Info className={styles.icon} />
    </Tooltip>
  );
}
