import type { ComponentPropsWithRef, ReactNode } from 'react';

export function CrossOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="M13.771 13.075c.2.19.2.51 0 .7-.1.1-.22.15-.35.15-.13 0-.26-.05-.36-.15l-5.07-5.07-5.07 5.07c-.1.1-.22.15-.35.15a.501.501 0 0 1-.36-.85l5.08-5.08-5.08-5.07a.501.501 0 0 1 0-.7c.2-.2.52-.2.71 0l5.08 5.08 5.07-5.08c.2-.2.52-.2.71 0 .2.19.2.51 0 .7l-5.08 5.08 5.08 5.08-.01-.01Z" />
    </svg>
  );
}
