import { type ReactElement } from 'react';
import cx from 'clsx';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

export function MandateListLoading(): ReactElement {
  return (
    <div className={styles.bodyLoading} data-test-id="mandate-list-loading">
      <div className={cx(styles.bodyContent, styles.bodyMembers)}>
        {Array.from({ length: 8 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key -- static array
          <div className={styles.mandateListItem} key={index}>
            <div className={cx(styles.placeholder, 'middle')}>
              <SkeletonLoader.Avatar className={styles.icon} height="32px" width="32px" />
              <SkeletonLoader.Header lines={1} smallLinesWidth="232px" width="198px" />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.bodyDetails}>
        <div className={styles.bodyContent}>
          <div className={styles.bodyDetailsTitle}>
            <SkeletonLoader.Avatar className={styles.icon} height="48px" width="48px" />
            <SkeletonLoader.Header
              className={styles.bodyDetailsHeader}
              lines={1}
              smallLinesWidth="472px"
              width="512px"
            />
          </div>

          <SkeletonLoader.Line className={styles.bodyDetailsIdsSubtitle} width="224px" />

          <SkeletonLoader.Header lines={2} smallLinesWidth="496px" width="576px" />

          <div className={styles.bodyDetailsOverviewSubtitle}>
            <SkeletonLoader.Line width="224px" />
          </div>

          <SkeletonLoader.Line className={styles.bodyDetailsOverviewContent} width="360px" />

          <div className={styles.bodyDetailsCtas}>
            <div className={styles.bodyDetailsCtasLeft}>
              <SkeletonLoader.Line standalone width="144px" />
            </div>
            <div className={styles.bodyDetailsCtasRight}>
              <SkeletonLoader.Line standalone width="72px" />
              <SkeletonLoader.Line className={styles.rightEdit} standalone width="144px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
