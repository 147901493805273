import React, { type ReactNode } from 'react';
import { Tooltip, Button } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

interface CategorizationSubtitleWidgetProps {
  categorizationSubtitle: string;
  uniqueCounterparties: string[];
  remainingCounterpartiesLength: number;
  remainingCounterpartiesString: string;
  bulkCounterpartyPlaceholder: string;
}

export function CategorizationSubtitleWidget({
  categorizationSubtitle,
  uniqueCounterparties,
  remainingCounterpartiesLength,
  remainingCounterpartiesString,
  bulkCounterpartyPlaceholder,
}: CategorizationSubtitleWidgetProps): ReactNode {
  return (
    <>
      {uniqueCounterparties.length > 1 ? (
        <p className="mb-16 body-2" data-testid="bulk-counterpartynames">
          {categorizationSubtitle}
          {remainingCounterpartiesLength > 0 ? (
            <>
              {' '}
              <Tooltip
                className={styles.tooltip}
                data-testid="tooltip-content"
                label={remainingCounterpartiesString}
                placement="top"
              >
                <Button data-testid="remaining-counterpartynames" variant="tertiary">
                  {bulkCounterpartyPlaceholder}
                </Button>
              </Tooltip>
            </>
          ) : (
            <span>.</span>
          )}
        </p>
      ) : (
        <p className="mb-16 body-2" data-testid="single-counterpartyname">
          {categorizationSubtitle}
        </p>
      )}
    </>
  );
}
