import * as React from 'react';
import { useIntl } from 'react-intl';
import {
  RadioButtonBoolean,
  RadioGroupBoolean,
  type RadioGroupBooleanProps,
} from '@repo/design-system-kit';

export interface WithUsTaxPayer {
  usTaxPayer: boolean;
}

export interface UsTaxPayerRadioGroupProps
  extends Omit<RadioGroupBooleanProps, 'onChange' | 'value' | 'label' | 'labelClassName' | 'name'> {
  entity: WithUsTaxPayer;
}

export function UsTaxPayerRadioGroup({
  entity,
  ...props
}: UsTaxPayerRadioGroupProps): React.ReactElement {
  const { formatMessage } = useIntl();

  return (
    <RadioGroupBoolean
      label={formatMessage({ id: 'organizations.profile.owner-form.tax-payer-us' })}
      labelClassName="title-4"
      name="usTaxPayer"
      value={entity.usTaxPayer}
      onChange={value => {
        entity.usTaxPayer = value;
      }}
      {...props}
    >
      <RadioButtonBoolean data-test-tax-info-us-tax-payer-false value={false}>
        {formatMessage({ id: 'organizations.profile.company_profile.no' })}
      </RadioButtonBoolean>
      <RadioButtonBoolean data-test-tax-info-us-tax-payer-true value={true}>
        {formatMessage({ id: 'organizations.profile.company_profile.yes' })}
      </RadioButtonBoolean>
    </RadioGroupBoolean>
  );
}
