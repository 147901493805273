import { type ReactNode, type SVGProps } from 'react';

export function Stolen(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.0224 33.4681L29.0207 33.4691C26.6499 34.8521 23.4822 35.6183 20.0926 35.6183C16.705 35.6183 13.5361 34.8344 11.163 33.4683C8.67716 32.027 7.4 30.1558 7.4 28.222C7.4 26.268 8.67929 24.3788 11.1631 22.9387C13.5348 21.5734 16.7217 20.8072 20.0926 20.8072C23.4625 20.8072 26.6314 21.573 29.0226 22.9391C31.5041 24.3779 32.7667 26.2664 32.7667 28.222C32.7667 30.1755 31.4881 32.0463 29.0224 33.4681Z"
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeWidth="0.8"
      />
      <path
        d="M32.1741 28.1853L32.1741 28.1893C32.1907 29.8433 31.1 31.5647 28.7259 32.95C26.3564 34.3049 23.2306 34.9937 20.0834 34.9937C16.9366 34.9937 13.8113 34.3051 11.4418 32.9506C9.06661 31.5464 7.9741 29.8233 7.9741 28.1853V24.3446H32.1741L32.1741 28.1853Z"
        fill="white"
        stroke="#1D1D1B"
        strokeWidth="0.8"
      />
      <path
        d="M11.4413 28.728L11.4403 28.7275C9.06837 27.3618 7.9741 25.6302 7.9741 23.9792C7.9741 22.3281 9.06851 20.592 11.4404 19.2171C13.81 17.862 16.9359 17.1732 20.0834 17.1732C23.2307 17.1732 26.3565 17.862 28.7261 19.2169C31.0881 20.5914 32.1787 22.3274 32.1787 23.9792C32.1787 25.6309 31.0882 27.3625 28.7258 28.7278L28.7254 28.728C26.3561 30.1012 23.2304 30.7991 20.0834 30.7991C16.9363 30.7991 13.8107 30.1012 11.4413 28.728Z"
        fill="white"
        stroke="#1D1D1B"
        strokeWidth="0.8"
      />
      <path
        d="M13.6641 27.4138L13.6642 27.4137L13.6567 27.4096C11.9084 26.4383 11.1402 25.1457 11.1402 23.9444V12.2593C11.1402 7.36802 15.0918 3.4 19.981 3.4H20.1662C25.0553 3.4 29.0069 7.36802 29.0069 12.2593V23.9444C29.0069 25.1414 28.2066 26.436 26.4704 27.4104L26.4704 27.4104L26.4637 27.4143C24.7249 28.4345 22.4104 28.9565 20.0713 28.9565C17.7323 28.9565 15.4127 28.4345 13.6641 27.4138Z"
        fill="#D5C8FB"
        stroke="#1D1D1B"
        strokeWidth="0.8"
      />
      <rect
        x="0.34641"
        y="0.2"
        width="0.8"
        height="7.32717"
        transform="matrix(0.866025 -0.5 0 1 19.2456 21.3732)"
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeWidth="0.8"
      />
      <circle cx="20" cy="18" r="3.6" fill="#FFEADD" stroke="#1D1D1B" strokeWidth="0.8" />
      <path
        d="M19.7527 14.4084C20.8081 16.6861 20.8081 19.3139 19.7527 21.5916C17.8798 21.4646 16.4 19.9051 16.4 18C16.4 16.0949 17.8798 14.5354 19.7527 14.4084Z"
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeWidth="0.8"
      />
      <path
        d="M17.4534 7.17092C16.3608 6.67092 14.9719 7.30055 14.3238 8.63388C13.6756 9.93018 14.0275 11.4117 15.1386 11.9672C16.2312 12.5042 17.6386 11.8746 18.2867 10.5228C18.9349 9.22647 18.5645 7.72647 17.4719 7.17092H17.4534Z"
        fill="white"
      />
      <path d="M2 17H8M38 17H32" stroke="#FA7272" />
      <path d="M4.41211 26L9.60826 23M35.589 8L30.3929 11" stroke="#FA7272" />
      <path d="M4.41211 8L9.60826 11M35.589 26L30.3929 23" stroke="#FA7272" />
    </svg>
  );
}
