import { type ReactNode, type SVGProps } from 'react';

export function IconGas(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M4.592 1.931a.5.5 0 1 0 0 1H7.08a.5.5 0 0 0 0-1H4.592ZM5.37 6.463a.5.5 0 1 0-.712.702l2.704 2.74-2.71 2.733a.5.5 0 0 0 .71.705l2.703-2.725 2.715 2.752a.5.5 0 0 0 .712-.703l-2.723-2.76 2.601-2.623a.5.5 0 0 0-.71-.704L8.066 9.196 5.37 6.463Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.552-.003H3.105c-.423.001-.83.167-1.132.464A1.585 1.585 0 0 0 1.5 1.586V14.41c0 .424.172.828.473 1.125.302.297.71.463 1.132.463h9.79c.424 0 .83-.166 1.132-.463.301-.297.472-.702.473-1.125V4.974a.745.745 0 0 0-.484-.697l-.194-.074.408-1.223a.633.633 0 0 0-.379-.794l-2.238-.832a.63.63 0 0 0-.814.383l-.416 1.158-.226-.086V1.585c0-.423-.172-.827-.473-1.124a1.617 1.617 0 0 0-1.132-.464Zm1.023 3.66a.65.65 0 0 1-.418-.608V1.587a.583.583 0 0 0-.175-.414.615.615 0 0 0-.43-.176H3.105a.615.615 0 0 0-.43.176.583.583 0 0 0-.176.414v12.821c0 .154.062.303.175.414a.615.615 0 0 0 .43.175h9.79a.616.616 0 0 0 .43-.175.583.583 0 0 0 .175-.414V5.151L9.575 3.657Zm3.312.19-1.57-.597.298-.828 1.555.577-.283.848Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
