import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function StopOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 16 16"
      height={16}
      width={16}
      {...props}
    >
      <g clipPath="url(#stop_outlined_path)">
        <path d="M13.99 2.71a3.8 3.8 0 0 0-.33-.37 3.8 3.8 0 0 0-.37-.33A7.945 7.945 0 0 0 8 0C3.58 0 0 3.58 0 8c0 2.03.76 3.88 2.01 5.29.1.13.21.25.33.37l.37.33A7.945 7.945 0 0 0 8 16c4.42 0 8-3.58 8-8 0-2.03-.76-3.88-2.01-5.29zM1 8c0-3.86 3.14-7 7-7 1.68 0 3.31.6 4.58 1.72l-9.86 9.86A6.913 6.913 0 0 1 1 8zm7 7c-1.68 0-3.31-.6-4.58-1.72l9.86-9.86A6.913 6.913 0 0 1 15 8c0 3.86-3.14 7-7 7z" />
      </g>
      <defs>
        <clipPath id="stop_outlined_path">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
