import { type ReactNode, type SVGProps } from 'react';

export function IconLegal(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.543 1.751A.49.49 0 0 1 2.97 1.5h10.018c.119 0 .228.043.313.115a.69.69 0 0 1 .277.353l1.427 3.95H16l-.147.618a3.016 3.016 0 0 1-2.926 2.327 3.015 3.015 0 0 1-2.926-2.328l-.146-.617h.993L12.084 2.5H8.485v11h2.503c.271 0 .491.224.491.5s-.22.5-.491.5H5.236a.496.496 0 0 1-.492-.5c0-.276.22-.5.492-.5h2.266v-11H3.915L5.15 5.918h.995l-.147.618a3.015 3.015 0 0 1-2.926 2.327A3.015 3.015 0 0 1 .147 6.536L0 5.918h.994l1.427-3.95a.72.72 0 0 1 .122-.217Zm.529 1.315-1.03 2.852h2.06l-1.03-2.852Zm1.71 3.852h-3.42a2.02 2.02 0 0 0 3.42 0Zm8.145-3.852-1.03 2.852h2.06l-1.03-2.852Zm1.71 3.852h-3.42a2.02 2.02 0 0 0 3.42 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
