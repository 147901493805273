import type { Label, LabelList } from 'qonto/react/models/label';
import type {
  LabelCashflow,
  LabelListCashflow,
  IntervalLabelListCashflow,
  IntervalUnlabeledCashflow,
} from '../api/labels';
import type { LabelTableInterval, LabelTableRow } from '../models/labels-cashflow-display';
import type { AggregateAmounts } from '../api/statistics';

const UNLABELED_COLOR = '#DCDCD9';

interface LabelListCashflowWithInterval {
  labelListCashflow: LabelListCashflow | undefined;
  interval: LabelTableInterval;
}

const generateLabelTableRows = (
  labels: Label[],
  labelListCashflows: LabelListCashflowWithInterval[],
  offset: number,
  displayedMonths: number,
  color: string
): LabelTableRow[] => {
  return labels.map(label => {
    const labelCashflows = labelListCashflows.map(monthCashflow => {
      const { interval } = monthCashflow;
      if (!monthCashflow.labelListCashflow) return { interval };

      const labelCashflow = monthCashflow.labelListCashflow.data.labels_group.find(
        labelCashflowItem => labelCashflowItem.key === label.id
      );

      return {
        labelCashflow,
        interval,
      };
    });

    const labelColumns = labelCashflows.map(labelCashflow => {
      return {
        interval: labelCashflow.interval,
        amount: (Number(labelCashflow.labelCashflow?.data.amount_sum) || 0).toFixed(2),
      };
    });

    const labelPeriodSum = labelColumns.reduce((sum, column) => {
      return sum + Number(column.amount);
    }, 0);

    return {
      id: label.id,
      attribute: label.name,
      type: 'label',
      color,
      periodSum: labelPeriodSum,
      columns: labelColumns.slice(offset, offset + displayedMonths),
    } as LabelTableRow;
  });
};

const generateLabelListTableRows = (
  labelLists: LabelList[],
  periodLabelListCashflows: IntervalLabelListCashflow[],
  offset: number,
  displayedMonths: number
): LabelTableRow[] => {
  return labelLists.map(list => {
    const labelListCashflows = periodLabelListCashflows.map(
      (monthCashflow: IntervalLabelListCashflow) => {
        const labelListCashflow = monthCashflow.data.label_lists_group.find(
          labelList => labelList.key === list.id
        );
        return {
          labelListCashflow,
          interval: {
            start: monthCashflow.start_date,
            end: monthCashflow.end_date,
          },
        };
      }
    );

    const labelListColumns = labelListCashflows.map(labelListCashflow => {
      const { interval } = labelListCashflow;
      if (!labelListCashflow.labelListCashflow)
        return {
          interval,
          amount: Number(0).toFixed(2),
        };

      const labelCashflows: LabelCashflow[] = labelListCashflow.labelListCashflow.data.labels_group;
      const labelListSum: number = labelCashflows.reduce(
        (sum: number, labelCashflow: LabelCashflow) => {
          const labelData: AggregateAmounts = labelCashflow.data;
          return sum + Number(labelData.amount_sum);
        },
        0
      );

      return {
        interval,
        amount: labelListSum.toFixed(2),
      };
    });

    const labelListPeriodSum = labelListColumns.reduce((sum, column) => {
      return sum + Number(column.amount);
    }, 0);

    const labelRows = generateLabelTableRows(
      list.labels,
      labelListCashflows,
      offset,
      displayedMonths,
      list.color
    );

    const isListPeriodSumPositive = labelListPeriodSum >= 0;
    const absoluteSortedLabelRows = labelRows.sort((a, b) => {
      const absoluteResult = Math.abs(b.periodSum ?? 0) - Math.abs(a.periodSum ?? 0);
      return absoluteResult === 0 ? a.attribute.localeCompare(b.attribute) : absoluteResult;
    });
    const positiveLabelRows = absoluteSortedLabelRows.filter(row => (row.periodSum ?? 0) >= 0);
    const negativeLabelRows = absoluteSortedLabelRows.filter(row => (row.periodSum ?? 0) < 0);

    const sortedLabelRows = isListPeriodSumPositive
      ? [...positiveLabelRows, ...negativeLabelRows]
      : [...negativeLabelRows, ...positiveLabelRows];

    return {
      id: list.id,
      attribute: list.name,
      type: 'labelsList',
      color: list.color,
      periodSum: labelListPeriodSum,
      columns: labelListColumns.slice(offset, offset + displayedMonths),
      subRows: sortedLabelRows,
    } as LabelTableRow;
  });
};

export const generateTableData = (
  labelLists: LabelList[],
  labelsCashflows: IntervalLabelListCashflow[],
  unlabeledCashflows: IntervalUnlabeledCashflow[],
  offset: number,
  displayedMonths: number
): LabelTableRow[] => {
  const labelListTableRows = generateLabelListTableRows(
    labelLists,
    labelsCashflows,
    offset,
    displayedMonths
  );

  const sortedLabelTableRows = labelListTableRows.sort((a, b) => {
    const absoluteResult = Math.abs(b.periodSum ?? 0) - Math.abs(a.periodSum ?? 0);
    return absoluteResult === 0 ? a.attribute.localeCompare(b.attribute) : absoluteResult;
  });

  const unlabeledColumns = unlabeledCashflows.map(unlabeledCashflow => {
    return {
      amount: unlabeledCashflow.data.amount_sum,
      interval: {
        start: unlabeledCashflow.start_date,
        end: unlabeledCashflow.end_date,
      },
    };
  });

  const unlabeledTableRow: LabelTableRow = {
    id: 'unlabeled',
    attribute: 'Unlabeled',
    type: 'labelsList',
    color: UNLABELED_COLOR,
    columns: unlabeledColumns.slice(offset, offset + displayedMonths),
  };

  return [...sortedLabelTableRows, unlabeledTableRow];
};
