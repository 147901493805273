import { type ReactNode, type SVGProps } from 'react';

export function IconLock(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 1a3 3 0 0 0-3 3v2h6V4a3 3 0 0 0-3-3Zm4 5V4a4 4 0 0 0-8 0v2H3a1.5 1.5 0 0 0-1.5 1.5v7A1.5 1.5 0 0 0 3 16h10a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 13 6h-1ZM3 7a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-7A.5.5 0 0 0 13 7H3Zm5 3a.5.5 0 0 1 .5.5v2.25a.5.5 0 0 1-1 0V10.5A.5.5 0 0 1 8 10Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
