import { useEffect, type ReactNode } from 'react';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import { clsx } from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedMessage } from 'react-intl';
import styles from './styles.strict-module.css';

interface SuccessScreenProps {
  onCtaClick: () => void;
}

export function SuccessScreen({ onCtaClick }: SuccessScreenProps): ReactNode {
  const segment = useEmberService('segment');

  useEffect(() => {
    segment.track('nrc_success');
  }, [segment]);

  return (
    <div className={styles.wrapper}>
      <LottiePlayer
        className={clsx(styles.illustration, 'mb-8')}
        data-test-nrc-payments-success-illustration
        path="/lotties/success.json"
      />

      <h1 className={clsx(styles.title, 'mb-8', 'title-2')} data-test-nrc-payments-success-title>
        <FormattedMessage id="aeat.pay.review.success.title" />
      </h1>

      <p className={clsx(styles.subtitle, 'body-2 mb-32')} data-test-nrc-payments-success-subtitle>
        <FormattedMessage
          id="aeat.pay.review.success.subtitle"
          values={{
            italic: (
              <i>
                <FormattedMessage id="aeat.pay.review.italic" />
              </i>
            ),
          }}
        />
      </p>

      <Button
        data-test-nrc-payments-success-cta
        onPress={onCtaClick}
        type="button"
        variant="primary"
      >
        <FormattedMessage id="aeat.pay.review.success.cta" />
      </Button>
    </div>
  );
}
