import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { Status } from 'qonto/react/graphql';
import { BaseCell } from '../base-cell';
import styles from './styles.strict-module.css';

interface DateCellProps {
  date?: string | null;
  isDeclined: boolean;
}

function DateCellRoot({ date, isDeclined }: DateCellProps): ReactNode {
  const { locale } = useIntl();

  const formattedDate = dateToken({
    date: date ?? '',
    locale,
    token: 'date-year-s',
  });

  return (
    <div data-testid="date-cell">
      <span className={isDeclined ? styles.declined : undefined}>{formattedDate || '-'}</span>
    </div>
  );
}

export function DateCell(): ReactNode {
  const transaction = cellContextManager.useCellContext();
  const { emittedAt: date, status } = transaction;
  const isDeclined = status === Status.Declined || status === Status.Reversed;

  return (
    <BaseCell>
      <DateCellRoot date={date} isDeclined={isDeclined} />
    </BaseCell>
  );
}
