import React, { type ReactNode } from 'react';
import { ListBoxItem } from 'react-aria-components';
import cx from 'clsx';
import { isNil } from 'es-toolkit';
import type { CashflowCategory } from 'qonto/react/models/cash-flow-category';
import { PERCENTAGE_SYMBOL } from 'qonto/react/components/cash-flow/components/categories-management/constants';
import styles from './styles.strict-module.css';
import { CategoryIcon } from './category-icon';

interface CategoryListBoxItemProps {
  item: CashflowCategory;
  showIcon?: boolean;
}

export function CategoryListBoxItem({
  item,
  showIcon = true,
}: CategoryListBoxItemProps): ReactNode {
  const { id, name: categoryName } = item;

  return (
    <ListBoxItem
      className={styles['category-list-item']}
      data-testid="category-list-item"
      id={id ?? ''}
      textValue={categoryName}
    >
      <Category item={item} showIcon={showIcon} />
    </ListBoxItem>
  );
}

interface CategoryProps extends CategoryListBoxItemProps {
  emphasized?: boolean;
  isAutoCategorized?: boolean;
  avatarSize?: number;
  showVat?: boolean;
}

export function Category({
  item,
  showIcon = true,
  showVat = false,
  emphasized = false,
  isAutoCategorized = false,
  avatarSize,
}: CategoryProps): ReactNode {
  const { name: categoryName, iconKey, colorKey, vatRate } = item;
  const vatValue = isNil(vatRate) || String(vatRate) === '0' ? '0' : vatRate;
  const stringifiedVatRate = vatValue ? `${String(vatValue)}${PERCENTAGE_SYMBOL}` : null;
  const icon = showIcon ? (iconKey ?? null) : null;

  return (
    <div className={cx(styles.category, emphasized ? 'body-1' : 'body-2')}>
      <CategoryIcon
        avatarSize={avatarSize}
        color={colorKey}
        icon={icon}
        isAutoCategorized={isAutoCategorized}
        isSelectedCategory={emphasized}
      />
      <span className={styles['category-name']} data-testid="category-name">
        {categoryName}
      </span>
      {stringifiedVatRate && showVat ? (
        <span className={styles['percent-badge']} data-testid="category-vat-rate">
          {stringifiedVatRate}
        </span>
      ) : null}
    </div>
  );
}
