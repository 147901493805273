import { type ReactNode } from 'react';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { Status } from 'qonto/react/graphql';
import { BaseCell } from '../base-cell';
import { CopyButton } from '../../buttons';

export function CaptionCell(): ReactNode {
  const transaction = cellContextManager.useCellContext();
  const { description: caption, status } = transaction;
  const isDeclined = status === Status.Declined || status === Status.Reversed;

  if (isDeclined || !caption) {
    return (
      <BaseCell>
        <span data-testid="empty">-</span>
      </BaseCell>
    );
  }

  return (
    <BaseCell actionSlot={<CopyButton text={caption} />} actionSlotAlignment="right">
      <span className="body-2" data-testid="caption-cell">
        {caption}
      </span>
    </BaseCell>
  );
}
