import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function CloseCircleFilled(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...props}
    >
      <path
        d="M6 0C2.685 0 0 2.685 0 6C0 9.315 2.685 12 6 12C9.315 12 12 9.315 12 6C12 2.685 9.315 0 6 0ZM8.16 7.605C8.31 7.755 8.31 8.0025 8.16 8.1525C8.085 8.2275 7.9875 8.265 7.89 8.265C7.7925 8.265 7.695 8.2275 7.62 8.1525L6.0075 6.54L4.3875 8.1525C4.3125 8.2275 4.215 8.265 4.1175 8.265C4.02 8.265 3.9225 8.2275 3.8475 8.1525C3.6975 8.0025 3.6975 7.755 3.8475 7.605L5.46 6L3.8475 4.395C3.6975 4.2525 3.6975 4.005 3.8475 3.855C3.9975 3.705 4.2375 3.705 4.3875 3.855L6.0075 5.46L7.62 3.855C7.77 3.705 8.01 3.705 8.16 3.855C8.31 4.005 8.31 4.2525 8.16 4.395L6.5475 6L8.16 7.605Z"
        fill="currentColor"
      />
    </svg>
  );
}
