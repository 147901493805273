import React, { useEffect, useId, useRef, type ComponentPropsWithRef, type ReactNode } from 'react';
import { type Uppy } from '@uppy/core';
import { useEventCallback } from 'usehooks-ts';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { PlusRoundedOutlined } from 'qonto/react/assets/icons';
import type { Body, Meta } from 'qonto/react/hooks/use-attachments-uploader';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { ATTACHMENT_POPOVER_CLICK_EVENT_NAME } from 'qonto/react/constants';
import { PopoverButton } from '../popover';
import styles from './styles.strict-module.css';

const DEFAULT_EXTENSIONS = '.pdf,.jpg,.jpeg,.png';

interface AttachmentUploaderProps extends ComponentPropsWithRef<'div'> {
  uppy: Uppy<Meta, Body>;
  isDisabled?: boolean;
  buttonLabel: string;
  className?: string;
}

export function AttachmentUploader({
  uppy,
  isDisabled = false,
  buttonLabel,
  ...props
}: AttachmentUploaderProps): ReactNode {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const inputIdSuffix = useId();
  const inputId = `attachment-uploader-${inputIdSuffix}`;
  const segment = useEmberService('segment');
  const { attachments } = cellContextManager.useCellContext();

  const handleFileInputChange = useEventCallback((event: Event) => {
    const target = event.target as HTMLInputElement;
    const files = Array.from(target.files ?? []);

    segment.track(ATTACHMENT_POPOVER_CLICK_EVENT_NAME, {
      attachments_action_type: attachments.length ? 'add_more_attachments' : 'add_attachment',
      table: 'transactions',
    });

    uppy.setState({ info: [] });
    uppy.clear();

    files.forEach(file => {
      try {
        uppy.addFile({
          source: 'attachment input',
          name: file.name,
          type: file.type,
          data: file,
        });
      } catch {
        // we show error messages in the UI when uploading fails
      }
    });
  });

  useEffect(() => {
    if (fileInputRef.current) {
      const fileInput = fileInputRef.current;
      fileInput.addEventListener('change', handleFileInputChange);

      return () => {
        fileInput.removeEventListener('change', handleFileInputChange);
      };
    }
  }, [handleFileInputChange]);

  return (
    <div data-testid="attachment-uploader" {...props}>
      <input
        accept={DEFAULT_EXTENSIONS}
        className={styles.hidden}
        disabled={isDisabled}
        id={inputId}
        multiple
        ref={fileInputRef}
        type="file"
      />
      <label htmlFor={inputId}>
        <PopoverButton
          aria-label={buttonLabel}
          data-testid="upload-file-button"
          isDisabled={isDisabled}
          onPress={() => {
            fileInputRef.current?.click();
          }}
          slots={{
            icon: <PlusRoundedOutlined data-testid="default-icon" />,
            text: buttonLabel,
          }}
        />
      </label>
    </div>
  );
}
