import React from 'react';
import { Button } from '@repo/design-system-kit';
import cx from 'clsx';
import { FormattedMessage } from 'react-intl';
import styles from './styles.strict-module.css';

interface AccountClosingBannerProps {
  onBookACall: () => void;
}

export function AccountClosingBanner({
  onBookACall,
}: AccountClosingBannerProps): React.JSX.Element {
  return (
    <div className={cx('mb-24', styles.wrapper)}>
      <h4 className="title-4" data-test-consequences-banner-title>
        <FormattedMessage id="subscription.account-closing.consequences.banner.title" />
      </h4>
      <p className={cx('body-2', styles.description)} data-test-consequences-banner-subtitle>
        <FormattedMessage id="subscription.account-closing.consequences.banner.subtitle" />
      </p>
      <Button data-test-consequences-banner-cta onPress={onBookACall} variant="tertiary">
        <FormattedMessage id="subscription.account-closing.consequences.banner.btn-bookcall" />
      </Button>
    </div>
  );
}
