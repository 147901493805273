import { useContext, type ReactNode } from 'react';
import type { Uppy } from '@uppy/core';
import { useIntl } from 'react-intl';
import useUppyState from '@uppy/react/lib/useUppyState';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { OverlayTriggerStateContext } from 'react-aria-components';
import window from 'ember-window-mock';
import { PopoverSection } from 'qonto/react/components/table-v2/popover';
import type { Attachment } from 'qonto/react/graphql';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { useUnlinkTransactionAttachment } from 'qonto/react/hooks/mutations/use-unlink-attachment';
import type { Body, Meta } from 'qonto/react/hooks/use-attachments-uploader';
import { useRefetchTransaction } from 'qonto/react/hooks/mutations/use-refetch-transaction';
import { ATTACHMENT_POPOVER_CLICK_EVENT_NAME } from 'qonto/react/constants';
import { routeContextManager } from 'qonto/react/contexts/route-context';
import { getDisplayFilename } from '../../utils';
import { AttachmentItemUploading } from '../attachment-item-uploading';
import { AttachmentItem } from '../attachment-item';
import styles from './styles.strict-module.css';

interface AttachmentListSectionProps {
  uppy: Uppy<Meta, Body>;
  setIsDeleteModalOpen: (isOpen: boolean) => void;
  setAttachmentToDelete: (attachmentId: string | null) => void;
  isUploading: boolean;
}

export function AttachmentListSection({
  uppy,
  setIsDeleteModalOpen,
  setAttachmentToDelete,
  isUploading,
}: AttachmentListSectionProps): ReactNode {
  const { formatMessage } = useIntl();
  const transaction = cellContextManager.useCellContext();
  const { id: transactionId, attachments } = transaction;
  const { mutate: refetchTransaction } = useRefetchTransaction(transactionId);
  const { mutate: deleteAttachment } = useUnlinkTransactionAttachment(transactionId);
  const uploadingFiles = useUppyState(uppy, state => state.files);
  const segment = useEmberService('segment');
  const { openModal } = routeContextManager.useRouteContext();
  const triggerState = useContext(OverlayTriggerStateContext);
  const abilities = useEmberService('abilities');

  const handleDownload = (attachment: Attachment): void => {
    segment.track(ATTACHMENT_POPOVER_CLICK_EVENT_NAME, {
      attachments_action_type: 'download_attachment',
      table: 'transactions',
    });
    window.open(attachment.downloadUrl, '_blank');
  };

  const handleCancelUpload = (fileId: string): void => {
    segment.track(ATTACHMENT_POPOVER_CLICK_EVENT_NAME, {
      attachments_action_type: 'cancel_upload',
      table: 'transactions',
    });
    uppy.removeFile(fileId);
  };

  const handleConfirmDeleteModal = (attachmentId: string): void => {
    segment.track(ATTACHMENT_POPOVER_CLICK_EVENT_NAME, {
      attachments_action_type: 'delete_attachment',
      table: 'transactions',
    });
    setAttachmentToDelete(attachmentId);
    setIsDeleteModalOpen(true);
  };

  const handleOpen = (attachmentId: string): void => {
    segment.track(ATTACHMENT_POPOVER_CLICK_EVENT_NAME, {
      attachments_action_type: 'view_attachment',
      table: 'transactions',
    });
    triggerState.close();
    openModal('attachments/attachment-viewer-modal-wrapper', {
      isFullScreenModal: true,
      hasSidebar: true,
      title: formatMessage({
        id: 'transactions.sidebar.bookkeeping.bookkeeping-details',
      }),
      transactionId,
      onClose: () => {
        refetchTransaction();
      },
      onRemove: () => {
        deleteAttachment(attachmentId);
      },
      markAsReviewTask: () => {
        refetchTransaction();
      },
    });
  };

  return attachments.length || Object.values(uploadingFiles).length ? (
    <PopoverSection className={styles.section}>
      <div className={styles.container}>
        {isUploading
          ? Object.values(uploadingFiles).map(({ id, name, progress }) => (
              <AttachmentItemUploading
                fileName={getDisplayFilename(name)}
                key={id}
                onCancel={() => {
                  handleCancelUpload(id);
                }}
                progress={progress.percentage ?? 0}
              />
            ))
          : null}
        {attachments.map(attachment => {
          const { id, file, probativeAttachment } = attachment;
          return (
            <AttachmentItem
              canProbate={abilities.can('probate attachment')}
              fileName={getDisplayFilename(file?.name)}
              fileStatus={probativeAttachment?.status}
              key={id}
              onDelete={() => {
                handleConfirmDeleteModal(id);
              }}
              onDownload={() => {
                handleDownload(attachment);
              }}
              onFilePreview={() => {
                handleOpen(id);
              }}
            />
          );
        })}
      </div>
    </PopoverSection>
  ) : null;
}
