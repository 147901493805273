import { type ReactNode, type SVGProps } from 'react';

export function IconMileage(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m4.881 1.703.627 1.024-.895.504-.622-1.017a6.813 6.813 0 0 0-1.815 1.8l1.003.547-.513.866-.992-.54a6.46 6.46 0 0 0-.622 2.361H2.14v.996H1.064c.072.81.298 1.6.665 2.331l.937-.51.513.866-1.684.917-.057-.096-.152.094a7.458 7.458 0 0 1-1.28-3.88c-.055-1.381.28-2.75.969-3.963a7.828 7.828 0 0 1 2.946-2.93A8.26 8.26 0 0 1 8.014 0a8.258 8.258 0 0 1 4.09 1.087 7.825 7.825 0 0 1 2.935 2.94 7.42 7.42 0 0 1 .954 3.966 7.46 7.46 0 0 1-1.295 3.877l-.177-.111-.052.089-1.685-.916.512-.866.966.525c.372-.735.601-1.53.674-2.347h-1.113v-.996h1.125a6.46 6.46 0 0 0-.63-2.377l-1.021.556-.513-.866 1.03-.56a6.816 6.816 0 0 0-1.83-1.804l-.634 1.034-.895-.504.635-1.037a7.193 7.193 0 0 0-2.59-.677l.001 1.228H7.463V1.016c-.899.066-1.775.3-2.582.687ZM11.82 3.78c.21.187.221.502.026.703L9.598 6.801c.15.246.235.533.235.838 0 .921-.779 1.668-1.739 1.668-.274 0-.533-.06-.763-.169l-.402.414a.535.535 0 0 1-.733.025.484.484 0 0 1-.027-.703l.407-.42a1.608 1.608 0 0 1-.22-.815c0-.921.778-1.668 1.738-1.668.264 0 .514.057.738.157l2.254-2.324a.535.535 0 0 1 .734-.025ZM8.094 6.967c-.387 0-.7.301-.7.672 0 .371.313.672.7.672.387 0 .7-.3.7-.672 0-.371-.313-.672-.7-.672ZM4.308 13.93c0-.71.6-1.284 1.339-1.284h4.917c.74 0 1.339.575 1.339 1.284v.786c0 .71-.6 1.284-1.339 1.284H5.647c-.74 0-1.339-.575-1.339-1.284v-.786Zm1.339-.288c-.166 0-.3.129-.3.288v.786c0 .16.134.288.3.288h4.917c.166 0 .3-.129.3-.288v-.786a.295.295 0 0 0-.3-.288H5.647Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
