import { type ReactNode } from 'react';

export function Calculator(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        d="M2.5 1.152c0-.36.273-.652.61-.652h9.78c.337 0 .61.292.61.652v13.696c0 .36-.273.652-.61.652H3.11c-.337 0-.61-.292-.61-.652V1.152Z"
      />
      <path
        stroke="currentColor"
        d="M4.715 3.22c0-.152.114-.274.256-.274h6.099c.14 0 .255.122.255.273v2.367c0 .151-.114.274-.255.274h-6.1c-.14 0-.255-.123-.255-.274V3.22Z"
      />
      <path
        fill="currentColor"
        d="M5.736 8.955c0 .362-.274.655-.612.655-.338 0-.612-.293-.612-.655 0-.361.274-.654.612-.654.338 0 .612.293.612.654ZM8.608 8.955c0 .362-.274.655-.612.655-.338 0-.611-.293-.611-.655 0-.361.273-.654.611-.654.338 0 .612.293.612.654ZM11.47 8.955c0 .362-.273.655-.611.655-.338 0-.612-.293-.612-.655 0-.361.274-.654.612-.654.338 0 .612.293.612.654ZM5.736 11.96c0 .362-.274.655-.612.655-.338 0-.612-.293-.612-.655 0-.361.274-.654.612-.654.338 0 .612.293.612.654ZM8.608 11.96c0 .362-.274.655-.612.655-.338 0-.611-.293-.611-.655 0-.361.273-.654.611-.654.338 0 .612.293.612.654ZM11.47 11.96c0 .362-.273.655-.611.655-.338 0-.612-.293-.612-.655 0-.361.274-.654.612-.654.338 0 .612.293.612.654Z"
      />
    </svg>
  );
}
