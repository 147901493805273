import { type ReactNode, type SVGProps } from 'react';

export function IconTag(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        stroke="currentColor"
        d="M9.26 14.985c.09.075.203.121.326.133.255.025.53-.1.715-.326l4.878-5.9c.286-.347.285-.825-.004-1.066L6.893.899a.573.573 0 0 0-.12-.078.14.14 0 0 0-.134-.044c-.162-.015-.916.16-2.25.487-.242.06-.432.106-.515.124l-2.761.613a.146.146 0 0 0-.114.138L.903 4.982c-.003.086-.014.283-.028.535C.78 7.23.775 7.682.811 7.825l-.002.001a.129.129 0 0 0 .058.11.56.56 0 0 0 .108.121l8.285 6.928Z"
      />
      <path
        fill="currentColor"
        d="M6.755 9.365a2.523 2.523 0 0 1-1.74-.45 2.525 2.525 0 0 1 3.336-.369c-.413.46-.982.752-1.596.82Zm2.213-2.76a2.484 2.484 0 0 1-.448 1.73 2.5 2.5 0 0 1-.378-3.32c.463.41.757.976.826 1.59Zm1.348 1.07a2.523 2.523 0 0 1 1.74.45 2.525 2.525 0 0 1-3.336.369c.413-.46.982-.751 1.596-.818Zm-2.213 2.761A2.505 2.505 0 0 1 8.556 8.7c.462.41.751.983.82 1.597a2.484 2.484 0 0 1-.447 1.73 2.499 2.499 0 0 1-.826-1.59Z"
      />
      <ellipse
        cx={1}
        cy={1.001}
        stroke="currentColor"
        rx={1}
        ry={1.001}
        transform="matrix(.99538 .09596 -.11116 .9938 3.584 3.836)"
      />
    </svg>
  );
}
