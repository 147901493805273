import React, { type ReactNode } from 'react';
import cx from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';

interface RecommendationCardProps {
  className?: string;
}

export function RecommendationCard({ className, ...rest }: RecommendationCardProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');
  const zendeskLocalization = useEmberService('zendesk-localization');

  const handleClick = (): void => {
    segment.track('recommendation_multi-account_clicked', {
      page: 'accounts',
    });
  };

  return (
    <li className={cx(styles['recommendation-card'], className)} {...rest}>
      <div className={styles.content}>
        <p className="title-4 mb-8" data-test-recommendation-card-title>
          <FormattedMessage id="accounts.recommendation-multi-accounts.title" />
        </p>
        <p
          className={cx('body-2', styles['recommendation-body'])}
          data-test-recommendation-card-description
        >
          <FormattedMessage id="accounts.recommendation-multi-accounts.text" />
        </p>
        <a
          className="btn btn--tertiary"
          data-test-recommendation-card-link
          href={formatMessage(
            { id: 'accounts.recommendation-multi-accounts.learn-more.url' },
            {
              faqUrl: zendeskLocalization.getLocalizedArticle(4560206),
            }
          )}
          onClick={handleClick}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage id="accounts.recommendation-multi-accounts.learn-more.text" />
        </a>
      </div>
      <StaticThemedAsset
        assetPath="/illustrations/accounts/recommendation-card.svg"
        className={styles['recommendation-img']}
        data-test-recommendation-card-img
      />
    </li>
  );
}
