import { SkeletonLoader } from '@repo/design-system-kit';
import type { ReactElement } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

export interface TablePlaceholderProps {
  isShrinked: boolean;
}

export function TablePlaceholder({ isShrinked }: TablePlaceholderProps): ReactElement {
  return (
    <>
      <td aria-hidden="true" />
      <td className={styles.cell}>
        <div className={styles.cellContent}>
          <SkeletonLoader.Block
            borderRadius="1px"
            className={styles.icon}
            data-test-card-table-placeholder-block=""
            height="20px"
            width="32px"
          />
          <SkeletonLoader.Header
            data-test-card-table-placeholder-header=""
            lines={1}
            smallLinesWidth="40%"
            width="60%"
          />
        </div>
      </td>
      <td className={styles.cell}>
        <SkeletonLoader.Line data-test-card-table-placeholder-line="" standalone width="50%" />
      </td>
      <td className={styles.cell}>
        <div className={styles.cellContent}>
          <SkeletonLoader.Line
            className={cx(isShrinked && styles.alignRight)}
            data-test-card-table-placeholder-line=""
            standalone
            width="50%"
          />
        </div>
      </td>
      <td className={cx(styles.cell, isShrinked && styles.hidden)}>
        <SkeletonLoader.Line
          className={styles.alignRight}
          data-test-card-table-placeholder-line=""
          standalone
          width="224px"
        />
      </td>
      <td aria-hidden="true" />
    </>
  );
}
