import { SkeletonLoader } from '@repo/design-system-kit';
import { type HTMLAttributes, type ReactNode } from 'react';
import styles from './styles.strict-module.css';

interface TableCellProps extends HTMLAttributes<HTMLDivElement> {
  isLoading: boolean;
  isLabel?: boolean;
  align?: 'left' | 'right' | 'center';
}
export function TableCell({
  isLoading,
  isLabel = false,
  align = 'center',
  children,
}: TableCellProps): ReactNode {
  return (
    <div className={styles.tableCell} style={{ justifyContent: align }}>
      {isLoading ? (
        <div
          className={styles.skeletonContainer}
          data-testid="table-cell-loading"
          style={{ justifyContent: align }}
        >
          {isLabel ? (
            <SkeletonLoader.Line borderRadius="50%" height="8px" standalone width="8px" />
          ) : null}
          <SkeletonLoader.Line height="12px" standalone width="60%" />
        </div>
      ) : (
        children
      )}
    </div>
  );
}
