import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function Comment(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.96 13.59c-.18-.05-.37-.04-.55.01l-2.85.9.44-1.72a.997.997 0 0 0-.27-.97C1.63 10.75 1 9.38 1 7.91c0-3.14 2.99-5.92 7-5.92s7 2.78 7 5.92-2.99 5.92-7 5.92c-.71 0-1.39-.09-2.04-.26v.02Zm-3.92-1.05C.77 11.32 0 9.7 0 7.92 0 4.1 3.58 1 8 1s8 3.1 8 6.92-3.58 6.92-8 6.92c-.79 0-1.56-.1-2.29-.29l-2.85.9a.996.996 0 0 1-1.27-1.2l.44-1.72.01.01Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
