import * as React from 'react';
import { clsx } from 'clsx';
import { useIntl, FormattedNumber } from 'react-intl';
import { Button } from '@repo/design-system-kit';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';

interface FeatureDiscoveryCardProps {
  illustration: string;
  optionKey: string;
  name: string;
  isFree: boolean;
  price: number;
  features: string[];
  handleSelectOption: (optionKey: string) => void;
}

export function FeatureDiscoveryCard({
  illustration,
  optionKey,
  name,
  isFree,
  price,
  features,
  handleSelectOption,
}: FeatureDiscoveryCardProps): React.JSX.Element {
  const { formatMessage } = useIntl();

  const freeOption = formatMessage({ id: 'painted-door-test.free-plan-label' });
  const priceSuffix = formatMessage({ id: 'painted-door-test.price-suffix' });

  const formattedPrice = (): JSX.Element | string => {
    if (isFree) {
      return freeOption;
    }

    return (
      <>
        <FormattedNumber
          currency="EUR"
          maximumFractionDigits={2}
          minimumFractionDigits={2}
          style="currency"
          value={Number(price)}
        />
        <span className="caption">{priceSuffix}</span>
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div className="mb-24">
        <StaticThemedAsset
          assetPath={illustration}
          className={clsx(styles.illustration, 'mb-24')}
        />

        <h2 className="title-2 mb-8">{name}</h2>

        <div className="title-4 mb-24">{formattedPrice()}</div>

        <h3 className="title-4 mb-8">
          {formatMessage({ id: 'painted-door-test.included-features' })}
        </h3>

        <ul className={styles['feature-list']}>
          {features.map(feature => (
            <li className="mb-8 body-2" key={optionKey}>
              {feature}
            </li>
          ))}
        </ul>
      </div>

      <Button
        onPress={() => {
          handleSelectOption(optionKey);
        }}
        stretch
        variant="primary"
      >
        {formatMessage({ id: 'painted-door-test.plan-cta' })}
      </Button>
    </div>
  );
}
