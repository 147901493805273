import React, { useEffect, useState, type ReactNode } from 'react';
import { LottiePlayer, Popup } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import window from 'ember-window-mock';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import ENV from 'qonto/config/environment';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import styles from './styles.strict-module.css';

const isTest = ENV.environment === 'test';

export const ACCOUNT_AGGREGATION_POPUP_STORAGE_KEY = 'accountaggregationpromopopupdismissed';

const popupDelay = ENV.environment === 'test' ? 0 : 2000;

const LOCALE_TO_LOTTIE_PATH_MAP: Record<string, string> = {
  de: '/lotties/account-aggregation/AA_Promotional-modal_504x216px_DE.json',
  es: '/lotties/account-aggregation/AA_Promotional-modal_504x216px_ES.json',
  fr: '/lotties/account-aggregation/AA_Promotional-modal_504x216px_FR.json',
  it: '/lotties/account-aggregation/AA_Promotional-modal_504x216px_IT.json',
  en: '/lotties/account-aggregation/AA_Promotional-modal_504x216px_EN.json',
};

export function AccountAggregationPromotionalPopup(): ReactNode {
  const [isOpen, setIsOpen] = useState(false);
  const segment = useEmberService('segment');
  const { formatMessage, locale } = useIntl();

  const lottiePath = LOCALE_TO_LOTTIE_PATH_MAP[locale] ?? String(LOCALE_TO_LOTTIE_PATH_MAP.en);

  useEffect(() => {
    if (safeLocalStorage.getItem(ACCOUNT_AGGREGATION_POPUP_STORAGE_KEY) === '1') {
      return;
    }

    const timeout = setTimeout(() => {
      setIsOpen(true);
      segment.track('account-aggregation_promo-pop-up_displayed');
    }, popupDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [segment]);

  const handleConfirm = (): void => {
    segment.track('account-aggregation-promo-pop-up_clicked');

    safeLocalStorage.setItem(ACCOUNT_AGGREGATION_POPUP_STORAGE_KEY, '1');

    window.location.href = `${window.location.origin}/?tdm=add-account`;
  };

  const handleCancel = (): void => {
    segment.track('account-aggregation-promo-pop-up_closed');

    safeLocalStorage.setItem(ACCOUNT_AGGREGATION_POPUP_STORAGE_KEY, '1');
    setIsOpen(false);
  };

  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <main> component)
  const TEST_PORTAL_CONTAINER = document.querySelector('main') ?? undefined;

  return (
    <Popup
      UNSTABLE_portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
      cancelText={formatMessage({ id: 'btn.close' })}
      className={styles.popup}
      confirmText={formatMessage({ id: 'account-aggregation.pop-up.cta' })}
      illustration={
        <LottiePlayer
          className={styles.illustration}
          data-test-account-aggregation-promotional-popup-illustration={lottiePath}
          path={useThemedAssetPath(lottiePath)}
        />
      }
      isOpen={isOpen}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      title={formatMessage({ id: 'account-aggregation.pop-up.title' })}
      type="promotional"
    >
      <ul>
        <li data-test-id="account-aggregation-promotional-popup-list-point-1">
          <FormattedMessage id="account-aggregation.pop-up.list-point-1" />
        </li>
        <li data-test-id="account-aggregation-promotional-popup-list-point-2">
          <FormattedMessage id="account-aggregation.pop-up.list-point-2" />
        </li>
      </ul>
    </Popup>
  );
}
