import type { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { OperationMethod, Status } from 'qonto/react/graphql';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import styles from './styles.strict-module.css';

export function PaymentMethodCell(): ReactElement {
  const transaction = cellContextManager.useCellContext();
  const { operationMethod, status } = transaction;
  const isDeclined = status === Status.Declined || status === Status.Reversed;

  const labelForOperationMethod = {
    [OperationMethod.Biller]: <FormattedMessage id="transactions.operation-types.biller" />,
    [OperationMethod.Card]: <FormattedMessage id="transactions.operation-types.card" />,
    [OperationMethod.Cheque]: <FormattedMessage id="transactions.operation-types.cheque" />,
    [OperationMethod.DirectDebit]: (
      <FormattedMessage id="transactions.operation-types.direct-debit" />
    ),
    [OperationMethod.DirectDebitHold]: (
      <FormattedMessage id="transactions.operation-types.direct-debit-hold" />
    ),
    [OperationMethod.PagopaPayment]: (
      <FormattedMessage id="transactions.operation-types.pagopa-payment" />
    ),
    [OperationMethod.Tax]: <FormattedMessage id="transactions.operation-types.tax" />,
    [OperationMethod.Transfer]: <FormattedMessage id="transactions.operation-types.transfer" />,
    [OperationMethod.PayLater]: <FormattedMessage id="transactions.operation-types.pay-later" />,
    [OperationMethod.Other]: <FormattedMessage id="transactions.operation-types.unknown" />,
  };

  return (
    <BaseCell className={isDeclined ? styles.declined : undefined} data-testid="payment-method">
      {labelForOperationMethod[operationMethod]}
    </BaseCell>
  );
}
