import type { StatusProps } from '@repo/design-system-kit';
import type { MessageDescriptor } from 'react-intl';
import type { Transaction } from 'qonto/react/graphql';
import { Status as TransactionStatusEnum } from 'qonto/react/graphql';

export function getVerificationStatusInfo(
  status: Transaction['status'],
  qualifiedForAccounting: boolean,
  formatMessage: (descriptor: MessageDescriptor) => string
): {
  level: StatusProps['level'];
  text: string;
} | null {
  if ([TransactionStatusEnum.Declined, TransactionStatusEnum.Reversed].includes(status)) {
    return null;
  }

  if (qualifiedForAccounting) {
    return {
      level: 'validated',
      text: formatMessage({
        id: 'transactions.modular_table.bookkeeping.verified.label',
      }),
    };
  }

  return {
    level: 'waiting',
    text: formatMessage({
      id: 'transactions.modular_table.bookkeeping.to_verify.label',
    }),
  };
}
