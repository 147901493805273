import { type ReactNode } from 'react';
import { Button, type ButtonProps } from 'react-aria-components';
import cx from 'clsx';
import styles from './vat-rate-field.strict-module.css';

interface ToggleButtonProps extends ButtonProps {
  isSelected: boolean;
  onChange: () => void;
}
export function ToggleButton({
  children,
  onChange,
  isSelected,
  className,
  ...props
}: ToggleButtonProps): ReactNode {
  return (
    <Button
      className={cx(styles['toggle-button'], className)}
      data-selected={isSelected}
      onPress={onChange}
      {...props}
    >
      {children}
    </Button>
  );
}
