interface Amount {
  value: string;
  currency: string;
}

export enum EarlyRepaymentOption {
  Full = 'full_repayment',
  Next = 'next_installment',
}

export interface RepaymentOption {
  earlyRepaymentOption: EarlyRepaymentOption;
  totalAmount: Amount;
  capitalAmount: Amount;
  interestAmount: Amount;
  previousInterestAmount: Amount | null;
  penaltyFeesAmount: Amount | null;
  savingsAmount: Amount | null;
}
