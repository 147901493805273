import { type ReactNode } from 'react';

export function Receipt(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        d="M13.5 1.438V15.5c-.573 0-.802-.938-1.375-.938s-.802.938-1.375.938-.802-.938-1.375-.938S8.573 15.5 8 15.5s-.802-.938-1.375-.938-.802.938-1.375.938-.802-.938-1.375-.938-.802.938-1.375.938V1.438C2.5.918 2.91.5 3.417.5h9.166c.506 0 .917.42.917.938Z"
      />
      <path
        fill="currentColor"
        d="M7.065 10.12a2.69 2.69 0 0 1-1.765.814c.037-.68.318-1.322.79-1.803a2.692 2.692 0 0 1 1.764-.813 2.791 2.791 0 0 1-.79 1.803Zm0-3.896c.471.476.752 1.116.79 1.795a2.665 2.665 0 0 1-1.766-.797 2.764 2.764 0 0 1-.79-1.795 2.664 2.664 0 0 1 1.766.797Zm1.854-.009a2.69 2.69 0 0 1 1.765-.813 2.792 2.792 0 0 1-.79 1.803c-.471.483-1.1.773-1.764.813.037-.68.318-1.322.79-1.803Zm0 3.897a2.788 2.788 0 0 1-.79-1.804 2.677 2.677 0 0 1 1.766.806c.47.476.752 1.116.79 1.795a2.663 2.663 0 0 1-1.766-.797Z"
      />
    </svg>
  );
}
