import { type ReactElement, type ReactNode } from 'react';
import cx from 'clsx';
import { Button, type ButtonProps as AriaButtonProps } from 'react-aria-components';
import styles from './styles.strict-module.css';

interface MembershipActionProps extends AriaButtonProps {
  icon: ReactNode;
  text: string;
}

export function MembershipAction({ icon, text, ...props }: MembershipActionProps): ReactElement {
  return (
    <Button className={cx(styles.wrapper, 'overlay')} {...props}>
      {icon}
      <span className="body-2" data-testid="text">
        {text}
      </span>
    </Button>
  );
}
