import { FormattedMessage, useIntl } from 'react-intl';
import { ProductBadge } from '@repo/domain-kit/pricing';
import { clsx } from 'clsx';
import type { ReactElement } from 'react';
import React from 'react';
import { Addons } from 'qonto/constants/addons';
import { ManageAddon } from 'qonto/react/assets/icons/manage-addon';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './styles.strict-module.css';

interface BillingRecurrenceTranslationsParams {
  recurrence: string;
  monthlyPrice: string;
  annualPrice: string;
}

interface MiniAddonCardProps {
  name: string;
  badgeTitle: string;
  billingRecurrence: string;
  nextBillingDate: string;
  trackingId: string;
  onClick: (trackingId: string) => void;
}

interface AddonSubscription {
  product: {
    localName: string;
    code: Addons.AccountsPayable | Addons.AccountsReceivable | Addons.ExpenseSpendManagement;
    monthlyPrice: {
      value: number;
      currency: string;
    };
    annualPrice: {
      value: number;
      currency: string;
    };
  };
  recurrence: string;
  nextSubscriptionBillingDate: string;
}

interface HeaderAddonsOverviewProps {
  addonSubscriptions?: AddonSubscription[];
  onClick: (trackingId: string) => void;
}

function MiniAddonCard({
  name,
  trackingId,
  badgeTitle,
  billingRecurrence,
  nextBillingDate,
  onClick,
}: MiniAddonCardProps): React.JSX.Element {
  function handleClick(): void {
    onClick(trackingId);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick();
    }
  }

  return (
    <div
      className={styles.container}
      data-test-addon-card={name}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <div className="mb-8">
        <ProductBadge traits={[{ name: 'active', title: badgeTitle }]} />
      </div>

      <h3 className={clsx(styles.name, 'body-1')} data-test-addon-name>
        {name}
      </h3>

      <div>
        <div className={clsx(styles['color-secondary'], 'caption')} data-test-billing-recurrence>
          {billingRecurrence}
        </div>
        <div className={clsx(styles['color-secondary'], 'caption')} data-test-next-billing-date>
          {nextBillingDate}
        </div>
      </div>
    </div>
  );
}

function EmptyAddonCard({ onClick }: { onClick: (trackingId: string) => void }): React.JSX.Element {
  function handleClick(): void {
    onClick('main_card');
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick();
    }
  }

  return (
    <div
      className={clsx(styles.container, styles.empty)}
      data-test-empty-card
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <div className={styles.wrapper}>
        <ManageAddon />
        <h3 className="body-1" data-test-empty-card-description>
          <FormattedMessage id="subscription.consumption-table.addon-overview.empty-state.title" />
        </h3>
      </div>
    </div>
  );
}

export function HeaderAddonsOverview({
  addonSubscriptions = [],
  onClick,
}: HeaderAddonsOverviewProps): ReactElement {
  const { formatMessage, formatNumber, locale } = useIntl();

  function billingRecurrenceTranslations({
    recurrence,
    monthlyPrice,
    annualPrice,
  }: BillingRecurrenceTranslationsParams): string {
    return recurrence === 'monthly'
      ? formatMessage(
          { id: 'subscription.consumption-table.addon-overview.addon-list.monthly-billing' },
          {
            amount: monthlyPrice,
          }
        )
      : formatMessage(
          { id: 'subscription.consumption-table.addon-overview.addon-list.annual-billing' },
          {
            amount: annualPrice,
          }
        );
  }

  function formatPrice(price: number): string {
    return formatNumber(price, {
      currency: 'EUR',
      style: 'currency',
    });
  }

  function formatDate(date: string): string {
    return dateToken({
      date,
      locale,
      token: 'date-year-s',
    });
  }

  const trackingAddonsId = {
    [Addons.AccountsPayable]: 'ap_card',
    [Addons.AccountsReceivable]: 'ar_card',
    [Addons.ExpenseSpendManagement]: 'e&sm_card',
  };

  const addonsData: MiniAddonCardProps[] = addonSubscriptions.map(
    (addonSubscription: AddonSubscription) => ({
      name: addonSubscription.product.localName,
      trackingId: trackingAddonsId[addonSubscription.product.code],
      billingRecurrence: billingRecurrenceTranslations({
        recurrence: addonSubscription.recurrence,
        monthlyPrice: formatPrice(addonSubscription.product.monthlyPrice.value),
        annualPrice: formatPrice(addonSubscription.product.annualPrice.value),
      }),
      nextBillingDate: formatMessage(
        { id: 'subscription.consumption-table.addon-overview.addon-list.billing-date' },
        {
          date: formatDate(addonSubscription.nextSubscriptionBillingDate),
        }
      ),
      badgeTitle: formatMessage({ id: 'subscription.consumption-table.addon-overview.badge' }),
    })
  ) as MiniAddonCardProps[];

  return (
    <div className={styles.scroll}>
      {addonsData.map(addon => (
        <div key={addon.name}>
          <MiniAddonCard
            badgeTitle={addon.badgeTitle}
            billingRecurrence={addon.billingRecurrence}
            name={addon.name}
            nextBillingDate={addon.nextBillingDate}
            onClick={onClick}
            trackingId={addon.trackingId}
          />
        </div>
      ))}
      <EmptyAddonCard onClick={onClick} />
    </div>
  );
}
