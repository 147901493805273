import type { ComponentPropsWithRef, ReactNode } from 'react';

export function EmptyAvatar(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="1" y="1" width="32" height="32" rx="16" stroke="#DCDCD9" strokeDasharray="4 4" />
    </svg>
  );
}
