import { FormattedMessage } from 'react-intl';
import { type ReactNode } from 'react';
import type { BalanceTableType } from './balance-table';

interface AttributeCellProps {
  type: BalanceTableType;
}

export function AttributeCell({ type }: AttributeCellProps): ReactNode {
  return (
    <span>
      <FormattedMessage
        id={
          type === 'start'
            ? 'cash-flow.table.header-column.start-of-month'
            : 'cash-flow.table.header-column.end-of-month'
        }
      />
    </span>
  );
}
