import { type ReactNode } from 'react';
import { motion, AnimatePresence, type HTMLMotionProps } from 'framer-motion';
import cx from 'clsx';
import styles from './side-panel.strict-module.css';

interface SidePanelProps extends HTMLMotionProps<'aside'> {
  isVisible: boolean;
  children?: ReactNode;
  width?: number;
}

export function SidePanel({
  children,
  isVisible = false,
  width = 384,
  ...props
}: SidePanelProps): ReactNode {
  return (
    <AnimatePresence>
      {isVisible ? (
        <motion.aside
          animate={{ width, opacity: 1 }}
          className={cx(styles['side-panel'], props.className)}
          data-testid="side-panel"
          exit={{ width: 0, opacity: 0 }}
          initial={{ width: 0, opacity: 0 }}
          transition={{
            opacity: { duration: 0.2, ease: 'easeInOut' },
            width: { duration: 0.5, ease: [0.55, 0, 0.1, 1], delay: 0.1 },
          }}
          {...props}
        >
          {children}
        </motion.aside>
      ) : null}
    </AnimatePresence>
  );
}
