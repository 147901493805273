import { type ReactNode, type SVGProps } from 'react';

export function IconMoneyClip(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.24 1.045a.5.5 0 0 1 .261.283l.856 2.339h.598c.938 0 1.684.78 1.684 1.712v1.47h.473c.495 0 .885.417.885.893v3.124c.051.547-.416.922-.885.922h-.473v1.5c0 .931-.746 1.712-1.684 1.712H1.683C.745 15 0 14.22 0 13.288v-7.91c0-.93.745-1.711 1.683-1.711h2.245l6.926-2.634a.5.5 0 0 1 .385.012ZM6.741 3.667h4.55l-.556-1.52-3.994 1.52Zm5.25 1H1.682A.702.702 0 0 0 1 5.379v7.909c0 .402.32.712.683.712h11.272c.364 0 .684-.31.684-.712v-1.5h-3.94c-.998 0-1.803-.841-1.803-1.833V8.682c0-.992.805-1.833 1.802-1.833h3.94v-1.47a.702.702 0 0 0-.683-.712H11.991Zm2.158 6.121h-.011a.41.41 0 0 0-.012 0H9.698c-.422 0-.802-.37-.802-.833V8.682c0-.463.38-.833.802-.833h5.299v2.939h-.847Z"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="M10.5 9.4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" />
    </svg>
  );
}
