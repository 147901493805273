import { type ReactNode } from 'react';

export function Pencil(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill="currentColor"
        d="m12.318 1.01 2.68 2.68L4.97 13.71 1.011 15l1.28-3.96L12.318 1.01Zm0-1.01c-.26 0-.52.1-.71.29L1.591 10.31s-.08.07-.11.12c-.06.08-.12.18-.15.29l-1.28 3.97c-.22.67.3 1.31.95 1.31.1 0 .21-.02.31-.05l3.969-1.28c.16-.05.3-.14.41-.26L15.708 4.4c.39-.4.39-1.03 0-1.43L13.028.29c-.2-.2-.45-.29-.71-.29Z"
      />
    </svg>
  );
}
