import React from 'react';
import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './styles.strict-module.css';

export function SubscriptionCloseStepsClosingDate(): ReactNode {
  const { locale } = useIntl();

  const getClosingDate = (): string => {
    const lastDayOfCurrentMonth = dayjs().endOf('month');
    const isTodayLastDayOfMonth = dayjs().isSame(lastDayOfCurrentMonth, 'day');

    const date = isTodayLastDayOfMonth
      ? lastDayOfCurrentMonth.add(1, 'month').toDate()
      : lastDayOfCurrentMonth.toDate();

    return dateToken({
      date: dayjs(date).toISOString(),
      locale,
      token: 'date-year-l',
    });
  };

  return (
    <span className={styles.purple} data-test-success-date>
      {getClosingDate()}
    </span>
  );
}
