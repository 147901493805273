import { useState, type ReactNode } from 'react';
import type { Uppy } from '@uppy/core';
import { useIntl } from 'react-intl';
import { Popup, Spinner } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Popover, PopoverHeader } from 'qonto/react/components/table-v2/popover';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import type { DataWithIconProps } from 'qonto/react/components/table-v2/cells/data-with-icon-cell/data-with-icon-cell';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { useUnlinkTransactionAttachment } from 'qonto/react/hooks/mutations/use-unlink-attachment';
import type { Body, Meta } from 'qonto/react/hooks/use-attachments-uploader';
import styles from './styles.strict-module.css';
import { ActionsPopoverSection } from './actions-popover-section';
import { DisclaimerPopoverSection } from './disclaimer-popover-section';
import { AttachmentListSection } from './attachment-list-section';

interface AttachmentCellPopoverProps {
  title?: string;
  subtitle?: string | null;
  icon?: ReactNode;
  type?: DataWithIconProps['type'];
  uppy: Uppy<Meta, Body>;
  isUploading: boolean;
}

export function AttachmentCellPopover({
  title,
  icon,
  type,
  uppy,
  subtitle,
  isUploading,
}: AttachmentCellPopoverProps): ReactNode {
  const { formatMessage } = useIntl();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [attachmentToDelete, setAttachmentToDelete] = useState<string | null>(null);
  const transaction = cellContextManager.useCellContext();
  const { id: transactionId, attachments, automaticallyAddedAttachmentIds } = transaction;
  const { mutate: deleteAttachment, isPending: isDeleteAttachmentPending } =
    useUnlinkTransactionAttachment(transactionId);
  const abilities = useEmberService('abilities');
  const hasAutoAttachments =
    abilities.can('access email forward attachment') && automaticallyAddedAttachmentIds.length;

  const getPopoverHeaderMeta = (): {
    title: string;
    type?: 'info' | 'error' | undefined;
    subtitle?: string | null;
    icon: ReactNode;
  } => {
    if (isUploading) {
      return {
        title: formatMessage({
          id: 'transactions.table.attachments.status.title.uploading',
        }),
        icon: <Spinner color="primary-a" />,
      };
    }

    if (attachments.length === 0) {
      if (!title) {
        throw new Error('title prop is required when there are no attachments');
      }

      return {
        title,
        icon,
        type,
        subtitle,
      };
    }

    return {
      title: attachments.length.toString(),
      icon,
      subtitle,
    };
  };

  return (
    <>
      <Popover className={styles['popover-container']}>
        <PopoverHeader>
          <DataWithIconCell {...getPopoverHeaderMeta()} />
        </PopoverHeader>
        <DisclaimerPopoverSection uppy={uppy} />
        <AttachmentListSection
          isUploading={isUploading}
          setAttachmentToDelete={setAttachmentToDelete}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          uppy={uppy}
        />
        {!hasAutoAttachments && <ActionsPopoverSection isUploading={isUploading} uppy={uppy} />}
      </Popover>

      <Popup
        cancelText={formatMessage({
          id: 'btn.cancel',
        })}
        confirmActionLoading={isDeleteAttachmentPending}
        confirmText={formatMessage({
          id: 'transactions.modals.delete-attachment.delete-attachment',
        })}
        isOpen={isDeleteModalOpen}
        onCancel={() => {
          setIsDeleteModalOpen(false);
        }}
        onConfirm={() => {
          if (attachmentToDelete) {
            deleteAttachment(attachmentToDelete, {
              onSuccess: () => {
                setIsDeleteModalOpen(false);
              },
            });
          }
        }}
        onOpenChange={setIsDeleteModalOpen}
        subtitle={formatMessage({
          id: 'transactions.modals.delete-attachment.description',
        })}
        title={formatMessage({
          id: 'transactions.modals.delete-attachment.title',
        })}
        type="destructive"
      />
    </>
  );
}
