import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function ChevronTopOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" {...props}>
      <path
        fill="currentColor"
        d="M8 4.508a.47.47 0 0 0-.35.15l-5.99 5.98c-.2.2-.2.51 0 .71.2.2.51.2.71 0L8 5.718l5.63 5.63c.2.2.51.2.71 0 .2-.2.2-.51 0-.71l-5.99-5.98c-.1-.1-.23-.15-.35-.15Z"
      />
    </svg>
  );
}
