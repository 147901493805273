import { useDeepMemo } from 'qonto/react/hooks/use-deep-memo';
import {
  UNCATEGORIZED_CATEGORY,
  type CashflowParentCategory,
} from 'qonto/react/models/cash-flow-category';
import {
  DEFAULT_TIMESERIES,
  type CashflowSide,
  type CashflowTimeframe,
  type CashflowTimeseries,
} from 'qonto/react/models/cash-flow-timeseries';
import type { Amount } from 'qonto/react/models/amount';
import { useMapCategoriesWithData } from 'qonto/react/utils/cashflow-categories/categories-data-map';
import {
  getCategoryType,
  type CategoriesSideTables,
  type CategoriesTableColumn,
  type CategoriesTableRow,
} from '../models/categories-table-display';

const DEFAULT_AMOUNT: Amount = { value: 0, currency: 'EUR' };

export const getCategoryColumns = (
  side: CashflowSide,
  timeframes: CashflowTimeframe[],
  categoryId: string | null,
  subcategoryId?: string | null
): CategoriesTableColumn[] => {
  return timeframes.map((timeframe: CashflowTimeframe) => {
    const flows = timeframe.cashFlowData[side];
    const datapoint = flows.categoriesDataPoints.find(({ categoryId: id }) => id === categoryId);

    const subcategoryDataPoint = datapoint?.subcategoriesDataPoints?.find(
      ({ subcategoryId: id }) => id === subcategoryId
    );

    let amount = DEFAULT_AMOUNT;

    if (subcategoryId) {
      amount = subcategoryDataPoint?.amountSum ?? DEFAULT_AMOUNT;
    } else {
      amount = datapoint?.amountSum ?? DEFAULT_AMOUNT;
    }

    return {
      amount,
      interval: {
        start: new Date(timeframe.inclusiveStartDate).getTime(),
        end: new Date(timeframe.exclusiveEndDate).getTime(),
      },
    };
  });
};

export const getCategoryRow = (
  category: CashflowParentCategory,
  timeframes: CashflowTimeframe[],
  side: CashflowSide
): CategoriesTableRow => {
  const columns = getCategoryColumns(side, timeframes, category.id);
  const { id, name, colorKey: color, iconKey: icon } = category;

  const subRows: CategoriesTableRow[] =
    category.subcategories?.map(subcategory => {
      return {
        id: subcategory.id,
        name: subcategory.name,
        color: subcategory.colorKey,
        type: getCategoryType(subcategory as CashflowParentCategory),
        columns: getCategoryColumns(side, timeframes, category.id, subcategory.id),
      };
    }) ?? [];

  return {
    id,
    name,
    color,
    icon,
    type: getCategoryType(category),
    columns,
    subRows,
  };
};

export const getSideAmountSums = (
  timeframes: CashflowTimeframe[],
  side: CashflowSide
): CategoriesTableColumn[] => {
  return timeframes.map((timeframe: CashflowTimeframe) => {
    const { amountSum: amount } = timeframe.cashFlowData[side];

    return {
      amount,
      interval: {
        start: new Date(timeframe.inclusiveStartDate).getTime(),
        end: new Date(timeframe.exclusiveEndDate).getTime(),
      },
    };
  });
};

export const useCategoriesCashflows = (
  offset: number,
  displayedMonths: number,
  timeseries: CashflowTimeseries = DEFAULT_TIMESERIES
): CategoriesSideTables => {
  const { mapCategoriesWithData } = useMapCategoriesWithData();
  const selectedTimeframes = useDeepMemo(
    () => timeseries.timeframes.slice(offset, offset + displayedMonths),
    [timeseries, offset, displayedMonths]
  );

  const categoriesData = mapCategoriesWithData({
    inflow: [...timeseries.categoriesData.inflow, UNCATEGORIZED_CATEGORY],
    outflow: [...timeseries.categoriesData.outflow, UNCATEGORIZED_CATEGORY],
  });

  const { inflow: inflowCategories, outflow: outflowCategories } = categoriesData;

  const inflows: CategoriesTableRow[] = inflowCategories.map(category =>
    getCategoryRow(category, selectedTimeframes, 'inflows')
  );
  const outflows: CategoriesTableRow[] = outflowCategories.map(category =>
    getCategoryRow(category, selectedTimeframes, 'outflows')
  );
  const inflowSums = getSideAmountSums(selectedTimeframes, 'inflows');
  const outflowSums = getSideAmountSums(selectedTimeframes, 'outflows');

  return { inflows, outflows, inflowSums, outflowSums };
};
