import type { ComponentPropsWithRef, ReactNode } from 'react';

export function ArrowLeftOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M8.02 1.98a.5.5 0 0 1 0 .707L3.207 7.5H14a.5.5 0 0 1 0 1H3.207l4.813 4.813a.5.5 0 1 1-.707.707L1.646 8.354a.5.5 0 0 1 0-.708L7.313 1.98a.5.5 0 0 1 .707 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
