export const VAT_RATES_PER_COUNTRY: Record<string, string[]> = {
  FR: ['0', '2.1', '5.5', '10', '20'],
  IT: ['0', '4', '5', '10', '22'],
  DE: ['0', '7', '19'],
  ES: ['0', '4', '10', '21'],
  AU: ['0', '10', '13', '20'],
  NL: ['0', '9', '21'],
  BE: ['0', '6', '12', '21'],
  PT: ['0', '6', '13', '23'],
};

export const DEFAULT_VAT_RATE_PER_COUNTRY: Record<string, string> = {
  FR: '20',
  IT: '22',
  DE: '19',
  ES: '21',
  AU: '20',
  NL: '21',
  BE: '21',
  PT: '23',
};
