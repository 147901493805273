import { type ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import { DragCellHandle } from 'qonto/react/assets/icons';
import styles from './categories-list.strict-module.css';

export function CategoriesItemLoading(): ReactNode {
  return (
    <div className={styles.wrapper} data-testid="categories-list-loading">
      <div className={styles['category-item']}>
        <div className={styles['category-item-details']}>
          <button
            className={styles['drag-handle']}
            data-testid="loading-drag-handle"
            disabled
            type="button"
          >
            <DragCellHandle />
          </button>
          <SkeletonLoader.Avatar
            data-testid="categories-list-loading-avatar"
            height="32px"
            width="32px"
          />
          <SkeletonLoader.Line
            data-testid="categories-list-loading-line"
            height="12px"
            standalone
            width="95px"
          />
          <SkeletonLoader.Block
            data-testid="categories-list-loading-block"
            height="16px"
            standalone
            width="33px"
          />
        </div>
        <div className={styles['category-item-actions']} data-test-categories-actions-block>
          <SkeletonLoader.Block
            data-testid="categories-list-loading-block"
            height="32px"
            standalone
            width="32px"
          />
          <SkeletonLoader.Block
            data-testid="categories-list-loading-block"
            height="32px"
            standalone
            width="32px"
          />
        </div>
      </div>
    </div>
  );
}
