import type { ReactNode } from 'react';

export function ArrowLeft(): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      viewBox="0 0 32 32"
    >
      <path d="M9.01 16c0-.26.1-.52.3-.7L21.27 3.32a.99.99 0 0 1 1.42 0c.4.4.4 1.02 0 1.42L11.43 16l11.26 11.26c.4.4.4 1.02 0 1.42-.4.4-1.02.4-1.42 0L9.31 16.7c-.2-.2-.3-.46-.3-.7Z" />
    </svg>
  );
}
