import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';

interface MileageTableCellProps {
  note: string;
}

export function MileageTableCell({ note }: MileageTableCellProps): ReactNode {
  return (
    <div className={styles['cell-content']} data-test-request-cell-mileage>
      <div
        aria-hidden="true"
        className={styles['request-mileage-icon']}
        data-test-request-cell-mileage-icon
      >
        <StaticThemedAsset assetPath="/illustrations/requests/request-mileage.svg" />
      </div>
      <div className={styles['cell-info']}>
        <p data-test-request-cell-mileage-title>
          <FormattedMessage id="labels.mileage" />
        </p>
        <p className={cx(styles.subtitle, 'caption')} data-test-request-cell-mileage-subtitle>
          {note}
        </p>
      </div>
    </div>
  );
}
