import type { ReactNode } from 'react';
import { Button, IconPlusOutlined, Tooltip } from '@repo/design-system-kit';
import cx from 'clsx';
import styles from './category-item.strict-module.css';

export interface AddSubcategoryButtonProps {
  handleOnPress: () => void;
  hasReachedLimit: boolean;
  limitReachedToolTipText: string;
  withinLimitsToolTipText: string;
}

export function AddSubcategoryButton({
  handleOnPress,
  hasReachedLimit,
  limitReachedToolTipText,
  withinLimitsToolTipText,
}: AddSubcategoryButtonProps): ReactNode {
  return (
    <div>
      {hasReachedLimit ? (
        <Tooltip
          className={styles.tooltip}
          data-testid="add-subcategory-with-limit-reached-tooltip"
          label={limitReachedToolTipText}
          placement="top"
        >
          <div aria-disabled="true" data-testid="add-subcategory-tooltip-wrapper">
            <Button
              className={cx(styles['btn-disabled'], hasReachedLimit)}
              data-testid="add-subcategory-button-with-limit-reached-tooltip"
              iconOnly
              size="medium"
              variant="tertiary"
            >
              <IconPlusOutlined height={16} width={16} />
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Tooltip
          className={styles['within-limits-tooltip']}
          data-testid="add-subcategory-within-limits-tooltip"
          label={withinLimitsToolTipText}
          placement="top"
        >
          <Button
            data-testid="subcategory-create"
            iconOnly
            onPress={handleOnPress}
            size="small"
            variant="tertiary"
          >
            <IconPlusOutlined height={16} width={16} />
          </Button>
        </Tooltip>
      )}
    </div>
  );
}
