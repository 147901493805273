import React, { useCallback, useEffect, useState, type Key, type ReactNode } from 'react';
import {
  Button,
  Label,
  ListBox,
  Popover,
  Select,
  Collection,
  Section,
  Header,
} from 'react-aria-components';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { SkeletonLoader } from '@repo/design-system-kit';
import type {
  CashflowActiveCategoryId,
  CashflowCategories,
} from 'qonto/react/models/cash-flow-category';
import { ChevronBottomOutlined } from 'qonto/react/assets/icons';
import { getFlatCategories } from '../utils/cash-flow-categories';
import styles from './styles.strict-module.css';
import { Category } from './category';
import { ParentCategory } from './parent-category';
import { CategoriesMenu } from './categories-menu';

interface CashflowCategorySelectorProps {
  categories: CashflowCategories;
  activeCategoryId: CashflowActiveCategoryId;
  onSelectionChange: (categoryId: CashflowActiveCategoryId) => void;
  isLoading: boolean;
  isAutoCategorized?: boolean;
}
export function CashflowCategorySelector({
  categories = {
    inflow: [],
    outflow: [],
  },
  activeCategoryId = null,
  onSelectionChange,
  isLoading,
  isAutoCategorized = false,
}: CashflowCategorySelectorProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');
  const { inflow, outflow } = categories;

  const flatCategories = getFlatCategories(categories);
  const hasCategories = flatCategories.length > 0;
  const categoriesData = [
    {
      id: 'inflow',
      name: formatMessage({ id: 'cash-flow-categories.group-title.inflows' }),
      categories: inflow,
    },
    {
      id: 'outflow',
      name: formatMessage({ id: 'cash-flow-categories.group-title.outflows' }),
      categories: outflow,
    },
  ];

  const [selectedCategoryId, setSelectedCategoryId] = useState(activeCategoryId);
  useEffect(() => {
    setSelectedCategoryId(activeCategoryId);
  }, [activeCategoryId]);

  const handleOpenChange = (isOpen: boolean): void => {
    if (isOpen) {
      segment.track('transaction_categories-opened');
    }
  };

  const handleSelectionChange = (key: Key): void => {
    const newCategoryId = key === selectedCategoryId ? null : (key as string);
    setSelectedCategoryId(newCategoryId);
    onSelectionChange(newCategoryId);

    const category = flatCategories.find(({ id }) => id === key);
    segment.track('transaction_category-selected', {
      category: category?.nameKey ?? category?.name,
      selection: newCategoryId ? 'selected' : 'unselected',
    });
  };

  const displaySelectedCategory = (): ReactNode => {
    const selectedCategory = flatCategories.find(category => category.id === selectedCategoryId);
    if (selectedCategory)
      return <Category emphasized isAutoCategorized={isAutoCategorized} item={selectedCategory} />;

    return (
      <span className={styles['select-placeholder']}>
        {hasCategories
          ? formatMessage({ id: 'transactions.sidebar.cash-flow-categories.dropdown.empty' })
          : 'No categories available'}
      </span>
    );
  };

  const [triggerWidth, setTriggerWidth] = useState<string>('auto');
  const buttonRef = useCallback((node: HTMLButtonElement | null) => {
    if (node !== null) {
      const newWidth = node.getBoundingClientRect().width;
      setTriggerWidth(`${newWidth.toString()}px`);
    }
  }, []);

  return (
    <div className={styles['category-block']}>
      <Select
        className={styles.select}
        data-testid="cash-flow-category-selector"
        isDisabled={!hasCategories}
        onOpenChange={handleOpenChange}
        onSelectionChange={handleSelectionChange}
        selectedKey={selectedCategoryId}
      >
        <Label className={styles.title} data-testid="title">
          {formatMessage({ id: 'transactions.sidebar.category' })}
        </Label>
        {isLoading ? (
          <SkeletonLoader.Line borderRadius="4px" height="40px" width="100%" />
        ) : (
          <Button
            className={cx('body-1', 'overlay', styles.trigger)}
            data-testid="trigger"
            ref={buttonRef}
          >
            {displaySelectedCategory()}
            <ChevronBottomOutlined aria-hidden="true" className={styles['arrow-icon']} />
          </Button>
        )}
        {hasCategories ? (
          <Popover style={{ width: triggerWidth }}>
            <div className={styles.popover} data-testid="cash-flow-category-popover">
              <ListBox className={styles['list-box']} items={categoriesData}>
                {inOutFlow => {
                  if (!inOutFlow.categories.length) return null;
                  return (
                    <Section className={styles.section} data-testid="section" id={inOutFlow.name}>
                      <Header className={styles['section-title']} data-testid="section-title">
                        {inOutFlow.name}
                      </Header>
                      <Collection items={inOutFlow.categories}>
                        {item => {
                          return <ParentCategory category={item} />;
                        }}
                      </Collection>
                    </Section>
                  );
                }}
              </ListBox>
            </div>
          </Popover>
        ) : null}
      </Select>
      <div className={styles['menu-trigger']}>
        <CategoriesMenu />
      </div>
    </div>
  );
}
