import { type ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface MeterProps {
  readonly value: number;
  readonly min?: number;
  readonly max?: number;
  readonly high?: number;
  readonly low?: number;
  readonly height?: number;
  readonly disabled?: boolean;
}

type PercentLevel = 'low' | 'high' | 'disabled';

const MIN_PERCENTAGE_THRESHOLD = 1; // under this value (0 excluded), the width of the bar is set to MIN_PERCENTAGE
const MIN_PERCENTAGE = 1; // the minimum value to display if the percentage is between 0 and MIN_PERCENTAGE_THRESHOLD

export function Meter(props: MeterProps): ReactNode {
  const {
    value,
    min = 0,
    max = 1,
    high = 0,
    low = 0,
    height = 6,
    disabled = false,
    ...attributes
  } = props;

  const halfHeight = height / 2;

  const getWidthPercent = (): number => {
    const percent = ((value - min) / (max - min)) * 100 || 0;
    const width = Math.max(0, Math.min(100, percent));

    if (width <= MIN_PERCENTAGE_THRESHOLD && width > 0) {
      return MIN_PERCENTAGE;
    }

    return Math.max(0, Math.min(100, percent));
  };

  const getPercentStyle = (): PercentLevel | undefined => {
    if (disabled) {
      return 'disabled';
    }

    if ((low === min && high === min) || low >= high) {
      return undefined;
    }

    const percent = getWidthPercent();

    if (percent >= high) {
      return 'high';
    } else if (percent >= low) {
      return 'low';
    }

    return undefined;
  };

  const widthPercent = getWidthPercent();
  const percentStyle = getPercentStyle();

  return (
    <div
      aria-valuemax={max}
      aria-valuemin={min}
      aria-valuenow={value}
      className={styles.meter}
      data-test-meter
      role="meter"
      {...attributes}
    >
      <svg aria-hidden="true" height={height}>
        <rect
          className={styles['meter-background']}
          height={height}
          rx={halfHeight}
          ry={halfHeight}
          width="100%"
          x="0"
          y="0"
        />
        <rect
          className={cx(styles['meter-value-now'], percentStyle && styles[percentStyle])}
          data-test-meter-value-now
          height={height}
          rx={halfHeight}
          ry={halfHeight}
          width={`${widthPercent.toString()}%`}
          x="0"
          y="0"
        />
      </svg>
    </div>
  );
}
