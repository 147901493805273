import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function EyeOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="M8 6.005c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2Zm0-1c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3Z" />
      <path
        fillRule="evenodd"
        d="M1.01 8.005c2.21-6.75 11.76-6.75 13.97 0-2.21 6.75-11.76 6.75-13.97 0Zm14.95.25c-2.48 7.75-13.44 7.75-15.92 0a.886.886 0 0 1 0-.51c2.48-7.75 13.44-7.75 15.92 0 .05.17.05.34 0 .51Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
