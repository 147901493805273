import cx from 'clsx';
import type { ReactElement } from 'react';
import { CheckmarkOutlined } from 'qonto/react/assets/icons';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';

interface CardProps {
  index: number;
  type: string;
  illustration: string;
  title: string;
  list: string[];
}

type CardItem = Omit<CardProps, 'index'>;

interface CardsProps {
  items: CardItem[];
  className?: string;
}

function Card({ index, type, title, illustration, list }: CardProps): ReactElement {
  return (
    <div className={cx(styles.card, styles[type])} data-test-empty-state-card={index}>
      <div className={cx('mb-16', styles['illustration-wrapper'])}>
        <StaticThemedAsset
          assetPath={illustration}
          className={styles.illustration}
          data-test-empty-state-card-illustration
        />
      </div>
      <h2 className="title-2 mb-16" data-test-empty-state-card-title>
        {title}
      </h2>
      <ul className={cx('body-1', styles.list)} data-test-empty-state-card-list>
        {list.map((item, i) => (
          <li data-test-empty-state-card-list-item={i} key={item}>
            <CheckmarkOutlined className="mr-16" />
            <span>{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export function Cards({ items, className }: CardsProps): ReactElement {
  return (
    <div className={cx(styles.cards, className)} data-test-empty-state-cards>
      {items.map((item, index) => (
        <Card index={index} key={item.type} {...item} />
      ))}
    </div>
  );
}
