import { useEffect, useRef, useState, type FormEvent, type ReactNode } from 'react';
import { useSegment } from '@repo/poly-hooks/segment';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import { type CountryCode } from '@repo/design-system-kit';
import type { CashflowParentCategory } from 'qonto/react/models/cash-flow-category';
import { CategoryIcon } from 'qonto/react/components/transactions/sidebar/category/cashflow-category/components/category-icon';
import { useCreateCashFlowSubcategory } from 'qonto/react/hooks/use-create-cash-flow-subcategory';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { useCategoriesManagementRouting } from '../hooks/use-categories-management-routing';
import { useSearchTransactionsByCategoryId } from '../hooks/use-search-transactions-by-category-id';
import { DEFAULT_VAT_RATE_PER_COUNTRY } from '../../../constants/vat';
import styles from './category-item-new.strict-module.css';
import { NewItemForm } from './new-item-form';
import { ConfirmFirstSubcategoryCreationPopup } from './subcategory-confirm-popup';

interface SubcategoryItemNewProps {
  category: CashflowParentCategory;
}

export function SubcategoryItemNew({ category }: SubcategoryItemNewProps): ReactNode {
  const categoryId = category.id ?? '';

  const segment = useSegment();
  const { formatMessage } = useIntl();
  const { organization } = useOrganizationManager();
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const { mutate, isPending } = useCreateCashFlowSubcategory();
  const {
    currentType,
    navigateToType,
    navigateToCategoryDetails,
    isCreatingNewSubcategoryForParent,
  } = useCategoriesManagementRouting();
  const shouldShow = isCreatingNewSubcategoryForParent(categoryId);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [subcategoryName, setSubcategoryName] = useState('');

  const hasSingleParent = category.subcategories?.length === 0 && shouldShow;
  const { data: transactionsWithCategory } = useSearchTransactionsByCategoryId(
    hasSingleParent ? categoryId : null
  );
  const shouldShowConfirmationPopup = Boolean(
    transactionsWithCategory?.meta.total_count && transactionsWithCategory.meta.total_count > 0
  );

  const countryCode = organization.legalCountry as CountryCode;
  const defaultVatRate =
    (DEFAULT_VAT_RATE_PER_COUNTRY as Partial<Record<CountryCode, string>>)[countryCode] ?? '20';

  const textFieldRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (shouldShow && textFieldRef.current) {
      textFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [shouldShow]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    if (!formData.get('name')?.toString()) {
      setShowRequiredError(true);
    } else {
      setIsSubmitting(true);
      segment.track('cash-flow-categories_subcategory_add_confirmed');

      const name = formData.get('name')?.toString() || '';
      setSubcategoryName(name);

      if (shouldShowConfirmationPopup) {
        setIsPopupOpen(true);
      } else {
        mutate(
          {
            categoryId,
            payload: {
              name,
              vat_rate: defaultVatRate,
            },
          },
          {
            onSuccess: response => {
              toastFlashMessages.toastInfo(
                formatMessage({ id: 'categories-management.create-subcategory.toast.success' })
              );
              navigateToCategoryDetails(response.id);
            },
            onSettled: () => {
              setIsSubmitting(false);
            },
          }
        );
      }
    }
  };

  const handleCancel = (): void => {
    navigateToType(currentType);
  };

  const handlePopupConfirm = (): void => {
    segment.track('cash-flow-categories_subcategory_move-to-other_confirmed');
    setIsPopupOpen(false);

    mutate(
      {
        categoryId,
        payload: {
          name: subcategoryName,
          vat_rate: defaultVatRate,
        },
      },
      {
        onSuccess: response => {
          toastFlashMessages.toastInfo(
            formatMessage({
              id: 'categories-management.create-subcategory.modal.success.toast',
            })
          );
          navigateToCategoryDetails(response.id);
        },
        onSettled: () => {
          setIsSubmitting(false);
        },
      }
    );
  };

  const handlePopupCancel = (): void => {
    segment.track('cash-flow-categories_subcategory_move-to-other_canceled');
    setIsSubmitting(false);
    setIsPopupOpen(false);
    navigateToType(currentType);
  };

  const handleRemoveError = (): void => {
    setShowRequiredError(false);
  };

  if (!shouldShow || isPending) return null;

  return (
    <div
      className={cx(
        styles['subcategory-container'],
        showRequiredError ? styles['container-with-error'] : ''
      )}
      data-testid="subcategory-item-new"
      ref={textFieldRef}
    >
      <CategoryIcon avatarSize={32} color={category.colorKey} icon={null} />
      <NewItemForm
        handleCancel={handleCancel}
        handleRemoveError={handleRemoveError}
        handleSubmit={handleSubmit}
        isSubcategory
        isSubmitting={isSubmitting}
        showRequiredError={showRequiredError}
      />
      <ConfirmFirstSubcategoryCreationPopup
        isOpen={isPopupOpen}
        onPopupCancel={handlePopupCancel}
        onPopupConfirm={handlePopupConfirm}
        parentCategory={category}
      />
    </div>
  );
}
