import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function StopXS(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" {...props}>
      <rect width={16} height={16} x={0.002} fill="#fff" rx={8} />
      <path fill="#F95656" d="M.002 8a8 8 0 1 1 16 0 8 8 0 0 1-16 0Z" />
      <path
        fill="#fff"
        d="M8.002 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm3-4c0-.622-.196-1.228-.56-1.733L6.269 10.44A2.993 2.993 0 0 0 11.002 8Zm-6 0c0 .622.195 1.228.559 1.733L9.736 5.56A2.993 2.993 0 0 0 5.002 8Z"
      />
    </svg>
  );
}
