import React, { useEffect, useState, type ReactNode } from 'react';
import { Popup } from '@repo/design-system-kit';
import { useEmberService, useFlags, useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import ENV from 'qonto/config/environment';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import type Organization from 'qonto/models/organization';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import styles from './promotional-popup.strict-module.css';

export const AR_PROMO_POPUP_STORAGE_KEY = 'ar-promo-popup-dismissed';

export const ILLUSTRATION_VARIANTS: IllustrationVariants = {
  de: {
    base: '/illustrations/ar-promotional-popup/illustration-de.png',
    higher: '/illustrations/ar-promotional-popup/illustration-de@2x.png',
  },
  en: {
    base: '/illustrations/ar-promotional-popup/illustration-en.png',
    higher: '/illustrations/ar-promotional-popup/illustration-en@2x.png',
  },
  es: {
    base: '/illustrations/ar-promotional-popup/illustration-es.png',
    higher: '/illustrations/ar-promotional-popup/illustration-es@2x.png',
  },
  fr: {
    base: '/illustrations/ar-promotional-popup/illustration-fr.png',
    higher: '/illustrations/ar-promotional-popup/illustration-fr@2x.png',
  },
  it: {
    base: '/illustrations/ar-promotional-popup/illustration-it.png',
    higher: '/illustrations/ar-promotional-popup/illustration-it@2x.png',
  },
  pt: {
    base: '/illustrations/ar-promotional-popup/illustration-pt.png',
    higher: '/illustrations/ar-promotional-popup/illustration-pt@2x.png',
  },
};

const isTest = ENV.environment === 'test';

const popupDelay = isTest ? 0 : 2000;

interface CopyKeys {
  title: Record<string, string>;
  list: Record<string, string[]>;
}

interface IllustrationVariant {
  base: string;
  higher: string;
}

type IllustrationVariants = Record<string, IllustrationVariant>;

interface OrganizationManager {
  organization: Organization | null;
}

export function ArPromotionalPopup(): ReactNode {
  const [isOpen, setIsOpen] = useState(false);
  const flags = useFlags();
  const segment = useEmberService('segment');
  const { formatMessage, locale } = useIntl();
  const { organization } = useOrganizationManager() as OrganizationManager;
  const router = useRouter();

  const legalCountry = organization?.legalCountry as string;

  useEffect(() => {
    if (safeLocalStorage.getItem(AR_PROMO_POPUP_STORAGE_KEY) === 'true') {
      return;
    }

    const timeout = setTimeout(() => {
      setIsOpen(true);
      segment.track('promotional-pop-up_displayed', { feature: 'ar_discoverability' });
    }, popupDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [segment]);

  const handleCancel = (): void => {
    segment.track('promotional-pop-up_closed', { feature: 'ar_discoverability' });

    safeLocalStorage.setItem(AR_PROMO_POPUP_STORAGE_KEY, 'true');

    setIsOpen(false);
  };

  const handleConfirm = (): void => {
    safeLocalStorage.setItem(AR_PROMO_POPUP_STORAGE_KEY, 'true');

    if (flags.experimentRolloutInvoicePromoPopupRedirect) {
      segment.track('promotional-pop-up_clicked', {
        feature: 'ar_discoverability',
        experiment: 'invoice_creation',
      });
      void router.push(
        `/organizations/${organization?.slug}/flows/account-receivable-onboarding/summary`
      );
      return;
    }

    segment.track('promotional-pop-up_clicked', {
      feature: 'ar_discoverability',
      experiment: 'empty_state',
    });
    void router.push(`/organizations/${organization?.slug}/receivable-invoices?status=draft`);
  };

  const copyKeys: CopyKeys = {
    title: {
      FR: formatMessage({ id: 'ar-fr.pop-up.title' }),
      DE: formatMessage({ id: 'ar-de.pop-up.title' }),
      ES: formatMessage({ id: 'ar-es.pop-up.title' }),
    },
    list: {
      FR: [
        formatMessage({ id: 'ar-fr.pop-up.list-point-1' }),
        formatMessage({ id: 'ar-fr.pop-up.list-point-2' }),
        formatMessage({ id: 'ar-fr.pop-up.list-point-3' }),
      ],
      DE: [
        formatMessage({ id: 'ar-de.pop-up.list-point-1' }),
        formatMessage({ id: 'ar-de.pop-up.list-point-2' }),
        formatMessage({ id: 'ar-de.pop-up.list-point-3' }),
      ],
      ES: [
        formatMessage({ id: 'ar-es.pop-up.list-point-1' }),
        formatMessage({ id: 'ar-es.pop-up.list-point-2' }),
        formatMessage({ id: 'ar-es.pop-up.list-point-3' }),
      ],
    },
  };

  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <main> component)
  const TEST_PORTAL_CONTAINER =
    document.getElementById('ember-testing') ?? document.querySelector('main') ?? undefined;

  return (
    <Popup
      UNSTABLE_portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
      badge={{
        type: 'activate',
        text: formatMessage({ id: 'promotion-system.badge.included-in-subscription.label' }),
      }}
      className={styles.popup}
      confirmText={formatMessage({ id: 'ar.pop-up.cta' })}
      data-test-id="ar-promotional-popup"
      illustration={
        <img
          alt=""
          data-test-id="ar-promotional-popup-illustration"
          src={ILLUSTRATION_VARIANTS[locale]?.base ?? ILLUSTRATION_VARIANTS.en?.base}
          srcSet={`${ILLUSTRATION_VARIANTS[locale]?.higher ?? ILLUSTRATION_VARIANTS.en?.higher} 2x`}
        />
      }
      isOpen={isOpen}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      title={formatMessage({ id: copyKeys.title[legalCountry] })}
      type="promotional"
    >
      <ul>
        {copyKeys.list[legalCountry]?.map((item, index) => (
          <li data-test-id={`ar-promotional-popup-list-point-${index + 1}`} key={item}>
            {item}
          </li>
        ))}
      </ul>
    </Popup>
  );
}
