import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function Euro(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M5.686 5.692a4.558 4.558 0 0 0-.391 1.212h-.732v.758h.63v.631h-.63v.745h.707c.075.493.176.91.328 1.25.164.392.379.708.631.96.253.253.543.455.884.606.354.152.745.215 1.162.215.518 0 .972-.101 1.376-.316a2.56 2.56 0 0 0 .973-.896l-.594-.543s-.063-.063-.088-.063a.367.367 0 0 0-.114-.026.282.282 0 0 0-.215.114l-.252.24a1.372 1.372 0 0 1-.379.227 1.527 1.527 0 0 1-.631.114 1.43 1.43 0 0 1-1.086-.455c-.29-.303-.48-.783-.581-1.414h1.894a.362.362 0 0 0 .265-.101c.076-.076.114-.152.114-.227v-.417H6.633v-.619h2.488a.371.371 0 0 0 .278-.113.316.316 0 0 0 .1-.228V6.93H6.748c.126-.581.328-1.023.631-1.314.29-.29.657-.442 1.086-.442.165 0 .303.013.417.038.126.038.24.076.303.114.076.038.152.088.215.139l.177.139.126.113s.088.038.139.038a.287.287 0 0 0 .164-.038.379.379 0 0 0 .126-.113l.467-.518a2.85 2.85 0 0 0-.896-.796c-.354-.202-.796-.29-1.3-.29-.38 0-.746.063-1.087.202-.34.126-.631.328-.896.58-.253.253-.543.544-.733.91Z"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8 7A7 7 0 1 0 8 1a7 7 0 0 0 0 14Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
