import { useState } from 'react';
import type { Label } from 'qonto/react/models/label';

export const useLabelFilter = (
  list: Label[]
): {
  filteredLabels: Label[];
  filter: (value: string) => void;
  resetFilter: () => void;
} => {
  const [filteredLabels, setFilteredLabels] = useState(list);

  const filter = (value: string): void => {
    if (value === '') {
      resetFilter();
    } else {
      setFilteredLabels(list.filter(l => l.name.toLowerCase().includes(value.toLowerCase())));
    }
  };

  const resetFilter = (): void => {
    setFilteredLabels(list);
  };

  return { filteredLabels, filter, resetFilter };
};
