import cx from 'clsx';
import type { ButtonHTMLAttributes, ReactElement } from 'react';
import React, { isValidElement } from 'react';
import { IconChevronRightOutlined } from '@repo/design-system-kit/assets/icons';
import styles from './styles.strict-module.css';

export interface SelectorProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'title' | 'type' | 'content' | 'subtitle'> {
  icon?: ReactElement;
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
  content?: string | ReactElement;
}

/**
 * This component is an incomplete implementation of the Qonto Selector component.
 * Though it adheres to the Qonto Selector API, it is not a full implementation.
 * Dedicated to the Account Receivable flow. It handles only the classic variant and does not implement the pending & error status
 */
export function Selector({
  disabled = false,
  className,
  icon,
  title,
  subtitle,
  content,
  onClick,
  ...props
}: SelectorProps): ReactElement {
  return (
    <button
      className={cx(styles.selector, styles.classic, disabled && styles.disabled, className)}
      disabled={disabled}
      onClick={onClick}
      type="button"
      {...props}
      data-test-selector
    >
      {icon ? (
        <div className={styles.icon} data-test-selector-icon>
          {icon}
        </div>
      ) : null}
      <div className={styles.header}>
        {title ? (
          <span className={cx('body-1', styles.title)} data-test-selector-title>
            {title}
          </span>
        ) : null}
        {subtitle ? (
          <span className={cx('body-2', styles.subtitle)} data-test-selector-subtitle>
            {subtitle}
          </span>
        ) : null}
      </div>
      {content ? (
        <div className={cx(styles.content, 'ml-24')} data-test-selector-content>
          {isValidElement(content) ? content : <span className="body-2">{content}</span>}
        </div>
      ) : null}
      <div className={styles['action-icon']}>
        <IconChevronRightOutlined />
      </div>
    </button>
  );
}
