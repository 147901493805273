import dayjs from 'dayjs';
import type {
  IntervalLabelListCashflow,
  IntervalUnlabeledCashflow,
  LabelCashflow,
  LabelData,
  LabelListCashflow,
  LabelListData,
} from '../api/labels';
import type { AggregateAmounts, IntervalStatistics } from '../api/statistics';

const sumAmounts = (
  amount1: AggregateAmounts,
  amount2: AggregateAmounts,
  isDebit: boolean
): AggregateAmounts => {
  const debitMultiplier = isDebit ? -1 : 1;
  return {
    amount_count: amount1.amount_count + debitMultiplier * amount2.amount_count,
    amount_sum: (
      parseFloat(amount1.amount_sum) +
      debitMultiplier * parseFloat(amount2.amount_sum)
    ).toFixed(2),
  };
};

const sortIntervals = <T extends IntervalStatistics<LabelListData | AggregateAmounts>>(
  intervals: T[]
): T[] => {
  return intervals.sort((a, b) => dayjs(a.start_date).unix() - dayjs(b.start_date).unix());
};

export const sumLabelListCashflowSides = (
  credits: IntervalLabelListCashflow[],
  debits: IntervalLabelListCashflow[]
): IntervalLabelListCashflow[] => {
  const mergeLabelData = (data1: LabelData, data2: LabelData, isDebit: boolean): LabelData => {
    const labelsGroupMap = new Map<string, LabelCashflow>();

    data1.labels_group.forEach(labelCashflow => {
      labelsGroupMap.set(labelCashflow.key, labelCashflow);
    });

    data2.labels_group.forEach(labelCashflow => {
      if (labelsGroupMap.has(labelCashflow.key)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- key is set
        const existing = labelsGroupMap.get(labelCashflow.key)!;
        labelsGroupMap.set(labelCashflow.key, {
          key: labelCashflow.key,
          data: sumAmounts(existing.data, labelCashflow.data, isDebit),
        });
      } else {
        labelsGroupMap.set(labelCashflow.key, {
          key: labelCashflow.key,
          data: sumAmounts({ amount_count: 0, amount_sum: '0' }, labelCashflow.data, isDebit),
        });
      }
    });

    return { labels_group: Array.from(labelsGroupMap.values()) };
  };

  const mergeLabelListData = (
    data1: LabelListData,
    data2: LabelListData,
    isDebit: boolean
  ): LabelListData => {
    const labelListsGroupMap = new Map<string, LabelListCashflow>();

    data1.label_lists_group.forEach(labelListCashflow => {
      labelListsGroupMap.set(labelListCashflow.key, labelListCashflow);
    });

    data2.label_lists_group.forEach(labelListCashflow => {
      if (labelListsGroupMap.has(labelListCashflow.key)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- key is set
        const existing = labelListsGroupMap.get(labelListCashflow.key)!;
        labelListsGroupMap.set(labelListCashflow.key, {
          key: labelListCashflow.key,
          data: mergeLabelData(existing.data, labelListCashflow.data, isDebit),
        });
      } else {
        labelListsGroupMap.set(labelListCashflow.key, {
          key: labelListCashflow.key,
          data: mergeLabelData({ labels_group: [] }, labelListCashflow.data, isDebit),
        });
      }
    });

    return { label_lists_group: Array.from(labelListsGroupMap.values()) };
  };

  const mergeIntervalLabelListCashflow = (
    interval1: IntervalLabelListCashflow,
    interval2: IntervalLabelListCashflow,
    isDebit: boolean
  ): IntervalLabelListCashflow => {
    return {
      start_date: interval1.start_date,
      end_date: interval1.end_date,
      data: mergeLabelListData(interval1.data, interval2.data, isDebit),
    };
  };

  const result: IntervalLabelListCashflow[] = [];

  const creditsMap = new Map<number, IntervalLabelListCashflow>();
  credits.forEach(interval => creditsMap.set(interval.start_date, interval));

  debits.forEach(debitInterval => {
    if (creditsMap.has(debitInterval.start_date)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- key is set
      const creditInterval = creditsMap.get(debitInterval.start_date)!;
      result.push(mergeIntervalLabelListCashflow(creditInterval, debitInterval, true));
      creditsMap.delete(debitInterval.start_date);
    } else {
      result.push({
        ...debitInterval,
        data: mergeLabelListData({ label_lists_group: [] }, debitInterval.data, true),
      });
    }
  });

  creditsMap.forEach(creditInterval => result.push(creditInterval));

  return sortIntervals<IntervalLabelListCashflow>(result);
};

export const sumUnlabeledCashflowSides = (
  credits: IntervalUnlabeledCashflow[],
  debits: IntervalUnlabeledCashflow[]
): IntervalUnlabeledCashflow[] => {
  const mergeIntervalUnlabeledCashflow = (
    interval1: IntervalUnlabeledCashflow,
    interval2: IntervalUnlabeledCashflow,
    isDebit: boolean
  ): IntervalUnlabeledCashflow => {
    return {
      start_date: interval1.start_date,
      end_date: interval1.end_date,
      data: sumAmounts(interval1.data, interval2.data, isDebit),
    };
  };

  const result: IntervalUnlabeledCashflow[] = [];

  const creditsMap = new Map<number, IntervalUnlabeledCashflow>();
  credits.forEach(interval => creditsMap.set(interval.start_date, interval));

  debits.forEach(debitInterval => {
    if (creditsMap.has(debitInterval.start_date)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- key is set
      const creditInterval = creditsMap.get(debitInterval.start_date)!;
      result.push(mergeIntervalUnlabeledCashflow(creditInterval, debitInterval, true));
      creditsMap.delete(debitInterval.start_date);
    } else {
      result.push({
        ...debitInterval,
        data: sumAmounts({ amount_count: 0, amount_sum: '0' }, debitInterval.data, true),
      });
    }
  });

  creditsMap.forEach(creditInterval => result.push(creditInterval));

  return sortIntervals<IntervalUnlabeledCashflow>(result);
};
