import { TextField } from '@repo/design-system-kit';
import React, { useState, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import styles from './category-details-form.strict-module.css';

interface NameFieldProps {
  categoryName: string;
  isParentCategory: boolean;
  onTitleChange: (value: string) => void;
}
export function NameField({
  categoryName,
  onTitleChange,
  isParentCategory,
}: NameFieldProps): ReactNode {
  const { formatMessage } = useIntl();
  const placeholder = isParentCategory
    ? formatMessage({ id: 'categories-management.create-category.placeholder' })
    : formatMessage({ id: 'categories-management.create-subcategory.placeholder' });
  const errorMessage = isParentCategory
    ? formatMessage({ id: 'categories-management.create-category.form.error.field-required' })
    : formatMessage({ id: 'categories-management.create-subcategory.form.error.field-required' });

  const [isError, setIsError] = useState(false);
  const handleChange = (value: string): void => {
    setIsError(false);
    if (value.length === 0) {
      setIsError(true);
    } else {
      onTitleChange(value);
    }
  };

  return (
    <TextField
      aria-label={placeholder}
      className={styles['name-field']}
      data-testid="category-details-name-field"
      defaultValue={categoryName}
      errorMessage={isError ? errorMessage : undefined}
      label={formatMessage({ id: 'categories-management.create-category.title.label' })}
      name="name"
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
}
