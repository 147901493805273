import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function LetterOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 16 16"
      height={16}
      width={16}
      {...props}
    >
      <path d="M15.94 3.17c-.12-.34-.42-.6-.79-.66L.85 2.5a1.042 1.042 0 0 0-.85 1v9c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-9a.88.88 0 0 0-.06-.33zm-2.11.33L8 7.88 2.17 3.5h11.66zm1.17 9H1V3.88L7.7 8.9c.09.07.19.1.3.1.11 0 .21-.03.3-.1L15 3.88v8.62z" />
    </svg>
  );
}
