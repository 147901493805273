import { Popup } from '@repo/design-system-kit';
import { useEffect, useState, type ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import window from 'ember-window-mock';
import ENV from 'qonto/config/environment';
import { setLastShownTimestamp, shouldShowModal } from 'qonto/utils/regate-modal';
import { regateOauthURL } from 'qonto/constants/hosts';
import { importAsset } from 'qonto/helpers/import-asset';
import styles from './styles.strict-module.css';

const isTest = ENV.environment === 'test';

export function RegatePopup(): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (shouldShowModal()) {
      segment.track('accounting-hub-regate-modal-shown');
      setIsOpen(true);
      setLastShownTimestamp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- No need to include segment in the dependencies
  }, []);

  const handleConfirm = (): void => {
    segment.track('accounting-hub-regate-modal-regate-flow-started');
    window.location.href = regateOauthURL as string;
  };

  const handleCancel = (): void => {
    setIsOpen(false);
  };

  const handleBookDemoClick = (): void => {
    segment.track('accounting-hub-regate-modal-demo-button-clicked');
    window.open('https://www.regate.io/demande-demo/qonto', '_blank');
  };

  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <main> component)
  const TEST_PORTAL_CONTAINER = document.querySelector('main') ?? undefined;

  return (
    <Popup
      UNSTABLE_portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
      cancelText={formatMessage({
        id: 'redirect-accountants-to-regate.accounting-hub.promotional-modal.book-a-demo-cta',
      })}
      className={styles.popup}
      confirmText={formatMessage({
        id: 'redirect-accountants-to-regate.accounting-hub.promotional-modal.regate-flow-cta',
      })}
      isOpen={isOpen}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      onCustomCancelCta={handleBookDemoClick}
      title={formatMessage({
        id: 'redirect-accountants-to-regate.accounting-hub.promotional-modal.title',
      })}
      type="promotional"
    >
      {/* eslint-disable-next-line jsx-a11y/media-has-caption -- not available */}
      <video className={styles.video} controls>
        <source
          src={importAsset(['/videos/regate/regate-for-accountants-fr.webm'])}
          type="video/webm;codecs=vp8, vorbis"
        />
      </video>

      <div className={styles.listHeader}>
        <FormattedMessage id="redirect-accountants-to-regate.accounting-hub.promotional-modal.list-header" />
      </div>
      <ul>
        <li data-test-id="redirect-to-regate-popup-list-item-1">
          <FormattedMessage id="redirect-accountants-to-regate.accounting-hub.promotional-modal.bullet-1" />
        </li>
        <li data-test-id="redirect-to-regate-popup-list-item-2">
          <FormattedMessage id="redirect-accountants-to-regate.accounting-hub.promotional-modal.bullet-2" />
        </li>
        <li data-test-id="redirect-to-regate-popup-list-item-3">
          <FormattedMessage id="redirect-accountants-to-regate.accounting-hub.promotional-modal.bullet-3" />
        </li>
      </ul>
    </Popup>
  );
}
