import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function CheckmarkOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="M5.49 13.495a.467.467 0 01-.349-.15L.156 8.365c-.2-.2-.21-.53-.02-.72.19-.19.51-.2.71 0l4.635 4.65 9.66-9.65c.2-.2.51-.2.71 0 .199.2.199.51 0 .71l-10.01 10a.51.51 0 01-.35.15v-.01z" />
    </svg>
  );
}
