import { type ReactNode } from 'react';
import { LottiePlayer } from '@repo/design-system-kit';
import { clsx } from 'clsx';
import { FormattedMessage } from 'react-intl';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import styles from './styles.strict-module.css';

export function ProcessingScreen(): ReactNode {
  return (
    <div className={styles.wrapper}>
      <LottiePlayer
        className={clsx(styles['illustration-processing'], 'mb-16')}
        data-test-nrc-payments-processing-illustration
        path={useThemedAssetPath('/lotties/nrc-payments/result-processing.json')}
      />

      <h1 className={clsx(styles.title, 'mb-8', 'title-2')} data-test-nrc-payments-processing-title>
        <FormattedMessage id="aeat.pay.review.processing.title" />
      </h1>

      <p
        className={clsx(styles.subtitle, 'body-2 mb-32')}
        data-test-nrc-payments-processing-subtitle
      >
        <FormattedMessage id="aeat.pay.review.processing.subtitle" />
      </p>
    </div>
  );
}
