import { useEffect, useMemo, useRef, type ComponentPropsWithoutRef, type ReactNode } from 'react';
import { useTernaryDarkMode } from 'usehooks-ts';
// @ts-expect-error -- TODO remove this when ui-kit constants are typed
import { APPEARANCE_KEY } from '@qonto/ui-kit/constants/appearance-setting';

type ThemedAssetProps = {
  assetPath: string;
  type?: 'image' | 'video';
  playOnMouseOver?: boolean;
  posterPath?: string;
} & (
  | ({ type?: 'image' } & Omit<ComponentPropsWithoutRef<'img'>, 'src'>)
  | ({ type: 'video' } & Omit<ComponentPropsWithoutRef<'video'>, 'src'>)
);

const reqWithContext = require.context('../../../static/', true, /\.[a-z]+$/) as (
  path: string
) => string;

const getAssetModulePath = (path: string): string | null => {
  try {
    return reqWithContext(`./${path.replace(/^\//, '')}`);
  } catch {
    return null;
  }
};

const getThemedAssetPath = (assetPath: string, isDarkMode: boolean): string => {
  const themedIconPath = isDarkMode
    ? assetPath.replace(/(?<extension>\.[a-z]+)$/, '-dark$1')
    : assetPath;
  let modulePath = getAssetModulePath(themedIconPath);

  if (isDarkMode && !modulePath) {
    modulePath = getAssetModulePath(assetPath);
  }

  if (!modulePath) {
    throw new Error(`Could not find asset for path: ${assetPath}`);
  }

  return modulePath;
};

export function StaticThemedAsset({
  assetPath,
  type = 'image',
  playOnMouseOver = false,
  posterPath,
  ...props
}: ThemedAssetProps): ReactNode {
  const videoRef = useRef<HTMLVideoElement>(null);

  const { isDarkMode } = useTernaryDarkMode({
    localStorageKey: APPEARANCE_KEY as string,
    initializeWithValue: true,
  });

  const assetModulePath = useMemo(() => {
    return getThemedAssetPath(assetPath, isDarkMode);
  }, [isDarkMode, assetPath]);

  const posterModulePath = useMemo(() => {
    return posterPath ? getThemedAssetPath(posterPath, isDarkMode) : undefined;
  }, [isDarkMode, posterPath]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video || !playOnMouseOver) return;

    video.muted = true;

    const handleMouseOver = (): void => {
      video.play().catch(() => {
        // Handle any autoplay errors silently
        // This can happen due to browser policies
      });
    };

    video.addEventListener('mouseover', handleMouseOver);

    return () => {
      video.removeEventListener('mouseover', handleMouseOver);
    };
  }, [playOnMouseOver]);

  return type === 'image' ? (
    <img
      alt=""
      data-test-themed-asset
      src={assetModulePath}
      {...(props as ComponentPropsWithoutRef<'img'>)}
    />
  ) : (
    // eslint-disable-next-line jsx-a11y/media-has-caption -- no audio
    <video
      data-test-themed-asset
      playsInline
      poster={posterModulePath}
      ref={videoRef}
      {...(props as ComponentPropsWithoutRef<'video'>)}
    >
      <source src={assetModulePath} type="video/webm;codecs=vp8, vorbis" />
    </video>
  );
}
