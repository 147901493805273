import React, { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface SlugProps {
  transaction: {
    slug: string;
  };
}

export function Slug({ transaction }: SlugProps): ReactElement {
  const { slug: transactionSlug } = transaction;

  return (
    <section className={cx('caption', styles.container)} data-test-slug>
      <p>
        <FormattedMessage
          id="transactions.sidebar.slug.transaction-slug"
          values={{ transactionSlug }}
        />
      </p>
    </section>
  );
}
