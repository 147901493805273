import type { ReactElement } from 'react';
import cx from 'clsx';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

interface PlaceholderRowProps {
  isSidepanelOpen?: boolean;
  isTableHeader?: boolean;
  className?: string;
}

export function TransactionTablePlaceholder({
  isSidepanelOpen,
  isTableHeader,
  className,
  ...props
}: PlaceholderRowProps): ReactElement {
  return (
    <tr className={cx(isTableHeader ? styles['table-header'] : styles.row, className)} {...props}>
      <td aria-hidden="true" className={styles.empty} />

      <td
        className={cx(styles.col, isSidepanelOpen ? styles['col-12'] : styles['col-9'])}
        data-test-first-col
      >
        {isTableHeader ? (
          <div className={styles.header}>
            <SkeletonLoader.Header smallLinesWidth="70px" width="118px" />
          </div>
        ) : (
          <div className={styles.header}>
            <SkeletonLoader.Avatar className={styles.block} height="32px" width="32px" />
            <SkeletonLoader.Header lines={1} smallLinesWidth="70px" width="118px" />
          </div>
        )}
      </td>

      <td
        className={cx(styles.col, isSidepanelOpen ? styles.hide : styles['col-5'])}
        data-test-second-col
      >
        <SkeletonLoader.Line width="62px" />
      </td>

      <td className={styles.col}>
        <SkeletonLoader.Line width="104px" />
      </td>

      <td className={styles.col} data-test-receipt-column-placeholder>
        <SkeletonLoader.Line width="66px" />
      </td>

      <td align="right" className={cx(styles.col, styles['col-4'])}>
        <SkeletonLoader.Line width="76px" />
      </td>
    </tr>
  );
}
