import type { ComponentPropsWithRef, ReactNode } from 'react';

export function LightningOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M13.9149 32.0024C13.6949 32.0024 13.4749 31.9624 13.2749 31.8824C12.4749 31.6024 11.9949 30.8424 12.0349 30.0024L12.7749 18.3224H6.07487C5.37487 18.3224 4.73487 17.9424 4.41487 17.3224C4.09487 16.7024 4.13487 15.9624 4.53487 15.3824L14.7349 0.802356C15.2149 0.122356 16.0549 -0.157644 16.8349 0.0823556C17.6149 0.322356 18.1549 1.04236 18.1549 1.86236V11.7624H25.9149C26.6149 11.7624 27.2549 12.1624 27.5949 12.7824C27.9149 13.4224 27.8549 14.1624 27.4349 14.7424L15.4349 31.2224C15.0749 31.7224 14.5149 32.0024 13.9349 32.0024H13.9149ZM13.7749 16.4424C14.0349 16.4424 14.2749 16.5424 14.4549 16.7424C14.6349 16.9224 14.7349 17.1824 14.7149 17.4424L14.6549 18.6824L13.9349 30.1424L25.9149 13.6624H17.2149C16.6949 13.6624 16.2749 13.2624 16.2749 12.7424V1.88236L6.07487 16.4624L13.7749 16.4424Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
