import type { ReactNode } from 'react';
import { Button, Tooltip } from '@repo/design-system-kit';
import cx from 'clsx';
import styles from './page-header.strict-module.css';

export interface CreateCategoryButtonProps {
  buttonCtaText: string;
  handleClick: () => void;
  hasReachedLimit: boolean;
  toolTipText: string;
}

export function CreateCategoryButton({
  buttonCtaText,
  handleClick,
  hasReachedLimit,
  toolTipText,
}: CreateCategoryButtonProps): ReactNode {
  return (
    <>
      {hasReachedLimit ? (
        <Tooltip
          className={styles.tooltip}
          data-testid="add-category-tooltip"
          label={toolTipText}
          placement="bottom"
        >
          <div aria-disabled="true" data-testid="add-category-tooltip-wrapper">
            <Button
              className={cx(styles.btn, styles['btn-disabled'], hasReachedLimit)}
              data-testid="add-category-button-with-tooltip"
              size="medium"
              variant="primary"
            >
              {buttonCtaText}
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button
          className={styles.btn}
          data-testid="add-category-button"
          onPress={handleClick}
          size="medium"
          variant="primary"
        >
          {buttonCtaText}
        </Button>
      )}
    </>
  );
}
