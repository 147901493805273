import { LottiePlayer } from '@repo/design-system-kit';
import { FormattedMessage } from 'react-intl';
import { type ReactNode } from 'react';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import styles from './styles.strict-module.css';

export function BudgetDetailsError({ retry }: { retry: () => null }): ReactNode {
  return (
    <div className={styles['budget-details-error']}>
      <LottiePlayer
        className={`mb-32 ${styles.illustration ?? ''}`}
        data-test-budget-details-error-state-illustration=""
        path={useThemedAssetPath('/lotties/error-404.json')}
      />
      <h2 className="title-2 mb-16 title" data-test-budget-details-error-state-title>
        <FormattedMessage id="team-budgets.detail.error.title" />
      </h2>
      <p className="body-2 mb-32 subtitle" data-test-budget-details-error-state-subtitle>
        <FormattedMessage id="team-budgets.detail.error.subtitle" />
      </p>
      <button
        className="btn btn--primary"
        data-test-budget-details-error-state-cta
        onClick={retry}
        type="button"
      >
        <FormattedMessage id="team-budgets.detail.error.cta" />
      </button>
    </div>
  );
}
