import { type ReactElement } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

export function ApprovalWorkflowTablePlaceholder(): ReactElement {
  return (
    <tr className={styles.row}>
      <td aria-hidden="true" />
      <td className={styles.cell}>
        <div className={styles.cellContent}>
          <SkeletonLoader.Avatar className="mr-16" height="32px" width="32px" />
          <SkeletonLoader.Line standalone width="70%" />
        </div>
      </td>
      <td className={styles.cell}>
        <SkeletonLoader.Line standalone width="20%" />
      </td>
      <td className={styles.cell}>
        <SkeletonLoader.Line standalone width="45%" />
      </td>
      <td aria-hidden="true" />
    </tr>
  );
}
