import type { ReactNode } from 'react';
import styles from './styles.strict-module.css';

interface NextStepProps {
  id: string;
  illustrationSrc: string;
  title: string;
  subTitle: string;
}

export function NextStep({
  id,
  title,
  subTitle,
  illustrationSrc,
  ...props
}: NextStepProps): ReactNode {
  const titleId = `next-step-card-title-${id}`;
  const descriptionId = `next-step-card-description-${id}`;

  return (
    <span
      aria-describedby={descriptionId}
      aria-labelledby={titleId}
      className={styles.container}
      {...props}
    >
      <img
        alt=""
        className={styles.illustration}
        data-test-invoices-import-clients-next-step-illustration
        src={illustrationSrc}
      />
      <span className={styles.content}>
        <span
          className={styles.title}
          data-test-invoices-import-clients-next-step-title
          id={titleId}
        >
          {title}
        </span>
        <span
          className={styles.subTitle}
          data-test-invoices-import-clients-next-step-subtitle
          id={descriptionId}
        >
          {subTitle}
        </span>
      </span>
    </span>
  );
}
