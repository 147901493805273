import { type ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl, type IntlShape } from 'react-intl';
import { AnimatePresence, motion } from 'framer-motion';
import type { TransactionModel } from 'qonto/react/models/transaction';
import { CategorizationSubtitleWidget } from 'qonto/react/components/transactions/table/labels/categorization/categorization-subtitle-widget';
import { CategorizationWidget } from 'qonto/react/components/transactions/table/labels/categorization/categorization-widget';
import type { MemorizeCategoriesRule } from 'qonto/react/models/memorize-category';

interface CashFlowCategoryMemorizeWidgetProps {
  transactions: TransactionModel[];
  categoryId: string | null;
  onGenerateRules: (rules: MemorizeCategoriesRule[]) => void;
}

export function CashFlowCategoryMemorizeWidget({
  transactions = [],
  categoryId,
  onGenerateRules,
}: CashFlowCategoryMemorizeWidgetProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');

  const uniqueMatchingCriteria = [
    ...new Map(
      transactions.map(({ counterpartyName, rawCounterpartyName }) => [
        `${counterpartyName}:${rawCounterpartyName}`,
        { counterpartyName, rawCounterpartyName },
      ])
    ).values(),
  ];

  const uniqueCounterpartiesNames = [
    ...new Set(
      uniqueMatchingCriteria.map(
        criteria => criteria.counterpartyName || criteria.rawCounterpartyName
      )
    ),
  ];

  const hasMultipleCounterparties = uniqueCounterpartiesNames.length > 1;
  const remainingCounterparties = uniqueCounterpartiesNames.slice(2);

  const trackGenerateRules = (includePast: boolean): void => {
    segment.track('cashflow_category_generate_rules_clicked', {
      type: includePast ? 'past-future' : 'future',
      bulk_selection: transactions.length > 1,
      number_of_counterparties: uniqueMatchingCriteria.length,
    });
  };

  const handleGenerateRules = (includePast: boolean): void => {
    const categorizationRules = uniqueMatchingCriteria.map(matchingCriteria => ({
      categoryId,
      matchingCriteria,
      includePast,
    }));

    trackGenerateRules(includePast);
    onGenerateRules(categorizationRules);
  };

  const {
    title,
    categorizationSubtitle,
    bulkCounterpartyPlaceholder,
    remainingCounterpartiesString,
    pastToFutureCta,
    futureCta,
  } = getWidgetContent({
    handleGenerateRules,
    formatMessage,
    remainingCounterparties,
    uniqueCounterparties: uniqueCounterpartiesNames,
    hasMultipleCounterparties,
  });

  if (!transactions.length) return null;
  return (
    <AnimatePresence>
      <motion.div
        animate={{ opacity: 1, height: 'auto' }}
        initial={{ opacity: 0, height: 0.8 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
        <CategorizationWidget
          data-testid="cash-flow-category-memorize-widget"
          futureCta={futureCta}
          pastFutureCta={pastToFutureCta}
          subtitle={
            <CategorizationSubtitleWidget
              bulkCounterpartyPlaceholder={bulkCounterpartyPlaceholder}
              categorizationSubtitle={categorizationSubtitle}
              remainingCounterpartiesLength={remainingCounterparties.length}
              remainingCounterpartiesString={remainingCounterpartiesString}
              uniqueCounterparties={uniqueCounterpartiesNames}
            />
          }
          title={title}
        />
      </motion.div>
    </AnimatePresence>
  );
}

const getWidgetContent = ({
  formatMessage,
  handleGenerateRules,
  remainingCounterparties,
  uniqueCounterparties,
  hasMultipleCounterparties,
}: {
  handleGenerateRules: (includePast: boolean) => void;
  formatMessage: IntlShape['formatMessage'];
  remainingCounterparties: string[];
  uniqueCounterparties: string[];
  hasMultipleCounterparties: boolean;
}): {
  title: string;
  categorizationSubtitle: string;
  bulkCounterpartyPlaceholder: string;
  remainingCounterpartiesString: string;
  pastToFutureCta: {
    copy: string;
    action: (includePast: boolean) => void;
  };
  futureCta: {
    copy: string;
    action: (includePast: boolean) => void;
  };
} => {
  const getRemainingCounterpartiesString = (): string => {
    if (remainingCounterparties.length > 1) {
      return `${remainingCounterparties.slice(0, -1).join(', ')} ${formatMessage({ id: 'transactions.sidepanel.cash-flow.categories.auto-categorize-widget.tooltip' }, { counterparty_name: remainingCounterparties.at(-1) ?? '' })}`;
    }
    return remainingCounterparties[0] ?? '';
  };

  const title = formatMessage({
    id: 'transactions.sidepanel.cash-flow.categories.auto-categorize-widget.title',
  });
  const categorizationSubtitle = hasMultipleCounterparties
    ? formatMessage(
        {
          id: 'transactions.sidepanel.cash-flow.categories.auto-categorize-widget.subtitle.bulk.message',
        },
        {
          counterparty_name_one: uniqueCounterparties[0] ?? '',
          counterparty_name_two: uniqueCounterparties[1] ?? '',
        }
      )
    : formatMessage(
        {
          id: 'transactions.sidepanel.cash-flow.categories.auto-categorize-widget.subtitle.single',
        },
        {
          counterparty_name: uniqueCounterparties[0] ?? '',
        }
      );
  const bulkCounterpartyPlaceholder = formatMessage(
    {
      id: 'transactions.sidepanel.cash-flow.categories.auto-categorize-widget.subtitle.bulk.counterparty-placeholder',
    },
    { count: remainingCounterparties.length }
  );
  const pastToFutureCta = {
    copy: formatMessage({
      id: 'transactions.sidepanel.cash-flow.categories.auto-categorize-widget.button.past-future',
    }),
    action: handleGenerateRules,
  };
  const futureCta = {
    copy: formatMessage({
      id: 'transactions.sidepanel.cash-flow.categories.auto-categorize-widget.button.future',
    }),
    action: handleGenerateRules,
  };

  return {
    title,
    categorizationSubtitle,
    bulkCounterpartyPlaceholder,
    remainingCounterpartiesString: getRemainingCounterpartiesString(),
    pastToFutureCta,
    futureCta,
  };
};
