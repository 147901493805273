import { type ReactNode } from 'react';

export function Megaphone(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M14.14 5.946c.792.305 1.36 1.109 1.36 2.056 0 .947-.568 1.751-1.36 2.056m-7.755 1.38V4.563m-.612 10.21-1.5-3M13.736.5s-2.06 4.063-6.765 4.063H2.265C1.295 4.563.5 5.407.5 6.438v3.125c0 1.032.794 1.875 1.765 1.875H6.97c4.706 0 6.765 4.063 6.765 4.063V.5Z"
      />
    </svg>
  );
}
