import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function Card(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.667 5.999v5.333c0 .736-.597 1.333-1.333 1.333H2.667a1.333 1.333 0 0 1-1.333-1.333V4.665c0-.736.596-1.333 1.333-1.333h10.666c.737 0 1.334.597 1.334 1.333V6Zm0 0H4"
      />
    </svg>
  );
}
