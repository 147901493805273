import { FormattedMessage } from 'react-intl';
import type { ReactElement } from 'react';
import { EarlyRepaymentOption } from 'qonto/react/api/models/repayment-option';
import type { RepaymentOption } from 'qonto/react/api/models/repayment-option';
import { FullRepayment } from './internal/full-repayment';
import { NextInstallment } from './internal/next-installment';

export interface OptionSelectionProps {
  repaymentOptions: RepaymentOption[];
  onSelect: (option: EarlyRepaymentOption) => void;
}

export function OptionSelection({
  repaymentOptions,
  onSelect,
}: OptionSelectionProps): ReactElement {
  const fullRepaymentOption = repaymentOptions.find(
    option => option.earlyRepaymentOption === EarlyRepaymentOption.Full
  );
  const nextInstallmentOption = repaymentOptions.find(
    option => option.earlyRepaymentOption === EarlyRepaymentOption.Next
  );

  return (
    <>
      <h1 className="title-1 mb-32">
        <FormattedMessage id="financing.pay-later.early-repayment.options.title" />
      </h1>
      {fullRepaymentOption ? (
        <FullRepayment className="mb-16" onSelect={onSelect} option={fullRepaymentOption} />
      ) : null}
      {nextInstallmentOption ? (
        <NextInstallment onSelect={onSelect} option={nextInstallmentOption} />
      ) : null}
    </>
  );
}
