import { useCallback, type ReactNode } from 'react';
import { Button, Tooltip } from '@repo/design-system-kit';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { Status } from 'qonto/react/graphql';
import { StatusAvatar } from 'qonto/react/components/avatar/status-avatar';
import { StopXS } from 'qonto/react/assets/icons/stop-xs';
import { OpenLink } from 'qonto/react/assets/icons/open-link';
import { routeContextManager } from 'qonto/react/contexts/route-context';
import styles from './styles.strict-module.css';

export function TransactionCell(): ReactNode {
  const transaction = cellContextManager.useCellContext();
  const { counterpartyName: name, slug: id, enrichmentData, status } = transaction;
  const avatar = enrichmentData.logo.small;
  const isDeclined = status === Status.Declined || status === Status.Reversed;
  const { highlightItem } = routeContextManager.useRouteContext();

  const openSidePanel = useCallback(() => {
    // refetch transactions
    highlightItem(transaction.id);
  }, [highlightItem, transaction.id]);

  const subtitle = getSubtitle(id);
  const icon = avatar ? (
    <StatusAvatar
      alt={name}
      dataTestId="avatar"
      hasBorder
      icon={isDeclined ? <StopXS /> : undefined}
      src={avatar}
    />
  ) : (
    <StatusAvatar
      avatar="custom"
      dataTestId="custom-avatar"
      icon={isDeclined ? <StopXS /> : undefined}
      name={name}
    />
  );

  const sidePanelButton = (
    <Tooltip
      closeDelay={0}
      delay={300}
      label="Open transaction details"
      placement="top"
      tooltipTestId="tooltip"
    >
      <Button
        className={styles.button}
        data-testid="sidepanel-button"
        iconOnly
        onPress={openSidePanel}
        type="button"
        variant="tertiary"
      >
        <OpenLink />
      </Button>
    </Tooltip>
  );

  return (
    <BaseCell
      actionSlot={sidePanelButton}
      actionSlotAlignment="right"
      data-testid="transaction-cell"
    >
      <DataWithIconCell
        cellSubtitleClassName={isDeclined ? styles.declined : undefined}
        cellTitleClassName={isDeclined ? styles.declined : undefined}
        icon={icon}
        subtitle={subtitle}
        title={name}
      />
    </BaseCell>
  );
}

export function getSubtitle(slug: string): string {
  return `⋅⋅ ${slug.slice(-14).toUpperCase()}`;
}
