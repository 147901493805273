import { type ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { useCopyToClipboard } from 'usehooks-ts';
import { Button, Tooltip } from '@repo/design-system-kit';
import { Copy } from 'qonto/react/assets/icons/copy';
import styles from './styles.strict-module.css';

interface CopyButtonProps {
  text: string;
  className?: string;
}

export function CopyButton({ text, className }: CopyButtonProps): ReactNode {
  const { formatMessage } = useIntl();
  const [_copiedText, copy] = useCopyToClipboard();
  const toastFlashMessages = useEmberService('toast-flash-messages');

  const handleCopy = async (): Promise<void> => {
    try {
      await copy(text);
      toastFlashMessages.toastInfo(formatMessage({ id: 'toast.copy_to_clipboard.generic' }));
    } catch (error) {
      //
    }
  };

  const onClickHandler = (): void => {
    void handleCopy();
  };

  return (
    <Tooltip
      closeDelay={0}
      delay={300}
      label={formatMessage({ id: 'aria.copy_to_clipboard.generic' })}
      placement="top"
      tooltipTestId="tooltip"
    >
      <Button
        className={cx(styles.button, className)}
        data-testid="copy-button"
        iconOnly
        onPress={onClickHandler}
        type="button"
        variant="tertiary"
      >
        <Copy />
      </Button>
    </Tooltip>
  );
}
