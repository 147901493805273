import { type ReactNode } from 'react';
import { motion, AnimatePresence, type HTMLMotionProps } from 'framer-motion';
import ENV from 'qonto/config/environment';
import styles from './styles.strict-module.css';

interface SidePanelProps extends HTMLMotionProps<'aside'> {
  isVisible: boolean;
  children?: ReactNode;
}

const duration = ENV.environment === 'test' ? 0 : 0.5;

export function SidePanel({ children, isVisible = false, ...props }: SidePanelProps): ReactNode {
  return (
    <AnimatePresence>
      {isVisible ? (
        <motion.aside
          animate={{ x: '0%' }}
          className={styles['side-panel']}
          data-testid="side-panel"
          exit={{ x: '100%' }}
          initial={{ x: '100%' }}
          transition={{ duration, ease: [0.55, 0, 0.1, 1] }}
          {...props}
        >
          {children}
        </motion.aside>
      ) : null}
    </AnimatePresence>
  );
}
