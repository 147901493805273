import type { CamelCasedPropertiesDeep, JsonPrimitive } from 'type-fest';

const STRING_CAMELIZE_REGEXP = /(?<separator>-|_|\.|\s)+(?<char>.)?/g;
export const camelizeKey = (key: string): string =>
  key.replace(STRING_CAMELIZE_REGEXP, (_, __, char: string) => (char ? char.toUpperCase() : ''));

interface NestedObject {
  [key: string]: NestedObject | JsonPrimitive | NestedObject[] | JsonPrimitive[] | undefined;
}

function isNestedObject(value: unknown): value is NestedObject {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

function isNestedObjectArray(value: unknown): value is NestedObject[] {
  return Array.isArray(value) && value.every(isNestedObject);
}

function camelizeKeysArray<T>(array: T[]): CamelCasedPropertiesDeep<T>[] {
  return array.map(item => camelizeKeys(item)) as CamelCasedPropertiesDeep<T>[];
}

export function camelizeKeys<T>(obj: T): CamelCasedPropertiesDeep<T> {
  if (Array.isArray(obj)) {
    return camelizeKeysArray(obj) as CamelCasedPropertiesDeep<T>;
  }
  if (obj && typeof obj === 'object') {
    return Object.keys(obj).reduce<Record<string, unknown>>((acc, key) => {
      const value = (obj as Record<string, unknown>)[key];
      const camelizedKey = camelizeKey(key);
      if (isNestedObject(value)) {
        return { ...acc, [camelizedKey]: camelizeKeys(value) };
      }
      if (isNestedObjectArray(value)) {
        return { ...acc, [camelizedKey]: value.map(camelizeKeys) };
      }
      return { ...acc, [camelizedKey]: value };
    }, {}) as CamelCasedPropertiesDeep<T>;
  }
  return obj as CamelCasedPropertiesDeep<T>;
}
