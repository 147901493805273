import { Popup } from '@repo/design-system-kit';
import { useCallback, useEffect, useState, type ReactNode } from 'react';
import { useSegment } from '@repo/poly-hooks/segment';
import { useIntl } from 'react-intl';
import ENV from 'qonto/config/environment';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import type Organization from 'qonto/models/organization';
import styles from './styles.strict-module.css';

const FEATURE_NAME = 'bank-switch-move-recurring-payments';

interface MoveRecurringPaymentsPopupProps {
  bankName: string;
  onAfterConfirm?: () => void;
}

interface OrganizationManager {
  organization: Organization | null;
}

export function MoveRecurringPaymentsPopup({
  bankName,
  onAfterConfirm,
}: MoveRecurringPaymentsPopupProps): ReactNode {
  const isTest = ENV.environment === 'test';
  const popupDelay = isTest ? 0 : 2000;

  const [isOpen, setIsOpen] = useState(false);
  const segment = useSegment();
  const { formatMessage } = useIntl();
  const { organization } = useOrganizationManager() as OrganizationManager;

  const segmentEvent = useCallback(
    (action: 'clicked' | 'closed' | 'displayed'): void => {
      segment.track(`promotional-pop-up_${action}`, { feature: FEATURE_NAME });
    },
    [segment]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      segmentEvent('displayed');
      safeLocalStorage.setItem(`move-recurring-payments-popup-shown_${organization?.id}`, '1');
      setIsOpen(true);
    }, popupDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [segmentEvent, popupDelay, organization?.id]);

  const handleCancel = (): void => {
    segmentEvent('closed');
    setIsOpen(false);
  };

  const handleConfirm = (): void => {
    segmentEvent('clicked');
    setIsOpen(false);
    onAfterConfirm && onAfterConfirm();
  };

  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <main> component)
  const TEST_PORTAL_CONTAINER =
    document.getElementById('ember-testing') ?? document.querySelector('main') ?? undefined;

  return (
    <Popup
      UNSTABLE_portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
      className={styles.popup}
      confirmText={formatMessage({ id: 'accounts.pop-up.move-recurring-payments.cta' })}
      data-test-id="move-recurring-payments-popup"
      illustration={
        <img
          alt=""
          data-test-id="move-recurring-payments-popup-illustration"
          src="/illustrations/promotional-popups/move-recurring-payments-popup.png"
          srcSet="/illustrations/promotional-popups/move-recurring-payments-popup@2x.png"
        />
      }
      isOpen={isOpen}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      subtitle={formatMessage(
        { id: 'accounts.pop-up.move-recurring-payments.subtitle' },
        { bankName }
      )}
      title={formatMessage({ id: 'accounts.pop-up.move-recurring-payments.title' })}
      type="promotional"
    />
  );
}
