import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function ShareFilled(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.012 13.477c0 .537.341.684.76.33l5.912-5.16a.812.812 0 0 0 0-1.284l-5.912-5.17c-.42-.355-.76-.206-.76.33V6h-1C4.192 6 .925 9.166.129 12.586a5.91 5.91 0 0 0-.125.77c-.03.268.1.327.291.139 0 0 .054-.048.1-.1a11.46 11.46 0 0 1 7.62-3.4h1l-.003 3.483Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
