import React, { type ComponentPropsWithRef, type ReactNode } from 'react';

export function SearchOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M15.85 14.96 11.7 10.8a6.503 6.503 0 0 0 1.42-4.06c0-3.63-2.94-6.56-6.56-6.56C2.94.18 0 3.11 0 6.74c0 3.63 2.94 6.56 6.56 6.56 1.72 0 3.28-.66 4.45-1.75l4.13 4.12c.2.2.52.2.71 0 .2-.19.2-.51 0-.71Zm-9.29-2.67a5.55 5.55 0 1 1 5.55-5.55c0 3.07-2.48 5.55-5.55 5.55Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
