import { useEffect, useMemo, useState, type ReactNode } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  type ColumnDef,
  createColumnHelper,
  flexRender,
  type ExpandedState,
  type Header,
} from '@tanstack/react-table';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { ArrowRight } from 'qonto/react/assets/icons/arrow-right';
import { ArrowDown } from 'qonto/react/assets/icons/arrow-down';
import { IconCog } from 'qonto/react/assets/icons/cash-flow-categories/icon-cog';
import {
  generateLoadingCategoriesData,
  generateLoadingHeaders,
} from '../../utils/generate-loading-data';
import { TableCell } from '../shared/table-cell';
import type {
  CategoriesTableColumn,
  CategoriesTableRow,
} from '../../models/categories-table-display';
import { CategoryCell } from './category-cell';
import { BalanceCell } from './balance-cell';
import styles from './styles.strict-module.css';

export interface Amount {
  value: string;
  currency: string;
}

const buildTable = (
  data: CategoriesTableRow[],
  sums: CategoriesTableColumn[],
  isLoading: boolean,
  numberOfColumns: number,
  headerLabel: string
): {
  tableData: CategoriesTableRow[];
  columns: ColumnDef<CategoriesTableRow>[];
} => {
  const headersData = isLoading
    ? generateLoadingHeaders(numberOfColumns)
    : Array.from({ length: numberOfColumns }, (_, index) => String(index));

  const tableData = isLoading ? generateLoadingCategoriesData(numberOfColumns) : data;

  const columnHelper = createColumnHelper<CategoriesTableRow>();
  const columns = [
    columnHelper.accessor('name', {
      header: headerLabel,
      cell: info => (
        <CategoryCell
          canExpand={info.row.getCanExpand()}
          color={info.row.original.color}
          icon={info.row.original.icon}
          isExpanded={info.row.getIsExpanded()}
          name={info.getValue()}
          onExpandToggle={info.row.getToggleExpandedHandler()}
          type={info.row.original.type}
        />
      ),
    }),
    ...headersData.map((col, index) =>
      columnHelper.accessor('columns', {
        id: col,
        // Set period total amount when available
        header: () => {
          return <BalanceCell amount={sums[index]?.amount} />;
        },
        cell: info => {
          return <BalanceCell amount={info.getValue()[index]?.amount} />;
        },
      })
    ),
  ] as ColumnDef<CategoriesTableRow>[];

  return {
    tableData,
    columns,
  };
};

interface CategoriesTableProps {
  data: CategoriesTableRow[];
  sums: CategoriesTableColumn[];
  isLoading?: boolean;
  numberOfColumns?: number;
  headerLabelKey?: string;
}

export function CategoriesTable({
  data,
  sums,
  isLoading = false,
  numberOfColumns = 4,
  headerLabelKey,
  ...props
}: CategoriesTableProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');
  const router = useEmberService('router');
  const headerLabel = useMemo(
    () => formatMessage({ id: headerLabelKey }),
    [formatMessage, headerLabelKey]
  );

  const expandedTableLocalStorageKey = useMemo(
    () => `cashflow-categories-${headerLabel.toLowerCase()}-table`,
    [headerLabel]
  );

  const expandedRowsLocalStorageKey = useMemo(
    () => `cashflow-categories-${headerLabel.toLowerCase()}-table-rows`,
    [headerLabel]
  );

  const cachedExpandedTableState = localStorage.getItem(expandedTableLocalStorageKey);
  const cachedExpandedRowsState = localStorage.getItem(expandedRowsLocalStorageKey);

  const [isTableExpanded, setIsTableExpanded] = useState(
    cachedExpandedTableState ? (JSON.parse(cachedExpandedTableState) as boolean) : true
  );
  const [expandedRows, setExpandedRows] = useState<ExpandedState>(
    cachedExpandedRowsState ? (JSON.parse(cachedExpandedRowsState) as ExpandedState) : true
  );

  useEffect(() => {
    localStorage.setItem(expandedTableLocalStorageKey, JSON.stringify(isTableExpanded));
  }, [isTableExpanded, expandedTableLocalStorageKey]);

  useEffect(() => {
    localStorage.setItem(expandedRowsLocalStorageKey, JSON.stringify(expandedRows));
  }, [expandedRows, expandedRowsLocalStorageKey]);

  useEffect(() => {
    const expandedRowsCount = Object.keys(expandedRows).length;
    if (isTableExpanded && expandedRowsCount) {
      segment.track('cash-flow_category_expand');
    }
  }, [expandedRows, segment, isTableExpanded]);

  const { tableData, columns } = useMemo(
    () => buildTable(data, sums, isLoading, numberOfColumns, headerLabel),
    [data, sums, isLoading, numberOfColumns, headerLabel]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      expanded: expandedRows,
    },
    onExpandedChange: setExpandedRows,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: row => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: row => Boolean(row.subRows.length),
  });

  const handleManageCategories = (header: Header<CategoriesTableRow, unknown>): void => {
    const title =
      typeof header.column.columnDef.header === 'string'
        ? header.column.columnDef.header.toLowerCase()
        : undefined;
    if (title) {
      segment.track('cash-flow_category_manage');
      void router.transitionTo('cash-flow-categories.type', title);
    }
  };

  return (
    <table aria-busy={isLoading} aria-live="polite" className={styles.categoriesTable} {...props}>
      <colgroup>
        <col />
        {Array.from({ length: numberOfColumns }, (_, index) => (
          <col key={index} />
        ))}
      </colgroup>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => {
              const isFirstColumn = index === 0;
              return (
                <th
                  className={cx(styles.colHeader, !isTableExpanded && styles.tableCollapsed)}
                  key={header.id}
                  scope="col"
                >
                  {header.isPlaceholder ? null : (
                    <TableCell
                      align={isFirstColumn ? 'left' : 'center'}
                      isLoading={isFirstColumn ? false : isLoading}
                    >
                      {isFirstColumn ? (
                        <div className={styles.colHeaderActions}>
                          <div className={styles.colHeaderActionsLabel}>
                            <button
                              className={styles.categoriesActionButton}
                              data-testid="expand-table-button"
                              onClick={() => {
                                setIsTableExpanded(!isTableExpanded);
                              }}
                              type="button"
                            >
                              {isTableExpanded ? <ArrowDown /> : <ArrowRight />}
                            </button>
                            <span data-testid="table-title">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </span>
                          </div>

                          <button
                            className={styles.categoriesActionButton}
                            data-testid="manage-categories-button"
                            onClick={() => {
                              handleManageCategories(header);
                            }}
                            type="button"
                          >
                            <IconCog data-testid="manage-categories-icon" />
                          </button>
                        </div>
                      ) : (
                        flexRender(header.column.columnDef.header, header.getContext())
                      )}
                    </TableCell>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {isTableExpanded
          ? table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell, index) => {
                  if (index === 0) {
                    return (
                      <th
                        className={styles.rowHeader}
                        data-testid="row-header"
                        key={cell.id}
                        scope="row"
                      >
                        <TableCell align="left" isLabel isLoading={isLoading}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      </th>
                    );
                  }
                  return (
                    <td className={styles.rowCell} key={cell.id}>
                      <TableCell isLoading={isLoading}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    </td>
                  );
                })}
              </tr>
            ))
          : ''}
      </tbody>
    </table>
  );
}
