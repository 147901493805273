import { type ReactNode, type SVGProps } from 'react';

export function IconComputer(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M0 1.64C0 .737.734.004 1.637.004h12.726C15.266.004 16 .737 16 1.64v10.031c0 .904-.734 1.637-1.637 1.637h-5.86v1.696h2.527a.5.5 0 1 1 0 1H5.048a.5.5 0 0 1 0-1h2.454v-1.696H1.637A1.637 1.637 0 0 1 0 11.672V1.64Zm1.637-.636A.638.638 0 0 0 1 1.64v7.735h14V1.64a.638.638 0 0 0-.637-.636H1.637ZM15 10.375H1v1.296c0 .351.286.637.637.637h12.726c.351 0 .637-.286.637-.636v-1.297Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
