import { type ReactNode, type SVGProps } from 'react';

export function IconHook(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.007.329a.5.5 0 0 1 .5.5v1.91h1.514a.5.5 0 0 1 .5.5v1.006h.092a.71.71 0 0 1 .702.611l.675 4.819a.71.71 0 0 1-.703.808h-2.79v.865c.26.066.501.18.713.322a2.185 2.185 0 1 1-3.398 1.817.5.5 0 0 1 1 0 1.185 1.185 0 1 0 1.843-.985 1.206 1.206 0 0 0-.658-.218.5.5 0 0 1-.5-.5v-1.301h-2.79a.71.71 0 0 1-.704-.803l.636-4.818a.71.71 0 0 1 .704-.617h.134V3.238a.5.5 0 0 1 .5-.5h1.53V.83a.5.5 0 0 1 .5-.5Zm-2.41 4.916-.56 4.238h5.916l-.593-4.238H5.597Zm3.924-1H6.477v-.507H9.52v.507Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
