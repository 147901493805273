import { type ReactNode, type SVGProps } from 'react';

export function IconWrench(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m13.982.324-.239.44.239-.44a.5.5 0 0 1 .114.794l-1.368 1.36.167.661.646.164 1.368-1.36a.5.5 0 0 1 .805.14l-.452.215.452-.214v.002l.002.002.003.008.01.022c.008.019.019.043.03.074a3.689 3.689 0 0 1 .236 1.127 3.195 3.195 0 0 1-.973 2.479 3.399 3.399 0 0 1-3.066.923l-5.24 5.206a3.357 3.357 0 0 1-.93 3.05c-.874.868-1.81 1.06-2.534 1.018a3.314 3.314 0 0 1-1.115-.267 2.124 2.124 0 0 1-.073-.034l-.022-.012-.008-.004-.002-.001H2.03l.238-.44-.239.439a.5.5 0 0 1-.113-.794l1.367-1.36-.166-.66-.67-.167-1.37 1.362a.5.5 0 0 1-.807-.146l.454-.209-.454.209v-.001l-.001-.001-.002-.003-.003-.008-.01-.022a3.22 3.22 0 0 1-.114-.33 3.7 3.7 0 0 1-.138-.87 3.235 3.235 0 0 1 .987-2.474 3.399 3.399 0 0 1 3.042-.928l.018-.018.002-.002L9.3 4.105a3.357 3.357 0 0 1 .925-3.082c.873-.868 1.81-1.06 2.533-1.018a3.312 3.312 0 0 1 1.115.267l.073.034.023.012.007.003.003.002h.002ZM9.696 5.119 5.07 9.657a3.407 3.407 0 0 1 1.26 1.246l4.598-4.569a3.407 3.407 0 0 1-1.23-1.215Zm2.158.542a2.395 2.395 0 0 1-1.519-1.552 2.358 2.358 0 0 1 .596-2.377c.645-.641 1.29-.756 1.77-.728.029.001.057.004.085.006l-.965.959a.5.5 0 0 0-.132.476l.309 1.228a.5.5 0 0 0 .362.362l1.21.307a.5.5 0 0 0 .474-.13l.947-.94c.002.03.005.062.006.095a2.196 2.196 0 0 1-.68 1.721 2.4 2.4 0 0 1-2.271.628.5.5 0 0 0-.192-.055Zm1.654-4.457Zm-7.83 10.692a2.393 2.393 0 0 0-1.532-1.56.496.496 0 0 1-.134-.04 2.401 2.401 0 0 0-2.318.616 2.236 2.236 0 0 0-.688 1.805l.935-.93a.5.5 0 0 1 .473-.13l1.236.307a.5.5 0 0 1 .364.363l.309 1.228a.5.5 0 0 1-.133.476l-.964.959.084.006c.48.028 1.126-.087 1.77-.728a2.358 2.358 0 0 0 .598-2.372Zm-3.174 2.9Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
