import React, { type ReactNode, type ComponentPropsWithRef } from 'react';
import { useIntl } from 'react-intl';
import styles from './styles.strict-module.css';
import { CategoryAvatar } from './category-avatar';
import { AutoCategorizeIndicator } from './auto-categorize-indicator';

interface CategoryIconProps extends ComponentPropsWithRef<'div'> {
  color: string;
  icon: string | null;
  isAutoCategorized?: boolean;
  isSelectedCategory?: boolean;
  avatarSize?: number;
}

export function CategoryIcon({
  color,
  icon,
  isAutoCategorized = false,
  isSelectedCategory = false,
  avatarSize = 24,
  ...props
}: CategoryIconProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <div className={styles['category-icon-container']} style={{ width: avatarSize }} {...props}>
      {icon ? (
        <>
          {isAutoCategorized && isSelectedCategory ? (
            <AutoCategorizeIndicator
              label={formatMessage({ id: 'cash-flow-categories.auto-categorized.tooltip' })}
            >
              <CategoryAvatar color={color} icon={icon} />
            </AutoCategorizeIndicator>
          ) : (
            <CategoryAvatar color={color} icon={icon} />
          )}
        </>
      ) : (
        <div
          className={styles['category-color-dot']}
          data-testid="category-color-dot"
          style={{
            backgroundColor: `var(${color})`,
            ...getColorDotDimensions(avatarSize),
          }}
        />
      )}
    </div>
  );
}

const getColorDotDimensions = (size: number): { width: string; height: string } | undefined => {
  const dimensions: Record<string, { width: string; height: string }> = {
    '24': {
      width: '8px',
      height: '8px',
    },
    '32': {
      width: '12px',
      height: '12px',
    },
  };
  return dimensions[size.toString()] ?? dimensions['24'];
};
