/* eslint-disable camelcase -- handling server response */

import type { LabelList } from 'qonto/react/models/label';
import type { LabelLists } from '../models';

type LabelsMap = Record<
  string,
  { id: string; name: string; listId: string; categoryId: string; type: 'label' }[]
>;

function addLabelsToLabelLists(data: LabelLists): LabelList[] {
  const { label_lists, labels = [] } = data;
  const labelsMap: LabelsMap = {};

  labels.forEach(({ id, name, label_list_id }) => {
    if (!labelsMap[label_list_id]) labelsMap[label_list_id] = [];
    labelsMap[label_list_id]?.push({
      id,
      name,
      listId: label_list_id,
      categoryId: label_list_id,
      type: 'label',
    });
  });

  const updatedLabelLists = label_lists.map(labelList => {
    const { id, name, color, rank, organization_id } = labelList;
    const matchingLabels = labelsMap[id] ?? [];
    return {
      id,
      name,
      color,
      rank: rank.toString(),
      organizationId: organization_id, // todo is it used?
      labels: matchingLabels,
    };
  });

  return updatedLabelLists;
}

export function normalizeLabelLists(data: LabelLists): LabelList[] {
  return addLabelsToLabelLists(data);
}
