import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@repo/design-system-kit';
import type { InstallmentModel } from 'qonto/react/models/installment';
import type { FinancingModel } from 'qonto/react/models/financing';
import { InstallmentTimeline } from '../installment-timeline';
import styles from './styles.strict-module.css';

export function FinancingInstallments({
  financing,
  installments,
}: {
  financing: FinancingModel;
  installments: InstallmentModel[];
}): ReactNode {
  const canEarlyRepay = financing.status === 'in_progress';

  return (
    <>
      <InstallmentTimeline list={installments} />
      {canEarlyRepay ? (
        <div className={styles.repaymentContainer}>
          <Button data-testid="pay-now-button" size="small" stretch variant="secondary">
            <FormattedMessage id="financing.pay-later.cockpit.details.early-repayment-cta" />
          </Button>
        </div>
      ) : null}
    </>
  );
}
