import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function SddOneOff(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={80} height={80} fill="none" {...props}>
      <path
        fill="#D5C8FB"
        d="m19.501 47.168.062 21.738c.003.814.22 1.47.622 1.9l.01.008c-.008-.004-.003-.004-.01-.009.119.127.256.236.408.322l6.046 3.54c.038.022 0 0 .116.06a1.655 1.655 0 0 1-.116-.06l-.036-.022-.01-.006-.025-.016a.533.533 0 0 1-.031-.02l-.017-.012a.3.3 0 0 1-.174-.138h-.002a.624.624 0 0 1-.043-.041.96.96 0 0 1-.042-.041l-.009-.009-.014-.014a.954.954 0 0 1-.078-.09c-.015-.019-.03-.035-.044-.055l-.002-.003a1.304 1.304 0 0 1-.042-.058l-.04-.06v-.001a.826.826 0 0 1-.045-.075.719.719 0 0 1-.025-.044l-.009-.015-.03-.056-.005-.01a1.68 1.68 0 0 1-.03-.063l-.005-.012-.021-.047a.5.5 0 0 1-.012-.028l-.024-.06a1.35 1.35 0 0 1-.028-.075l-.023-.07a1.358 1.358 0 0 1-.031-.102 2.56 2.56 0 0 1-.02-.078v-.002l-.016-.063c0-.006-.003-.012-.004-.018l-.01-.053a2.196 2.196 0 0 1-.01-.045l-.012-.067a2.867 2.867 0 0 1-.023-.164l-.007-.066-.006-.048-.005-.071-.005-.072-.003-.073-.002-.071v-.078l-.062-21.737c-.003-1.058.357-2.264 1.014-3.396.657-1.132 1.527-2.048 2.452-2.58L48.021 33.81c.273-.16.565-.285.87-.373.594-.166.661-.19 1.109.064l-4.472-2.84-.164-.094-.928-.537c-.661-.375-1.535-.29-2.46.242l-19.01 10.922c-.924.533-1.796 1.448-2.452 2.58-.656 1.131-1.016 2.337-1.013 3.395Z"
      />
      <path
        stroke="#1D1D1B"
        strokeMiterlimit={10}
        strokeWidth={0.8}
        d="M20.185 70.805c-.402-.43-.62-1.085-.622-1.9l-.062-21.737c-.003-1.058.357-2.264 1.013-3.395.657-1.132 1.528-2.047 2.452-2.58l19.01-10.922c.925-.531 1.799-.617 2.46-.242l.928.537.164.094L50 33.5c-.448-.255-.515-.23-1.11-.064a3.812 3.812 0 0 0-.869.373L29.023 44.743c-.925.53-1.795 1.447-2.452 2.579-.657 1.132-1.017 2.338-1.014 3.396l.062 21.737v.078l.002.07.003.075.005.07.005.072.006.048.007.066a3.485 3.485 0 0 0 .023.164l.012.067.01.045.01.053c.001.006.004.012.004.018l.015.063v.002a2.56 2.56 0 0 0 .052.18l.023.07.028.075.024.06a.5.5 0 0 0 .012.028l.02.047.006.012.03.063.006.01.03.056.008.015a.719.719 0 0 0 .034.06c.011.02.024.04.036.059v.002l.04.06.042.057.002.002c.014.02.03.037.044.056a.954.954 0 0 0 .092.104l.009.009a.96.96 0 0 0 .085.082h.002a.3.3 0 0 0 .02.017v.002a1.398 1.398 0 0 0 .118.094.708.708 0 0 0 .036.025l.017.012.03.02.026.016.01.006.036.022m-6.454-3.862.01.008c-.008-.003-.003-.003-.01-.008Zm0 0c.119.127.256.236.408.322l6.046 3.54m0 0c.038.022 0 0 .116.06a1.655 1.655 0 0 1-.116-.06Z"
      />
      <mask id="a" fill="#fff">
        <path d="M28.747 44.517c-.93.536-1.805 1.459-2.465 2.6-.66 1.14-1.022 2.355-1.005 3.427l.062 21.905v.08l.002.07.003.075.005.071.006.072a2.391 2.391 0 0 0 .012.115 4.714 4.714 0 0 0 .024.165l.012.068.009.045.002.012.01.042c0 .006.003.011.003.018l.015.063v.003l.02.079.013.041a1.378 1.378 0 0 0 .042.131 2.427 2.427 0 0 0 .085.212l.006.012.03.064.006.01.03.056.008.016a.813.813 0 0 0 .035.06c.01.02.023.04.035.06a1.948 1.948 0 0 0 .083.119l.002.002.044.057a1.46 1.46 0 0 0 .07.08l.023.024.008.009.042.042.014.013.03.028h.002a.279.279 0 0 0 .02.017v.002l.04.034a1.308 1.308 0 0 0 .115.087l.017.011.03.02.027.016a1.383 1.383 0 0 0 .163.09 1.658 1.658 0 0 0 .183.073l.043.013c.048.016.098.029.148.04l.059.011a2.115 2.115 0 0 0 .817-.012l.016-.004a2.827 2.827 0 0 0 .331-.086c.033-.01.066-.023.099-.034.033-.01.066-.022.1-.035.192-.077.379-.167.558-.271l19.105-11.006a5.303 5.303 0 0 0 .6-.406 6.45 6.45 0 0 0 .575-.506 8.053 8.053 0 0 0 1.29-1.687 8.62 8.62 0 0 0 .437-.863l.01-.026a6.937 6.937 0 0 0 .308-.841 4.807 4.807 0 0 0 .115-.43l.023-.107a5.984 5.984 0 0 0 .104-.681l.007-.077a4.62 4.62 0 0 0 .006-.097c.002-.024.004-.048.004-.071V57.41l-.062-21.905a4.34 4.34 0 0 0-.054-.659 3.116 3.116 0 0 0-.184-.646c-.175-.415-.797-.934-.797-.934-.45-.256-1.002-.298-1.599-.131-.306.088-.6.215-.873.376L28.747 44.517Z" />
      </mask>
      <path
        fill="#fff"
        stroke="#1D1D1B"
        strokeMiterlimit={10}
        strokeWidth={1.6}
        d="M28.747 44.517c-.93.536-1.805 1.459-2.465 2.6-.66 1.14-1.022 2.355-1.005 3.427l.062 21.905v.08l.002.07.003.075.005.071.006.072a2.391 2.391 0 0 0 .012.115 4.714 4.714 0 0 0 .024.165l.012.068.009.045.002.012.01.042c0 .006.003.011.003.018l.015.063v.003l.02.079.013.041a1.378 1.378 0 0 0 .042.131 2.427 2.427 0 0 0 .085.212l.006.012.03.064.006.01.03.056.008.016a.813.813 0 0 0 .035.06c.01.02.023.04.035.06a1.948 1.948 0 0 0 .083.119l.002.002.044.057a1.46 1.46 0 0 0 .07.08l.023.024.008.009.042.042.014.013.03.028h.002a.279.279 0 0 0 .02.017v.002l.04.034a1.308 1.308 0 0 0 .115.087l.017.011.03.02.027.016a1.383 1.383 0 0 0 .163.09 1.658 1.658 0 0 0 .183.073l.043.013c.048.016.098.029.148.04l.059.011a2.115 2.115 0 0 0 .817-.012l.016-.004a2.827 2.827 0 0 0 .331-.086c.033-.01.066-.023.099-.034.033-.01.066-.022.1-.035.192-.077.379-.167.558-.271l19.105-11.006a5.303 5.303 0 0 0 .6-.406 6.45 6.45 0 0 0 .575-.506 8.053 8.053 0 0 0 1.29-1.687 8.62 8.62 0 0 0 .437-.863l.01-.026a6.937 6.937 0 0 0 .308-.841 4.807 4.807 0 0 0 .115-.43l.023-.107a5.984 5.984 0 0 0 .104-.681l.007-.077a4.62 4.62 0 0 0 .006-.097c.002-.024.004-.048.004-.071V57.41l-.062-21.905a4.34 4.34 0 0 0-.054-.659 3.116 3.116 0 0 0-.184-.646c-.175-.415-.797-.934-.797-.934-.45-.256-1.002-.298-1.599-.131-.306.088-.6.215-.873.376L28.747 44.517Z"
        mask="url(#a)"
      />
      <path
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeLinejoin="round"
        strokeWidth={0.8}
        d="M42 32 24.278 42.041l2.968 1.846 17.7-10.142L42 32Z"
      />
      <mask
        id="b"
        width={35}
        height={30}
        x={16}
        y={14}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill="#1D1D1B" d="M45.84 33.115 51 14H16l7.914 27.817 3.192 1.862 18.733-10.564Z" />
      </mask>
      <g mask="url(#b)">
        <path
          fill="#FFEADD"
          stroke="#1D1D1B"
          strokeMiterlimit={10}
          strokeWidth={0.8}
          d="M43.361 17.25a5.005 5.005 0 0 0-1.19-.497c-.77-.212-1.59-.24-2.382-.136-1.156.15-2.413.59-3.727 1.35-5.666 3.27-10.245 11.2-10.226 17.712.003 1.22.168 2.303.472 3.237v.006c.244.754.58 1.41.991 1.96.133.177.272.342.418.497.333.352.703.643 1.109.88l-1.42-.826-.398-.231-.038-.022c-.558-.33-1.563-1.067-1.978-1.612-.94-1.238-1.485-3.011-1.492-5.237-.019-6.51 4.56-14.443 10.226-17.714 1.758-1.013 3.41-1.463 4.861-1.4.782.032 2.02.507 2.662.824.234.114.228.124 2.11 1.213l.002-.003Z"
        />
        <path
          fill="#fff"
          stroke="#1D1D1B"
          strokeMiterlimit={10}
          strokeWidth={0.8}
          d="M36.056 17.966C30.39 21.238 25.81 29.166 25.83 35.68c.02 6.51 4.627 9.135 10.292 5.864 5.669-3.27 10.245-11.2 10.226-17.713-.019-6.51-4.627-9.139-10.295-5.864h.003Z"
        />
        <path
          fill="#1D1D1B"
          d="M37.492 36.704c-3.529 2.038-5.631-.021-5.69-3.7.072-3.767 2.174-8.282 5.726-10.332 2.215-1.279 3.748-.765 3.748-.765l-.863 2.192s-1.165-.275-2.92.738c-2.462 1.422-3.57 4.476-3.583 6.95.013 2.454 1.115 4.247 3.582 2.803 1.65-.952 2.688-2.52 2.688-2.52l1.11.914s-1.344 2.304-3.798 3.72Zm-6.992-.508v-1.76l7.791-4.499v1.76L30.5 36.197Zm0-3.102v-1.76l7.791-4.499v1.76L30.5 33.095Z"
        />
      </g>
      <path
        fill="#fff"
        d="m64.503 37.7-2-2.845 4-2.31-2 5.154Zm-.347-2.66v-1.908l.693-.4v1.907l-.693.4Zm0-3.815v-1.907l.693-.4v1.907l-.693.4Zm0-3.814v-1.907l.692-.4v1.907l-.692.4Zm0-3.814V21.69l.692-.4v1.907l-.692.4Zm0-3.814-.001-1.907.693-.4v1.907l-.693.4Zm-.001-3.814v-1.907l.692-.4v1.907l-.692.4Zm0-3.814v-.953l.692-.4v.953l-.692.4Z"
      />
      <path
        fill="#393937"
        fillRule="evenodd"
        d="M61.155 13.062v1.907l.693-.4v-1.907l-.693.4Zm0 3.814v1.907l.694-.4v-1.907l-.693.4Zm0 3.814.001 1.907.693-.4V20.29l-.693.4Zm.001 3.814v1.907l.693-.4v-1.907l-.693.4Zm0 3.814v1.907l.694-.4v-1.907l-.694.4Zm.694 4.182 1.653-.955-2 5.155-2-2.845 1.654-.955v-.768l.693-.4v.768Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
