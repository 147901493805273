import { SkeletonLoader } from '@repo/design-system-kit';
import type { HTMLAttributes, ReactElement } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

export function TeamPlaceholder({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>): ReactElement {
  return (
    <div className={cx(styles.container, className)} {...props}>
      <SkeletonLoader.Block
        borderRadius="50%"
        className={cx('ml-16', 'mr-16')}
        data-test-budgets-create-team-placeholder-block=""
        height="40px"
        width="40px"
      />

      <div className="lines">
        <SkeletonLoader.Line
          className="mb-8"
          data-test-budgets-create-team-placeholder-line=""
          height="12px"
          standalone
          width="95px"
        />
        <SkeletonLoader.Line
          data-test-budgets-create-team-placeholder-line=""
          height="12px"
          width="167px"
        />
      </div>
    </div>
  );
}
