import { type ReactNode, type SVGProps } from 'react';

export function IconTarget(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.546.038a.5.5 0 0 1 .308.462v1.643h1.643a.5.5 0 0 1 .354.853l-.953.953a8 8 0 1 1-2.85-2.85L13 .148a.5.5 0 0 1 .545-.109Zm-2.233 1.796a7 7 0 1 0 2.851 2.849l-1.26 1.26a5.324 5.324 0 1 1-2.85-2.85l1.26-1.26ZM9.64 4.004a4.324 4.324 0 1 0 2.354 2.353h-1.642l-.162.162a2.643 2.643 0 1 1-.707-.707l.157-.158v-1.65Zm1 1.353h1.436l2.214-2.214h-.936a.5.5 0 0 1-.5-.5v-.936L10.64 3.921v1.436ZM8.755 6.54a1.643 1.643 0 1 0 .707.708L8.355 8.352a.5.5 0 0 1-.707-.707L8.755 6.54Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
