import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function QontoFlower(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_93_430)">
        <path
          d="M2.33858 5.24441C0.93893 3.84228 0.1064 1.97528 0 0C1.97361 0.109551 3.83612 0.945729 5.22649 2.34644C6.62575 3.74888 7.45821 5.61575 7.56507 7.59095C5.59145 7.48136 3.72894 6.64515 2.33858 5.24441ZM13.6614 5.24441C12.2711 6.64515 10.4085 7.48136 8.43493 7.59095C8.54179 5.61575 9.37425 3.74888 10.7735 2.34644C12.1639 0.945729 14.0264 0.109551 16 0C15.8936 1.97528 15.0611 3.84228 13.6614 5.24441ZM13.6614 10.7556C15.0611 12.1577 15.8936 14.0247 16 16C14.0264 15.8904 12.1639 15.0543 10.7735 13.6536C9.37425 12.2511 8.54179 10.3843 8.43493 8.40905C10.4085 8.51864 12.2711 9.35485 13.6614 10.7556ZM2.33858 10.7556C3.72894 9.35485 5.6187 8.51779 7.59232 8.4082C7.48545 10.3834 6.62575 12.2511 5.22649 13.6536C3.83612 15.0543 1.97361 15.8904 0 16C0.1064 14.0247 0.93893 12.1577 2.33858 10.7556Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_93_430">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
