import dayjs from 'dayjs';
import { utcToZonedDate } from 'qonto/utils/chart';
import type { CashflowTimeframe } from 'qonto/react/models/cash-flow-timeseries';
import type { MonthlyBalance } from '../api/balance';
import type { EndBalance, StartBalance } from '../models/balance';

export interface NormalizedBalances {
  startBalances: StartBalance[];
  endBalances: EndBalance[];
}

export const normalizeBalances = (
  balances: MonthlyBalance[],
  timezone?: string
): NormalizedBalances => {
  return balances.reduce(
    (acc: NormalizedBalances, balance) => {
      const { start_date: startDate, data: balanceDetails } = balance;
      const { starting_balance: start, ending_balance: end } = balanceDetails;
      const now = utcToZonedDate(new Date().getTime(), timezone) ?? new Date();
      const date = utcToZonedDate(startDate, timezone) ?? new Date(startDate);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const period = `${String(year)}-${String(month)}`;
      const isCurrentMonth = dayjs(date).isSame(dayjs(now), 'month');

      acc.startBalances.push({
        period,
        start: { amount: Number(start), isCurrentMonth, type: 'start' },
      });
      acc.endBalances.push({
        period,
        end: { amount: Number(end), isCurrentMonth, type: 'end' },
      });
      return acc;
    },
    { startBalances: [], endBalances: [] }
  );
};

export const normalizeTimeframeBalances = (timeframes: CashflowTimeframe[]): NormalizedBalances => {
  return timeframes.reduce(
    (normalized: NormalizedBalances, timeframe) => {
      const { cashFlowData, inclusiveStartDate: startDate } = timeframe;

      const { startingAmount, endingAmount } = cashFlowData;
      const now = utcToZonedDate(new Date().getTime()) ?? new Date();
      const date = utcToZonedDate(startDate) ?? new Date(startDate);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const period = `${String(year)}-${String(month)}`;
      const isCurrentMonth = dayjs(date).isSame(dayjs(now), 'month');

      normalized.startBalances.push({
        period,
        start: { amount: startingAmount.value, isCurrentMonth, type: 'start' },
      });
      normalized.endBalances.push({
        period,
        end: { amount: endingAmount.value, isCurrentMonth, type: 'end' },
      });

      return normalized;
    },
    { startBalances: [], endBalances: [] }
  );
};
