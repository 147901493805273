import { SkeletonLoader } from '@repo/design-system-kit';
import type { ComponentPropsWithoutRef, ReactElement } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

export function TilePlaceholder({
  className,
  ...props
}: ComponentPropsWithoutRef<'article'>): ReactElement {
  return (
    <article
      aria-hidden="true"
      className={cx(styles.bankAccountTileLoading, className)}
      data-test-account-tile-loading=""
      {...props}
    >
      <header className={styles.header} data-test-header="">
        <section className={styles.headings}>
          <SkeletonLoader.Block
            borderRadius="20px"
            data-test-avatar=""
            height="40px"
            width="40px"
          />
          <SkeletonLoader.Line data-test-title="" standalone width="100px" />
        </section>
        <SkeletonLoader.Block
          borderRadius="4px"
          className={styles.menu}
          data-test-menu=""
          height="32px"
          width="32px"
        />
      </header>
      <main className={styles.content} data-test-content>
        <section className={styles.details}>
          <SkeletonLoader.Line data-test-balance="" standalone width="120px" />
          <SkeletonLoader.Line data-test-identifier="" standalone width="198px" />
        </section>
      </main>
      <footer className={styles.footer} data-test-footer>
        <SkeletonLoader.Line
          className="mr-16"
          data-test-action-transactions=""
          standalone
          width="82px"
        />
        <SkeletonLoader.Line data-test-action-cards="" standalone width="40px" />
      </footer>
    </article>
  );
}
