import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function Calculator(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_41323_17135)">
        <rect
          width="27.4104"
          height="19.0449"
          rx="2"
          transform="matrix(0.866041 0.499972 -0.866041 0.499972 16.4941 8.77374)"
          fill="#1D1D1B"
          stroke="#1D1D1B"
          strokeWidth="0.8"
        />
        <rect
          width="27.4104"
          height="19.0449"
          rx="2"
          transform="matrix(0.866041 0.499972 -0.866041 0.499972 16.4941 8.36359)"
          fill="#1D1D1B"
          stroke="#1D1D1B"
          strokeWidth="0.8"
        />
        <rect
          width="27.4104"
          height="19.0449"
          rx="2"
          transform="matrix(0.866041 0.499972 -0.866041 0.499972 16.4941 7.95343)"
          fill="#1D1D1B"
          stroke="#1D1D1B"
          strokeWidth="0.8"
        />
        <rect
          width="27.4104"
          height="19.0449"
          rx="2"
          transform="matrix(0.866041 0.499972 -0.866041 0.499972 16.4941 7.54327)"
          fill="#1D1D1B"
          stroke="#1D1D1B"
          strokeWidth="0.8"
        />
        <rect
          width="27.4104"
          height="19.0449"
          rx="2"
          transform="matrix(0.866041 0.499972 -0.866041 0.499972 16.4941 7.13312)"
          fill="#1D1D1B"
          stroke="#1D1D1B"
          strokeWidth="0.8"
        />
        <rect
          width="27.4104"
          height="19.0449"
          rx="2"
          transform="matrix(0.866041 0.499972 -0.866041 0.499972 16.4951 7.00031)"
          fill="#D5C8FB"
          stroke="#1D1D1B"
          strokeWidth="0.8"
        />
        <rect
          width="14.0411"
          height="5.42169"
          rx="1"
          transform="matrix(0.866041 -0.499972 0.866041 0.499972 4.22363 16.3962)"
          fill="white"
          stroke="#1D1D1B"
          strokeWidth="0.8"
        />
        <path
          d="M12.0311 20.911C11.5528 20.6349 11.5528 20.1872 12.0311 19.911L15.2159 18.0724L19.2668 20.411L15.2159 22.7496L12.0311 20.911Z"
          fill="#FFEADD"
          stroke="#1D1D1B"
          strokeWidth="0.8"
        />
        <rect
          width="4.67744"
          height="4.67744"
          transform="matrix(0.866041 -0.499972 0.866041 0.499972 15.2158 22.751)"
          fill="#FFEADD"
          stroke="#1D1D1B"
          strokeWidth="0.8"
        />
        <path
          d="M19.2666 25.0864L27.3675 20.4097L31.418 22.7481L24.1831 26.9248C23.7048 27.201 22.9293 27.2009 22.451 26.9248L19.2666 25.0864Z"
          fill="#FFEADD"
          stroke="#1D1D1B"
          strokeWidth="0.8"
        />
        <rect
          width="4.67744"
          height="4.67744"
          transform="matrix(0.866041 -0.499972 0.866041 0.499972 15.2158 18.0756)"
          fill="#FFEADD"
          stroke="#1D1D1B"
          strokeWidth="0.8"
        />
        <rect
          width="4.67744"
          height="4.67744"
          transform="matrix(0.866041 -0.499972 0.866041 0.499972 19.2666 20.411)"
          fill="#FFEADD"
          stroke="#1D1D1B"
          strokeWidth="0.8"
        />
        <path
          d="M19.2666 15.7355L22.4514 13.8969C22.9297 13.6208 23.7052 13.6208 24.1835 13.8969L27.3683 15.7355L23.3175 18.0741L19.2666 15.7355Z"
          fill="#FFEADD"
          stroke="#1D1D1B"
          strokeWidth="0.8"
        />
        <rect
          width="4.67744"
          height="4.67744"
          transform="matrix(0.866041 -0.499972 0.866041 0.499972 23.3174 18.0756)"
          fill="#FFEADD"
          stroke="#1D1D1B"
          strokeWidth="0.8"
        />
        <path
          d="M27.3672 20.411L31.418 18.0724L34.6029 19.911C35.0812 20.1872 35.0812 20.6349 34.6029 20.911L31.418 22.7496L27.3672 20.411Z"
          fill="#FFEADD"
          stroke="#1D1D1B"
          strokeWidth="0.8"
        />
      </g>
      <defs>
        <clipPath id="clip0_41323_17135">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
