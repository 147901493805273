import { type ReactNode } from 'react';
import { useEmberService, useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedMessage } from 'react-intl';
import type { TransactionModel } from 'qonto/react/models/transaction';
import styles from './footer.strict-module.css';

interface TransactionsSidebarFooterProps {
  highlightedItem: TransactionModel;
  handleShowHelpSection: () => void;
}

export function TransactionsSidebarFooter({
  highlightedItem,
  handleShowHelpSection,
}: TransactionsSidebarFooterProps): ReactNode {
  const flags = useFlags();
  const segment = useEmberService('segment');

  const onHelpButtonClick = (): void => {
    const event = flags.featurePrismicZendeskMigration
      ? 'transaction-helper_button_clicked'
      : 'navigation_transactionhelper_clicked';
    segment.track(event, { transaction_type: highlightedItem.operationType });
    handleShowHelpSection();
  };

  return (
    <>
      {!highlightedItem.isInstantTransfer && (
        <div data-test-sidebar-footer>
          <div className={styles.footer}>
            <button
              className="btn btn--tertiary"
              data-test-open-transaction-help-btn
              onClick={onHelpButtonClick}
              type="button"
            >
              <span>
                <FormattedMessage id="transactions.sidebar.help" />
              </span>
            </button>
          </div>
        </div>
      )}
    </>
  );
}
