import cx from 'clsx';
import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Pencil } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface Client {
  name?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

interface ClientCardProps {
  client: Client;
  onClick: (client: Client) => void;
  onEditClick: (client: Client) => void;
  className?: string;
  index?: number;
}

export function ClientCard({
  client,
  onClick,
  onEditClick,
  className,
  index,
}: ClientCardProps): ReactNode {
  const { formatMessage } = useIntl();

  const displayName = client.name || `${client.firstName} ${client.lastName}`;

  return (
    <div
      className={cx(styles.card, className)}
      data-test-client-card={typeof index === 'number' ? index.toString() : true}
    >
      <button
        className={styles['select-button']}
        data-test-client-card-select
        onClick={() => {
          onClick(client);
        }}
        type="button"
      >
        <p className="body-1" data-test-client-card-name>
          {displayName}
        </p>
        <p className="body-2" data-test-client-card-email>
          {client.email || '-'}
        </p>
      </button>

      <div className="absolute right-4 top-1/2 -translate-y-1/2">
        <button
          aria-label={formatMessage({ id: 'receivable-invoices.customers.actions.edit' })}
          className={cx('btn btn--icon-only btn--secondary', styles['edit-button'])}
          data-test-client-card-edit
          onClick={() => {
            onEditClick(client);
          }}
          type="button"
        >
          <Pencil />
        </button>
      </div>
    </div>
  );
}
