/* eslint-disable react/jsx-no-leaked-render -- This rule does not make sense when using && in JSX */
import { Button, Disclaimer, RadioButton, RadioGroup } from '@repo/design-system-kit';
import { useId, useState, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import { CHARGEBACK_CLAIM_CARD_POSSESSION_VALUES } from 'qonto/react/constants';
import { InPossession, Stolen } from 'qonto/react/assets/icons/chargeback-claim';
import styles from './styles.strict-module.css';

interface CardPossessionRadioButtonProps {
  checked: boolean;
  disclaimer: ReactNode;
  icon: ReactNode;
  label: ReactNode;
  value: string;
}

function CardPossessionRadioButton({
  checked,
  disclaimer,
  icon,
  label,
  value,
}: CardPossessionRadioButtonProps): ReactNode {
  const disclaimerId = useId();

  return (
    <div className={styles['selector-wrapper']}>
      <RadioButton
        aria-describedby={checked ? disclaimerId : undefined}
        className={styles.selector}
        data-test-card-possession-selector={value}
        value={value}
      >
        <div className={styles['selector-content']}>
          {icon}
          <span className="body-1">{label}</span>
        </div>
      </RadioButton>
      {checked && (
        <p
          className={styles['selector-disclaimer']}
          data-test-card-possession-disclaimer-wrapper={value}
          id={disclaimerId}
        >
          <Disclaimer.Inline data-test-card-possession-disclaimer={value}>
            {disclaimer}
          </Disclaimer.Inline>
        </p>
      )}
    </div>
  );
}

interface CardPossessionProps {
  context: {
    hadCardDuringFraud: boolean;
  };
  transitionToNext: () => void;
}

type CardPossessionRadioButtonValue =
  (typeof CHARGEBACK_CLAIM_CARD_POSSESSION_VALUES)[keyof typeof CHARGEBACK_CLAIM_CARD_POSSESSION_VALUES];

export function CardPossession({ context, transitionToNext }: CardPossessionProps): ReactNode {
  const segment = useEmberService('segment');

  const titleId = useId();

  const [selectedValue, setSelectedValue] = useState<CardPossessionRadioButtonValue>(
    context.hadCardDuringFraud
      ? CHARGEBACK_CLAIM_CARD_POSSESSION_VALUES.YES
      : CHARGEBACK_CLAIM_CARD_POSSESSION_VALUES.NO
  );

  const handleContinue = (): void => {
    context.hadCardDuringFraud = selectedValue === CHARGEBACK_CLAIM_CARD_POSSESSION_VALUES.YES;
    segment.track('cards-chargeback_declare-card-in-possession-cta_clicked');
    transitionToNext();
  };

  return (
    <>
      <h1 className="mb-32 title-1" data-test-card-possession-title id={titleId}>
        <FormattedMessage id="dispute-flow.card-possession.title" />
      </h1>

      <RadioGroup
        aria-labelledby={titleId}
        className={cx(styles['selector-group'], 'mb-32')}
        data-test-card-possession-radio-group
        onChange={value => {
          setSelectedValue(value);
        }}
        value={selectedValue}
      >
        <CardPossessionRadioButton
          checked={selectedValue === CHARGEBACK_CLAIM_CARD_POSSESSION_VALUES.YES}
          disclaimer={
            <FormattedMessage id="dispute-flow.card-possession.in-possession.disclaimer" />
          }
          icon={
            <InPossession
              aria-hidden="true"
              data-test-card-possession-selector-icon={CHARGEBACK_CLAIM_CARD_POSSESSION_VALUES.YES}
            />
          }
          label={<FormattedMessage id="dispute-flow.card-possession.in-possession.label" />}
          value={CHARGEBACK_CLAIM_CARD_POSSESSION_VALUES.YES}
        />

        <CardPossessionRadioButton
          checked={selectedValue === CHARGEBACK_CLAIM_CARD_POSSESSION_VALUES.NO}
          disclaimer={<FormattedMessage id="dispute-flow.card-possession.lost-stolen.disclaimer" />}
          icon={
            <Stolen
              aria-hidden="true"
              data-test-card-possession-selector-icon={CHARGEBACK_CLAIM_CARD_POSSESSION_VALUES.NO}
            />
          }
          label={<FormattedMessage id="dispute-flow.card-possession.lost-stolen.label" />}
          value={CHARGEBACK_CLAIM_CARD_POSSESSION_VALUES.NO}
        />
      </RadioGroup>

      <Button data-test-card-possession-button onPress={handleContinue} variant="primary">
        <FormattedMessage id="btn.continue" />
      </Button>
    </>
  );
}
