import type { ReactNode } from 'react';

export function ArrowRight(): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      viewBox="0 0 32 32"
    >
      <path d="M23 15.996c0 .26-.1.52-.3.7l-11.98 11.98c-.4.4-1.02.4-1.42 0a.99.99 0 0 1 0-1.42l11.26-11.26L9.32 4.716a.967.967 0 0 1 0-1.4c.4-.38 1.02-.4 1.4 0l11.98 11.98c.2.2.3.46.3.7Z" />
    </svg>
  );
}
