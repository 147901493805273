import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function Copy(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="currentColor"
      data-testid="icon"
      {...props}
    >
      <path d="M13.5 2h-9v-.31c0-.38.31-.69.69-.69h8.62c.38 0 .69.31.69.69v9.62c0 .38-.31.69-.69.69h-.31V2Z" />
      <path
        fillRule="evenodd"
        d="M2.19 4h8.62c.38 0 .69.31.69.69v9.62c0 .38-.31.69-.69.69H2.19c-.38 0-.69-.31-.69-.69V4.69c0-.38.31-.69.69-.69Zm.31 1v9h8V5h-8Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
