import { type ReactNode, type SVGProps } from 'react';

export function IconKey(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M0 8c0-2.226 1.867-4 4.13-4 1.565 0 2.94.847 3.64 2.104h7.73a.5.5 0 0 1 .463.689l-.54 1.328a.5.5 0 0 1-.182.225l-1.175.8a.5.5 0 0 1-.62-.046l-.204-.189-.396.246a.5.5 0 0 1-.548-.014l-.369-.255-.407.263a.5.5 0 0 1-.558-.01l-.283-.198-.54.57a.5.5 0 0 1-.363.155h-1.89C7.231 11.05 5.787 12 4.13 12 1.867 12 0 10.226 0 8Zm8.205.668h1.358l.69-.726a.5.5 0 0 1 .65-.065l.359.252.405-.262a.5.5 0 0 1 .556.008l.374.26.44-.273a.5.5 0 0 1 .601.056l.193.177.723-.49.203-.5H8.158a3.9 3.9 0 0 1 .047 1.563ZM4.13 5C2.384 5 1 6.36 1 8s1.384 3 3.13 3c1.747 0 3.132-1.36 3.132-3S5.877 5 4.13 5Zm-.874 2.535a.406.406 0 0 0-.273.108.336.336 0 0 0-.097.173.317.317 0 0 0 .02.19.353.353 0 0 0 .134.157.4.4 0 0 0 .433 0 .353.353 0 0 0 .134-.157.317.317 0 0 0 .02-.19.336.336 0 0 0-.097-.173.406.406 0 0 0-.273-.108Zm-.521-.9a1.406 1.406 0 0 1 1.49.288c.193.187.327.427.381.692.055.264.026.538-.08.786a1.353 1.353 0 0 1-.512.602 1.4 1.4 0 0 1-1.515 0 1.353 1.353 0 0 1-.512-.602 1.317 1.317 0 0 1 .301-1.478 1.38 1.38 0 0 1 .447-.288Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
