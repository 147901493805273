import cx from 'clsx';
import styles from './value-proposition.strict-module.css';

interface FlowValuePropositionProps {
  children: React.ReactNode;
  // TODOL: Remove the `string` type when ember UI will be gone. At the moment ReactBridge doesn't support `React.ReactNode` props neither html components. The only way to pass html components is to pass them as a string or in children.
  illustration: React.ReactNode | string;
  className?: string;
  mainClassName?: string;
  illustrationClassName?: string;
}

export function FlowValueProposition({
  children,
  illustration,
  className,
  mainClassName,
  illustrationClassName,
}: FlowValuePropositionProps): React.ReactElement {
  return (
    <div className={cx(styles.container, className)}>
      <main className={cx(styles.content, mainClassName)}>
        <section>{children}</section>
      </main>
      <div className={cx(styles.illustration, illustrationClassName)}>
        {typeof illustration === 'string' ? (
          <figure dangerouslySetInnerHTML={{ __html: illustration }} />
        ) : (
          <figure> {illustration}</figure>
        )}
      </div>
    </div>
  );
}
