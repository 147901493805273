import { type ComponentPropsWithoutRef, type ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from 'qonto/react/components/selector/checkbox';
import { type Membership } from 'qonto/react/models/membership';
import styles from './styles.strict-module.css';

interface SupervisorSelectorProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onSelect' | 'title'> {
  membership: Membership;
  onSelect: (item: Membership) => void;
  selected: string[];
}

export function SupervisorSelector({
  membership,
  onSelect,
  selected,
  ...props
}: SupervisorSelectorProps): ReactNode {
  return (
    <Checkbox
      icon={
        membership.avatar?.fileUrl ? (
          <img alt={membership.fullName} src={membership.avatar.fileUrl} />
        ) : null
      }
      item={membership}
      onSelect={onSelect}
      selected={selected}
      subtitle={
        <span className={cx('body-2', styles.subtitle)}>
          <FormattedMessage id={`roles.${membership.role}`} />
        </span>
      }
      title={<span className="body-1">{membership.fullName}</span>}
      {...props}
    />
  );
}
