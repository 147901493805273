import React, { useCallback, useState } from 'react';
import { Button, RadioButton, RadioGroup, IconWarningSignOutlined } from '@repo/design-system-kit';
import cx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { FlowValueProposition } from 'qonto/react/components/flows/screens';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './hear-from-you.strict-module.css';

enum SlotPreferenceType {
  AM = 'AM',
  PM = 'PM',
}

interface HearFromYouScreenProps {
  onBookCall: (params: { slotPreference: SlotPreferenceType | undefined }) => void;
  onClose: () => void;
}

export function HearFromYouScreen({
  onBookCall,
  onClose,
}: HearFromYouScreenProps): React.ReactNode {
  const [selectedTime, setSelectedTime] = useState<SlotPreferenceType | undefined>(undefined);
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);

  const handleBookCall = useCallback(() => {
    if (!selectedTime) setShouldShowError(true);
    else onBookCall({ slotPreference: selectedTime });
  }, [selectedTime, onBookCall]);

  const handleSelectTime = (slotPreference: SlotPreferenceType): void => {
    setShouldShowError(false);
    setSelectedTime(slotPreference);
  };

  return (
    <FlowValueProposition
      illustration={
        <StaticThemedAsset
          alt="Illustration of a concierge"
          assetPath="/illustrations/concierge/concierge.svg"
          className={styles.illustration}
        />
      }
      illustrationClassName={styles.flowillustration}
      mainClassName={styles.flowmain}
    >
      <form className={styles.container}>
        <section className={styles.proposal} role="main">
          <h1 className="title-1">
            <FormattedMessage id="subscription.account-closing.book-a-call.title" />
          </h1>
          <p className="body-2">
            <FormattedMessage id="subscription.account-closing.book-a-call.subtitle" />
          </p>
        </section>
        <section className={styles.contact} role="listbox">
          <h2 className="title-4">
            <FormattedMessage id="subscription.account-closing.book-a-call.title2" />
          </h2>
          <p className={cx('body-1', styles.description)}>
            <FormattedMessage id="subscription.account-closing.book-a-call.subtitle2" />
          </p>
          <RadioGroup<SlotPreferenceType> onChange={handleSelectTime}>
            <RadioButton value={SlotPreferenceType.AM}>
              <FormattedMessage id="subscription.account-closing.book-a-call.reserve-am">
                {chunks => <span aria-label={SlotPreferenceType.AM}>{chunks}</span>}
              </FormattedMessage>
            </RadioButton>
            <RadioButton value={SlotPreferenceType.PM}>
              <FormattedMessage id="subscription.account-closing.book-a-call.reserve-pm">
                {chunks => <span aria-label={SlotPreferenceType.PM}>{chunks}</span>}
              </FormattedMessage>
            </RadioButton>
          </RadioGroup>
          {shouldShowError ? (
            <div className={styles.error} role="alert">
              <IconWarningSignOutlined />
              <FormattedMessage id="subscription.account-closing.book-a-call.reserve-error">
                {chunks => <span className="body-2">{chunks}</span>}
              </FormattedMessage>
            </div>
          ) : null}
        </section>
        <section className={styles.submit}>
          <Button data-close-button onPress={onClose} size="medium" variant="secondary">
            <FormattedMessage id="subscription.account-closing.book-a-call.btn-continue" />
          </Button>
          <Button data-book-a-call-button onPress={handleBookCall} size="medium" variant="primary">
            <FormattedMessage id="subscription.account-closing.book-a-call.btn-bookcall" />
          </Button>
        </section>
      </form>
    </FlowValueProposition>
  );
}
