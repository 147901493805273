import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function AttachmentWarningOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        fill="currentColor"
        d="M7.046 1.21a4.313 4.313 0 0 1 5.99 0c.4.39.71.84.93 1.35.21.51.33 1.05.33 1.61s-.12 1.09-.33 1.61c-.22.51-.54.97-.93 1.35l-4.35 4.2c-.89.87-2.43.87-3.34 0-.23-.22-.41-.47-.52-.77-.13-.29-.19-.6-.19-.91 0-.31.06-.62.19-.9.12-.29.29-.56.52-.78l3.21-3.11.17-.1.2-.03.19.04.16.12a.557.557 0 0 1-.02.78l-3.2 3.1c-.13.12-.22.26-.28.41a1.228 1.228 0 0 0 0 .97c.06.15.16.29.28.41.54.47 1.4.48 1.94-.01l4.34-4.2c.29-.28.52-.62.68-.99a3.048 3.048 0 0 0-.68-3.37C11.086.81 8.996.81 7.736 2L3.048 6.548a.529.529 0 1 1-.734-.761L7.046 1.21ZM15.456 9.69c0 .14-.06.27-.16.38l-4.34 4.19A6.199 6.199 0 0 1 6.636 16c-1.022 0-1.953-.283-2.874-.691a.548.548 0 0 1-.254-.721.53.53 0 0 1 .704-.258c.778.36 1.552.6 2.424.6 1.35 0 2.64-.51 3.62-1.46l4.34-4.2.17-.1.2-.03.19.04.17.12c.09.1.15.25.14.4l-.01-.01ZM1.65 15.82a.65.65 0 1 0 0-1.3.65.65 0 0 0 0 1.3ZM1.65 8c-.28 0-.5.22-.5.5v4.55c0 .28.22.5.5.5s.5-.22.5-.5V8.5c0-.28-.22-.5-.5-.5Z"
      />
    </svg>
  );
}
