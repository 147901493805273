import { type ReactNode, type SVGProps } from 'react';

export function IconFood(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.617 0a.5.5 0 0 1 .5.5v5.617c0 .401.361.787.885.787h.054V.503a.5.5 0 1 1 1 0v6.401h.059c.524 0 .885-.386.885-.787V.5a.5.5 0 0 1 1 0v5.617c0 1.02-.879 1.787-1.886 1.787h-.058v7.59a.5.5 0 1 1-1 0v-7.59h-.054c-1.006 0-1.885-.766-1.885-1.787V.5a.5.5 0 0 1 .5-.5ZM2.636 1.719C3.046.836 3.745.001 4.79.001c1.046 0 1.745.834 2.155 1.718.424.912.637 2.061.637 3.082 0 1.047-.277 1.867-.803 2.429-.41.438-.933.679-1.495.765V15.5a.5.5 0 0 1-1 0V7.993a2.525 2.525 0 0 1-1.482-.763C2.277 6.668 2 5.848 2 4.8c0-1.02.213-2.17.636-3.082Zm.907.421C3.19 2.897 3 3.898 3 4.801c0 .878.23 1.423.532 1.746.299.32.727.484 1.258.484.532 0 .96-.165 1.26-.484.301-.323.532-.868.532-1.746 0-.903-.192-1.904-.543-2.66C5.674 1.354 5.227 1 4.79 1c-.437 0-.883.353-1.247 1.139Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
