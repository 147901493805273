import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import styles from './styles.strict-module.css';

export function Header(): ReactNode {
  return (
    <>
      <th aria-hidden="true" className={cx(styles['header-cell'], styles.empty)} scope="col" />
      <th className={styles['header-cell']} data-test-table-header-statement scope="col">
        <FormattedMessage id="statements.list.columns.statement" />
      </th>
      <th className={styles['header-cell']} data-test-table-header-date scope="col">
        <FormattedMessage id="statements.list.columns.date" />
      </th>
      <th className={styles['header-cell']} data-test-table-header-account scope="col">
        <FormattedMessage id="statements.list.columns.account" />
      </th>
      <th className={styles['header-cell']} data-test-table-header-limit scope="col" />
      <th aria-hidden="true" className={cx(styles['header-cell'], styles.empty)} scope="col" />
    </>
  );
}
