import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import type { ReactNode } from 'react';
import { Spinner } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

interface RecommendationsHeaderProps {
  bankName: string;
  onClick: () => void;
  hasCtaSpinner?: boolean;
  className?: string;
}

export function ExternalBankRecommendationsHeader({
  bankName,
  onClick,
  hasCtaSpinner = false,
  className,
}: RecommendationsHeaderProps): ReactNode {
  return (
    <div className={cx(styles.wrapper, className)}>
      <div>
        <p className="title-3" data-test-recommendations-header-title>
          <FormattedMessage id="accounts.recommendation-header.title" />
        </p>
        <p className="body-2" data-test-recommendations-header-description>
          <FormattedMessage id="accounts.recommendation-header.subtitle" values={{ bankName }} />
        </p>
      </div>
      <button
        className={cx('btn', 'btn--tertiary', 'btn--small', 'body-1', styles['not-interested'])}
        data-test-recommendations-not-interested
        onClick={onClick}
        type="button"
      >
        <FormattedMessage id="accounts.recommendation-header.not-interested-cta" />

        {hasCtaSpinner ? (
          <Spinner
            className="ml-8"
            color="primary-a"
            data-test-recommendations-not-interested-spinner
            size="small"
          />
        ) : null}
      </button>
    </div>
  );
}
