import { type ReactNode } from 'react';

export function Speedometer(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="none">
      <path
        fill="currentColor"
        d="M6.274 9.888a.5.5 0 0 0 .732.68l-.732-.68Zm5.468-4.41a.5.5 0 1 0-.732-.681l.732.681ZM2.41 12.329a6.706 6.706 0 0 1-1.073-3.391l-1 .038a7.706 7.706 0 0 0 1.234 3.897l.84-.544ZM1.336 8.938a6.706 6.706 0 0 1 .813-3.464l-.879-.478a7.706 7.706 0 0 0-.933 3.98l1-.038Zm.813-3.464a6.706 6.706 0 0 1 2.469-2.56l-.51-.86A7.706 7.706 0 0 0 1.27 4.996l.879.478Zm2.469-2.56a6.706 6.706 0 0 1 3.431-.937l.002-1a7.706 7.706 0 0 0-3.943 1.077l.51.86Zm3.431-.937a6.706 6.706 0 0 1 3.428.95l.514-.86A7.706 7.706 0 0 0 8.05.978l-.002 1Zm3.428.95a6.706 6.706 0 0 1 2.46 2.569l.88-.476a7.706 7.706 0 0 0-2.826-2.952l-.514.858Zm2.46 2.569c.575 1.062.85 2.26.8 3.466l1 .041a7.706 7.706 0 0 0-.92-3.983l-.88.476Zm.8 3.466a6.706 6.706 0 0 1-1.085 3.387l.838.547a7.707 7.707 0 0 0 1.247-3.893l-1-.041ZM3.394 5.557l-1.622-.92-.493.869 1.622.92.493-.87Zm2.244-1.842L4.701 2.12l-.862.506.936 1.594.862-.506ZM7.52 1.387v1.84h1v-1.84h-1Zm3.819.734-.937 1.594.863.506.936-1.594-.863-.506Zm2.929 2.515-1.622.92.494.87 1.622-.92-.494-.87Zm1.252 3.62h-1.874v1h1.874v-1Zm-.759 3.748-1.622-.92-.493.87 1.622.92.493-.87Zm-12.99.87 1.622-.92-.493-.87-1.622.92.493.87Zm.622-4.618H.52v1h1.873v-1Zm4.613 2.313.733-.787-.733-.682-.732.788.732.68Zm2.27-2.44 2.466-2.651-.732-.681-2.467 2.652.732.68Zm-.473.519a.675.675 0 0 1-.675.675v1c.925 0 1.675-.75 1.675-1.675h-1Zm-.675.675a.675.675 0 0 1-.675-.675h-1c0 .925.75 1.675 1.675 1.675v-1Zm-.675-.675c0-.373.302-.675.675-.675v-1c-.925 0-1.675.75-1.675 1.675h1Zm.675-.675c.373 0 .675.302.675.675h1c0-.925-.75-1.675-1.675-1.675v1ZM5.77 14.675h4.737v-1H5.77v1Zm5.026.29v.79h1v-.79h-1Zm-.29 1.079H5.772v1h4.737v-1Zm-5.026-.29v-.789h-1v.79h1Zm.29.29a.29.29 0 0 1-.29-.29h-1c0 .713.577 1.29 1.29 1.29v-1Zm5.026-.29c0 .16-.13.29-.29.29v1a1.29 1.29 0 0 0 1.29-1.29h-1Zm-.29-1.079c.16 0 .29.13.29.29h1a1.29 1.29 0 0 0-1.29-1.29v1Zm-4.736-1a1.29 1.29 0 0 0-1.29 1.29h1c0-.16.13-.29.29-.29v-1Z"
      />
    </svg>
  );
}
