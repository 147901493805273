import { type ReactNode } from 'react';

export function AttachmentOutlined(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
      <path
        fill="currentColor"
        d="M15.454 9.69c0 .14-.06.27-.16.38l-4.34 4.19A6.199 6.199 0 0 1 6.634 16a6.21 6.21 0 0 1-4.31-1.73 5.996 5.996 0 0 1-1.33-1.94c-.3-.73-.46-1.51-.46-2.3 0-.79.16-1.57.46-2.31.31-.73.77-1.38 1.33-1.94l4.72-4.57a4.313 4.313 0 0 1 5.99 0c.4.39.71.84.93 1.35.21.51.33 1.05.33 1.61s-.12 1.09-.33 1.61c-.22.51-.54.97-.93 1.35l-4.35 4.2c-.89.87-2.43.87-3.34 0-.23-.22-.41-.47-.52-.77-.13-.29-.19-.6-.19-.91 0-.31.06-.62.19-.9.12-.29.29-.56.52-.78l3.21-3.11.17-.1.2-.03.19.04.16.12a.557.557 0 0 1-.02.78l-3.2 3.1c-.13.12-.22.26-.28.41a1.228 1.228 0 0 0 0 .97c.06.15.16.29.28.41.54.47 1.4.48 1.94-.01l4.34-4.2c.29-.28.52-.62.68-.99a3.048 3.048 0 0 0-.68-3.37C11.084.81 8.994.81 7.734 2l-4.71 4.58c-.47.45-.84.98-1.1 1.57-.25.6-.38 1.23-.38 1.88 0 .65.13 1.28.38 1.88.26.6.63 1.12 1.1 1.57.98.93 2.26 1.45 3.61 1.45s2.64-.51 3.62-1.46l4.34-4.2.17-.1.2-.03.19.04.17.12c.09.1.15.25.14.4l-.01-.01Z"
      />
    </svg>
  );
}
