import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function PlusRoundedOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M8 1c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.14-7-7 3.14-7 7-7Zm0-1C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.55 8.52H8.52v3.02c0 .29-.23.52-.51.52-.14 0-.27-.06-.36-.15a.475.475 0 0 1-.15-.36V8.51H4.45a.524.524 0 0 1-.36-.16.515.515 0 0 1-.15-.36c0-.28.23-.51.52-.51h3.03V4.45c0-.28.22-.51.5-.51s.51.23.51.51l.02 3.04h3.03c.28.01.51.23.51.52 0 .29-.23.51-.51.51Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
