import { useDeepMemo } from 'qonto/react/hooks/use-deep-memo';
import {
  DEFAULT_TIMESERIES,
  type CashflowTimeseries,
} from 'qonto/react/models/cash-flow-timeseries';
import { normalizeTimeframeBalances } from '../utils/normalize-balances';
import { transposeBalances } from '../utils/transpose-balances';
import type { TransposedData } from '../utils/transpose-table-data';

export const useTimeseriesBalances = (
  offset: number,
  displayedMonths: number,
  timeseries: CashflowTimeseries = DEFAULT_TIMESERIES
): { startBalances: TransposedData[]; endBalances: TransposedData[] } => {
  const selectedBalances = useDeepMemo(
    () => timeseries.timeframes.slice(offset, offset + displayedMonths),
    [timeseries, offset, displayedMonths]
  );

  const normalizedBalances = normalizeTimeframeBalances(selectedBalances);
  const { startBalances, endBalances } = transposeBalances(normalizedBalances);

  return { startBalances, endBalances };
};
