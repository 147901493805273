import { Badge, type CountryCode, Flag } from '@repo/design-system-kit';
import cx from 'clsx';
import type { ComponentProps, ComponentPropsWithoutRef, ReactNode } from 'react';
import styles from './styles.strict-module.css';

interface CountryType {
  code: CountryCode;
  displayName?: string;
}

interface CountryProps extends ComponentPropsWithoutRef<'div'> {
  country: CountryType;
  badgeOptions?: Pick<ComponentProps<typeof Badge>, 'type' | 'text'>;
  isoCodeOnly?: boolean;
}

export function Country({
  country: { code, displayName },
  badgeOptions,
  isoCodeOnly = false,
  className,
  ...props
}: CountryProps): ReactNode {
  const formattedCountryName =
    isoCodeOnly || !displayName || code === displayName ? code : displayName;

  return (
    <div className={cx(className, styles.country)} data-test-country={code} {...props}>
      <Flag className={styles.flag} code={code} data-test-country-flag={code} size="small" />

      <span data-test-country-name>{formattedCountryName}</span>

      {badgeOptions ? (
        <Badge className={styles.badge} {...badgeOptions} data-test-country-badge />
      ) : null}
    </div>
  );
}
