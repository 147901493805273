import { useEmberService, useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedNumber } from 'react-intl';
import type { ReactNode } from 'react';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import type { LabelList } from 'qonto/react/models/label';
import type Organization from 'qonto/models/organization';
import type { LabelTableInterval } from '../../models/labels-cashflow-display';
import {
  type FilterExpression,
  type FilterGroup,
  FilterConditional,
  FilterExpressionOperator,
} from '../../../../models/filters';
import styles from './styles.strict-module.css';

interface BalanceCellProps {
  amount: string | undefined;
  interval: LabelTableInterval | undefined;
  labelEntityId: string;
  type: 'label' | 'labelsList';
}

interface OrganizationManager {
  organization: Organization | null;
}

export function BalanceCell({
  amount,
  labelEntityId,
  type,
  interval,
}: BalanceCellProps): ReactNode {
  const router = useRouter();
  const segment = useEmberService('segment');
  const { organization } = useOrganizationManager() as OrganizationManager;

  const labelLists = (organization?.labelLists ?? []) as LabelList[];
  const number = Number(amount);

  if (!amount || isNaN(number)) {
    return <span data-testid="balance-cell">-</span>;
  }

  const handleClick = (): void => {
    setTransactionsFilter(labelEntityId, type, interval, labelLists, organization?.id);
    segment.track('cash-flow_cell_view-transactions', {
      type,
      isUnlabeled: labelEntityId === 'unlabeled',
    });
    void router.push(`/organizations/${organization?.slug ?? ''}/transactions`);
  };

  return (
    <button
      className={styles.balanceButton}
      data-testid="balance-cell"
      onClick={handleClick}
      type="button"
    >
      <FormattedNumber currency="EUR" style="currency" value={number} />
    </button>
  );
}

function setTransactionsFilter(
  labelEntityId: string,
  type: 'label' | 'labelsList',
  interval: LabelTableInterval | undefined,
  labelLists: LabelList[] | undefined,
  organizationId: string | undefined
): void {
  const property = type === 'label' ? 'label_ids' : 'label_list_ids';
  const isUnlabeled = type === 'labelsList' && labelEntityId === 'unlabeled';
  const expressions = [] as FilterExpression[];

  if (isUnlabeled) {
    labelLists?.forEach(labelList => {
      expressions.push({
        property,
        operator: FilterExpressionOperator.NotIn,
        values: [labelList.id],
      });
    });
  } else {
    expressions.push({
      property,
      operator: FilterExpressionOperator.In,
      values: [labelEntityId],
    });
  }

  if (interval) {
    expressions.push({
      property: 'emitted_at',
      operator: FilterExpressionOperator.Within,
      values: [interval.start, interval.end],
    });
  }

  const filterGroup: FilterGroup = {
    conditional: FilterConditional.And,
    expressions: [
      {
        conditional: FilterConditional.And,
        expressions,
      },
    ],
  };

  localStorage.setItem(
    `${organizationId ?? ''}-transactions-filter-group`,
    JSON.stringify(filterGroup)
  );
}
