import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function PlusComment(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.96 13.34c-.18-.05-.37-.04-.55.01l-2.85.9.44-1.72a.997.997 0 0 0-.27-.97C1.63 10.5 1 9.13 1 7.66c0-3.13 2.99-5.91 7-5.91s7 2.78 7 5.92-2.99 5.92-7 5.92c-.71 0-1.39-.09-2.04-.26v.01Zm-3.92-1.05C.77 11.07 0 9.45 0 7.67 0 3.85 3.58.75 8 .75s8 3.1 8 6.92-3.58 6.92-8 6.92c-.79 0-1.56-.1-2.29-.29l-2.85.9A.996.996 0 0 1 1.59 14l.44-1.72.01.01ZM8 4.25c-.28 0-.5.22-.5.5v2.5H5c-.28 0-.5.22-.5.5s.22.5.5.5h2.5v2.5c0 .28.22.5.5.5s.5-.22.5-.5v-2.5H11c.28 0 .5-.22.5-.5s-.22-.5-.5-.5H8.5v-2.5c0-.28-.22-.5-.5-.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
